import { FC, useCallback } from 'react';
import { useCheckEmptyDDAccounts } from '../hooks/use-check-empty-dd-accounts';
import { EmptyDDModal } from './empty-dd-modal';
import { useDispatch, useSelector } from 'react-redux';
import { accountsSlice } from '../store/accounts.slice';
import { IAccount } from '../types/account';
import { accountsSelector } from '../store/accounts.selector';

export const EmptyDDAccounts: FC = () => {
    const editingAccountId = useSelector(accountsSelector.updateAccountId);
    const { emptyDDAccounts } = useCheckEmptyDDAccounts();
    const dispatch = useDispatch();

    const onEnabledDDClick = useCallback((account: IAccount) => {
        dispatch(accountsSlice.actions.accountEditOpened(account.id))
    }, [dispatch]);

    if (emptyDDAccounts?.length && !editingAccountId) {
        return (
            <EmptyDDModal isOpen={true} onOk={() => onEnabledDDClick(emptyDDAccounts[0])} />
        );
    }

    return null;
}

