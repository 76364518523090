import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { AxiosError } from 'axios';
import { ProviderEventLogs } from '../types/provider-event-logs';
import { ReactPaginateProps } from 'react-paginate';
import { MarketplaceProviderLogsMapper } from '../mappers/marketplace-provider-logs.mapper';

export type GetProviderEventLogsData = {
  data: ProviderEventLogs[];
  pagination: ReactPaginateProps;
};

export type GetProviderEventLogsResponse = IHttpResponse<200, GetProviderEventLogsData> | IHttpResponse<400, string>;

export type ProviderEventLogsParams = {
  page: number;
  perPage: number;
  providerId: string;
};

export const getProviderEventLogs = async ({
  page,
  perPage,
  providerId,
}: ProviderEventLogsParams): Promise<GetProviderEventLogsResponse> => {
  try {
    const query = stringifyParams({
      page,
      size: perPage,
    });

    const response = await fetcher.get(`/trade-ideas/providers/${providerId}/event-logs?${query}`);

    return {
      status: 200,
      payload: {
        data: response.data.data?.map((item) => MarketplaceProviderLogsMapper.toDomain(item)) || [],
        pagination: {
          pageCount: response.data.total / perPage > 1 ? Math.ceil(response.data.total / perPage) : 1,
        },
      },
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
