import { ORDER_STATUS_CANCELED, ORDER_STATUS_ERROR, ORDER_STATUS_OPEN, ORDER_STATUS_QUEUED } from '@3lgn/shared/dist/constants/trading_bot';
import { Text } from '@root/shared/ui/typography/text';
import styled from 'styled-components';

export const TPDot = styled.div<{ reached: boolean; closed: boolean }>`
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    background: ${({ theme, reached, closed }) => (reached ? theme.pink[500] : closed ? theme.primary[500] : theme.warning[500])};
    border-radius: 50%;
  }
`;

export const BuySellBadge = styled.div<{ isSell: boolean }>`
  padding: 2px 12px;
  border-radius: 15px;
  background: ${({ theme }) => theme.grayscale[700]};
  color: ${({ theme, isSell }) => isSell ? theme.danger[500] : theme.success[400]};
  text-center;
  width: min-content;
`;


export const OrderStatusDot = styled.div<{ status: string }>`
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    background: ${({ theme, status }) => {
    if (status === ORDER_STATUS_CANCELED || status === ORDER_STATUS_ERROR) {
      return theme.danger[500];
    }
    if (status === ORDER_STATUS_OPEN || status === ORDER_STATUS_QUEUED) {
      return theme.primary[500];
    }
    return theme.success[500];
  }};
    border-radius: 50%;
  }
`;

export const IDText = styled(Text)`
  max-width: 100px;
`;
