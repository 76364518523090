import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { purchaseSubscriptionPlan } from '../services/purchase-subscription-plan.service';
import { SubscriptionPeriod } from '../types/subscription-period';
import { SubscriptionProductType } from '../types/subscription-product-type';
import { SubscriptionInfo, SubscriptionProduct } from '../types/subscription-products';
import { useGetSubscriptionProducts } from './use-get-subscription-products';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetActiveSubscriptions } from './use-get-active-subscriptions';
import { getPaymentUrl } from '../services/get-payment-url.service';
import { cancelSubscription } from '../services/cancel-subscription.service';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, authSlice } from '@root/shared-files/modules/auth/store';
import { reactivateSubscription } from '../services/reactivate-subscription.service';
import { IS_UPGRADED_SUBSCRIPTION, SHOULD_REFRESH_TOKEN, SUBSCRIPTION_PURCHASE_URL } from '@root/shared-files/modules/shared/constants/local-storage-keys';

export const useSubscriptionProducts = () => {
  const [period, setPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.Yearly);

  const { data: activeSubscriptions, refetch } = useGetActiveSubscriptions();

  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionProduct | null>(null);
  const [retryPaymentLoading, setRetryPaymentLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const { data, isLoading } = useGetSubscriptionProducts();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const [reactivateLoading, setReactivateLoading] = useState<boolean>(false);
  const userSubscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const [, setSubscriptionPurchaseUrl] = useLocalStorage<string>(SUBSCRIPTION_PURCHASE_URL, '');
  const { t } = useTranslation('subscriptions');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const id = searchParams.get('id');

  const subscriptions = useMemo(() => {
    if (data) {
      return {
        [SubscriptionProductType.Trial]: data.find((item) => item.type === SubscriptionProductType.Trial),
        [SubscriptionProductType.Basic]: data.find((item) => item.type === SubscriptionProductType.Basic),
        [SubscriptionProductType.Advanced]: data.find((item) => item.type === SubscriptionProductType.Advanced),
        [SubscriptionProductType.Pro]: data.find((item) => item.type === SubscriptionProductType.Pro),
      };
    }
  }, [data]);

  const { currentSubscription, activeSubscription } = useMemo(() => {
    if (subscriptions && activeSubscriptions) {
      let activeSubscription: SubscriptionInfo | null = null;
      let subscription: SubscriptionProduct | null = null;
      activeSubscriptions.forEach((item) => {
        Object.values(subscriptions).forEach((sb) =>
          sb?.plans.forEach((plan) => {
            // if (plan.id === item.planId && !activeSubscription && !subscription) {
            //   activeSubscription = item;
            //   subscription = sb;
            // }
            if (plan.id === item.planId) {
              if (
                (!activeSubscription && !subscription) ||
                activeSubscription?.gatewaySubscription?.status === 'canceled'
              ) {
                activeSubscription = item;
                subscription = sb;
              }
            }
          }),
        );
      });

      return {
        currentSubscription: (subscription || null) as SubscriptionProduct | null,
        activeSubscription: activeSubscription as SubscriptionInfo | null,
      };
    }

    return {
      currentSubscription: null,
      activeSubscription: null,
    };
  }, [subscriptions, activeSubscriptions]);

  const { canDowngrade, canUpgrade } = useMemo(() => {
    const canDowngrade = {
      [SubscriptionProductType.Trial]: false,
      [SubscriptionProductType.Basic]: false,
      [SubscriptionProductType.Advanced]: false,
      [SubscriptionProductType.Pro]: false,
    };

    const canUpgrade = {
      [SubscriptionProductType.Trial]: false,
      [SubscriptionProductType.Basic]: false,
      [SubscriptionProductType.Advanced]: false,
      [SubscriptionProductType.Pro]: false,
    };

    // if (!currentSubscription) {
    //   return { canDowngrade, canUpgrade };
    // }

    // if (currentSubscription?.type === SubscriptionProductType.Pro) {
    //   canDowngrade[SubscriptionProductType.Advanced] = true;
    //   canDowngrade[SubscriptionProductType.Basic] = true;
    // }

    // if (currentSubscription?.type === SubscriptionProductType.Advanced) {
    //   canDowngrade[SubscriptionProductType.Basic] = true;
    // }

    // if (currentSubscription?.type === SubscriptionProductType.Basic) {
    //   canUpgrade[SubscriptionProductType.Advanced] = true;
    //   canUpgrade[SubscriptionProductType.Pro] = true;
    // }

    // if (currentSubscription?.type === SubscriptionProductType.Advanced) {
    //   canUpgrade[SubscriptionProductType.Pro] = true;
    // }

    return { canDowngrade, canUpgrade };
  }, [currentSubscription]);

  const isSubscribedFromShop = useMemo(() => {
    if (!activeSubscriptions?.length && userSubscriptionInfo?.roles?.filter(role => (!role?.includes('forex') && !role?.includes('post subscription') && !role?.includes('provider'))).length) {
      return true;
    }
    return false;
  }, [activeSubscriptions, userSubscriptionInfo]);

  const handleSubscribeClick = useCallback((subscription: SubscriptionProduct) => {
    setSelectedSubscription(subscription);
    setConfirmationOpen(true);
  }, []);

  const handleCancelSubscription = useCallback(async () => {
    setCancelLoading(true);
    if (activeSubscription?.id) {
      const response = await cancelSubscription(activeSubscription.id);
      if (response.status === 200) {
        notify({
          title: t('actions.cancelSuccess'),
          type: 'success',
        });
        const timeout = setTimeout(() => {
          refetch();
          dispatch(authSlice.actions.refreshSession());
          clearTimeout(timeout);
        }, 3000);
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }
    setCancelOpen(false);
    setCancelLoading(false);
  }, [activeSubscription?.id, dispatch, refetch, t]);

  const handleReactivateSubscription = useCallback(async () => {
    setReactivateLoading(true);
    if (activeSubscription?.id) {
      const response = await reactivateSubscription(activeSubscription.id);
      if (response.status === 200) {
        notify({
          title: t('actions.renewSuccess'),
          type: 'success',
        });
        const timeout = setTimeout(() => {
          refetch();
          dispatch(authSlice.actions.refreshSession());
          clearTimeout(timeout);
        }, 3000);
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }
    setReactivateLoading(false);
  }, [activeSubscription?.id, dispatch, refetch, t]);

  const handleRetryPayment = useCallback(async () => {
    setRetryPaymentLoading(true);
    const response = await getPaymentUrl(activeSubscription?.gatewaySubscription?.id || '');
    if (response.status === 200) {
      localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
      window.location.href = response.payload.paymentUrl;
    } else {
      notify({
        title: response.payload,
        type: 'danger',
      });
    }
    setRetryPaymentLoading(false);
  }, [activeSubscription]);

  const handleConfirmationClick = useCallback(async () => {
    setPurchaseLoading(true);
    if (selectedSubscription && selectedSubscription.plans?.length) {
      const suitablePlan =
        selectedSubscription.type === SubscriptionProductType.Trial
          ? selectedSubscription.plans?.[0]
          : selectedSubscription.plans.find((item) => {
              if (period === SubscriptionPeriod.Monthly) {
                return item.recurringIntervalUnit === 'month';
              } else {
                return item.recurringIntervalUnit === 'year';
              }
            });

      if (suitablePlan) {
        const planId = suitablePlan?.id;
        const response = await purchaseSubscriptionPlan(planId);
        if (response.status === 200 && response.payload?.purchaseType === 'downgrade') {
          notify({
            title: t('actions.downgradeSuccess'),
            type: 'success',
          });

          setConfirmationOpen(false);
          setSelectedSubscription(null);
          const timeout = setTimeout(() => {
            refetch();
            dispatch(authSlice.actions.refreshSession());
            clearTimeout(timeout);
          }, 3000);
        }

        if (response.status === 200) {
          const approvalUrl =
            response.payload?.data?.fields?.['_links']?.find((item) => item.rel === 'approvalUrl')?.href || '';
          if (approvalUrl) {
            setSubscriptionPurchaseUrl(approvalUrl);
            localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
            if (response.payload?.purchaseType === 'upgrade') {
              localStorage.setItem(IS_UPGRADED_SUBSCRIPTION, 'true');
            }
            window.location.href = approvalUrl;
          }
        } else {
          notify({ type: 'danger', title: response.payload });
        }
      } else {
        notify({ type: 'danger', title: t('noSuitablePlan') });
      }
    }
    setPurchaseLoading(false);
  }, [selectedSubscription, period, t, refetch, dispatch, setSubscriptionPurchaseUrl]);

  const handleSuccessClick = useCallback(() => {
    navigate(`/subscriptions`);
    setSuccessOpen(false);
  }, [navigate]);

  useEffect(() => {
    if (id && result === 'approved') {
      setSuccessOpen(true);
    }
  }, [id, result]);

  const state = {
    isSubscribedFromShop,
    activeSubscription,
    subscriptions,
    isLoading,
    period,
    selectedSubscription,
    currentSubscription,
    confirmationOpen,
    purchaseLoading,
    successOpen,
    retryPaymentLoading,
    cancelLoading,
    reactivateLoading,
    cancelOpen,
    canDowngrade,
    canUpgrade,
  };

  const handlers = {
    setPeriod,
    setSelectedSubscription,
    setConfirmationOpen,
    handleSubscribeClick,
    handleConfirmationClick,
    setPurchaseLoading,
    handleSuccessClick,
    handleCancelSubscription,
    handleRetryPayment,
    handleReactivateSubscription,
    setCancelOpen,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
