import { ExternalWebhookServices } from '@3lgn/shared/dist/constants/webhooks';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';

export type GetAlertUrlResposne = IHttpResponse<200, { url: string }> | IHttpResponse<400, string>;

export const getAlertUrl = async (service: ExternalWebhookServices): Promise<GetAlertUrlResposne> => {
  try {
    const response = await fetcher.get(`/deals_idea/url/${service}`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
