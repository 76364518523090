export const clearLocalCache = () => {
  caches
    ?.keys()
    .then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
