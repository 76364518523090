import infoImage from '@root/assets/images/cases/info.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Title, Text } from '@root/shared/ui/typography';
import { FC } from 'react';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

type Props = ModalProps & {
  loading: boolean;
};

export const MarketplaceRetryPaymentModal: FC<Props> = ({ isOpen, loading, onOk, onCancel }) => {
  const { t } = useTranslation('marketplace-trade-idea-modals');

  const { hideActions } = useGhostLogin();

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} footer={null}>
      <div className='flex flex-col items-center gap-8'>
        <div className='w-16 h-16'>
          <Image src={infoImage} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
        </div>
        <div>
          <Title level={5} className='font-bold text-center mb-4'>
            {t('retry.differentSubscriptionType')}
          </Title>
          <Text size='sm' className='text-center'>
            * {t('retry.cancelCurrentSubscription')}
          </Text>
        </div>
        <div className='w-full flex justify-between items-center'>
          <Button onClick={onCancel} variant='info' outlined>
            {t('retry.cancel')}
          </Button>
          <Button loading={loading} onClick={onOk} disabled={hideActions}>
            {t('retry.retryPayment')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
