import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { GET_ORDERS_HISTORY_COUNT } from '@root/modules/orders/queries/get-orders-history-count.query';

export const useGetHistoryCount = () => {
  const userId = useSelector(authSelector.userId);
  return useQuery(['orders-history-count'], GET_ORDERS_HISTORY_COUNT, {
    enabled: !!userId,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });
};
