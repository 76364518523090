import { ExternalWebhookServices, FxAlertEvent } from '@3lgn/shared/dist/constants/webhooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IExpert } from '@root/modules/experts/types/expert';
import { notify } from '@root/shared/utils/notification';
import { getAlertUrl } from '../services/get-alert-url.service';
import { Alert } from '../types/alert-form';
import { createAlertsValidation } from '../validations/alert.validation';

export const useAlertForm = (expert: IExpert) => {
  const { t } = useTranslation('forex-experts');

  const [url, setUrl] = useState<string>('');

  const initialValues = useMemo<Alert>(() => {
    const symbol = expert?.symbols.length ? expert?.symbols?.[0] : '';

    return {
      type: FxAlertEvent.START_LONG_MARKET_DEAL,
      symbol: symbol,
      expertSymbols: expert.symbols,
    };
  }, [expert]);

  const schema = useMemo(() => createAlertsValidation(t), [t]);

  const fetchAlertUrl = useCallback(async () => {
    const response = await getAlertUrl(ExternalWebhookServices.TRADINGVIEW);

    if (response.status === 200) {
      setUrl(response.payload.url);
    } else {
      notify({
        type: 'danger',
        title: response.payload,
      });
    }
  }, []);

  useEffect(() => {
    fetchAlertUrl();
  }, [fetchAlertUrl]);

  const state = {
    url,
    schema,
    initialValues,
  };

  const handlers = {
    setUrl,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
