import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ResetDrawdownLimitResponse = IHttpResponse<200, IExpert> | IHttpResponse<400, string>;

export const resetDrawdownLimit = async (expertId: string): Promise<ResetDrawdownLimitResponse> => {
  try {
    const response = await fetcher.post(`/experts/${expertId}/reset-draw-down-limit`, undefined, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response?.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
