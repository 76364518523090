import warningImg from '@root/assets/images/cases/warning.webp';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';
import { FC } from 'react';

type Props = ModalProps;

export const EmptyDDModal: FC<Props> = ({ isOpen, onOk }) => {
    const { t } = useTranslation('forex-accounts');

    return (
        <Modal isOpen={isOpen} onCancel={onOk} footer={null}  className='!pt-0 !max-w-[800px]'>
            <div className='flex flex-col items-center gap-8'>
                <div className='w-20 h-20'>
                    <Image src={warningImg} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
                </div>
                <div>
                    <Title level={5} className='font-bold text-center'>
                        {t('emptyDDAccount.title')}
                    </Title>
                    <Text className='text-center mt-4 text-gray-400'>{t('emptyDDAccount.text')}</Text>
                    <Text className='mt-4 text-gray-400 font-semibold'>{t('emptyDDAccount.reasons.title')}</Text>
                    <ul className='list-disc pl-6 mb-2'>
                        <li>
                            <Text size='sm'>
                                <Trans i18nKey='forex-accounts:emptyDDAccount.reasons.bullets.0' components={{ b: <b /> }} />
                            </Text>
                        </li>
                        <li>
                            <Text size='sm'>
                                <Trans i18nKey='forex-accounts:emptyDDAccount.reasons.bullets.1' components={{ b: <b /> }} />
                            </Text>
                        </li>
                        <li>
                            <Text size='sm'>
                                <Trans i18nKey='forex-accounts:emptyDDAccount.reasons.bullets.2' components={{ b: <b /> }} />
                            </Text>
                        </li>
                    </ul>

                </div>
                <div className='w-full flex justify-center items-center'>
                    <Button onClick={onOk}>
                        {t('emptyDDAccount.ok')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
