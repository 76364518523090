import dayjs from 'dayjs';
import isNull from 'lodash/isNull';

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { settingsSelector } from '@root/modules/app-settings/store/settings.selector';
import { FullStatsData, StatItem } from '@root/modules/orders/types/statistic';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';
import { getGainLostColor, getPrefix } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';


const { Wrapper, Head, Row, HeadCellSlim, Body, DataCellSuperSlim } = Table;

const nullReplacer = (val) => (isNull(val) ? '-' : val);

type Props = {
  data: Omit<FullStatsData, 'stats'>;
  loading: boolean;
  currency: string;
  isCustomRange: boolean;
  fromDate?: Date;
  toDate?: Date;
};

export const StatisticTable: FC<Props> = ({ data, currency, loading, isCustomRange, fromDate, toDate }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'generalStatistics' });
  const isBalanceVisible = useSelector(settingsSelector.isBalanceVisible);
  const customRangeTitle = useMemo(() => {
    if (!isCustomRange) return null;
    const fromDateString = dayjs(fromDate).format('DD.MM.YYYY');
    const toDateString = dayjs(toDate).format('DD.MM.YYYY');
    return `${fromDateString}-${toDateString}`;
  }, [isCustomRange, fromDate, toDate]);

  return (
    <Wrapper>
      <colgroup>
        <col />
        <col className='text-center' />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <Head>
        <Row>
          <HeadCellSlim className='font-bold !text-light-100 pl-0'>{t('profits')}</HeadCellSlim>
          <HeadCellSlim>
            {t('gainLabel')}({t('difference')})
          </HeadCellSlim>
          <HeadCellSlim>
            {t('profit')}({t('difference')})
          </HeadCellSlim>
          <HeadCellSlim>
            {t('win')} % ({t('difference')})
          </HeadCellSlim>
          <HeadCellSlim>
            {t('trades')}({t('difference')})
          </HeadCellSlim>
          <HeadCellSlim>
            {t('lots')}({t('difference')})
          </HeadCellSlim>
        </Row>
      </Head>
      <Body>
        {loading ? (
          <SkeletonTable columns={7} rows={5} />
        ) : (
          (isCustomRange ? ['custom'] : ['today', 'week', 'month', 'year']).map((key) => {
            const statItem: StatItem = data[key];
            const { gain, gainPrev, lots, lotsPrev, profit, profitPrev, trades, tradesPrev, winPercent, winPercentPrev } = statItem || {};
            return (
              <Row key={key} className='border-t border-gray-700 text-sm'>
                <DataCellSuperSlim className='pl-0'>{isCustomRange ? customRangeTitle : t(key)}</DataCellSuperSlim>
                <DataCellSuperSlim>
                  {gain === -100 && <span className={getGainLostColor(0)}>- (-)</span>}
                  {gain !== -100 && (
                    <>
                      <span className={getGainLostColor(gain)}>{gain ? `${getPrefix(gain)}${globalRound(Math.abs(gain), 2)}%` : nullReplacer(gain)}</span>{' '}
                      <span>({!isNull(gainPrev) ? `${getPrefix(gain - gainPrev)}${globalRound(Math.abs(gain - gainPrev), 2)}%` : nullReplacer(gainPrev)})</span>
                    </>
                  )}
                </DataCellSuperSlim>
                <DataCellSuperSlim>
                  {isBalanceVisible ? (
                    <>
                      <span className={getGainLostColor(profit)}>{profit ? `${getPrefix(profit)}${currency}${globalRound(Math.abs(profit), 2)}` : nullReplacer(profit)}</span>{' '}
                      <span>
                        ({!isNull(profitPrev) ? `${getPrefix(profit - profitPrev)}${currency}${globalRound(Math.abs(profit - profitPrev), 2)}` : nullReplacer(profitPrev)})
                      </span>
                    </>
                  ) : (
                    <span>- (-)</span>
                  )}
                </DataCellSuperSlim>
                <DataCellSuperSlim>
                  {winPercent ? `${globalRound(Math.round(Math.abs(winPercent)), 0)}%` : nullReplacer(winPercent)} (
                  {!isNull(winPercentPrev)
                    ? `${getPrefix(winPercent - winPercentPrev)}${globalRound(Math.abs(Math.round(winPercent - winPercentPrev)), 0)}%`
                    : nullReplacer(winPercentPrev)}
                  )
                </DataCellSuperSlim>
                <DataCellSuperSlim>
                  {trades || nullReplacer(trades)} ({!isNull(tradesPrev) ? `${getPrefix(trades - tradesPrev)}${Math.abs(trades - tradesPrev)}` : nullReplacer(tradesPrev)})
                </DataCellSuperSlim>
                <DataCellSuperSlim>
                  {globalRound(lots, 2) || nullReplacer(lots)} (
                  {!isNull(lotsPrev) ? `${getPrefix(lots - lotsPrev)}${globalRound(Math.abs(lots - lotsPrev), 2)}` : nullReplacer(lotsPrev)})
                </DataCellSuperSlim>
              </Row>
            );
          })
        )}
      </Body>
    </Wrapper>
  );
};
