import { useCallback, useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { IAccount } from '@root/modules/accounts';
import { AccountStatus } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { getOpenOrdersService } from '@root/modules/orders/services/get-open-orders.service';
import { IOrder } from '@root/modules/orders/types/orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { Id } from '@root/shared/utils/types';

export const useGetOpenOrders = ({ enabled, selectedBroker, mtType }) => {
  const { data: accounts } = useGetAccounts();
  const quotesData = useSelector(quotesSelector.quotesData);
  const balancesCurrency = useSelector(accountsSelector.balanceCurrency);

  const { data } = useQuery<Array<Record<string, IOrder[]>>>(['open-orders'], async () => Promise.resolve([]), {
    enabled: enabled,
  });

  const socketOrders = enabled ? data : [];

  const openOrdersQueries = useQueries(
    ((accounts as IAccount[]) || [])
      ?.filter((item) => (selectedBroker === 'all' ? true : selectedBroker === item.id))
      .map((account) => {
        return {
          queryKey: ['fx', account?.id as Id, 'open-orders'],
          queryFn: () => getOpenOrdersService({ accountId: account?.id }),
          enabled: enabled && account?.status === AccountStatus.Connected,
          keepPreviousData: true,
        };
      }),
  );

  const brokerIdByAccountId = useMemo(() => {
    return (accounts as IAccount[])?.reduce((acc, item) => {
      acc[item.id] = item.companyId;
      return acc;
    }, {});
  }, [accounts]);

  const socketOrderList = socketOrders
    ?.flatMap((item) => Object.values(item).flat())
    .map((item) => OpenOrdersDtoMapper.toDomain(item, undefined, undefined, balancesCurrency[item.accountId]))
    ?.filter((item) => {
      if (mtType) {
        const accountId = item?.accountId;
        const account = accounts?.find((acc) => acc.id === accountId);
        if (account?.mtType !== mtType) {
          return false;
        }
        return true;
      }
      return true;
    });

  const dataByCurrency = socketOrders?.flat().reduce((acc, val) => ({ ...acc, ...val }), {});

  const socketTickets = socketOrders?.flatMap((item) => Object.values(item).flat()).map((item) => item?.ticket);

  const list = openOrdersQueries
    ?.flatMap((query) => (query?.data?.status === 200 ? query?.data?.payload : []))
    ?.filter((item) => !socketTickets?.includes(item?.ticket))
    ?.filter((item) => {
      if (mtType) {
        const accountId = item?.accountId;
        const account = accounts?.find((acc) => acc.id === accountId);
        if (account?.mtType !== mtType) {
          return false;
        }
        return true;
      }
      return true;
    });

  const openOrdersWithCurrentPrice = [...(socketOrderList || []), ...(list || [])]
    ?.filter((item) => (selectedBroker === 'all' ? item : selectedBroker === item?.accountId))
    ?.filter((item) => {
      const ordersListBySymbol = dataByCurrency?.[item.symbol];
      if (ordersListBySymbol?.length) {
        return ordersListBySymbol.some((socketOrder) => socketOrder.ticket === item.ticket);
      }
      return true;
    })
    ?.map((order) => {
      return OpenOrdersDtoMapper.addCurrentPrice(order, quotesData?.[brokerIdByAccountId?.[order?.accountId]]?.[order.symbol]);
    });

  const invalidateOpenOrderQueries = useCallback(() => {
    ((accounts as IAccount[]) || []).map((account) => {
      queryClient.invalidateQueries(['fx', account?.id as Id, 'open-orders'], { exact: true });
    });
  }, [accounts]);

  return { openOrders: enabled ? openOrdersWithCurrentPrice : [], invalidateOpenOrderQueries };
};
