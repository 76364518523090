import { authSelector } from '@root/shared-files/modules/auth/store';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@root/shared/icons/bell-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { HidePasswordIcon } from '@root/shared/icons/hide-password-icon';
import { LogoutIcon } from '@root/shared/icons/log-out';
import { SettingsIcon } from '@root/shared/icons/settings-icon';
import { ShowPasswordIcon } from '@root/shared/icons/show-password-icon';
import { StackIcon } from '@root/shared/icons/stack';
import { Text } from '@root/shared/ui/typography';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDropdown } from '../hooks/use-dropdown';
import { useSubscriptionTitles } from '../hooks/use-subscription-titles';
import { HeaderElements, MenuItem } from './header.elements';
import { CheckTwoFaBadge } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';

const { Button } = HeaderElements;

export type DropdownProps = {
  wrapperClassName?: string;
};

const DropdownContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  z-index: 1000;
  background: ${({ theme }) => theme.gray[800]};
  box-shadow: 0px 10px 24px rgba(12, 12, 12, 0.6);
`;

const Wrapper = styled.div`
  width: 255px;
`;

const UserInfo: FC<DropdownProps & { isOpen: boolean }> = ({ wrapperClassName, isOpen }) => {
  const { title, subTitle, icon, className } = useSubscriptionTitles();
  const Icon = icon;

  return (
    <div className={clsx('text-left text-xs relative flex justify-between items-center gap-4 w-full', wrapperClassName)}>
      <div className='flex items-center gap-x-4 overflow-hidden'>
        <Button>
          <BellIcon className='text-base' />
        </Button>
        <span className='flex flex-col'>
          <span className='font-medium text-sm whitespace-nowrap text-ellipsis'>{title}</span>
          <div className='flex items-center gap-2'>
            {subTitle && (
              <div className={clsx('flex items-center gap-x-1', className)}>
                {Icon ? <Icon /> : null}
                <div className='mt-1'>{subTitle}</div>
              </div>
            )}
            <CheckTwoFaBadge />
          </div>
        </span>
      </div>
      <span className='ml-4 -mt-2'>
        <ChevronIcon className={clsx('transform transition-200', { 'rotate-180': !isOpen })} />
      </span>
    </div>
  );
};

export const HeaderDropdown: FC<DropdownProps> = (props) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const user = useSelector(authSelector.user);

  const [{ isBalanceVisible }, { hideBalance, logout }] = useDropdown();

  return (
    <Wrapper className='relative flex' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <div className='cursor-pointer p-4 w-full'>
        <UserInfo {...props} isOpen={isOpen} />
      </div>
      {isOpen && (
        <DropdownContent className='p-4 ml-4 z-50 w-full'>
          <UserInfo {...props} isOpen={isOpen} wrapperClassName='mb-2' />
          <div className='py-2 pl-8 border-t border-b border-gray-600'>
            <Text size='sm' className='font-medium text-ellipsis max-w-full overflow-hidden'>
              {user?.email as string}
            </Text>
          </div>
          {/* <MenuItem onClick={showNotifications}>
            <BellIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.notifications')}
            </Text>
          </MenuItem> */}
          <MenuItem onClick={hideBalance}>
            {isBalanceVisible ? (
              <>
                <ShowPasswordIcon />
                <Text size='sm' className='font-semibold'>
                  {t('header.dropdown.hideBalance')}
                </Text>
              </>
            ) : (
              <>
                <HidePasswordIcon />
                <Text size='sm' className='font-semibold'>
                  {t('header.dropdown.showBalance')}
                </Text>
              </>
            )}
          </MenuItem>
          <MenuItem link={'/accounts'}>
            <StackIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.brokerAccounts')}
            </Text>
          </MenuItem>
          {/* <MenuItem link={'/my-portfolios'}>
            <BagIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.myPortfolios')}
            </Text>
          </MenuItem> */}
          {/* <MenuItem link={'/subscription'}>
            <ScrollIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.subscription')}
            </Text>
          </MenuItem> */}
          {/* <MenuItem onClick={inviteFriends}>
            <TwoPeople />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.inviteFriends')}
            </Text>
          </MenuItem> */}
          {/* <MenuItem link={'/settings'}>
            <SettingsIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.settings')}
            </Text>
          </MenuItem> */}
          {/* <MenuItem link={'/exchanges/connect'}>
            <PaletteIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.api')}
            </Text>
          </MenuItem> */}
          <div className='flex justify-between items-center gap-2'>
            <MenuItem link={'/settings'}>
              <SettingsIcon />{' '}
              <Text size='sm' className='font-semibold'>
                {t('header.dropdown.settings')}
              </Text>
            </MenuItem>
            <CheckTwoFaBadge />
          </div>
          <MenuItem onClick={logout}>
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.logout')}
            </Text>
            <LogoutIcon />
          </MenuItem>
        </DropdownContent>
      )}
    </Wrapper>
  );
};
