import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

export type DeleteAccountServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export interface DeleteAccountServiceValues {
  userId: Id;
  accountId: string;
}

export const deleteAccountService = async ({ userId, accountId }: DeleteAccountServiceValues): Promise<DeleteAccountServiceResponse> => {
  try {
    await fetcher.delete(`/accounts/${accountId}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
