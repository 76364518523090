import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { UpdateAccountDto } from '../dtos/update-account.dto';
import { UpdateAccountDtoMapper } from '../mappers/update-account-dto.mapper';

export type UpdateAccountServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const updateAccountService = async (values: UpdateAccountDto): Promise<UpdateAccountServiceResponse> => {
  try {
    await fetcher.patch(`/accounts/${values.accountId}`, UpdateAccountDtoMapper.toPersistence(values), { baseURL: import.meta.env.VITE_FX_CORE_HOST });
    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
