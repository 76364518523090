import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ScannersIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='none' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.0098 5.99016C14.1985 5.8015 14.506 5.80037 14.6793 6.00323C15.5566 7.0303 16.0747 8.32169 16.1455 9.67954C16.2247 11.1988 15.7384 12.6935 14.7804 13.8752C13.8224 15.057 12.4606 15.842 10.9579 16.0788C9.45514 16.3156 7.91794 15.9874 6.64296 15.1575C5.36797 14.3276 4.44557 13.0549 4.05376 11.5849C3.66195 10.1149 3.82852 8.55195 4.5213 7.19757C5.21409 5.84318 6.38399 4.79341 7.80524 4.25084C9.07551 3.76591 10.4661 3.71687 11.7604 4.10331C12.016 4.17963 12.1395 4.46129 12.0433 4.71016C11.9472 4.95904 11.6679 5.08051 11.4112 5.00793C10.3409 4.70534 9.19687 4.75374 8.14981 5.15346C6.9517 5.61084 5.96548 6.4958 5.38146 7.63755C4.79744 8.77929 4.65703 10.0969 4.98732 11.3361C5.31761 12.5753 6.0952 13.6482 7.17001 14.3478C8.24483 15.0474 9.54068 15.3241 10.8075 15.1244C12.0743 14.9248 13.2223 14.263 14.0299 13.2668C14.8375 12.2706 15.2474 11.0106 15.1806 9.72985C15.1223 8.61061 14.7034 7.54492 13.9935 6.68873C13.8232 6.48336 13.8212 6.17881 14.0098 5.99016Z'
        fill='currentColor'
      />
      <path
        d='M16.4529 3.09547C16.6601 2.87376 17.0091 2.86103 17.2191 3.08008C18.7951 4.72418 19.7668 6.86209 19.9631 9.14212C20.1752 11.6051 19.4675 14.0593 17.9765 16.0312C16.4855 18.0031 14.317 19.3528 11.8895 19.8199C9.46189 20.287 6.94736 19.8383 4.83112 18.5605C2.71487 17.2827 1.14694 15.2664 0.429901 12.9005C-0.287137 10.5347 -0.102452 7.98715 0.948315 5.74949C1.99908 3.51183 3.84145 1.74268 6.11986 0.78347C8.22903 -0.104489 10.5735 -0.240099 12.7623 0.389086C13.054 0.472919 13.2052 0.787657 13.1055 1.07426L13.0687 1.1801C12.9689 1.4667 12.6561 1.61642 12.3638 1.5348C10.4584 1.00267 8.42295 1.12778 6.58973 1.89956C4.58723 2.74261 2.96797 4.29752 2.04445 6.26421C1.12092 8.2309 0.958603 10.47 1.58881 12.5493C2.21902 14.6286 3.59708 16.4008 5.45705 17.5239C7.31702 18.6469 9.52706 19.0413 11.6607 18.6307C13.7942 18.2202 15.7002 17.0339 17.0106 15.3008C18.321 13.5678 18.943 11.4107 18.7566 9.24601C18.586 7.26429 17.7489 5.40473 16.3907 3.96627C16.1824 3.74562 16.1692 3.39904 16.3764 3.17734L16.4529 3.09547Z'
        fill='currentColor'
      />
      <path
        d='M11.7582 9.78022C11.7582 10.7513 10.9711 11.5385 10 11.5385C9.02895 11.5385 8.24176 10.7513 8.24176 9.78022C8.24176 8.80917 9.02895 8.02198 10 8.02198C10.9711 8.02198 11.7582 8.80917 11.7582 9.78022Z'
        fill='currentColor'
      />
      <path
        d='M5.71429 9.89011C5.71429 10.6184 5.12389 11.2088 4.3956 11.2088C3.66732 11.2088 3.07692 10.6184 3.07692 9.89011C3.07692 9.16182 3.66732 8.57143 4.3956 8.57143C5.12389 8.57143 5.71429 9.16182 5.71429 9.89011Z'
        fill='currentColor'
      />
      <path
        d='M18.022 16.3736C18.022 17.1019 17.4316 17.6923 16.7033 17.6923C15.975 17.6923 15.3846 17.1019 15.3846 16.3736C15.3846 15.6453 15.975 15.0549 16.7033 15.0549C17.4316 15.0549 18.022 15.6453 18.022 16.3736Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.7822 12.3976C7.58908 12.2045 7.58908 11.8914 7.7822 11.6982L16.4235 3.05693C16.6166 2.86381 16.9297 2.86381 17.1229 3.05693C17.316 3.25004 17.316 3.56315 17.1229 3.75626L8.48154 12.3976C8.28842 12.5907 7.97532 12.5907 7.7822 12.3976Z'
        fill='currentColor'
      />
    </svg>
  );
};
