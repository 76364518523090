import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';

import { OpenOrdersDtoMapper } from '../mappers/open-orders-dto.mapper';
import { IOrder } from '../types/orders';

export const useOrderCurrentPrice = (order: IOrder) => {
  const { data: accounts } = useGetAccounts();
  const quotesData = useSelector(quotesSelector.quotesData);

  const brokerIdByAccountId = useMemo(() => {
    return (accounts as IAccount[])?.reduce((acc, item) => {
      acc[item.id] = item.companyId;
      return acc;
    }, {});
  }, [accounts]);

  const currentOrder = OpenOrdersDtoMapper.addCurrentPrice(order, quotesData?.[brokerIdByAccountId?.[order?.accountId]]?.[order.symbol]);

  const currentPrice = useMemo(
    () => (currentOrder?.takeProfit < currentOrder?.stopLoss ? currentOrder?.currentPrice?.ask : currentOrder?.currentPrice?.bid),
    [currentOrder.currentPrice, currentOrder.stopLoss, currentOrder.takeProfit],
  );

  return { currentPrice };
};
