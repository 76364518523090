import { useCallback } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { PAGINATION_PREFERENCES_KEY } from '../../shared-files/modules/shared/constants/local-storage-keys';

const intialValues = {
  dashboard: {
    balances: 10,
  },
  sharpTerminal: {
    tiProviders: 6,
    openOrders: 10,
    activeTrades: 10,
    tradesHistory: 10,
    ordersHistory: 10,
  },
  bots: {
    bots: 10,
    dcaBots: 10,
    openOrders: 10,
    activeTrades: 10,
    tradesHistory: 10,
    ordersHistory: 10,
  },
  singleBot: {
    openOrders: 10,
    activeTrades: 10,
    tradesHistory: 10,
    ordersHistory: 10,
  },
  marketplace: {
    tiProviders: 10,
  },
};

export const usePaginationPreferences = () => {
  const [preferences, setPreferences] = useLocalStorage(PAGINATION_PREFERENCES_KEY, intialValues);

  const handlePageChange = useCallback(
    (name: string, value: number) => {
      const [page, table] = name.split('.');
      setPreferences((prev) => ({ ...prev, [page]: { ...prev[page], [table]: value } }));
    },
    [setPreferences],
  );

  return {
    preferences,
    handlePageChange,
  };
};
