import { MutationFunction } from 'react-query';

import { RunExpertServiceValues, runExpertService } from '../services/run-expert.service';

export type RunExpertMutationData = void;
export type RunExpertMutationValues = RunExpertServiceValues;
export type RunExpertMutationError = Error;

export const RUN_EXPERT_MUTATION: MutationFunction<RunExpertMutationData, RunExpertMutationValues> = async (values) => {
  const result = await runExpertService(values);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }
};
