import { FC, useState } from 'react';
import { useGetTradeIdeaProvidersEventLogs } from '../hooks/use-get-trade-idea-providers-event-logs';
import { useSelector } from 'react-redux';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { Text } from '@root/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import dayjs from 'dayjs';
import { Select } from '@root/shared/ui/form';
import { Paginate } from '@root/shared/ui/pagination';

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { NoDataFound } from '@root/shared/ui/common/no-data-found';
import { MarketPlaceTradeIdeaProvider } from '../types/marketplace-trade-idea-provider';

type Props = {
    data?: MarketPlaceTradeIdeaProvider;
    isLoading: boolean;
}

export const ProviderEventLogs: FC<Props> = ({ data }) => {
    const [params, setParams] = useState({ page: 1, perPage: 10 });
    const { t } = useTranslation('marketplace-trade-idea-provider-details');
    const user = useSelector(authSelector.user);
    const { data: logsData, isLoading } = useGetTradeIdeaProvidersEventLogs({ providerId: data?.id as string, page: params.page, perPage: params.perPage }, data?.ownerIds?.includes(user?.id as string));

    if (!data || !data?.ownerIds?.includes(user?.id as string)) {
        return null;
    }

    return <div className='bg-gray-800 p-4 mt-5 rounded'>
        <div className='divide-y divider divide-gray-100 divide-opacity-10'>
            <div className='w-full grid grid-cols-7 py-2 gap-2'>
                <div className='col-span-1 lg:col-span-1'>
                    <Text size='sm' className='text-grayscale-500 font-medium'>
                        {t('events.id')}
                    </Text>
                </div>
                <div className='col-span-2 lg:col-span-1'>
                    <Text size='sm' className='text-grayscale-500 font-medium'>
                        {t('events.time')}
                    </Text>
                </div>
                <div className='col-span-2'>
                    <Text size='sm' className='text-grayscale-500 font-medium'>
                        {t('events.message')}
                    </Text>
                </div>
                <div className='col-span-2 lg:col-span-3'>
                    <Text size='sm' className='text-grayscale-500 font-medium'>
                        {t('events.info')}
                    </Text>
                </div>
            </div>

            {isLoading ? (
                Array.from({ length: 10 }).map((item, index) => (
                    <div key={index} className='w-full grid grid-cols-7 py-2 gap-2'>
                        <div className='col-span-1 lg:col-span-1'>
                            <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                        </div>
                        <div className='col-span-2 lg:col-span-1'>
                            <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                        </div>
                        <div className='col-span-2'>
                            <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                        </div>
                        <div className='col-span-2 lg:col-span-3'>
                            <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                        </div>
                    </div>
                ))
            ) : (
                <>
                    {!logsData?.data?.length ? <div className='py-20'>
                        <NoDataFound />
                    </div> : logsData?.data.map((item, index) => (
                        <div className='w-full grid grid-cols-7 py-3 gap-2 font-medium' key={index}>
                            <div className='col-span-1'>
                                <Text size='sm'>
                                    {item.id}
                                </Text>
                            </div>
                            <div className='col-span-2 lg:col-span-1'>
                                <Text size='sm'>
                                    {dayjs(item.createdAt).format('DD/MM/YYYY')} <br /> {dayjs(item.createdAt).format('hh:mm A')}
                                </Text>
                            </div>
                            <div className='col-span-2'>
                                {item.message}
                            </div>
                            <div className='col-span-2 lg:col-span-3'>
                                <JsonView src={item.data} collapseObjectsAfterLength={10} />
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
        <div className='flex flex-wrap justify-center items-center my-4 px-4 relative'>
            <Paginate
                pageCount={logsData?.pagination.pageCount || 1}
                forcePage={0}
                marginPagesDisplayed={1}
                disableInitialCallback
                onPageChange={(pageData) => setParams((prev) => ({ ...prev, page: pageData.selected + 1 }))}
            />
            <div className='w-20 ml-auto md:absolute right-5'>
                <Select
                    value={{ value: params.perPage, label: params.perPage }}
                    onChange={(option) => setParams(prev => ({ ...prev, perPage: option?.value as number }))}
                    options={[5, 10, 25].map((value) => ({ value, label: value }))}
                    isSearchable={false}
                    tiny
                    square
                    bordered
                />
            </div>
        </div>
    </div>;
}



