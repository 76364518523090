import { Layout } from '@root/infra/layout/containers';
import { CreateAccount } from '@root/modules/accounts/containers/create-account';
import { MT4IssuesBanner } from '@root/modules/dashboard/containers/mt4-issues-banner';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { BackButton } from '@root/shared/ui/back-button/back-button';

const CreateAccountPage = () => {
  return (
    <Layout>
      <div className='mb-5'>
        <BackButton failureLink={PageLinks.accounts} />
      </div>

      {/* <div className='mb-4'>
        <MT4IssuesBanner />
      </div> */}

      <CreateAccount />
    </Layout>
  );
};

export default CreateAccountPage;
