import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';

import { ExpertOrderDirection, ExpertOrderType, TemplateUnitType } from '@root/modules/experts/types/common';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { CreateExpertStepKey } from '../hooks/use-create-expert-steps';

export const makeExpertFormValues = (providerId?: string): CreateExpertDto => {
  return {
    name: '',
    account: '',
    description: '',
    providerId: providerId || '',
    expertPresetId: null,
    symbols: [],
    slippage: '',
    useSlippage: false,
    usePips: true,
    unitType: TemplateUnitType.PIPS,
    magicNumber: 0,
    allowClone: false,
    allowShare: false,
    strategy: {
      type: 'signal',
      usePriceTolerance: true,
      priceTolerance: '7',
      priceToleranceType: '0',
      timeTolerance: '15',
      timeToleranceType: '1',
      usePostSignalProximity: true,
      tpProximity: '15',
      tpProximityType: '0',
    },
    maxManualActiveTrades: '2',
    maxSignalActiveTrades: '2',
    manualMoneyManagement: {
      type: '0',
      lotsize: '1',
      riskPercent: '1',
      basedOn: '0',
      k: '1',
    },
    signalMoneyManagement: {
      type: '1',
      lotsize: '1',
      riskPercent: '1',
      basedOn: '0',
      k: '1',
      xPercent: '1',
    },
    breakEven: {
      use: false,
      type: '0',
      levels: [],
    },
    partialClose: {
      use: true,
      calculationType: '1',
      takeprofits: [
        {
          amount: '1',
          percent: '',
        },
      ],
    },
    manualSlTp: {
      type: '1',
      fixedSl: '30',
      fixedTp: '20',
      tpRatio: 0,
      riskRatio: 0,
    },
    signalSlTp: {
      type: '4',
      fixedSl: '',
      fixedTp: '',
      tpRatio: 2,
      riskRatio: 1,
      profitCalculationType: '0',
      slType: '0',
    },
    trailStop: {
      use: false,
      afterX: '1',
      followY: '1',
    },
    maxDrawDownLimit: {
      use: false,
      percent: '',
      basedOn: '0',
      period: '0',
    },
    sessionManagement: {
      use: false,
      type: FxSessionManagementActionType.LEAVE_RUNNING,
      sessions: ['all'],
      threshold: '',
    },
    conditionalClosure: {
      use: false,
      amount: '',
    },
    meta: {
      step: CreateExpertStepKey.Trigger,
    },
    orderType: ExpertOrderType.NEUTRAL,
    orderDirection: ExpertOrderDirection.NEUTRAL,
  };
};
