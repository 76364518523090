import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { CloseMenuIcon } from '@root/shared/icons/close-menu-icon';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Button } from '@root/shared/ui/button';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { SelectButton } from '@root/shared/ui/select-button/select-button';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const BreakEven: FC<{
  use: CreateExpertDto['breakEven']['use'];
  levels: CreateExpertDto['breakEven']['levels'];
  isPartialEdit?: boolean;
  isTradeUpdate?: boolean;
}> = ({ use, levels, isPartialEdit, isTradeUpdate }) => {
  const { t } = useTranslation('forex-experts');

  const { values, errors, setFieldValue, setErrors } = useFormikContext<CreateExpertDto>();
  const levelsErrors = errors.breakEven?.levels && values.breakEven?.levels?.length;
  const arrayError = typeof errors.breakEven?.levels === 'string' && errors.breakEven?.levels;

  const unitType = values.breakEven.type === '0' ? values?.unitType : '%';

  const options = useCreateExpertFieldOptions(values?.unitType);

  const onUseChange = useCallback((e) => {
    setFieldValue('breakEven.use', e.target.checked);
    setFieldValue('breakEven.levels', [{ afterX: '', setToY: '' }]);
  }, [setFieldValue]);

  useEffect(() => {
    if (!use && levels.length) {
      setFieldValue('breakEven.levels', []);
      setTimeout(() => {
        setErrors({});
      }, 0);
    }
  }, [use, levels.length, setErrors, setFieldValue]);

  return (
    <div>
      <SwitchField
        label={
          <IconLabel
            wrapperClassName='mb-0'
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.breakEven.use.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            <Title level={7}>{t('fields.breakEven.use.label')}</Title>
          </IconLabel>
        }
        name='breakEven.use'
        onChange={onUseChange}
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit && !isTradeUpdate, 'mb-2': isPartialEdit })}
      />
      {use && (
        <FieldArray name='breakEven.levels'>
          {({ push, remove }) => (
            <div>
              <Text className={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}>{t('fields.breakEven.levels.label')}</Text>
              <div className="flex items-center gap-x-4 mb-3">
                <SelectButton selected={values.breakEven.type === '0'} type='button' onClick={() => setFieldValue('breakEven.type', '0')}>
                  <Text size='sm'>{options.breakEvenTypeOptions[0].label}</Text>
                </SelectButton>
                <SelectButton selected={values.breakEven.type === '1'} type='button' onClick={() => setFieldValue('breakEven.type', '1')}>
                  <Text size='sm'>{options.breakEvenTypeOptions[1].label}</Text>
                </SelectButton>
              </div>
              {levels.map((_, index) => (
                <div className='flex items-start' key={index}>
                  <div className={clsx({ 'flex-1 grid grid-cols-1 sm:grid-cols-2 gap-x-4': !isPartialEdit, 'flex-grow': isPartialEdit })}>
                    <TextField
                      name={`breakEven.levels.${index}.afterX`}
                      type='number'
                      label={
                        index === 0 ? (
                          <IconLabel
                            icon={
                              <InfoTooltip content={<Text size='sm'>{t('fields.breakEven.levels.afterX.description')}</Text>}>
                                <InfoTooltipIcon />
                              </InfoTooltip>
                            }
                          >
                            {t('fields.breakEven.levels.afterX.label')}
                          </IconLabel>
                        ) : (
                          ' '
                        )
                      }
                      placeholder={t('fields.breakEven.levels.afterX.placeholder')}
                      suffix={unitType}
                      suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
                    />
                    <TextField
                      name={`breakEven.levels.${index}.setToY`}
                      type="text"
                      label={
                        index === 0 ? (
                          <IconLabel
                            icon={
                              <InfoTooltip content={<Text size='sm'>{t('fields.breakEven.levels.setToY.description')}</Text>}>
                                <InfoTooltipIcon />
                              </InfoTooltip>
                            }
                          >
                            {t('fields.breakEven.levels.setToY.label')}
                          </IconLabel>
                        ) : (
                          ' '
                        )
                      }
                      placeholder={t('fields.breakEven.levels.setToY.placeholder')}
                      suffix={unitType}
                      suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => remove(index)}
                    className={clsx('py-[15px] px-5 bg-white bg-opacity-10 mt-[26px] ml-4 text-xs rounded hover:opacity-80')}
                  >
                    <CloseMenuIcon />
                  </button>
                </div>
              ))}
              {arrayError && (
                <Text size='sm' className='text-danger-500 mb-4 mt-2 text-right'>
                  {arrayError}
                </Text>
              )}
              <Button disabled={!!levelsErrors} outlined onClick={() => push({ afterX: '', setToY: '' })} className='mt-2 border-none !px-0' prefix={<PlusIcon />}>
                {t('fields.breakEven.levels.add')}
              </Button>
            </div>
          )}
        </FieldArray>
      )}
    </div>
  );
};
