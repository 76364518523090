import { QueryFunction } from 'react-query';
import { getExpertsService } from '../services/get-experts.service';
import { IExpert } from '../types/expert';

export type GetExpertsQueryKey = ['experts'];
export type GetExpertsData = IExpert[];

export const GET_EXPERTS: QueryFunction<GetExpertsData, GetExpertsQueryKey> = async () => {
  const result = await getExpertsService();

  if (result.status === 200) {
    return result.payload.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  } else {
    throw new Error(result.payload);
  }
};
