import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SymbolType } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbolsWithType } from '@root/modules/accounts/hooks/use-get-symbols-with-type';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { generateId } from '@root/shared/utils/generate-id';

export const useCreateAccountStep = (accountId?: string, allowedSymbolType?: SymbolType | null, usePips?: boolean) => {
  const navigate = useNavigate();
  const { values, errors, setFieldValue } = useFormikContext<CreateExpertDto>();
  const accounts = useGetAccounts();
  const { data } = useGetSymbolsWithType();

  const accountOptions = useMemo(
    () =>
      accounts.data
        ?.sort((a, b) => {
          const connectedA = a.status === 'CONNECTED';
          const connectedB = b.status === 'CONNECTED';
          return Number(connectedB) - Number(connectedA);
        })
        .map((item) => ({
          value: item.id,
          label: `${item.username} ${item.company} - ${item.mtType}`,
        })),
    [accounts.data],
  );

  const activeAccountOptions = useMemo(
    () =>
      accounts.data
        ?.filter((item) => item.isSignedIn)
        ?.sort((a, b) => {
          const connectedA = a.status === 'CONNECTED';
          const connectedB = b.status === 'CONNECTED';
          return Number(connectedB) - Number(connectedA);
        })
        .map((item) => ({
          value: item.id,
          label: `${item.username} ${item.company} - ${item.mtType}`,
        })),
    [accounts.data],
  );

  const noAccounts = accounts.data?.length === 0 && accounts.isFetched;
  const selectedAccount = useMemo(() => accounts.data?.find((item) => item.id === accountId), [accounts, accountId]);

  const symbols = useMemo(
    () =>
      data
        ?.filter((item) => {
          const isPipsSymbol = !!(item.symbolType === SymbolType.CURRENCY || item.name.match('XAUUSD'));
          return (
            (usePips === undefined || (usePips && isPipsSymbol) || (!usePips && !isPipsSymbol)) &&
            (!allowedSymbolType || item.symbolType === allowedSymbolType || (allowedSymbolType === SymbolType.CURRENCY && item.name.match('XAUUSD'))) &&
            (selectedAccount?.symbolsMapping || {})[item.name] !== null &&
            (selectedAccount?.symbolsMapping || {})[item.name] !== undefined
          );
        })
        ?.map((item) => item.name),
    [data, selectedAccount, allowedSymbolType, usePips],
  );

  const symbolOptions = useMemo(() => {
    const result = [{ value: 'all', label: 'All' }];
    symbols?.forEach((item) => {
      result.push({ value: item, label: item });
    });

    return result;
  }, [symbols]);

  const onSymbolChange = useCallback(
    (options) => {
      if (options.some((item) => item.value === 'all')) {
        return setFieldValue('symbols', symbols);
      }

      const newValue = options.map((item) => item.value);
      setFieldValue('symbols', newValue);
    },
    [setFieldValue, symbols],
  );

  const onAccountCreate = useCallback(
    (nextPageUrl) => {
      const cacheId = generateId().toString();
      localStorage.setItem(cacheId, JSON.stringify(values));
      const nextUrl = nextPageUrl + `?cache=${cacheId}`;
      navigate(PageLinks.createAccount + `?next=${nextUrl}`);
    },
    [navigate, values],
  );

  const removeSymbolByIndex = useCallback(
    (index: number) => {
      setFieldValue(
        'symbols',
        values.symbols.filter((_, i) => i !== index),
      );
    },
    [setFieldValue, values.symbols],
  );

  const selectAllSymbols = useCallback(() => setFieldValue('symbols', symbols), [setFieldValue, symbols]);
  const clearAllSymbols = useCallback(() => setFieldValue('symbols', []), [setFieldValue]);

  useEffect(() => {
    if (accountOptions?.length && !values.account) {
      setTimeout(() => {
        setFieldValue('account', accountOptions[0].value);
      });
    }
  }, [accountOptions, values.account, setFieldValue]);

  const state = {
    noAccounts,
    accounts,
    accountOptions,
    symbols: values.symbols,
    selectAllSymbols,
    removeSymbolByIndex,
    clearAllSymbols,
    account: values.account,
    symbolOptions,
    errors,
    activeAccountOptions,
  };

  const handlers = {
    onAccountCreate,
    onSymbolChange,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
