import { authSlice } from '@root/shared-files/modules/auth/store';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { marketplaceSelector } from '../store/marketplace.selector';
import { marketplaceSlice } from '../store/marketplace.slice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PROVIDER_TO_BE_FETCHED } from '@root/shared-files/modules/shared/constants/local-storage-keys';

export const CHECKED_ALREADY = 'isTIResultCheckedAlready';

export const useTradeIdeaAfterPurchaseUpdate = () => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');
  const [isCheckedAlready, setCheckedAlready] = useLocalStorage<boolean>(CHECKED_ALREADY, false);

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const result = searchParams.get('result');

  const isUpdated = useSelector(marketplaceSelector.isUpdatedAfterSubscription);
  const dispatch = useDispatch();

  const checkForSubscriptionUpdate = useCallback(async () => {
    if (result && id) {
      localStorage.setItem(PROVIDER_TO_BE_FETCHED, id);
      dispatch(authSlice.actions.refreshSessionAfterTradeIdeaSubscription());
    }
  }, [result, id, dispatch]);

  useEffect(() => {
    checkForSubscriptionUpdate();
  }, [checkForSubscriptionUpdate]);

  useEffect(() => {
    if (result && id && isUpdated) {
      dispatch(marketplaceSlice.actions.setUpdatedAfterSubscription(false));
      //TODO: check router
      navigate(location.pathname.replace('[id]', id as string));
    }
  }, [result, id, isUpdated, dispatch, navigate, location.pathname]);

  useEffect(() => {
    if (result && !isCheckedAlready) {
      setCheckedAlready(true);
      if (result === 'approved') {
        notify({
          title: t('notifications.success'),
          type: 'success',
        });

        return;
      }

      if (result === 'rejected') {
        notify({
          title: t('notifications.rejected'),
          type: 'danger',
        });

        return;
      }

      notify({
        title: t('notifications.pending'),
        type: 'info',
      });
    }
  }, [result, isCheckedAlready]);
};
