import styled from 'styled-components';

import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FeedbackContent } from '@root/infra/layout/containers/feedback-tooltip';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { BoldIcon } from '@root/shared/icons/bold-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { DashboardIcon } from '@root/shared/icons/dashboard-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { FeedbackIcon } from '@root/shared/icons/feedback-icon';
import { HelixIcon } from '@root/shared/icons/helix-icon';
import { MagicTerminalIcon } from '@root/shared/icons/magic-terminal-icon';
import { ProvidersIcon } from '@root/shared/icons/providers-icon';
import { ScannersIcon } from '@root/shared/icons/scanners-icon';
import { SignalsIcon } from '@root/shared/icons/signals-icon';
import { SubscriptionsIcon } from '@root/shared/icons/subscriptions-icon';
import { ActiveLink } from '@root/shared/link/active-link';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';

import { LayoutElements } from '../components';

const { HelpItem, Help } = LayoutElements.Sidebar;

const { Wrapper, Group, Item } = LayoutElements.Sidebar;

const SidebarWrapper = styled(Wrapper)`
  font-size: 14px !important;
  padding-bottom: 72px;
`;

const MenuButtonWrapper = styled(Group)<{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '200px' : '64px')};
  z-index: 100;
  background: ${({ theme }) => theme.gray[800]};
`;

export const staticMenu = {
  top: [
    {
      title: 'sideBar.dashboard',
      Icon: DashboardIcon,
      url: '/',
    },

    {
      title: 'sideBar.accounts',
      Icon: BoldIcon,
      url: '/accounts',
    },
    // {
    //   title: 'sideBar.templates',
    //   iconId: 'templates',
    //   url: '/templates',
    // },
    {
      title: 'sideBar.experts',
      Icon: ExpertsIcon,
      url: '/assists',
    },
    {
      title: 'sideBar.magicTerminal',
      Icon: MagicTerminalIcon,
      url: '/magic-terminal',
      newLabel: 'sideBar.new',
    },
    // {
    //   title: 'sideBar.remap',
    //   iconId: 'transfer',
    //   url: '/symbol-remap',
    // },
  ],
  center: [
    {
      title: 'sideBar.providers',
      Icon: ProvidersIcon,
      url: '/marketplace',
    },
    {
      title: 'sideBar.presets',
      Icon: SignalsIcon,
      url: '/presets',
    },
  ],
  bottom: [
    {
      title: 'sideBar.subscriptions',
      Icon: SubscriptionsIcon,
      url: '/subscriptions',
    },
    // {
    //   title: 'sideBar.referral',
    //   Icon: ReferralIcon,
    //   url: '/referral',
    // },
  ],
  scanners: {
    info: {
      title: 'sideBar.scanners',
      Icon: ScannersIcon,
    },
    items: [
      {
        title: 'sideBar.heliX',
        Icon: HelixIcon,
        url: '/scanners/helix',
      },
    ],
  },
};

export const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const [isHelpOpen, setHelpOpen] = useState<boolean>(false);
  const [isMarketplaceOpen, setMarketplaceOpen] = useState<boolean>(true);
  const [isScannersOpen, setIsScannersOpen] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);

  const { isGhostLogin } = useGhostLogin();

  useOutsideClick(ref, () => setHelpOpen(false));

  const handleMenuClick = useCallback(() => {
    dispatch(userSettingsSlice.actions.setMenuOpened(!menuOpened));
    setMarketplaceOpen(true);
  }, [dispatch, menuOpened]);

  return (
    <SidebarWrapper>
      <div>
        <Group>
          <div className='w-full'>
            {staticMenu.top.map((item, index) => (
              <ActiveLink key={index} exact={index === 0} href={item.url}>
                <Item
                  key={item.title}
                  title={t(item.title)}
                  icon={<item.Icon width={20} height={20} />}
                  className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })}
                  menuOpened={menuOpened}
                  newLabel={item.newLabel ? t(item.newLabel) : undefined}
                />
              </ActiveLink>
            ))}
          </div>
        </Group>
        <Group className='border-t-2 border-black py-0.5'>
          <div className='w-full'>
            {menuOpened ? (
              <div className='flex justify-between items-center mx-5 py-4 cursor-pointer' onClick={() => setMarketplaceOpen((prev) => !prev)}>
                <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                  {t('sideBar.marketplace')}
                </Text>
                <ChevronIcon className={clsx('text-gray-400', { 'rotate-180': !isMarketplaceOpen })} />
              </div>
            ) : null}
            {isMarketplaceOpen ? (
              <>
                {staticMenu.center.map((item, index) => (
                  <ActiveLink key={index} href={item.url}>
                    <Item
                      key={item.title}
                      title={t(item.title)}
                      icon={<item.Icon width={20} height={20} />}
                      className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })}
                      menuOpened={menuOpened}
                    />
                  </ActiveLink>
                ))}
              </>
            ) : null}
          </div>
        </Group>

        <Group className='border-t-2 border-b-2 border-black py-0.5'>
          <div className='w-full'>
            {menuOpened ? (
              <div className='flex justify-between items-center mx-5 py-4 cursor-pointer' onClick={() => setIsScannersOpen((prev) => !prev)}>
                <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                  {t(staticMenu.scanners.info.title)}
                </Text>
                <ChevronIcon className={clsx('text-gray-400', { 'rotate-180': !isScannersOpen })} />
              </div>
            ) : null}
            {(menuOpened && isScannersOpen) || !menuOpened ? (
              <>
                {staticMenu.scanners.items.map((item, index) => (
                  <ActiveLink key={index} href={item.url}>
                    <Item
                      key={index}
                      title={t(item.title)}
                      icon={<item.Icon width={20} height={20} />}
                      className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })}
                      menuOpened={menuOpened}
                      disabled={isGhostLogin}
                    />
                  </ActiveLink>
                ))}
              </>
            ) : null}
          </div>
        </Group>

        <Group>
          <div className='w-full'>
            {staticMenu.bottom.map((item, index) => (
              <ActiveLink key={index} exact href={item.url} className={`${item.url === '/referral' ? 'pointer-events-none' : ''}`}>
                <Item
                  key={item.title}
                  title={t(item.title)}
                  icon={<item.Icon width={20} height={20} />}
                  className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })}
                  menuOpened={menuOpened}
                  disabled={item.url === '/referral'}
                />
              </ActiveLink>
            ))}
          </div>
        </Group>

        <Group className='justify-center py-2 '>
          <Help
            className={clsx('flex items-center w-full mx-2 !py-0', {
              'flex-row-reverse justify-center gap-x-4': menuOpened,
              'flex-col': !menuOpened,
            })}
          >
            <HelpItem className='py-0'>
              <div className='relative' onClick={() => setHelpOpen((prev) => !prev)} ref={ref}>
                <div className='flex justify-center items-center gap-x-2 hover:text-primary-400 cursor-pointer'>
                  {menuOpened ? <Text size='sm'>{t('sideBar.help.menuText')}</Text> : null}
                  <FeedbackIcon width={20} height={20} />
                </div>
                {isHelpOpen && <FeedbackContent />}
              </div>
            </HelpItem>
          </Help>
        </Group>
      </div>

      <MenuButtonWrapper
        className={clsx('py-4 border-t-2 border-black flex flex-col gap-2 fixed bottom-0 left-0 mt-[100%]', { 'left-[3px] bottom-[4px]': isGhostLogin })}
        expanded={menuOpened}
      >
        <div
          className={clsx('flex justify-start items-center cursor-pointer', {
            'gap-x-3 justify-start pl-5': menuOpened,
            'justify-center': !menuOpened,
          })}
          onClick={handleMenuClick}
        >
          <Button className='!w-8 !h-8 !px-0 m-0 flex justify-center items-center rounded text-gray-100 hover:text-primary-400 '>
            <ArrowIcon
              className={clsx('transform transition duration-200', {
                '-rotate-90': menuOpened,
                'rotate-90': !menuOpened,
              })}
              width={24}
              height={16}
            />
          </Button>
          {menuOpened && <Text className='!text-[14px]'>{t('sideBar.hideMenu')}</Text>}
        </div>
      </MenuButtonWrapper>
    </SidebarWrapper>
  );
};
