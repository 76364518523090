import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const InfoTooltipIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z'
        fill='#FCFCFC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 17.5C11.4477 17.5 11 17.0523 11 16.5L11 11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5L13 16.5C13 17.0523 12.5523 17.5 12 17.5Z'
        fill='#FCFCFC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 8.5C11.4477 8.5 11 8.05228 11 7.5L11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7L13 7.5C13 8.05228 12.5523 8.5 12 8.5Z'
        fill='#FCFCFC'
      />
    </svg>
  );
};
