import { AxiosError } from 'axios';
import { ReactPaginateProps } from 'react-paginate';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';

import { MarketplaceTradeIdeaMapper } from '../mappers/marketplace-trade-idea.mapper';
import { MarketPlaceTradeIdeaItem } from '../types/marketplace-trade-idea';

export type MarketplaceTradeIdeaListResponseData = {
  data: MarketPlaceTradeIdeaItem[];
  pagination: ReactPaginateProps;
};

export type GetMarketplaceTradeIdeaListServiceResponse = IHttpResponse<200, MarketplaceTradeIdeaListResponseData> | IHttpResponse<400, string>;

export type GetMarketplaceTradeIdeaListQuery = {
  page: number;
  itemsPerPage: number;
  search: string;
  markets?: string[];
};

export const getMarketplaceTradeIdeaListService = async ({
  page,
  itemsPerPage,
  search,
  markets = [],
}: GetMarketplaceTradeIdeaListQuery): Promise<GetMarketplaceTradeIdeaListServiceResponse> => {
  try {
    const params = {
      page,
      size: itemsPerPage,
      search,
      markets: (markets?.length > 1) ? markets : undefined,
      market: (markets?.length === 1) ? markets[0] : undefined,
      ['orderBy[]']: ['statistics.gain_year', 'statistics.gain_month', 'statistics.gain_week'],
      sortDirection: 'DESC',
    };

    if (!markets?.length) {
      params['markets'] = ['forex', 'indices', 'synthetic'];
    }

    const query = stringifyParams(params);

    const response = await fetcher(`/trade-ideas/providers?${query}`);

    return {
      status: 200,
      payload: {
        data: response.data.data.map((item) => MarketplaceTradeIdeaMapper.toDomain(item)),
        pagination: {
          pageCount: response.data.total / itemsPerPage > 1 ? Math.ceil(response.data.total / itemsPerPage) : 1,
        },
      },
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
