import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { GET_PRESETS } from '@root/modules/presets/queries/get-presets.query';

export const useGetPresets = () => {
  const userId = useSelector(authSelector.userId);

  return useQuery(['fx', 'presets'], GET_PRESETS, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
    enabled: !!userId,
  });
};
