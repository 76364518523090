import { getMarketplaceTradeIdeaProviderService, MarketplaceTradeIdeaProviderResponseData } from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider.service';
import { Id } from '@root/shared/utils/types';
import { QueryFunction } from 'react-query';

export type GetMarketplaceTradeIdeaProviderQueryKey = ['marketplace-trade-idea-provider', Id];
export type GetMarketplaceTradeIdeaProviderData = MarketplaceTradeIdeaProviderResponseData;

export const GET_MARKETPLACE_TRADE_IDEA_PROVIDER: QueryFunction<GetMarketplaceTradeIdeaProviderData, GetMarketplaceTradeIdeaProviderQueryKey> = async ({ queryKey }) => {
  const [, data] = queryKey;
  const result = await getMarketplaceTradeIdeaProviderService(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
