import successImg from '@root/assets/images/cases/success.webp';
import { useRunExpertModal } from '@root/modules/experts/hooks/use-run-expert-modal';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Modal } from '@root/shared/ui/modal';
import { Title } from '@root/shared/ui/typography/title';
import { FC } from 'react';

type Props = {
  isOpen: boolean;
  closeModal(): void;
};

export const EnableExpertModal: FC<Props> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation('forex-experts');
  const { runExpert, isRunning, postponeRunning } = useRunExpertModal();

  return (
    <Modal isOpen={isOpen} onCancel={closeModal} footer={null}>
      <div className='flex flex-col items-center gap-8'>
        <div className='w-20 h-20'>
          <Image src={successImg} width={220} height={220} objectFit='contain' alt='success' unoptimized={true} />
        </div>
        <Title level={5} className='font-bold text-center'>
          {t('actions.startExpert')}
        </Title>
        <div className='w-full flex justify-between items-center'>
          <Button outlined onClick={postponeRunning}>
            {t('actions.skip')}
          </Button>
          <Button onClick={runExpert} loading={isRunning}>
            {t('actions.run')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
