import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IAccount } from '@root/modules/accounts';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ResetAccountDrawdownResponse = IHttpResponse<200, IAccount> | IHttpResponse<400, string>;

export const resetDrawdownLimit = async (accountId: string): Promise<ResetAccountDrawdownResponse> => {
  try {
    const response = await fetcher.post(`/accounts/${accountId}/reset-draw-down-limit`, undefined, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
