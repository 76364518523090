import { useQuery } from 'react-query';

import { GET_EXPERT_EVENTS, GetExpertEventsData, GetExpertEventsQueryKey } from '../queries/get-expert-events.query';

export const useGetExpertEvents = (params) => {
  return useQuery<GetExpertEventsData, Error, GetExpertEventsData, GetExpertEventsQueryKey>(['expert-events', params], GET_EXPERT_EVENTS, {
    keepPreviousData: false,
    enabled: !!params?.expertId && !!params?.userId,
  });
};
