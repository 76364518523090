import dayjs from 'dayjs';

import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropsValue } from 'react-select';

import { IOrder } from '@root/modules/orders/types/orders';
import { OptionType } from '@root/shared/form';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { EmptyData } from '@root/shared/ui/empty-data';
import { Select } from '@root/shared/ui/form';
import { Paginate } from '@root/shared/ui/pagination';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Text } from '@root/shared/ui/typography';
import { getOrderSideColor } from '@root/shared/utils/colors';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';

import { useOrderEvents } from '../hooks/use-order-events';
import { IExpert } from '../types/expert';
import { IDText } from './details/trades-and-history/active-trade-item.ui';

type Props = {
  order: IOrder;
  expert: IExpert;
  wrapperClassName?: string;
  showPaginationIfListEmpty?: boolean;
  title?: React.ReactNode;
  expandable?: boolean;
};

export const OrderEvents: FC<Props> = ({ order, expert, title, wrapperClassName = '', expandable = false, showPaginationIfListEmpty = true }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslation('forex-experts', { keyPrefix: 'events' });

  const [{ controlParams: params, list, isLoading, pageCount }, { changeControlParam }] = useOrderEvents(order.id, expert.id);

  return (
    <div className={clsx('p-4 sm:p-10 bg-gray-800 rounded', wrapperClassName)}>
      {expandable ? (
        <div className='flex justify-start items-center gap-x-2 cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
          <ChevronIcon className={clsx({ 'rotate-180 -mt-1': !isOpen })} /> {title}
        </div>
      ) : (
        title
      )}
      {(!expandable || isOpen) && (
        <div>
          <div className='divide-y divider divide-grayscale-500'>
            <div className='border-b border-gray-700 pb-4 -mt-8'>
              <div className='w-full flex justify-between items-center gap-4'>
                <div className='flex items-center gap-x-4'>
                  <Text size='xl' className='font-bold text-[20px]'>
                    {t('table.orderLogs', { symbol: order?.symbol })}
                  </Text>
                  <Text size='sm' className={clsx(`whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full ${getOrderSideColor(order?.type)}`)}>
                    {order?.type}
                  </Text>
                </div>
              </div>
              <div className='w-full flex justify-start items-center gap-x-4'>
                <Text size='sm'>{expert?.name}</Text>
                <IDText size='sm' className='whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer' onClick={() => copyToClipboard(order.ticket)}>
                  ID: {order?.ticket || '-'}
                </IDText>
              </div>
            </div>

            {isLoading ? (
              Array.from({ length: 10 }).map((item, index) => (
                <div key={index} className='w-full grid grid-cols-4 py-2 gap-2'>
                  <div className='col-span-2 lg:col-span-1'>
                    <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                  </div>
                  <div className='col-span-2 lg:col-span-3'>
                    <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 20 }]} />
                  </div>
                </div>
              ))
            ) : (
              <>
                {list.map((item, index) => (
                  <div className='w-full grid grid-cols-4 py-3 gap-2' key={index}>
                    <div className='col-span-2 lg:col-span-1'>
                      <Text size='sm'>
                        {dayjs(item.createdAt).format('DD/MM/YYYY')} <br /> {dayjs(item.createdAt).format('hh:mm A')}
                      </Text>
                    </div>
                    <div className='col-span-2 lg:col-span-3 text-sm font-medium'>
                      {item.level}: <span>{item.message.split('\n')[0]}</span>
                      <ul className='mt-1'>
                        {item.message
                          .split('\n')
                          .slice(1)
                          .map((item, index) => (
                            <li className='mb-1' key={`${index}_${item.slice(8)}`}>
                              <span>- {item}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          {!list.length && !isLoading && <EmptyData />}

          {(!!list.length || showPaginationIfListEmpty) && (
            <div className='flex flex-wrap justify-center items-center my-3 px-5 relative'>
              <Paginate
                pageCount={pageCount || 1}
                forcePage={0}
                marginPagesDisplayed={1}
                disableInitialCallback
                onPageChange={(pageData) => changeControlParam({ page: pageData.selected + 1 })}
              />
              <div className='w-20 ml-auto md:absolute right-5'>
                <Select
                  value={{ value: params.itemsPerPage, label: params.itemsPerPage }}
                  onChange={(option: PropsValue<OptionType>) => changeControlParam({ itemsPerPage: (option as OptionType).value as number })}
                  options={[5, 10, 25].map((value) => ({ value, label: value }))}
                  isSearchable={false}
                  tiny
                  square
                  bordered
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
