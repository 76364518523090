import { useMemo } from 'react';

import { useGetAccounts } from './use-get-accounts';

export const useCheckEmptyDDAccounts = () => {
  const { data } = useGetAccounts();

  const emptyDDAccounts = useMemo(() => {
    return data?.filter((item) => !item.maxDrawDownLimit) || [];
  }, [data]);

  return { emptyDDAccounts };
};
