import React, { FC, forwardRef, ReactNode } from 'react';
import styled, { DefaultTheme, StyledComponentProps } from 'styled-components';

export const ModuleTabs = styled.div`
  display: flex;
  overflow: auto;
`;

const getTabButtonPadding = (theme: DefaultTheme, large = false) => {
  return large ? `${theme.spacing(4)}px ${theme.spacing(5)}px` : `${theme.spacing(2.5)}px ${theme.spacing(6)}px`;
};

type TabButtonExtraProps = { active?: boolean; large?: boolean };
type TabButtonProps = StyledComponentProps<'button', DefaultTheme, TabButtonExtraProps, any>;

const TabButton = styled.button<TabButtonExtraProps>`
  position: relative;
  padding: ${({ theme, large }) => getTabButtonPadding(theme, large)};
  color: ${({ active, theme }) => active && theme.info['300']};
  transition: color 0.2s;
  font-size: ${({ large }) => (large ? '16px' : '13px')};
  display: inline-flex;
  align-items: center;

  &.active {
    color: ${({ theme }) => theme.info['300']};

    &:after {
      background-color: ${({ theme }) => theme.info['300']};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.info['300']};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: ${({ large }) => (large ? '10px' : '3px')};
    width: ${({ large }) => (large ? '32px' : '24px')};
    margin: 0 auto;
    transform: translateX(-50%);
    background-color: ${({ active, theme }) => (active ? theme.info['300'] : 'transparent')};
    left: 50%;
    transition: background-color 0.2s;
  }

  &:disabled {
    color: ${({ theme }) => theme.gray['500']};
  }
`;

export type TabProps = TabButtonProps & { icon?: ReactNode };

export const ModuleTab: FC<TabProps> = forwardRef<HTMLButtonElement, TabProps>(function Tab({ icon, children, ...props }, ref) {
  return (
    <TabButton ref={ref} {...props}>
      {icon && <span className='mr-2 text-lg'>{icon}</span>}
      {children}
    </TabButton>
  );
});
