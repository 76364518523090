import clsx from 'clsx';
import { rgba } from 'polished';
import { forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';

const RawDatePicker = forwardRef<ReactDatePicker, ReactDatePickerProps<never, boolean>>(function DatePicker({ className, calendarClassName, ...props }, ref) {
  return <ReactDatePicker {...props} calendarClassName={clsx(className, calendarClassName)} ref={ref} />;
});

export const DatePicker = styled(RawDatePicker)`
  &.react-datepicker {
    background-color: ${({ theme }) => theme.muted['700']};
    border-color: ${({ theme }) => theme.muted['600']};
    border-radius: 0.5rem;
    font-family: 'Poppins', sans-serif;

    [aria-disabled="true"] {
      color: ${({ theme }) => theme.muted['500']} !important;
    }

    .react-datepicker__navigation {
      top: 12px;
    }

    .react-datepicker__month-container {
      width: 100%;

      @media screen and (min-width: ${({ theme }) => theme.screens.sm}) {
        width: auto;
      }

      &:first-of-type .react-datepicker__current-month {
        padding-left: 36px;
      }

      &:not(:first-of-type) .react-datepicker__current-month {
        padding-left: 16px;
      }

      .react-datepicker__header {
        background-color: ${({ theme }) => theme.muted['700']};
        border-bottom: none;
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;

        .react-datepicker__current-month {
          color: ${({ theme }) => theme.muted['100']};
          text-align: left;
          padding-top: 4px;
          padding-bottom: 4px;
          text-transform: capitalize;
        }

        .react-datepicker__day-name {
          color: ${({ theme }) => theme.muted['400']};
          width: 2rem;
          font-weight: bold;
          text-transform: capitalize;
        }
      }

      .react-datepicker__day {
        color: ${({ theme }) => theme.muted['100']};
        outline: none;
        width: 2rem;

        &.react-datepicker__day--selected,
        &.react-datepicker__day--in-range {
          background-color: ${({ theme }) => rgba(theme.muted['100'], 0.2)};
        }

        &.react-datepicker__day--in-selecting-range {
          background-color: ${({ theme }) => rgba(theme.muted['100'], 0.05)};
        }

        &.react-datepicker__day--outside-month {
          color: ${({ theme }) => theme.muted['500']};
        }

        &:hover {
          background-color: ${({ theme }) => rgba(theme.muted['100'], 0.1)};
        }
      }
    }
  }
`;
