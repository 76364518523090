import providerBannerImage from '@root/assets/images/trade-idea-provider-banner.webp';
import providerFallbackImage from '@root/assets/images/trade-idea-provider-fallback.webp';
import { useIsDesktop } from '@root/infra/user-agent';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { CollectionIcon } from '@root/shared/icons/collection-icon';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { ShowMore } from '@root/shared/ui/show-more/show-more';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Title } from '@root/shared/ui/typography';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nb';
import 'dayjs/locale/pt';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MarketPlaceTradeIdeaSubscribeModal } from '../components/marketpalce-trade-idea-subscribe-modal';
import { MarketPlaceTradeIdeaUnsubscribeModal } from '../components/marketplace-trade-idea-unsubscribe-modal';
import { ProviderInfoUI } from '../components/provider-info.ui';
import { ProviderSocials } from '../components/provider-socials';
import { ProviderStatistics } from '../components/provider-statistics';
import { useTradeIdeaAfterPurchaseUpdate } from '../hooks/use-trade-idea-after-purchase-update';
import { useTradeIdeaSubscription } from '../hooks/use-trade-idea-provider-subscription';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { Toggler } from '@root/shared/ui/toggler/toggler';
import { ThreeDotsIcon } from '@root/shared/icons/three-dots';
import { MarketplaceActionsTooltip } from '../components/marketplace-actions-tooltip';
import { MarketplaceRetryPaymentModal } from '../components/marketplace-retry-payment-modal';
import { BriefCaseIcon } from '@root/shared/icons/briefcase';
import styled from 'styled-components';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { GetMarketplaceTradeIdeaProviderData } from '../query/get-marketplace-trade-idea-provider.query';
import { useProviderSubscriptionState } from '@root/modules/marketplace/hooks/use-provider-subscription-state';
import { PlusIcon } from '@root/shared/icons/plus-icon';

const DescriptionWrapper = styled.div`
  ol,
  ul {
    li {
      margin-left: 20px !important;
    }
  }

  ol {
    list-style: auto !important;
  }

  ul {
    list-style: disc !important;
  }
`;

const { Banner, InfoContainer, ProviderImage } = ProviderInfoUI;

export const ProviderInfo = ({ data, isLoading }: { data?: GetMarketplaceTradeIdeaProviderData, isLoading: boolean }) => {
  const { t, i18n } = useTranslation('marketplace-trade-idea-provider-details');
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const [subscriptionType, setSubscription] = useState<'month' | 'year'>('month');

  const [
    { loading, isUnsubscribeOpen, isSubscribeOpen, retryPaymentLoadingId, retryPaymentSubscription },
    { handleSubscribeClick, handleUnsubscribeClick, onCancel, setSelectedProvider, handleRetryPayment, setRetrySubscription, retryModalOk },
  ] = useTradeIdeaSubscription({
    subscriptionType,
  });

  const price = useMemo(() => data?.plans.find((item) => item.recurringIntervalUnit === subscriptionType)?.price || 0, [data, subscriptionType]);

  const { isActive, isStillActive, isCancelled, isPaused, expiresAt } = useProviderSubscriptionState(data);

  useTradeIdeaAfterPurchaseUpdate();

  const { hideActions } = useGhostLogin();

  useEffect(() => {
    if (data?.subscription?.plan) {
      setSubscription(data?.subscription?.plan.recurringIntervalUnit === 'year' ? 'year' : 'month');
    }
  }, [data]);

  return (
    <div className='mb-10'>
      <Banner>
        <div className='absolute top-2 left-4 z-1'>
          <ButtonLink
            variant='info'
            link
            tint='300'
            className='flex-1 mr-2 text-center !font-bold !p-0 cursor-pointer'
            prefix={<ArrowIcon className='transform -rotate-90' />}
            onClick={() => navigate(PageLinks.providers)}
          >
            {t('info.back')}
          </ButtonLink>
        </div>
        <Image alt='provider banner' src={providerBannerImage} objectFit='cover' wrapperClassName='w-full h-full' />
      </Banner>

      <InfoContainer>
        <div className='flex flex-col'>
          <ProviderImage>
            {isLoading ? (
              <SkeletonLoader shapes={[{ circle: true, width: isDesktop ? 200 : 140, height: isDesktop ? 200 : 140 }]} />
            ) : (
              <Image src={data?.logo || providerFallbackImage} alt='provider image' layout='fill' objectFit='cover' wrapperClassName='w-full h-full' />
            )}
          </ProviderImage>
          <a href={data?.link} className='w-full overflow-hidden text-ellipsis text-info-300 text-sm font-bold inline-block mb-3 mt-6 self-center' target='_blank' rel='noreferrer'>
            {data?.link}
          </a>
          {isLoading ? (
            <div className='flex items-center gap-2 justify-center'>
              <SkeletonLoader
                shapes={[
                  { circle: true, width: 24, height: 24 },
                  { circle: true, width: 24, height: 24 },
                ]}
              />
            </div>
          ) : (
            <ProviderSocials socials={data?.socialsUrls as any} />
          )}
        </div>
        <div className='mt-4'>
          <div className='flex flex-wrap justify-between pb-3 border-b border-grayscale-700 mb-4'>
            <div>
              <Title level={4}>{isLoading ? <SkeletonLoader shapes={[{ circle: false, width: 200, height: 20 }]} /> : data?.name}</Title>
              <span className='flex items-center mt-1'>
                <span className='text-grayscale-500 flex items-center gap-2 text-base'>
                  <CollectionIcon />
                  {isLoading ? (
                    <SkeletonLoader
                      shapes={[
                        {
                          circle: false,
                          width: 100,
                          height: 20,
                        },
                      ]}
                    />
                  ) : (
                    t('info.subscribers', { count: data?.statistics.subscribersCount })
                  )}
                </span>
              </span>
            </div>
            <div className='flex flex-col items-end'>
              <div className='flex items-center justify-end gap-x-6 mb-4'>
                <div className='flex justify-end items-center gap-x-2'>
                  <Text size='sm'>{t('info.monthly')}</Text>
                  <Toggler checked={subscriptionType === 'year'} onChange={(checked) => setSubscription(checked ? 'year' : 'month')} alwaysActive disabled={isActive} />
                  <Text size='sm'>{t('info.yearly')}</Text>
                </div>
                {price ? (
                  <Text className='font-semibold text-primary-400' size='lg'>
                    {subscriptionType === 'month' ? t('info.monthPrice', { price }) : t('info.yearPrice', { price })}
                  </Text>
                ) : (
                  <Text className='font-semibold text-primary-400' size='lg'>
                    {t('info.free')}
                  </Text>
                )}
              </div>
              {isStillActive && isCancelled && (
                <Text size='sm' className='flex justify-end items-center mb-4'>
                  {t('info.stillActive', {
                    date: dayjs(expiresAt)
                      .locale(i18n.language || 'en')
                      .format('DD MMMM YYYY'),
                  })}
                </Text>
              )}
              {!!data &&
                !isPaused &&
                (isActive ? (
                  <MarketplaceActionsTooltip
                    content={
                      <Button
                        className='!p-0 !font-bold !text-right flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline'
                        link
                        onClick={() => setSelectedProvider(data)}
                        disabled={hideActions}
                      >
                        {t('info.unsubscribe')}
                      </Button>
                    }
                  >
                    <Button variant='gray' tint='700' className='border-none !p-2 !px-3 !font-bold'>
                      <ThreeDotsIcon />
                    </Button>
                  </MarketplaceActionsTooltip>
                ) : (
                  <Button onClick={() => setSelectedProvider(data)} disabled={hideActions}>
                    <div className='flex justify-center items-center gap-x-4'>
                      <BriefCaseIcon /> <Text size='sm'>{t('info.subscribe')} </Text>
                    </div>
                  </Button>
                ))}
              {!!data &&
                !isPaused &&
                isActive && (
                  <Link to={`${PageLinks.createExpert}?providerId=${data?.id}`}>
                    <Button className='flex justify-end items-center gap-2 border-none px-0 py-0' outlined>
                      <PlusIcon /> <span>{t('info.startAssist')}</span>
                    </Button>
                  </Link>
                )}
              {!!data && isPaused && (
                <div className='flex gap-x-4 justify-end items-center'>
                  <Text size='sm' className='text-danger-500 font-medium text-right'>
                    <Trans i18nKey='marketplace-trade-idea-provider-details:info.unsuccessfulPayment' components={{ br: <br /> }} />
                  </Text>
                  <MarketplaceActionsTooltip
                    content={
                      <div className='flex flex-col gap-y-2 items-end'>
                        <Button
                          className='!p-0 !font-bold flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline whitespace-nowrap'
                          link
                          onClick={() => handleRetryPayment(data)}
                          loading={retryPaymentLoadingId === data.id}
                          disabled={hideActions}
                        >
                          {t('info.retryPayment')}
                        </Button>
                        <Button
                          className='!p-0 !font-bold flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline'
                          link
                          onClick={() => setSelectedProvider(data)}
                          disabled={hideActions}
                        >
                          {t('info.unsubscribe')}
                        </Button>
                      </div>
                    }
                  >
                    <Button variant='gray' tint='700' className='border-none !p-2 !px-3 !font-bold'>
                      <ThreeDotsIcon />
                    </Button>
                  </MarketplaceActionsTooltip>
                </div>
              )}
            </div>
          </div>
          {isLoading ? (
            <SkeletonLoader shapes={[{ circle: false, width: 300, height: 100 }]} />
          ) : (
            data && (
              <>
                <div className='md:max-w-md mb-4'>
                  <ProviderStatistics
                    id={data.id as string}
                    winsCount={data.statistics.winsCount}
                    lossesCount={data.statistics.lossesCount}
                    signalCount={data.statistics.tradeIdeaCount}
                    pnl={data.statistics.pnl}
                    showSlWonInfo={data.showSlWonInfo}
                  />
                </div>
                <DescriptionWrapper>
                  <ShowMore lines={4} truncatedEndingComponent=' ' buttonAtNewLine gradientOverlay text={data.description} />
                </DescriptionWrapper>
              </>
            )
          )}
        </div>
      </InfoContainer>
      {!!retryPaymentSubscription && (
        <MarketplaceRetryPaymentModal isOpen={!!retryPaymentSubscription} loading={!!retryPaymentLoadingId} onCancel={() => setRetrySubscription(null)} onOk={retryModalOk} />
      )}
      {isSubscribeOpen && <MarketPlaceTradeIdeaSubscribeModal isOpen={isSubscribeOpen} onCancel={onCancel} onOk={() => handleSubscribeClick()} loading={loading} />}
      {isUnsubscribeOpen && <MarketPlaceTradeIdeaUnsubscribeModal isOpen={isUnsubscribeOpen} onCancel={onCancel} onOk={handleUnsubscribeClick} loading={loading} />}
    </div>
  );
};
