import { FC, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { Button } from '../button';

export const BackButton: FC<{ className?: string; backLink?: string; failureLink: string }> = ({ className = '', backLink, failureLink }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const next = searchParams.get('next');

  const onClick = useCallback(() => {
    navigate(next || backLink || failureLink);
  }, [navigate, next, backLink, failureLink]);

  return (
    <Button type='button' onClick={onClick} className={clsx('flex justify-between items-center gap-x-4 !border-none', className)} variant='primary' outlined>
      <ArrowIcon className='transform -rotate-90' />
      <span className='leading-none'>{t('back')}</span>
    </Button>
  );
};
