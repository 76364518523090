import { useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { signInAccountService } from '@root/modules/accounts/services/sign-in-account.service';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { expertsSelector } from '@root/modules/experts/store/experts.selector';
import { expertsSlice } from '@root/modules/experts/store/experts.slice';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { notify } from '@root/shared/utils/notification';

import { runExpertService } from '../services/run-expert.service';
import { useCustomNavigate } from '@root/shared/hooks/use-custom-navigate';

export const useRunExpertModal = () => {
  const dispatch = useDispatch();
  const runExpertId = useSelector(expertsSelector.runExpertId);
  const runExpertSymbols = useSelector(expertsSelector.runExpertSymbols);
  const { values } = useFormikContext<CreateExpertDto>();
  const { push } = useCustomNavigate();
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const { data: accounts, refetch } = useGetAccounts();

  const selectedAccount = useMemo(() => {
    return accounts?.find((account) => account?.id === values?.account);
  }, [accounts, values?.account]);

  const runExpert = useCallback(async () => {
    if (selectedAccount && !selectedAccount.isSignedIn) {
      const response = await signInAccountService({
        accountId: selectedAccount.id as string,
      });

      if (response.status === 200) {
        refetch();
      }
    }

    const result = await runExpertService({ symbols: runExpertSymbols, id: runExpertId as string });

    if (result.status === 200) {
      await queryClient.invalidateQueries(['experts'], { exact: false});
      notify({ type: 'success', text: 'Expert successfully run' });
      dispatch(expertsSlice.actions.runExpertClosed());
      push(PageLinks.experts);
    } else {
      notify({ type: 'danger', text: result.payload });
    }

    setIsRunning(false);
  }, [setIsRunning, runExpertSymbols, runExpertId, push, dispatch, selectedAccount, refetch]);

  const postponeRunning = useCallback(async () => {
    await queryClient.invalidateQueries(['experts']);
    push(PageLinks.experts);
    dispatch(expertsSlice.actions.runExpertClosed());
  }, [push, dispatch]);

  return { runExpert, isRunning, postponeRunning };
};
