import { useMemo, useState } from 'react';

import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { IOrder } from '@root/modules/orders/types/orders';
import { isMagicOrder } from '@root/modules/orders/utils/orders';
import { useSelect } from '@root/shared/form';
import { usePaginationPreferences } from '@root/shared/hooks/use-pagination-preferences';
import { usePaginatedList } from '@root/shared/utils/hooks';

import { TabType } from '../enums/orders';

export type PageSizeOption = { value: number; label: string };

type Props = {
  tab: TabType;
  mtType?: string;
  expertId?: string;
  enabled?: boolean;
  selectedBroker: string;
  noPending?: boolean;
  isMagicTerminal?: boolean;
};

export const useGetOrders = ({ tab, expertId, enabled, selectedBroker, mtType, isMagicTerminal }: Props) => {
  const [page, setPage] = useState<number>(0);
  const { handlePageChange } = usePaginationPreferences();

  const { openOrders, invalidateOpenOrderQueries } = useGetOpenOrders({ enabled: enabled || tab !== 'history', selectedBroker, mtType });

  const { sortedList } = useMemo(() => {
    const list: IOrder[] = [];

    for (const index in openOrders) {
      const order = openOrders[index];

      if (expertId && order.expertId !== expertId) continue;
      if (selectedBroker !== 'all' && selectedBroker !== order.accountId) continue;
      if (!isMagicOrder(order) && isMagicTerminal) continue;

      list.push(order);
    }

    return {
      sortedList: list.sort((a, b) => new Date(b.openTime).getTime() - new Date(a.openTime).getTime()),
    };
  }, [expertId, isMagicTerminal, openOrders, selectedBroker]);

  const pageSizeInput = useSelect<PageSizeOption>({
    defaultValue: { value: 10, label: '10' },
    onChange: (page) => handlePageChange('dashboard.balances', page?.value || 10),
  });

  const paginatedData = usePaginatedList<IOrder>(sortedList, {
    page,
    pageSize: pageSizeInput.value?.value || 10,
  });

  const state = {
    list: sortedList,
    totalOpenOrders: sortedList.length,
    page,
    pageSizeInput,
    paginatedData,
  };

  const handlers = { setPage, invalidateOpenOrderQueries };

  return [state, handlers] as [typeof state, typeof handlers];
};
