import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ModifyOrderDto } from '@root/modules/orders/dtos/modify-order.dto';
import { OrderType } from '@root/modules/orders/enums/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ModifyOrderServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export interface ModifyOrderServiceValues extends Omit<ModifyOrderDto, 'expertId'> {
  accountId: string;
}

export const modifyExternalOrderService = async ({ accountId, type, price, expirationTime, ...rest }: ModifyOrderServiceValues): Promise<ModifyOrderServiceResponse> => {
  try {
    const data = {
      ...rest,
      stoploss: Number(rest.stoploss),
      takeprofit: Number(rest.takeprofit),
      ticket: Number(rest.ticket),
      price: type === OrderType.PENDING ? Number(price) : undefined,
      expirationTime: type === OrderType.PENDING ? expirationTime : undefined,
    };

    await fetcher.put(`/accounts/${accountId}/orders`, data, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
