import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  bottom: 0;
  width: 100%;
  background: rgb(7, 0, 0);
  overflow: hidden;
  z-index: 1030;
  min-height: 100%;
  padding-top: 20%;
`;
