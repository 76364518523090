import { QueryFunction } from 'react-query';

import { getSharedExpert } from '@root/modules/experts/services/get-shared-expert.service';

import { IExpert } from '../types/expert';

export type GetExpertQueryKey = ['shared-experts', string];
export type GetExpertData = IExpert | null;

export const GET_SHARED_EXPERT: QueryFunction<GetExpertData, GetExpertQueryKey> = async ({ queryKey }) => {
  const [, expertId] = queryKey;

  if (!expertId) {
    throw new Error('Invalid query');
  }

  const result = await getSharedExpert(expertId);

  if (result.status === 200 || result.status === 403 || result.status === 404) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
