import { MarketPlaceTradeIdeaProvider } from '../types/marketplace-trade-idea-provider';
import {
  MarketPlaceTradeIdeaPlanMapper,
  MarketPlaceTradeIdeaSubscriptionMapper,
} from './marketplace-trade-idea.mapper';

export class MarketplaceTradeIdeaProviderMapper {
  public static toDomain(data): MarketPlaceTradeIdeaProvider {
    const ownerIds = [...(data.owner_id ? [data.owner_id] : []), ...(data.owner_ids?.length ? data.owner_ids : [])];

    return {
      id: data.id,
      name: data.name,
      logo: data.image_url,
      disclaimer: data.disclaimer,
      description: data.description,
      link: data.social_urls.site,
      socialsUrls: data.social_urls,
      statistics: {
        winsCount: data.statistics.wins,
        lossesCount: data.statistics.losses,
        tradeIdeaCount: data.statistics.signal_count,
        subscribersCount: data.statistics.subscribers_count,
        pnl: data.statistics.pnl,
      },
      showSlWonInfo: data.show_sl_won_info,
      allowDca: data.allowed_dca,
      allowSimple: data.allowed_simple,
      allowGrid: data.allowed_grid,
      allowDcaFutures: data.allowed_dca_futures,
      plans: data.plans?.map((item) => MarketPlaceTradeIdeaPlanMapper.toDomain(item)) || [],
      subscriptionStatus: data.subscription_status,
      subscription: data.subscription ? MarketPlaceTradeIdeaSubscriptionMapper.toDomain(data.subscription) : undefined,
      ownerIds: ownerIds,
      webhookId: data.webhookId,
      status: data.status,
      gainPerMonth: data.statistics.gain_per_month.map((obj) => {
        const date = new Date();
        date.setFullYear(obj.year);
        date.setMonth(obj.month - 1);

        return {
          date,
          value: obj.gain,
        };
      }),
      market: data.market,
    };
  }
}
