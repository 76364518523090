import { useQueries } from 'react-query';

import { useGetMarketplaceTradeIdeaList } from '@root/modules/marketplace/hooks/use-get-marketplace-trade-idea-list';
import { useGetSubscribedProviders } from '@root/modules/marketplace/hooks/use-get-subscribed-providers';
import {
  GetMarketplaceTradeIdeaProviderTradersServiceResponse,
  getMarketplaceTradeIdeaProviderTradersService,
} from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider-traders.service';
import { MarketPlaceTradeIdeaProviderTrader } from '@root/modules/marketplace/types/marketplace-trade-idea-provider-trader';

const controlParams = {
  page: 1,
  itemsPerPage: 99,
  search: '',
};

export const useProviderTraders = (showNotSubscribed?: boolean) => {
  const { data } = useGetMarketplaceTradeIdeaList(controlParams);
  const { data: subscribed } = useGetSubscribedProviders();

  let providers = subscribed?.data;

  if (showNotSubscribed) {
    providers = data?.data;
  }

  const tradersQueries = useQueries<GetMarketplaceTradeIdeaProviderTradersServiceResponse[]>(
    providers
      ?.filter((item) => !!item.subscription)
      ?.map((provider) => ({
        queryKey: ['trader', provider.id],
        queryFn: () =>
          getMarketplaceTradeIdeaProviderTradersService({
            id: provider.id,
            page: 1,
            itemsPerPage: 1000,
          }),
        enabled: !!provider.id,
        keepPreviousData: true,
        staleTime: 60 * 1000 * 3, // 3min
      })) || [],
  );

  const traders = tradersQueries
    .map((query) => {
      const data = query?.data as GetMarketplaceTradeIdeaProviderTradersServiceResponse;
      if (data?.status === 200) {
        return data?.payload?.data[0];
      }
      return null;
    })
    .filter(Boolean) as MarketPlaceTradeIdeaProviderTrader[];

  const emptyProviders = providers
    ?.filter((provider) => !traders?.find((trader) => trader?.providerId === provider?.id))
    ?.map((item) => ({
      ...item,
      providerId: item.id,
      market: item.market,
    }));

  return { traders: [...traders, ...(emptyProviders || [])].filter((item) => item?.id) as MarketPlaceTradeIdeaProviderTrader[] };
};
