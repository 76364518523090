import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IAccount } from '@root/modules/accounts';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { CreateAccountDto } from '../dtos/create-account.dto';
import { CreateAccountDtoMapper } from '../mappers/create-account-dto.mapper';

export type CreateAccountServiceResponse = IHttpResponse<200, IAccount> | IHttpResponse<400, string>;

export const createAccountService = async (values: CreateAccountDto): Promise<CreateAccountServiceResponse> => {
  try {
    const response = await fetcher.post(`/accounts`, CreateAccountDtoMapper.toPersistence(values), {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
