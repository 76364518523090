import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ExpertsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53129 2.64707C5.20621 2.64707 1.70005 6.15324 1.70005 10.4783C1.70005 14.8034 5.20621 18.3096 9.53129 18.3096C13.8564 18.3096 17.3625 14.8034 17.3625 10.4783C17.3625 10.0917 17.6759 9.77831 18.0625 9.77831C18.4491 9.77831 18.7625 10.0917 18.7625 10.4783C18.7625 15.5766 14.6296 19.7096 9.53129 19.7096C4.43302 19.7096 0.300049 15.5766 0.300049 10.4783C0.300049 5.38004 4.43302 1.24707 9.53129 1.24707C9.91789 1.24707 10.2313 1.56047 10.2313 1.94707C10.2313 2.33367 9.91789 2.64707 9.53129 2.64707Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5411 1.37118C15.6661 1.14235 15.906 1 16.1667 1C16.4274 1 16.6674 1.14235 16.7923 1.37118L17.345 2.38071C17.4103 2.501 17.5091 2.59978 17.6293 2.66509L18.6379 3.21677C18.8672 3.34187 19.0098 3.58218 19.0098 3.84335C19.0098 4.10451 18.8672 4.34482 18.6379 4.46992L17.6293 5.02255C17.5089 5.0876 17.41 5.18645 17.345 5.30693L16.7923 6.31551C16.6674 6.54434 16.4274 6.68669 16.1667 6.68669C15.906 6.68669 15.6661 6.54434 15.5411 6.31551L14.9885 5.30598C14.9234 5.1855 14.8246 5.08666 14.7041 5.02161L13.6955 4.46992C13.4662 4.34482 13.3236 4.10451 13.3236 3.84335C13.3236 3.58218 13.4662 3.34187 13.6955 3.21677L14.7041 2.66509C14.8244 2.59978 14.9231 2.501 14.9885 2.38071L15.5411 1.37118Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0164 8.58281C10.0164 8.19621 10.3298 7.88281 10.7164 7.88281H13.0861C13.7345 7.88281 14.2601 8.40841 14.2601 9.05677V13.7963C14.2601 14.4447 13.7345 14.9703 13.0861 14.9703H10.7164C10.3298 14.9703 10.0164 14.6569 10.0164 14.2703C10.0164 13.8837 10.3298 13.5703 10.7164 13.5703H12.8601V9.28281H10.7164C10.3298 9.28281 10.0164 8.96941 10.0164 8.58281Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.72861 11.1793H7.87236C8.25896 11.1793 8.57236 10.8659 8.57236 10.4793C8.57236 10.0927 8.25896 9.7793 7.87236 9.7793H5.50257C4.85421 9.7793 4.32861 10.3049 4.32861 10.9533V13.797C4.32861 14.4454 4.85421 14.971 5.50257 14.971H7.87236C8.25896 14.971 8.57236 14.6576 8.57236 14.271C8.57236 13.8844 8.25896 13.571 7.87236 13.571H5.72861V11.1793Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.57236 6.43906V13.5703H10.0161V6.43906H8.57236ZM7.17236 6.21302C7.17236 5.56466 7.69796 5.03906 8.34632 5.03906H10.2422C10.8905 5.03906 11.4161 5.56466 11.4161 6.21302V14.2703C11.4161 14.6569 11.1027 14.9703 10.7161 14.9703H7.87236C7.48576 14.9703 7.17236 14.6569 7.17236 14.2703V6.21302Z'
        fill='currentColor'
      />
    </svg>
  );
};
