import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaintenancePage from '@root/shared-files/modules/maintenance/pages';
import { isMaintenanceModeSelector } from '@root/shared-files/modules/maintenance/store/maintenance.selector';
import { getMaintenanceMode } from '@root/shared-files/modules/maintenance/services/get-maintenance-settings.service';
import { maintenanceSlice } from '@root/shared-files/modules/maintenance/store/maintenance.slice';

const withMaintenanceMode = (WrappedComponent) => {
  const WithMaintenanceMode = (props) => {
    const dispatch = useDispatch();
    const isMaintenanceMode = useSelector(isMaintenanceModeSelector);

    const handleFocus = async () => {
      const response = await getMaintenanceMode();
      if (response.status === 200) {
        dispatch(maintenanceSlice.actions.setIsMaintenance(response.payload));
      }
    };

    useEffect(() => {
      handleFocus().then(console.log);
      window.addEventListener('focus', handleFocus);
      return () => {
        window.removeEventListener('focus', handleFocus);
      };
    }, [dispatch]);

    if (isMaintenanceMode) {
      return <MaintenancePage />;
    }

    return <WrappedComponent {...props} />;
  };

  WithMaintenanceMode.displayName = `WithMaintenanceMode(${getDisplayName(WrappedComponent)})`;

  return WithMaintenanceMode;
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'AppComponent';
};

export default withMaintenanceMode;
