import successImage from '@root/assets/images/cases/success.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Title } from '@root/shared/ui/typography';
import { FC } from 'react';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { IS_UPGRADED_SUBSCRIPTION } from '../../shared/constants/local-storage-keys';

type Props = ModalProps & {};

export const SubscriptionSuccessModal: FC<Props> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation('subscriptions');
  const [isUpgraded] = useLocalStorage<boolean>(IS_UPGRADED_SUBSCRIPTION, false);

  return (
    <Modal onCancel={onCancel} footer={null} isOpen={isOpen}>
      <div className='flex flex-col items-center'>
        <Image src={successImage} height={120} width={120}  alt='success' unoptimized={true} />
        <Title className='mt-6 text-center px-8' level={5} bold>
          {isUpgraded ? t('success.upgraded') : t('success.text')}
        </Title>
        <Button onClick={onCancel} className='mt-4' tint='500'>
          {t('success.close')}
        </Button>
      </div>
    </Modal>
  );
};
