import { useCallback, useEffect, useState } from 'react';

import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback(
    (text: string) => {
      copyToClipboard(text);
      setIsCopied(true);
    },
    [setIsCopied],
  );

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  }, [isCopied]);

  return { isCopied, handleCopy };
};
