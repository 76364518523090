import isNull from 'lodash/isNull';

import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IRemapSymbolItem } from '@root/modules/accounts/types/remap-symbol-item';
import { IOption } from '@root/shared/types/general';
import { Select } from '@root/shared/ui/form';
import { Table } from '@root/shared/ui/table';

const { Row, DataCellSlim, DataCellSlimCenter } = Table;

interface IProps {
  index: number;
  item: IRemapSymbolItem;
  allOptions: IOption[];
  onSymbolChange: (v: Record<string, string | null>) => void;
}

export const RemapSymbolRow: FC<IProps> = ({ index, item, allOptions, onSymbolChange }) => {
  const { t } = useTranslation('forex-accounts', { keyPrefix: 'remapSymbol' });

  return (
    <Row key={index} className='border-t border-gray-700 text-sm'>
      <DataCellSlim className='hidden md:block'>{index + 1}</DataCellSlim>
      <DataCellSlimCenter>
        <div className={clsx(!item.status && 'border rounded border-warning-600')}>
          <Select
            tiny
            options={allOptions}
            name='account'
            value={item.mappedSymbol || isNull(item.mappedSymbol) ? { value: item.mappedSymbol, label: item.mappedSymbol || 'None' } : ''}
            onChange={(option) => onSymbolChange({ [item.sageSymbol]: option.value })}
          />
        </div>
      </DataCellSlimCenter>
      <DataCellSlimCenter>{item.sageSymbol}</DataCellSlimCenter>

      <DataCellSlimCenter>
        {item.status ? (
          <span className={`bg-gray-700 py-1 px-3 rounded-full ${item.status === 'active' ? 'text-success-400' : 'text-danger-500'} `}>{t(`rows.${item.status}`)}</span>
        ) : (
          <div className='flex w-full px-3'>—</div>
        )}
      </DataCellSlimCenter>
    </Row>
  );
};
