import { QueryFunction } from 'react-query';
import { GetExpertEventsQuery, GetExpertEventsResponseData, getExpertEventsService } from '../services/get-expert-events.service';

export type GetExpertEventsQueryKey = ['expert-events', GetExpertEventsQuery];
export type GetExpertEventsData = GetExpertEventsResponseData;

export const GET_EXPERT_EVENTS: QueryFunction<GetExpertEventsData, GetExpertEventsQueryKey> = async ({ queryKey }) => {
  const [, data] = queryKey;

  if (!data.userId || !data.expertId) {
    throw new Error('Invalid query');
  }

  const result = await getExpertEventsService(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
