import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 14 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.7272 0C9.34258 0 9.89249 0.371482 10.1273 0.932159L10.1727 1.05513L10.6681 2.60254H11.9277C11.9398 2.60254 11.9517 2.60287 11.9636 2.60352H12.7955C13.1548 2.60352 13.4461 2.89481 13.4461 3.25413C13.4461 3.58352 13.2013 3.85573 12.8837 3.89881L12.7955 3.90475H12.5783V13.4366C12.5783 14.8526 11.4308 16.0001 10.0149 16.0001H3.43062C2.01469 16.0001 0.867188 14.8526 0.867188 13.4366V3.90475H0.650618C0.291292 3.90475 0 3.61346 0 3.25413C0 2.92475 0.244766 2.65254 0.562333 2.60946L0.650618 2.60352H1.48185C1.49375 2.60287 1.50574 2.60254 1.51781 2.60254H2.77847L3.27367 1.05588C3.46086 0.468776 3.9827 0.0578106 4.5884 0.00562464L4.71939 0H8.7272ZM8.93361 1.45252L9.30182 2.60254H4.14477L4.51317 1.45191L4.53658 1.40171C4.57558 1.34011 4.64393 1.30124 4.71939 1.30124H8.7272L8.78187 1.30832C8.85221 1.32685 8.91044 1.3804 8.93361 1.45252ZM2.16797 3.9043H11.2766V13.4372C11.2766 14.1344 10.7117 14.6994 10.0144 14.6994H3.43017L3.30858 14.6936C2.66838 14.6324 2.16797 14.0934 2.16797 13.4372V3.9043ZM4.12034 6.02148C4.44973 6.02148 4.72194 6.26625 4.76502 6.58382L4.77096 6.6721V11.9291C4.77096 12.2884 4.47967 12.5797 4.12034 12.5797C3.79096 12.5797 3.51875 12.3349 3.47567 12.0174L3.46973 11.9291V6.6721C3.46973 6.31278 3.76102 6.02148 4.12034 6.02148ZM7.36756 5.57796C7.32448 5.26039 7.05227 5.01562 6.72288 5.01562C6.36356 5.01562 6.07227 5.30692 6.07227 5.66624V12.8404L6.07821 12.9287C6.12129 13.2462 6.3935 13.491 6.72288 13.491C7.08221 13.491 7.3735 13.1997 7.3735 12.8404V5.66624L7.36756 5.57796ZM9.32542 6.02148C9.65481 6.02148 9.92702 6.26625 9.9701 6.58382L9.97604 6.6721V11.9291C9.97604 12.2884 9.68475 12.5797 9.32542 12.5797C8.99604 12.5797 8.72383 12.3349 8.68074 12.0174L8.6748 11.9291V6.6721C8.6748 6.31278 8.9661 6.02148 9.32542 6.02148Z'
      />
    </svg>
  );
};
