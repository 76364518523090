import { MutationFunction } from 'react-query';
import { deleteExpertService } from '../services/delete-expert.service';

export type DeleteExpertMutationData = void;
export type DeleteExpertMutationValues = string;
export type DeleteExpertMutationError = Error;

export const DELETE_EXPERT_MUTATION: MutationFunction<DeleteExpertMutationData, DeleteExpertMutationValues> = async (values) => {
  const result = await deleteExpertService(values);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }
};
