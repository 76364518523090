import { FC } from 'react';
import { providerSocialStaticIcons } from '../constants/provider-socials-static-icons';

interface IProviderSocials {
  [key: string]: string;
}

export const ProviderSocials: FC<{ socials: IProviderSocials }> = ({ socials }) => {
  if (!socials || Object.keys(socials).length === 0) {
    return null;
  }

  return (
    <div className='flex items-center gap-2'>
      {Object.entries(socials).map((item) => {
        const name = item[0];

        if (!item[1] || !providerSocialStaticIcons[name]) {
          return null;
        }

        const Icon = providerSocialStaticIcons[name];

        return (
          <a href={item[1]} className='pr-2 text-grayscale-500 hover:text-info-300 pointer' target='_blank' rel='noreferrer' key={name}>
            <div className='text-2xl'>
              <Icon />
            </div>
          </a>
        );
      })}
    </div>
  );
};
