import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

import { IOrder } from '../types/orders';

export type GetOrderServiceResponse = IHttpResponse<200, IOrder[]> | IHttpResponse<400, string>;
export type GetOrderServiceQuery = { accountId: Id };

export const getOpenOrdersService = async ({ accountId }: GetOrderServiceQuery): Promise<GetOrderServiceResponse> => {
  try {
    const response = await fetcher(`/accounts/${accountId}/orders/open`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data.filter((item) => !!item.symbol).map((item) => OpenOrdersDtoMapper.toDomain(item, undefined, accountId as string)),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
