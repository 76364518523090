import isNull from 'lodash/isNull';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbols } from '@root/modules/accounts/hooks/use-get-symbols';
import { useSaveRemappedSymbols } from '@root/modules/accounts/hooks/use-save-remapped-symbols';
import { IRemapSymbolItem } from '@root/modules/accounts/types/remap-symbol-item';
import { IOption } from '@root/shared/types/general';
import { notify } from '@root/shared/utils/notification';

export const useSymbolRemapTable = (accountId) => {
  const symbols = useGetSymbols();
  const [initialMappedSymbols, setInitialMappedSymbols] = useState<Record<string, string | null>>({});
  const [mappedSymbols, setMappedSymbols] = useState<Record<string, string | null>>({});
  const { data, isLoading, dataUpdatedAt } = useGetAccounts();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSaveRemappedSymbols } = useSaveRemappedSymbols();

  const account = useMemo(() => {
    return data?.find((item) => item.id === accountId);
  }, [data, accountId]);

  const allOptions = useMemo(() => {
    const options: IOption[] = [{ value: null, label: 'None' }];
    account?.symbols.forEach((symbol) => {
      options.push({
        label: symbol,
        value: symbol,
      });
    });

    return options;
  }, [account?.symbols]);

  const list = useMemo<IRemapSymbolItem[]>(() => {
    return Object.keys(mappedSymbols)?.map((symbol) => ({
      sageSymbol: symbol,
      mappedSymbol: mappedSymbols[symbol],
      status: typeof mappedSymbols[symbol] === 'string' && !mappedSymbols[symbol]?.length ? '' : mappedSymbols[symbol] ? 'active' : 'disabled',
      isChanged: mappedSymbols[symbol] !== initialMappedSymbols[symbol],
    }));
  }, [account?.symbols, symbols.data, mappedSymbols, initialMappedSymbols]);

  const dataChanged = useMemo(() => list.some((item) => item.isChanged), [list]);

  const onSymbolChange = useCallback((item) => {
    setMappedSymbols((prev) => ({
      ...prev,
      ...item,
    }));
  }, []);

  const handleReset = useCallback(() => {
    setMappedSymbols(initialMappedSymbols);
  }, [initialMappedSymbols, setMappedSymbols]);

  const handleSaveSymbols = useCallback(async () => {
    setIsSubmitting(true);

    const remappedSymbols = {};

    for (const key in mappedSymbols) {
      if (mappedSymbols[key] !== '') {
        remappedSymbols[key] = mappedSymbols[key];
      }
    }

    const result = await handleSaveRemappedSymbols({
      symbols: mappedSymbols,
      accountId: account?.id as string,
      proxyServerId: account?.proxyServerId as string | null,
    });

    if (result.status === 200) {
      notify({
        type: 'success',
        title: 'Success',
      });
    } else {
      notify({
        type: 'danger',
        title: result.payload,
      });
    }

    setIsSubmitting(false);
  }, [mappedSymbols, account?.id, account?.proxyServerId, handleSaveRemappedSymbols]);

  useEffect(() => {
    if (symbols?.data && account?.symbols) {
      try {
        const data = symbols.data?.reduce((acc, symbol) => {
          const similarSymbols = account.symbols?.filter((accSymbol) => accSymbol.includes(symbol));
          const similarSymbolValue = similarSymbols.length === 1 ? similarSymbols[0] : '';
          const mappedSymbol = account?.symbolsMapping?.[symbol];
          acc[symbol] = isNull(mappedSymbol) ? null : mappedSymbol || similarSymbolValue;
          return acc;
        }, {});

        const unsorted = Object.entries(data);
        const sorted = Object.fromEntries(unsorted.sort((a, b) => a?.[0]?.length - b?.[0]?.length));

        setMappedSymbols(sorted as Record<string, string | null>);
        setInitialMappedSymbols(sorted as Record<string, string | null>);
      } catch (err) {
        console.log(err);
      }
    }
  }, [account?.symbols, symbols.data, accountId, dataUpdatedAt]);

  const state = {
    allOptions,
    mappedSymbols,
    list,
    dataChanged,
    isSubmitting,
    isLoading: isLoading || symbols.isLoading || !Object.keys(mappedSymbols).length,
  };

  const handlers = { onSymbolChange, handleSaveSymbols, handleReset };

  return [state, handlers] as [typeof state, typeof handlers];
};
