import dayjs from 'dayjs';

import { useEffect, useMemo, useState } from 'react';

import { GetMarketplaceTradeIdeaProviderData } from '../query/get-marketplace-trade-idea-provider.query';

export const useProviderAnalyticsChart = ({ data }: { data?: GetMarketplaceTradeIdeaProviderData; isLoading: boolean }) => {
  const dataSeries = useMemo(() => data?.gainPerMonth.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) || [], [data?.gainPerMonth]);
  const dataYears = useMemo(() => Array.from(new Set(dataSeries.map((item) => dayjs(item.date).year()))).sort((a, b) => b - a), [dataSeries]);

  const [activeYear, setActiveYear] = useState<number | undefined>();
  const [chartData, setChartData] = useState<Array<{ value: number; date: Date }>>(dataSeries);

  const yearsOptions = useMemo(() => dataYears.map((year) => ({ value: year, label: year })), [dataYears]);
  const yearsValue = useMemo(() => yearsOptions.find((option) => option.value === activeYear), [yearsOptions, activeYear]);

  const onYearChange = (option) => {
    setActiveYear(option.value);
  };

  useEffect(() => {
    setChartData(dataSeries.filter((item) => dayjs(item.date).year() === activeYear));
  }, [activeYear, dataSeries]);

  useEffect(() => {
    if (dataYears.length) {
      setActiveYear(dataYears[0]);
    }
  }, [dataYears]);

  const state = { chartData, yearsOptions, yearsValue };
  const handlers = { onYearChange };

  return [state, handlers] as [typeof state, typeof handlers];
};
