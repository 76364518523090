import { Paginate } from '@root/shared/ui/pagination';
import { Fragment } from 'react';
import { ProviderLastTradeIdeasTable } from '../components/provider-last-trade-ideas-table';
import { useMarketplaceTradeIdeaProviderIdeas } from '../hooks/use-marketplace-trade-provider-ideas';

export const ProviderLastTradeIdeas = ({ id, market }) => {
  const [{ list, isLoading, pageCount }, { changeControlParam }] = useMarketplaceTradeIdeaProviderIdeas(id);

  return (
    <Fragment>
      <ProviderLastTradeIdeasTable list={list} isLoading={isLoading} market={market}/>

      <div className='flex flex-wrap justify-center items-center my-5 px-5 relative'>
        <Paginate
          pageCount={pageCount || 1}
          forcePage={0}
          marginPagesDisplayed={1}
          disableInitialCallback
          onPageChange={(pageData) => changeControlParam({ page: pageData.selected + 1 })}
        />
      </div>
    </Fragment>
  );
};
