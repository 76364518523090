import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { GET_BROKERS, GetBrokersData, GetBrokersQueryKey } from '@root/modules/accounts/queries/get-brokers.query';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetBrokers = () => {
  const userId = useSelector(authSelector.userId);

  return useQuery<GetBrokersData, Error, GetBrokersData, GetBrokersQueryKey>(['brokers'], GET_BROKERS, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
    enabled: !!userId,
  });
};
