import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type EndGhostSessionResponse = IHttpResponse<200, { statusCode: 200; message: string }> | IHttpResponse<400, string>;

export const endGhostSession = async (): Promise<EndGhostSessionResponse> => {
  try {
    const response = await fetcher.post('/auth/end-ghost-session');

    return {
      status: 200,
      payload: response.data.message,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
