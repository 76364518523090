import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BreakEven as BreakEvenFields } from '@root/modules/experts/components/break-even';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';

import { EditableSettingsTitle } from './editable-settings-title';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { FxBreakevenType } from '@3lgn/shared/dist/constants/fx';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const BreakEven: FC<Props> = ({ expert, editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { values } = useFormikContext<CreateExpertDto>();
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.BreakEven);

  const use = values.breakEven.use;
  const levels = values.breakEven.levels;

  const options = useCreateExpertFieldOptions(values?.unitType);

  const breakEvenType = options.breakEvenTypeOptions.find((item) => item.value === values.breakEven.type)?.label;
  const breakEvenUnits = expert?.breakEven?.type === FxBreakevenType.PERCENT ? '%' : values?.unitType;

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.breakEven.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.BreakEven}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <BreakEvenFields use={values.breakEven.use} levels={values.breakEven.levels} isPartialEdit />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.breakEven.use.label')}
            </Text>
            <Text size='base' className='text-right'>
              {use ? t('on') : t('off')}
            </Text>
          </div>
          {
            use && <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.breakEven.breakEvenType.label')}
              </Text>
              <Text size='base' className='text-right'>
                {breakEvenType || '-'}
              </Text>
            </div>
          }
          {levels?.length
            ? levels.map((level, index) => (
              <div className='w-full' key={index}>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  #{index + 1}
                </Text>
                <div className='pl-4 w-full'>
                  <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.breakEven.levels.afterX.label')}
                    </Text>
                    <Text size='base' className='text-right'>
                      {level?.afterX} {breakEvenUnits}
                    </Text>
                  </div>
                  <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.breakEven.levels.setToY.label')}
                    </Text>
                    <Text size='base' className='text-right'>
                      {level?.setToY} {breakEvenUnits}
                    </Text>
                  </div>
                </div>
              </div>
            ))
            : null}
        </div>
      )}
    </div>
  );
};
