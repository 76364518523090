import styled from 'styled-components';

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { IOrder } from '@root/modules/orders/types/orders';
import { SettingsIcon } from '@root/shared/icons/settings-icon';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { PencilIcon } from '@root/shared/icons/pencil-icon';
import { CrossIcon } from '@root/shared/icons/cross-icon';
import { CircleCancelIcon } from '@root/shared/icons/circle-cancel-icon';
import { ArrowGoUpIcon } from '@root/shared/icons/arrow-go-up';
import { ShareDotsIcon } from '@root/shared/icons/share-dots-icon';
import { ChartPieIcon } from '@root/shared/icons/chart-pie-icon';
import { isLimitOrder } from '@root/modules/orders/utils/orders';

const DropdownContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  z-index: 1000;
  background: ${({ theme }) => theme.gray[700]};
  box-shadow: 0 10px 24px rgba(12, 12, 12, 0.6);
  min-width: 240px;
`;

type Props = {
  data: IOrder;
  status: 'open' | 'pending';
  modifyOrder: (order: IOrder) => void;
  modifyTrade: (order: IOrder) => void;
  modifyPartialClose: (order: IOrder) => void;
  deleteOrder: (order: IOrder) => void;
  shareOrder: (order: IOrder) => void;
};

export const ActiveTradeSettings: FC<Props> = ({ data, status, modifyOrder, modifyTrade, deleteOrder, shareOrder, modifyPartialClose }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');
  const { hideActions } = useGhostLogin();

  return (
    <div className='relative' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <Button variant='gray' tint='700' className='border-none' disabled={hideActions}>
        <SettingsIcon width={20} height={20} />
      </Button>
      {isOpen && !hideActions && (
        <DropdownContent className='py-6 px-8 z-50'>
          <div
            className='flex items-center gap-3 cursor-pointer mb-6'
            onClick={() => {
              modifyOrder(data);
              setIsOpen(false);
            }}
          >
            <ArrowGoUpIcon className='flex-shrink-0' />{' '}
            <Text size='sm' className='font-semibold text-gray-100'>
              {t('tradeItemCard.updateTpSl')}
            </Text>
          </div>
          {data.expertId && <div
            className='flex items-center gap-3 cursor-pointer mb-6'
            onClick={() => {
              modifyTrade(data);
              setIsOpen(false);
            }}
          >
            <PencilIcon className='flex-shrink-0' />{' '}
            <Text size='sm' className='font-semibold text-gray-100'>
              {t('tradeItemCard.editTrade')}
            </Text>
          </div>}
          {!isLimitOrder(data.type) && (
            <div
              className='flex items-center gap-3 cursor-pointer mb-6'
              onClick={() => {
                modifyPartialClose(data);
                setIsOpen(false);
              }}
            >
              <ChartPieIcon className='flex-shrink-0' />
              <Text size='sm' className='font-semibold text-gray-100'>
                {t('tradeItemCard.closePartially')}
              </Text>
            </div>
          )}

          <div
            className='flex items-center gap-3 cursor-pointer mb-6'
            onClick={() => {
              deleteOrder(data);
              setIsOpen(false);
            }}
          >
            {
              status === 'open' ? <CircleCancelIcon className='flex-shrink-0' /> : <CrossIcon className='flex-shrink-0' />
            }
            <Text size='sm' className='font-semibold text-gray-100'>
              {status === 'open' ? t('tradeItemCard.closeTrade') : t('tradeItemCard.deleteTrade')}
            </Text>
          </div>
          <div
            className='flex items-center gap-3 cursor-pointer'
            onClick={() => {
              shareOrder(data);
              setIsOpen(false);
            }}
          >
            <ShareDotsIcon className='flex-shrink-0'/>{' '}
            <Text size='sm' className='font-semibold text-gray-100'>
              {t('share', { ns: 'forex-common' })}
            </Text>
          </div>
        </DropdownContent>
      )}
    </div>
  );
};
