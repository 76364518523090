import { IOrder } from '@root/modules/orders/types/orders';
import { gerOrderType } from '@root/modules/orders/utils/orders';
import { IQuote } from '@root/modules/quotes/types';
import { globalRound } from '@root/shared/utils/number/round';

const calculateUnits = (data: IOrder) => {
  let units = 0;
  const takeProfitUnits = 0;
  const stopLossUnits = 0;

  if (!data.symbol) {
    return [0, 0, 0];
  }

  const usePips = data.expertConfiguration?.usePips;
  const isJPY = data.symbol?.includes('JPY');
  const isXAU = data.symbol?.includes('XAU') || data.symbol?.includes('GOLD_USD') || data.additionalDetails?.symbolBeforeMapping?.includes('XAU');

  const oneUnit = usePips ? (isJPY ? 0.01 : isXAU ? 0.1 : 0.0001) : 1;

  // units for history orders
  if (data.closePrice && data.openPrice && data.closeTime) {
    units = (data.closePrice - data.openPrice) / oneUnit;
  }

  // units for open orders
  if (data.currentPrice.ask && data.openPrice && !data.closeTime && data.profit) {
    units = (data.currentPrice.ask - data.openPrice) / oneUnit;
  }

  if (data.type.includes('Sell')) {
    units = units * -1;
  }

  return [units, takeProfitUnits, stopLossUnits];
};

export class OpenOrdersDtoMapper {
  public static addCurrentPrice(data, currentPrice?: IQuote): IOrder {
    const normalizedData: IOrder = { ...data, currentPrice: currentPrice ? { ask: globalRound(currentPrice.ask, 6), bid: globalRound(currentPrice.bid, 6) } : { ask: 0, bid: 0 } };

    const [units, takeProfitUnits, stopLossUnits] = calculateUnits(normalizedData);

    normalizedData.calculatedUnits = units;
    normalizedData.takeProfitUnits = takeProfitUnits;
    normalizedData.stopLossUnits = stopLossUnits;

    return normalizedData;
  }

  public static toDomain(data, currentPrice?: { bid: number; ask: number }, accountId?: string, currency?: string): IOrder {
    const normalizedData: IOrder = {
      ticket: data.ticket,
      openTime: data.openTime,
      closeTime: data.closeTime?.includes('1970') ? null : data.closeTime,
      type: gerOrderType(Number(data.type)),
      lots: data.lots || 0,
      symbol: data.symbol,
      openPrice: data.openPrice,
      stopLoss: data.stopLoss || 0,
      takeProfit: data.takeProfit || 0,
      closePrice: data.closePrice || 0,
      magicNumber: data.magicNumber || 0,
      swap: data.swap || 0,
      commission: data.commission || 0,
      comment: data.comment,
      profit: data.profit || 0,
      rateOpen: globalRound(data.rateOpen || 0, 5),
      rateClose: globalRound(data.rateClose || 0, 5),
      rateMargin: globalRound(data.rateMargin || 0, 5),
      calculatedUnits: 0,
      takeProfitUnits: 0,
      stopLossUnits: 0,
      currentPrice: { ask: globalRound(currentPrice?.ask || 0, 6), bid: globalRound(currentPrice?.bid || 0, 6) },
      accountId: data.accountId || accountId,
      volume: data.volume,
      expertId: data.expertId,
      openedBy: data.openedBy,
      signalId: data.signalId,
      providerId: data.providerId,
      expirationTime: data?.expirationTime,
      brokerCompanyName: data?.brokerCompanyName,
      source: data?.source,
      additionalDetails: data?.additionalDetails,
      dynamicPartialCloseTakeProfits: data?.dynamicPartialCloseTakeProfits,
      expertConfiguration: data?.expertConfiguration,
      updatedAt: data.updatedAt,
      createdAt: data.createdAt,
      deletedAt: data.deletedAt,
      status: data.status,
      id: data.id,
      childOrders: data.childOrders?.map((childOrder) => OpenOrdersDtoMapper.toDomain(childOrder, currentPrice, accountId)) || [],
      currency,
    };

    const [units, takeProfitUnits, stopLossUnits] = calculateUnits(normalizedData);

    normalizedData.calculatedUnits = units;
    normalizedData.takeProfitUnits = takeProfitUnits;
    normalizedData.stopLossUnits = stopLossUnits;

    return normalizedData;
  }
}
