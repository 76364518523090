import React, { FC } from 'react';

import { Text } from '@root/shared/ui/typography';


type Props = {
  label: string;
  value: string;
};

export const TradeTime: FC<Props> = ({ label, value }) => {
  return (
    <div className='w-24'>
      <Text size='xs' className='text-grayscale-500 mb-1 font-medium'>
        {label}
      </Text>
      <Text size='xs' className='text-grayscale-200 font-medium'>
        {value}
      </Text>
    </div>
  );
};
