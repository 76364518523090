export const getUnitData = (symbol: string) => {
  if (symbol.includes('JPY')) {
    return {
      pip: 100,
      point: 1,
      pipSize: 0.01,
    };
  }

  return {
    pip: 10_000,
    point: 1,
    pipSize: 0.0001,
  };
};
