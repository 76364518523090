import { QueryFunction } from 'react-query';

import { getPresetsService } from '../services/get-presets.service';
import { IPreset } from '../types/preset';

export type GetPresetsQueryKey = ['fx', 'presets'];
export type GetPresetsData = IPreset[];

export const GET_PRESETS: QueryFunction<GetPresetsData, GetPresetsQueryKey> = async () => {
  const result = await getPresetsService();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
