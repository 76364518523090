import { FxBreakevenType } from '@3lgn/shared/dist/constants/fx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import clsx from 'clsx';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IDText } from '@root/modules/experts/components/details/trades-and-history/active-trade-item.ui';
import { useTradeCardIndicators } from '@root/modules/experts/hooks/use-trade-card-indicators';
import { IOrder } from '@root/modules/orders/types/orders';
import { isLimitOrder } from '@root/modules/orders/utils/orders';
import { Button } from '@root/shared/ui/button';
import { Toggler } from '@root/shared/ui/toggler/toggler';
import { Text } from '@root/shared/ui/typography';
import { getGainLostColor, getOrderSideColor } from '@root/shared/utils/colors';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { globalRound } from '@root/shared/utils/number/round';
import { formatIfNegativeAmount } from '@root/shared/utils/string';

import { StatusBagde } from '../../experts.ui';
import { ActiveTradeSettings } from './active-trade-settings';
import { OrderStatus } from './order-status';
import { TradeItemBullet } from './trade-item-bullet';

type Props = {
  data: IOrder;
  deleteOrder: (order: IOrder) => void;
  modifyOrder: (order: IOrder) => void;
  modifyTrade: (order: IOrder) => void;
  modifyPartialClose: (order: IOrder) => void;
  showOrderLogs: (order: IOrder) => void;
  shareOrder: (order: IOrder) => void;
  enabledIds: number[];
  addToEnabledIds: (id: number) => void;
  removeFromEnabledIds: (id: number) => void;
};

dayjs.extend(utc);

export const ActiveTradeItem: FC<Props> = ({
  data,
  deleteOrder,
  showOrderLogs,
  modifyOrder,
  modifyTrade,
  shareOrder,
  modifyPartialClose,
  enabledIds,
  addToEnabledIds,
  removeFromEnabledIds,
}) => {
  const [autoUpdateEnabled, setAutoUpdateEnabled] = useState<boolean>(enabledIds.includes(data.ticket));
  const [savedCurrentPrice, setSavedCurrentPrice] = useState<number>(data?.currentPrice?.ask || data?.currentPrice?.bid || 0);
  const { t } = useTranslation('forex-experts');
  const calculatedUnits = Math.round(Math.abs(data?.calculatedUnits));
  const { partialCloseEnabled, partialTakeProfits, trailStopEnabled, trailingStop, breakEvenEnabled, breakEvenLevels } = useTradeCardIndicators({ order: data });
  const unitType = data?.expertConfiguration?.usePips ? 'pips' : 'points';
  const breakEvenUnits = data?.expertConfiguration?.breakEven?.type === FxBreakevenType.PIPS ? unitType : '%';
  const currentPrice = data?.takeProfit < data?.stopLoss ? data?.currentPrice?.ask : data?.currentPrice?.bid;
  const status = isLimitOrder(data.type) ? 'pending' : 'open';

  const handleAutoUpdateChange = useCallback(
    (isEnabled) => {
      setAutoUpdateEnabled(isEnabled);
      if (isEnabled) {
        addToEnabledIds(data.ticket);
      } else {
        removeFromEnabledIds(data.ticket);
      }
    },
    [data, addToEnabledIds, removeFromEnabledIds],
  );

  useEffect(() => {
    if (!savedCurrentPrice) {
      setSavedCurrentPrice(data?.currentPrice?.ask || data?.currentPrice?.bid || 0);
    }
  }, [savedCurrentPrice, data?.currentPrice?.ask, data?.currentPrice?.bid]);

  return (
    <div className='p-6 w-full bg-gray-800 rounded relative'>
      <div className='border-b border-gray-700 pb-4'>
        <div className='w-full flex justify-between items-center gap-4'>
          <div className='flex items-center gap-x-4'>
            <Text size='lg' className='font-bold'>
              {data?.symbol}
            </Text>
            <Text size='sm' className={clsx(`whitespace-nowrap bg-gray-700 py-1 px-3 rounded-full ${getOrderSideColor(data?.type)}`)}>
              {data?.type}
            </Text>
          </div>
          <div className='flex justify-end items-center gap-x-4'>
            <div className='flex justify-end items-center gap-x-4'>
              <Text size='sm' className='text-grayscale-500'>
                {t('tradeItemCard.autoUpdate')}
              </Text>
              <Toggler checked={autoUpdateEnabled} onChange={(value) => handleAutoUpdateChange(value)} />
            </div>
            <div className='flex justify-end items-center gap-x-2'>
              <ActiveTradeSettings
                data={data}
                status={status}
                deleteOrder={deleteOrder}
                modifyOrder={modifyOrder}
                modifyTrade={modifyTrade}
                shareOrder={shareOrder}
                modifyPartialClose={modifyPartialClose}
              />
            </div>
          </div>
        </div>
        <div className='w-full flex justify-start items-center gap-x-4'>
          <Text size='sm'>{data?.expertConfiguration?.name}</Text>
          <IDText size='sm' className='whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer' onClick={() => copyToClipboard(data.ticket)}>
            ID: {data?.ticket || '-'}
          </IDText>
        </div>
      </div>
      <div className='py-4 w-full flex justify-between items-center gap-x-4'>
        <div>
          <Text size='sm' className='text-grayscale-500 font-semibold mb-2'>
            {t('tradeItemCard.date')}
          </Text>
          <Text size='sm' className='text-gray-100 font-medium'>
            {data?.openTime ? dayjs(data.openTime).local().format('DD/MM/YYYY hh:mm:ss A') : '-'}
          </Text>
        </div>
        <div>
          <div className='flex justify-end items-center gap-x-4'>
            <div>
              <Text size='sm' className='text-grayscale-500 font-semibold mb-2'>
                {t('tradeItemCard.lotSize')}
              </Text>
              <Text size='sm' className='text-gray-100 font-medium'>
                {data.lots || '-'}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-between items-center gap-x-4'>
        <div className='flex justify-start items-center gap-x-4'>
          <Text size='sm'>{t('tradeItemCard.status')}</Text>
          <StatusBagde isRunning={status === 'open'}>
            <Text size='sm'>{status === 'pending' ? t('tradeItemCard.pending') : t('tradeItemCard.open')}</Text>
          </StatusBagde>
        </div>
        <div>
          <Text size='sm' className='text-right text-grayscale-500 font-semibold'>
            {t('tradeItemCard.pnl')}
          </Text>
          <Text size='sm'>
            <span className='whitespace-nowrap'>
              <span className={`ml-1 ${getGainLostColor(data?.profit)}`}>{formatIfNegativeAmount(globalRound(data?.profit, 2), getSymbolFromCurrency(data.currency || ''))}</span> /
              <span className={`ml-1 ${getGainLostColor(data?.profit)}`}>
                {calculatedUnits} {unitType}
              </span>
            </span>
          </Text>
        </div>
      </div>
      <OrderStatus
        current={autoUpdateEnabled ? globalRound(currentPrice || 0, 6) : globalRound(savedCurrentPrice, 6)}
        targets={[
          {
            reached: false,
            value: globalRound(data?.takeProfit || 0, 6),
            trailingTP: false,
          },
        ]}
        placed={globalRound(data?.openPrice || 0, 6)}
        stopLoss={{ price: globalRound(data?.stopLoss || 0, 6), reached: false }}
        trailingSL={false}
        trailingTP={false}
        isSell={data?.takeProfit < data?.stopLoss}
        status={status}
      />
      {(breakEvenEnabled || trailStopEnabled) && (
        <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-2 pb-4 mb-4 border-b border-[#373D47]'>
          {breakEvenEnabled && (
            <div>
              <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
                {t('tradeItemCard.breakEvenLevels')}
              </Text>
              {breakEvenLevels?.map((level, index) => (
                <div className='mb-1' key={index}>
                  <TradeItemBullet checked={level.enabled}>
                    <div>
                      <Text className='!text-[11px] font-semibold flex justify-start items-center gap-x-1'>
                        <span className='text-grayscale-500'>{t('tradeItemCard.profitDistance')}:</span>{' '}
                        <span className='text-gray-100'>
                          {level?.afterX} {breakEvenUnits}
                        </span>
                      </Text>
                      <Text className='!text-[11px] font-semibold flex justify-start items-center gap-x-1'>
                        <span className='text-grayscale-500'>{t('tradeItemCard.stopLossAdjustment')}:</span>{' '}
                        <span className='text-gray-100'>
                          {level?.setToY} {breakEvenUnits}
                        </span>
                      </Text>
                    </div>
                  </TradeItemBullet>
                </div>
              ))}
            </div>
          )}
          {trailStopEnabled && (
            <div>
              <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
                {t('tradeItemCard.trailingStopLoss')}
              </Text>
              <TradeItemBullet checked={trailingStop?.enabled} className='mb-1'>
                <div>
                  <Text className='!text-[11px] font-semibold flex justify-start items-center gap-x-1'>
                    <span className='text-grayscale-500'>{t('tradeItemCard.activateAfterProfit')}:</span>
                    <span className='text-gray-100'>
                      {trailingStop?.afterX} {unitType}
                    </span>
                  </Text>
                  <Text className='!text-[11px] font-semibold flex justify-start items-center gap-x-1'>
                    <span className='text-grayscale-500'>{t('tradeItemCard.trailingDistance')}:</span>
                    <span className='text-gray-100'>
                      {trailingStop?.followY} {unitType}
                    </span>
                  </Text>
                </div>
              </TradeItemBullet>
            </div>
          )}
        </div>
      )}
      <div className='w-full flex justify-between gap-2'>
        {partialCloseEnabled && (
          <div>
            <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
              {t('tradeItemCard.partialClose')}
            </Text>
            {partialTakeProfits?.map((takeProfit, index) => (
              <TradeItemBullet checked={takeProfit?.enabled} className='mb-1' key={index}>
                <div>
                  <Text className='!text-[11px] font-semibold mb-1 flex justify-start items-center gap-x-1 whitespace-nowrap'>
                    <span className='text-grayscale-500'>{t('tradeItemCard.distanceFromOpen')}:</span>
                    <span className='text-gray-100'>
                      {takeProfit?.amount} {unitType}
                    </span>
                  </Text>
                  <Text className='!text-[11px] font-semibold flex justify-start items-center gap-x-1 whitespace-nowrap'>
                    <span className='text-grayscale-500'>{t('tradeItemCard.closePercentage')}:</span>
                    <span className='text-gray-100'>{takeProfit?.percent}</span>
                  </Text>
                </div>
              </TradeItemBullet>
            ))}
          </div>
        )}
        <div>
          <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
            {t('tradeItemCard.liquidationPrice')}
          </Text>
          <Text size='sm' className='font-semibold'>
            {globalRound(data.closePrice || 0, 6)}
          </Text>
        </div>
        <div>
          <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
            {t('tradeItemCard.openPrice')}
          </Text>
          <Text size='sm' className='font-semibold'>
            {globalRound(data.openPrice || 0, 6)}
          </Text>
        </div>
        <div>
          <Text size='sm' className='pb-2 font-semibold text-grayscale-500'>
            {t('tradeItemCard.currentPrice')}
          </Text>
          <Text size='sm' className='font-semibold'>
            {globalRound(currentPrice || 0, 6) || '-'}
          </Text>
        </div>
      </div>

      <div className='mt-4 flex justify-end'>
        <Button outlined onClick={() => showOrderLogs(data)}>
          {t('tradeItemCard.viewLogs')}
        </Button>
      </div>
    </div>
  );
};
