import { useQuery } from 'react-query';
import {
  GetMarketplaceTradeIdeaProviderTradersData,
  GetMarketplaceTradeIdeaProviderTradersQueryKey,
  GET_MARKETPLACE_TRADE_IDEA_PROVIDER_TRADERS,
} from '../query/get-marketplace-trade-idea-provider-traders.query';

export const useGetMarketplaceTradeIdeaProviderTraders = (params) => {
  return useQuery<GetMarketplaceTradeIdeaProviderTradersData, Error, GetMarketplaceTradeIdeaProviderTradersData, GetMarketplaceTradeIdeaProviderTradersQueryKey>(
    ['marketplace-trade-idea-provider-traders', params],
    GET_MARKETPLACE_TRADE_IDEA_PROVIDER_TRADERS,
    {
      keepPreviousData: true,
      enabled: !!params.id,
    },
  );
};
