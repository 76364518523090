import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { queryClient } from '@root/infra/query';
import { ModifyModalProps } from '@root/modules/orders/components/update-order-modal';
import { ModifyOrderDto } from '@root/modules/orders/dtos/modify-order.dto';
import { OrderType } from '@root/modules/orders/enums/orders';
import { modifyOrderService } from '@root/modules/orders/services/modify-order.service';
import { isLimitOrder, isMagicOrder } from '@root/modules/orders/utils/orders';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';
import { Id } from '@root/shared/utils/types';

import { modifyExternalOrderService } from '../services/modify-external-order.service';

export const useModifyOrder = (props: ModifyModalProps) => {
  const { t } = useTranslation('forex-orders');
  const userId = useSelector(authSelector.userId);

  const isMagic = isMagicOrder(props.order);

  const location = useLocation();

  const expertId = location.pathname.replace('/assists/', '');

  const initialValues = useMemo<ModifyOrderDto>(
    () => ({
      accountId: props.order.accountId,
      stoploss: `${props.order.stopLoss || 0}`,
      takeprofit: `${props.order.takeProfit || 0}`,
      expertId: (props.order.expertId as string) || '64a430aac025182a5a357618',
      userId: userId as string,
      type: isLimitOrder(props.order.type) ? OrderType.PENDING : OrderType.INSTANT,
      symbol: props.order.symbol,
      ticket: props.order.ticket.toString(),
      price: props.order.openPrice.toString(),
      expirationTime: new Date(props.order.expirationTime).getTime() > 0 ? new Date(props.order.expirationTime) : new Date('1970-01-01T00:00:00.000Z'),
    }),
    [props.order, userId],
  );

  const onSubmit = useCallback(
    async (values: ModifyOrderDto, helpers: FormikHelpers<ModifyOrderDto>) => {
      helpers.setStatus(undefined);

      let result;

      if (props.order.source === 'EXTERNAL') {
        const data = { ...values, accountId: props.order.accountId as string, expertId: undefined };
        result = await modifyExternalOrderService(data);
      } else {
        result = await modifyOrderService({ ...values, isMagic });
      }

      if (result.status === 200) {
        helpers.setStatus({ type: 'success', message: null } as IFormStatus);

        expertId ? queryClient.invalidateQueries(['fx', expertId]) : queryClient.invalidateQueries(['fx', props.order.accountId as Id, 'open-orders']);

        notify({
          type: 'success',
          title: t('updated'),
        });

        props.closeModal();
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({
          type: 'danger',
          title: result.payload,
        });
      }
    },
    [props.order, userId, expertId],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        stoploss: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required()
          .label(t('fields.sl.label')),
        takeprofit: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required()
          .label(t('fields.tp.label')),
        price: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .when('type', {
            is: OrderType.PENDING,
            then: (schema) => schema.required(),
          })
          .label(t('manualOrders.pending.fields.price.label', { ns: 'forex-experts' })),
        expirationTime: yup
          .string()
          .when('type', {
            is: OrderType.PENDING,
            then: (schema) =>
              schema.required().test('isValidDate', t('date.invalidDate', { ns: 'yup' }), (value) => {
                if (value && new Date(value).getTime() > 0) {
                  return new Date(value).getTime() > new Date().getTime();
                }

                return true;
              }),
          })
          .label(t('manualOrders.pending.fields.expirationTime.label', { ns: 'forex-experts' })),
      }),
    [t],
  );

  return { initialValues, onSubmit, validationSchema };
};
