import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const LightningIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.17111 0.0223943C6.46327 0.0999822 6.66667 0.364438 6.66667 0.666727V5.33339H11.3333C11.571 5.33339 11.7907 5.4599 11.9099 5.66544C12.0292 5.87099 12.0301 6.12449 11.9122 6.33082L6.57883 15.6642C6.42885 15.9266 6.12105 16.0553 5.82889 15.9777C5.53673 15.9001 5.33333 15.6357 5.33333 15.3334V10.6667H0.666667C0.42902 10.6667 0.209341 10.5402 0.09006 10.3347C-0.0292214 10.1291 -0.030068 9.87564 0.0878379 9.6693L5.42117 0.335968C5.57115 0.0735077 5.87895 -0.0551936 6.17111 0.0223943ZM1.81545 9.3334H6C6.36819 9.3334 6.66667 9.63187 6.66667 10.0001V12.823L10.1845 6.66673H6C5.63181 6.66673 5.33333 6.36825 5.33333 6.00006V3.1771L1.81545 9.3334Z'
      />
    </svg>
  );
};
