import { PayloadAction } from '@reduxjs/toolkit';

import { SocketActionTypes } from '@root/infra/socket/socket-types';

type Data = { eventName: string; params: any };
// export type SocketAction = (data: Data) => { type: SocketActionTypes; payload: Data };

export type SocketDispatchAction = PayloadAction<Data, SocketActionTypes>;

export const emitSocketEvent: (data: Data) => SocketDispatchAction = (data) => ({
  type: SocketActionTypes.EMMIT_EVENT,
  payload: data,
});
