import { useMemo } from 'react';
import { IOrder } from '@root/modules/orders/types/orders';
import { IExpert } from '../types/expert';

type Props = {
  order: IOrder;
};

type Indicator<T> = T & { enabled: boolean };

export const useTradeCardIndicators = ({ order }: Props) => {
  const breakEvenLevels = useMemo<Indicator<IExpert['breakEven']['levels'][0]>[]>(() => {
    return order?.expertConfiguration?.breakEven?.levels.map((level) => {
      const enabled = false;

      return {
        ...level,
        enabled,
      };
    }) || [];
  }, [order?.expertConfiguration?.breakEven?.levels]);

  const trailingStop = useMemo<Indicator<IExpert['trailStop']>>(() => {
    const enabled = order?.additionalDetails?.options?.trailingStop?.isReportSent;

    return { ...(order?.expertConfiguration?.trailStop || {}) as IExpert['trailStop'], enabled };
  }, [order?.additionalDetails?.options?.trailingStop?.isReportSent, order?.expertConfiguration?.trailStop]);

  const partialTakeProfits = useMemo<Indicator<IExpert['partialClose']['takeprofits'][0]>[]>(() => {
    if (order.dynamicPartialCloseTakeProfits?.length) {
      return order.dynamicPartialCloseTakeProfits.map((takeprofit, index) => {
        const enabled = order?.additionalDetails?.options?.partialClose?.level > index;

        return {
          ...takeprofit,
          enabled,
        };
      });
    }

    return order?.expertConfiguration?.partialClose?.takeprofits.map((takeprofit, index) => {
      const enabled = order?.additionalDetails?.options?.partialClose?.level > index;

      return {
        ...takeprofit,
        enabled,
      };
    }) || [];
  }, [order]);

  return {
    breakEvenLevels,
    trailingStop,
    partialTakeProfits,
    breakEvenEnabled: order?.expertConfiguration?.breakEven?.use,
    trailStopEnabled: order?.expertConfiguration?.trailStop?.use,
    partialCloseEnabled: order?.expertConfiguration?.partialClose?.use,
  };
};
