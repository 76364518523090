import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { deleteOrderService } from '@root/modules/orders/services/delete-order.service';
import { IOrder } from '@root/modules/orders/types/orders';
import { isBuyOrder, isSellOrder } from '@root/modules/orders/utils/orders';
import { Button } from '@root/shared/ui/button';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { Modal } from '@root/shared/ui/modal';
import { Switch, SwitchItem } from '@root/shared/ui/switch';
import { Text } from '@root/shared/ui/typography';
import { Title } from '@root/shared/ui/typography/title';
import { notify } from '@root/shared/utils/notification';
import { TabType } from '../enums/orders';
import { globalRound } from '@root/shared/utils/number/round';

type Props = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
  status: TabType;
  onOrderClose?: () => void;
};

export const CloseOrderModal: FC<Props> = ({ isOpen, status, closeModal, onOrderClose, order }) => {
  const { t } = useTranslation('forex-orders');
  const userId = useSelector(authSelector.userId);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { traders } = useProviderTraders();

  const { hideActions } = useGhostLogin();

  const trader = useMemo(() => traders.find((item) => item.providerId === order.providerId), [order.providerId, traders]);

  const details = [
    {
      label: t('manualOrders.fields.stopLoss.label', { ns: 'forex-experts' }),
      value: order.stopLoss || '-',
    },
    {
      label: t('manualOrders.fields.takeProfit.label', { ns: 'forex-experts' }),
      value: order.takeProfit || '-',
    },
    {
      label: t('OpeningRate'),
      value: order.rateOpen || '-',
    },
    {
      label: t('CurrentRate'),
      value: order.rateMargin || '-',
    },
    // {
    //   label: t('Interest'),
    //   value: order.interest || '-',
    // },
    {
      label: t('Commission'),
      value: globalRound(order.commission, 2) || '-',
    },
    {
      label: t('BrokerTicket'),
      value: order.ticket || '-',
    },
  ];

  const handleCloseOrder = useCallback(async () => {
    setLoading(true);
    const response = await deleteOrderService(userId as string, order);
    if (response.status === 200) {
      notify({
        type: 'success',
        title: t('orderClosed'),
      });
      onOrderClose?.();
      closeModal();
    } else {
      notify({
        type: 'danger',
        title: response.payload,
      });
    }
    setLoading(false);
  }, [userId, order, t, onOrderClose, closeModal]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeModal}
      footer={null}
      title={
        <div className='flex w-full gap-x-6 mt-2 px-2'>
          <Title level={6} className='font-bold text-center'>
            {order.symbol}
          </Title>
          <Text
            size='sm'
            className={clsx('flex justify-center items-center bg-gray-700 py-0.5 px-3 rounded-full font-medium', {
              'text-danger-500': isSellOrder(order.type),
              'text-success-400': isBuyOrder(order.type),
            })}
          >
            {order.type}
          </Text>
        </div>
      }
    >
      <div className='flex flex-col items-center -mt-4 gap-4 w-full lg:px-2 '>
        <div className='flex w-full'>
          <Switch>
            <SwitchItem active={true}>{t('Information')}</SwitchItem>
            <SwitchItem active={false} disabled>
              {t('TradeLog')}
            </SwitchItem>
          </Switch>
        </div>

        {trader && (
          <div className='flex items-center w-full mt-2'>
            <Text className='text-grayscale-500 mr-2'>{t('trade_owner')}:</Text>
            <ImageWithFallback width={20} height={20} src={trader?.logo} label={trader?.name?.substring(2)} />
            <Text className='ml-2  text-grayscale-100'>{trader?.name || '-'}</Text>
          </div>
        )}

        <div className='flex flex-col p-4 bg-grayscale-700 gap-2 w-full rounded'>
          <Text className='text-grayscale-400'>{t('Profit')}</Text>
          <Text
            size='2xl'
            className={clsx('font-bold', {
              'text-danger-500 mr-2': order.profit < 0,
              'text-success-400': order.profit > 0,
            })}
          >
            {order.profit >= 0 ? `$${globalRound(order.profit, 2)}` : `-$${Math.abs(globalRound(order.profit, 2))}`}
          </Text>
        </div>

        <div className='w-full'>
          <Text size='sm'>{t('Details')} </Text>
          <div className='p-4 bg-grayscale-700 mb-4 w-full rounded'>
            {details.map((detail, index) => (
              <div key={index} className='flex justify-between mb-2'>
                <div className='text-gray-500'>{detail.label}</div>
                <div>{detail.value}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='w-full flex justify-center mt-4'>
          <Button onClick={handleCloseOrder} loading={loading} variant='primary' disabled={hideActions}>
            {status === 'open' ? t('closeTrade', { ns: 'forex-common' }) : t('deleteOrder', { ns: 'forex-common' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
