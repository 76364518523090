import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const PlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z' />
    </svg>
  );
};
