import { QueryFunction } from 'react-query';

import { GetExpertsStatsServiceQuery, getExpertStatistic } from '@root/modules/experts/services/get-expert-statistic.service';
import { TradeStats } from '@root/modules/orders/types/statistic';

export type GetExpertsStatsDataQueryKey = ['fx', GetExpertsStatsServiceQuery, 'expert-statistic'];
export type GetExpertsStatsDataData = TradeStats;

export const GET_EXPERT_STATS_DATA: QueryFunction<GetExpertsStatsDataData, GetExpertsStatsDataQueryKey> = async ({ queryKey }) => {
  const [, params] = queryKey;

  const result = await getExpertStatistic(params);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
