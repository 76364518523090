import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import connectionImg from '@root/assets/images/connection-grayed.webp';
import { CreateExpertDropdown } from '@root/modules/experts/components/create-expert-dropdown';
import { Image } from '@root/shared/ui/image';
import { Text, Title } from '@root/shared/ui/typography';

export const NoExpertBanner: FC = () => {
  const { t } = useTranslation('forex-experts');

  return (
    <div className='rounded p-4 lg:p-11'>
      <div className='flex flex-col lg:flex-row lg:items-center w-full mb-6 lg:flex-1'>
        <Image wrapperClassName='flex-shrink-0 self-center' src={connectionImg} height={124} width={124} alt='Connection' />
        <div className='flex flex-col items-start lg:ml-8 mt-4 lg:mt-0 max-w-screen-sm'>
          <Title level={6} className='mb-1'>
            {t('empty.title')}
          </Title>
          <Text>{t('empty.description')}</Text>

          <div className='flex flex-col sm:flex-row items-center justify-start gap-4 w-full'>
            {/*<ButtonLink isLocalRoute href={PageLinks.createExpert} className='flex items-center gap-3 mt-4'>*/}
            {/*  <PlusIcon />*/}
            {/*  {t('empty.actions.create')}*/}
            {/*</ButtonLink>*/}

            <CreateExpertDropdown buttonClassName='!py-1.5 mt-4' buttonText={t('actions.create')} />
          </div>
        </div>
      </div>
    </div>
  );
};
