import { useTranslation } from 'react-i18next';
import { SheetIcon } from '@root/shared/icons/sheet-icon';
import { Text, TextProps } from '@root/shared/ui/typography';
import clsx from 'clsx';
import { FC } from 'react';
import styled from 'styled-components';

interface WrapperProps {
  minHeight?: number;
  className?: string;
  textSize?: TextProps['size'];
}

export type EmptyDataProps = WrapperProps;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : `100$`)};
`;

export const EmptyData: FC<EmptyDataProps> = ({ minHeight, className, textSize, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper className={clsx(`p-4 rounded-xl color-grayscale-400 `, className)} {...props} minHeight={minHeight}>
      <SheetIcon className='mr-2.5' />
      <Text className='text-grayscale-400' size={textSize}>
        {t('emptyData')}
      </Text>
    </Wrapper>
  );
};
