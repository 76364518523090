import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';
import { notify } from '@root/shared/utils/notification';

import { TEST_PROVIDER_NAME } from '../constants/test-providers';
import {
  GET_MARKETPLACE_TRADE_IDEA_PROVIDER,
  GetMarketplaceTradeIdeaProviderData,
  GetMarketplaceTradeIdeaProviderQueryKey,
} from '../query/get-marketplace-trade-idea-provider.query';
import { checkTestProvidersAvailable } from '../utils/check-test-providers-available';

type Params = { shouldRedirect?: boolean; enabled?: boolean };

export const useGetMarketplaceTradeIdeaProvider = (id: string, { shouldRedirect = false, enabled = true }: Params) => {
  const { t } = useTranslation('marketplace-trade-idea-providers');
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);
  const isQAMember = useMemo(() => checkTestProvidersAvailable(subscriptionInfo?.roles), [subscriptionInfo]);

  const navigate = useNavigate();

  const result = useQuery<
    GetMarketplaceTradeIdeaProviderData,
    Error,
    GetMarketplaceTradeIdeaProviderData,
    GetMarketplaceTradeIdeaProviderQueryKey
  >(['marketplace-trade-idea-provider', id], GET_MARKETPLACE_TRADE_IDEA_PROVIDER, { enabled: !!id && enabled });

  const isAllowed = useMemo(() => {
    if (!result.data) {
      return true;
    }

    return isQAMember || !result.data?.name?.includes(TEST_PROVIDER_NAME);
  }, [isQAMember, result.data]);

  useEffect(() => {
    if (!isAllowed) {
      notify({ title: t('noTestTradeIdeaPermission'), type: 'danger' });

      if (shouldRedirect) {
        navigate('/marketplace');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowed, shouldRedirect]);

  return {
    ...result,
    data: isAllowed ? result.data : undefined,
  };
};
