import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const EyeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12.3434561,12.7272727 C14.3485066,12.7272727 15.9797389,14.3585455 15.9797389,16.3636364 C15.9797389,18.3687273 14.3485066,20 12.3434561,20 C10.3384056,20 8.70717329,18.3687273 8.70717329,16.3636364 C8.70717329,14.3585455 10.3384056,12.7272727 12.3434561,12.7272727 Z M12.3434561,14.3434343 C11.2295167,14.3434343 10.3232945,15.249697 10.3232945,16.3636364 C10.3232945,17.4775758 11.2295167,18.3838384 12.3434561,18.3838384 C13.4573955,18.3838384 14.3636177,17.4775758 14.3636177,16.3636364 C14.3636177,15.249697 13.4573955,14.3434343 12.3434561,14.3434343 Z M12.3434157,4 C12.7897187,4 13.1514965,4.36177778 13.1514965,4.80808081 L13.1514965,4.80808081 L13.1514965,8.47070707 C14.4854359,8.55737374 15.7836177,8.85846465 16.9930723,9.3590303 L16.9930723,9.3590303 L19.5591733,6.79284848 C19.8748097,6.47729293 20.3864056,6.47729293 20.7020016,6.79284848 C21.0175167,7.10844444 21.0175167,7.62008081 20.7019612,7.93563636 L20.7019612,7.93563636 L18.5146076,10.1230303 C20.296345,11.1889293 21.7087086,12.6843232 22.599941,14.4495354 C22.8011531,14.8479192 22.6412339,15.3339394 22.2428097,15.5351111 C21.8450319,15.7359596 21.3587692,15.5770505 21.1572743,15.1779798 C19.6596985,12.2117576 16.3210319,10.0606061 12.3434561,10.0606061 C8.36887026,10.0606061 5.02850662,12.2091717 3.52959753,15.1779394 C3.32850662,15.5763636 2.8423248,15.7362424 2.44406218,15.5350707 C2.04567834,15.333899 1.88575915,14.8479192 2.08689046,14.4494949 C2.97812278,12.6842828 4.39048642,11.1888889 6.17222379,10.1229899 L6.17222379,10.1229899 L3.98487026,7.93559596 C3.6693147,7.62 3.6693147,7.10836364 3.98482985,6.79280808 C4.30038541,6.47721212 4.81206218,6.47721212 5.12765814,6.79280808 L5.12765814,6.79280808 L7.69375915,9.3589899 C8.90321369,8.85842424 10.2013955,8.55733333 11.5353349,8.47066667 L11.5353349,8.47066667 L11.5353349,4.80808081 C11.5353349,4.36177778 11.8971127,4 12.3434157,4 Z'/>
    </svg>
  );
};
