import { authSelector } from '@root/shared-files/modules/auth/store';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { GET_SYMBOLS_WITH_TYPE } from '@root/modules/accounts/queries/get-symbols-with-type.query';

export const useGetSymbolsWithType = () => {
  const userId = useSelector(authSelector.userId);

  return useQuery(['fx', userId, 'symbols-with-type'], GET_SYMBOLS_WITH_TYPE, {
    enabled: !!userId,
    staleTime: 1000 * 60 * 10,
  });
};
