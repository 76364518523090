import { useQuery } from 'react-query';
import { GetSubscriptionProductsData, GetSubscriptionProductsQueryKey, GET_SUBSCRIPTION_PRODUCTS } from '../queries/get-subscription-products.query';
import { useSelector } from 'react-redux';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetSubscriptionProducts = () => {
  const user = useSelector(authSelector.user);

  return useQuery<GetSubscriptionProductsData, Error, GetSubscriptionProductsData, GetSubscriptionProductsQueryKey>(['subscription-products'], GET_SUBSCRIPTION_PRODUCTS, {
    enabled: !!user,
    keepPreviousData: false,
  });
};
