import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { Id } from '@root/shared/utils/types';

import { GetExpertEventsQuery } from '../services/get-expert-events.service';
import { useGetExpertEvents } from './use-get-expert-events';

export type ExpertEventsParams = Omit<GetExpertEventsQuery, 'expertId' | 'userId'>;

const initialData: ExpertEventsParams = {
  page: 1,
  itemsPerPage: 10,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export const useOrderEvents = (orderId, expertId: string) => {
  const userId = useSelector(authSelector.userId);

  const [controlParams, setControlParam] = useState<ExpertEventsParams>(initialData);

  const { data, isFetching } = useGetExpertEvents({ expertId, orderId, userId, ...controlParams });

  const changeControlParam = useCallback(
    (params: Partial<ExpertEventsParams>) => {
      setControlParam((values) => ({ ...values, ...params }));
    },
    [setControlParam],
  );

  const state = {
    list: data?.data || [],
    controlParams,
    isLoading: isFetching,
    pageCount: data?.pagination.pageCount,
  };

  const handlers = { changeControlParam };

  return [state, handlers] as [typeof state, typeof handlers];
};
