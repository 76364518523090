import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { IQuotesState } from '@root/modules/quotes/store/quotes.slice';

const getState = (state: IAppState) => state.quotes;
const getQuotesData = (state: IQuotesState) => state.quotesData;
const getSubscribedSymbols = (state: IQuotesState) => state.symbolsSubscribed;
const getSymbolsToSubscribe = (state: IQuotesState) => state.symbolsToSubscribe;

export const quotesSelector = {
  quotesData: createSelector<[Selector<IAppState, IQuotesState>], IQuotesState['quotesData']>([getState], getQuotesData),
  subscribedSymbols: createSelector<[Selector<IAppState, IQuotesState>], IQuotesState['symbolsSubscribed']>([getState], getSubscribedSymbols),
  symbolsToSubscribe: createSelector<[Selector<IAppState, IQuotesState>], IQuotesState['symbolsToSubscribe']>([getState], getSymbolsToSubscribe),
};
