import getSymbolFromCurrency from 'currency-symbol-map';
import { useMemo } from 'react';

import { IExpert } from '@root/modules/experts/types/expert';

import { useGetAccounts } from './use-get-accounts';

export const useGetAccountCurrency = (accountId: IExpert['accountId']) => {
  const { data: accounts } = useGetAccounts();

  const { currencySymbol, currency } = useMemo<{ currencySymbol: string; currency: string }>(() => {
    let currency = 'USD';
    if (accounts?.length) {
      currency = accounts?.find((item) => item.id === accountId)?.currency as string;
    }
    return {
      currencySymbol: getSymbolFromCurrency(currency) || '$',
      currency,
    };
  }, [accounts, accountId]);

  return { currencySymbol, currency };
};
