import dayjs from 'dayjs';

import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import advancedImg from '@root/assets/images/advanced.png';
import basicImg from '@root/assets/images/basic.png';
import proImg from '@root/assets/images/pro.png';
import { SubscriptionCancelModal } from '@root/shared-files/modules/subscriptions/components/subscription-cancel-modal';
import { BriefCaseIcon } from '@root/shared/icons/briefcase';
import { RefreshIcon } from '@root/shared/icons/refresh-icon';
import { VerifiedBadgeIcon } from '@root/shared/icons/verified-badge';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Switch, SwitchItem } from '@root/shared/ui/switch';
import { Text, Title } from '@root/shared/ui/typography';

import { ActiveBadge, DiscountPrice, Hightlight, Soon, SubscriptionDescription, SubscriptionItem } from '../components/subscription-item.ui';
import { SubscriptionConfirmationModal } from '../components/subscription-modal';
import { SubscriptionSuccessModal } from '../components/subscription-success-modal';
import { bottomLinks } from '../constants/bottom-links';
import { useSubscriptionProducts } from '../hooks/use-forex.subscription-products';
import { SubscriptionPeriod } from '../types/subscription-period';
import { SubscriptionProductType } from '../types/subscription-product-type';
import { SubscriptionProduct } from '../types/subscription-products';

export const Subscriptions: FC = () => {
  const { t } = useTranslation('subscriptions');
  const [
    {
      isSubscribedFromShop,
      subscriptions,
      isLoading,
      period,
      currentSubscription,
      activeSubscription,
      selectedSubscription,
      confirmationOpen,
      purchaseLoading,
      successOpen,
      retryPaymentLoading,
      cancelLoading,
      reactivateLoading,
      cancelOpen,
    },
    {
      setPeriod,
      setSelectedSubscription,
      setConfirmationOpen,
      handleSubscribeClick,
      handleConfirmationClick,
      handleSuccessClick,
      handleCancelSubscription,
      handleRetryPayment,
      handleReactivateSubscription,
      setCancelOpen,
    },
  ] = useSubscriptionProducts();

  return (
    <div>
      <div className='w-ful flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-10'>
        <div className='flex items-center gap-x-4'>
          <Title level={5} className='font-bold'>
            {t('title')}
          </Title>
          {/* <Button outlined>
            <div className='flex justify-center items-center gap-x-4'>
              <BriefCaseIcon /> <Text size='sm'>{t('manageSubscription')}</Text>
            </div>
          </Button> */}
        </div>
        <div className='flex justify-end items-center'>
          <Switch>
            <SwitchItem active={period === SubscriptionPeriod.Monthly} onClick={() => setPeriod(SubscriptionPeriod.Monthly)} disabled={true}>
              {t('periodSwitch.monthly')}
            </SwitchItem>
            <SwitchItem active={period === SubscriptionPeriod.Yearly} onClick={() => setPeriod(SubscriptionPeriod.Yearly)}>
              {t('periodSwitch.yearly')}
            </SwitchItem>
          </Switch>
        </div>
      </div>
      {isLoading || !subscriptions ? (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mb-10'>
          <SkeletonLoader
            shapes={[
              { circle: false, width: '100%', height: 500 },
              { circle: false, width: '100%', height: 500 },
              { circle: false, width: '100%', height: 500 },
            ]}
          />
        </div>
      ) : (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
          {!!subscriptions[SubscriptionProductType.Basic] && (
            <SubscriptionItem
              className='rounded bg-gray-800 flex flex-col items-center justify-between gap-y-7'
              selected={selectedSubscription?.type === SubscriptionProductType.Basic}
              onClick={() => setSelectedSubscription(subscriptions[SubscriptionProductType.Basic] as SubscriptionProduct)}
              color={'primary'}
              tint={400}
            >
              {currentSubscription?.type === SubscriptionProductType.Basic && (
                <ActiveBadge>
                  <div className='flex justify-center items-center text-primary-400 gap-x-2'>
                    <VerifiedBadgeIcon width={16} height={16} /> <Text size='sm'>{t('activeNow')}</Text>
                  </div>
                </ActiveBadge>
              )}
              <Image src={basicImg} width={120} height={120} objectFit='contain' alt='basic'  unoptimized={true} />
              <Title level={6}>{t('subscriptions.basic')}</Title>
              <div className='flex flex-col justify-center items-center gap-y-1'>
                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:broker_one'
                      components={{ b: <Hightlight /> }}
                      values={{ count: subscriptions[SubscriptionProductType.Basic]?.options.brokers }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:aiAssists_other'
                      components={{ b: <Hightlight /> }}
                      values={{ count: subscriptions[SubscriptionProductType.Basic]?.options.ea }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:tiForFree'
                      components={{ b: <Hightlight /> }}
                      values={{
                        count: subscriptions[SubscriptionProductType.Basic]?.options.maxProviders,
                      }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text className='flex justify-center items-center gap-x-2'>
                    <Trans i18nKey='subscriptions:portfolioManager' components={{ b: <Hightlight /> }} values={{ count: 1 }} /> <Soon>{t('soon')}</Soon>
                  </Text>
                </SubscriptionDescription>
              </div>
              <DiscountPrice color='#50bbff'>
                ${' '}
                {period === SubscriptionPeriod.Monthly
                  ? subscriptions[SubscriptionProductType.Basic]?.options?.priceMonth
                  : subscriptions[SubscriptionProductType.Basic]?.options?.priceYear}
              </DiscountPrice>
              {currentSubscription?.type === SubscriptionProductType.Basic && activeSubscription ? (
                <>
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <div className='my-4 flex justify-center items-center text-center text-success-400 font-medium'>
                      <Text size='sm'>{t('autoRenewText', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <div className='my-4 flex justify-center items-center text-center text-gray-100 font-medium'>
                      <Text size='sm'>{t('stillActive', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <div className='my-4 flex justify-center items-center text-center text-danger-100 font-medium'>
                      <Text size='sm'>{t('paymentFailed')}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <Button outlined onClick={() => setCancelOpen(true)} loading={cancelLoading}>
                      <Text size='sm'>{t('cancelSubscription')}</Text>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <Button onClick={handleRetryPayment} loading={retryPaymentLoading}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <RefreshIcon /> <Text size='sm'>{t('renew')}</Text>
                      </div>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <Button onClick={handleReactivateSubscription} loading={reactivateLoading}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                      </div>
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  onClick={() => handleSubscribeClick(subscriptions[SubscriptionProductType.Basic] as SubscriptionProduct)}
                  disabled={!!activeSubscription || isSubscribedFromShop}
                >
                  <div className='flex justify-center items-center gap-x-4'>
                    <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                  </div>
                </Button>
              )}
            </SubscriptionItem>
          )}
          {!!subscriptions[SubscriptionProductType.Advanced] && (
            <SubscriptionItem
              className='rounded bg-gray-800 flex flex-col items-center justify-between gap-y-7'
              selected={selectedSubscription?.type === SubscriptionProductType.Advanced}
              onClick={() => setSelectedSubscription(subscriptions[SubscriptionProductType.Advanced] as SubscriptionProduct)}
              color={'success'}
              tint={500}
            >
              {currentSubscription?.type === SubscriptionProductType.Advanced && (
                <ActiveBadge>
                  <div className='flex justify-center items-center text-success-500 gap-x-2'>
                    <VerifiedBadgeIcon width={16} height={16} /> <Text size='sm'>{t('activeNow')}</Text>
                  </div>
                </ActiveBadge>
              )}
              <Image src={advancedImg} width={120} height={120} objectFit='contain' alt='advance'  unoptimized={true} />
              <Title level={6}>{t('subscriptions.advance')}</Title>
              <div className='flex flex-col justify-center items-center gap-y-1'>
                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:broker_other'
                      components={{ b: <Hightlight /> }}
                      values={{ count: subscriptions[SubscriptionProductType.Advanced]?.options.brokers }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:aiAssists_other'
                      components={{ b: <Hightlight /> }}
                      values={{ count: subscriptions[SubscriptionProductType.Advanced]?.options.ea }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey={subscriptions[SubscriptionProductType.Advanced]?.options.maxProviders > 0 ? 'subscriptions:tiForFree' : 'subscriptions:tiForFreeUnlimited'}
                      components={{ b: <Hightlight /> }}
                      values={{
                        count: subscriptions[SubscriptionProductType.Advanced]?.options.maxProviders,
                      }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text className='flex justify-center items-center gap-x-2'>
                    <Trans i18nKey='subscriptions:portfolioManager' components={{ b: <Hightlight /> }} values={{ count: 1 }} /> <Soon>{t('soon')}</Soon>
                  </Text>
                </SubscriptionDescription>
              </div>
              <div className='flex flex-col items-center justify-center'>
                {/* <Text size='sm' className='line-through'>
                  $ 699
                </Text> */}
                <DiscountPrice color='#04E2CB'>
                  ${' '}
                  {period === SubscriptionPeriod.Monthly
                    ? subscriptions[SubscriptionProductType.Advanced]?.options?.priceMonth
                    : subscriptions[SubscriptionProductType.Advanced]?.options?.priceYear}
                </DiscountPrice>
                {/* <Text size='sm'>
                  {t('discount', {
                    count:
                      100 -
                      Math.round(
                        (((period === SubscriptionPeriod.Monthly
                          ? subscriptions[SubscriptionProductType.Advanced]?.options?.priceMonth
                          : subscriptions[SubscriptionProductType.Advanced]?.options?.priceYear) || 0) /
                          699) *
                          100,
                      ),
                  })}
                </Text> */}
              </div>
              {currentSubscription?.type === SubscriptionProductType.Advanced && activeSubscription ? (
                <>
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <div className='my-4 flex justify-center items-center text-center text-success-400 font-medium'>
                      <Text size='sm'>{t('autoRenewText', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <div className='my-4 flex justify-center items-center text-center text-gray-100 font-medium'>
                      <Text size='sm'>{t('stillActive', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <div className='my-4 flex justify-center items-center text-center text-danger-500 font-medium'>
                      <Text size='sm'>{t('paymentFailed')}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <Button outlined onClick={() => setCancelOpen(true)} loading={cancelLoading}>
                      <Text size='sm'>{t('cancelSubscription')}</Text>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <Button onClick={handleRetryPayment} loading={retryPaymentLoading}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <RefreshIcon /> <Text size='sm'>{t('renew')}</Text>
                      </div>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <Button onClick={handleReactivateSubscription} loading={reactivateLoading}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                      </div>
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  onClick={() => handleSubscribeClick(subscriptions[SubscriptionProductType.Advanced] as SubscriptionProduct)}
                  disabled={!!activeSubscription || isSubscribedFromShop}
                >
                  <div className='flex justify-center items-center gap-x-4'>
                    <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                  </div>
                </Button>
              )}
            </SubscriptionItem>
          )}
          {!!subscriptions[SubscriptionProductType.Pro] && (
            <SubscriptionItem
              className='rounded bg-gray-800 flex flex-col items-center justify-between gap-y-7'
              selected={selectedSubscription?.type === SubscriptionProductType.Pro}
              // onClick={() => setSelectedSubscription(subscriptions[SubscriptionProductType.Pro] as SubscriptionProduct)}
              color={'warning'}
              tint={600}
            >
              {currentSubscription?.type === SubscriptionProductType.Pro && (
                <ActiveBadge>
                  <div className='flex justify-center items-center text-warning-600 gap-x-2'>
                    <VerifiedBadgeIcon width={16} height={16} /> <Text size='sm'>{t('activeNow')}</Text>
                  </div>
                </ActiveBadge>
              )}
              <Image src={proImg} width={120} height={120} alt='pro'  unoptimized={true} />
              <Title level={6} className='capitalize'>
                {t('subscriptions.pro')}
              </Title>
              <div className='flex flex-col justify-center items-center gap-y-1'>
                <SubscriptionDescription>
                  <Text>
                    <Trans
                      i18nKey='subscriptions:broker_other'
                      components={{ b: <Hightlight /> }}
                      values={{ count: subscriptions[SubscriptionProductType.Pro]?.options.brokers }}
                    />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text>
                    <Trans i18nKey='subscriptions:aiAssists_other' components={{ b: <Hightlight /> }} values={{ count: subscriptions[SubscriptionProductType.Pro]?.options.ea }} />
                  </Text>
                </SubscriptionDescription>

                <SubscriptionDescription>
                  <Text className='flex justify-center items-center gap-x-2'>
                    <Trans i18nKey='subscriptions:portfolioManager' components={{ b: <Hightlight /> }} values={{ count: 1 }} /> <Soon>{t('soon')}</Soon>
                  </Text>
                </SubscriptionDescription>
              </div>
              <div className='flex flex-col items-center justify-center'>
                {/* <Text size='sm' className='line-through'>
                  $ 1199
                </Text> */}
                <DiscountPrice color='#ff6d41'>
                  ${' '}
                  {period === SubscriptionPeriod.Monthly
                    ? subscriptions[SubscriptionProductType.Pro]?.options?.priceMonth
                    : subscriptions[SubscriptionProductType.Pro]?.options?.priceYear}
                </DiscountPrice>
                {/* <Text size='sm'>
                  {t('discount', {
                    count:
                      100 -
                      Math.round(
                        (((period === SubscriptionPeriod.Monthly
                          ? subscriptions[SubscriptionProductType.Pro]?.options?.priceMonth
                          : subscriptions[SubscriptionProductType.Pro]?.options?.priceYear) || 0) /
                          1199) *
                          100,
                      ),
                  })}
                </Text> */}
              </div>
              {currentSubscription?.type === SubscriptionProductType.Pro && activeSubscription ? (
                <>
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <div className='my-4 flex justify-center items-center text-center text-success-400 font-medium'>
                      <Text size='sm'>{t('autoRenewText', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <div className='my-4 flex justify-center items-center text-center text-gray-100 font-medium'>
                      <Text size='sm'>{t('stillActive', { date: dayjs(activeSubscription.expiresAt).format('MM/DD/YYYY') })}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <div className='my-4 flex justify-center items-center text-center text-danger-500 font-medium'>
                      <Text size='sm'>{t('paymentFailed')}</Text>
                    </div>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'active' && (
                    <Button outlined onClick={() => setCancelOpen(true)} loading={cancelLoading}>
                      <Text size='sm'>{t('cancelSubscription')}</Text>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'paused' && (
                    <Button onClick={handleRetryPayment} loading={retryPaymentLoading}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <RefreshIcon /> <Text size='sm'>{t('renew')}</Text>
                      </div>
                    </Button>
                  )}
                  {activeSubscription.gatewaySubscription?.status === 'canceled' && (
                    <Button onClick={handleReactivateSubscription} loading={reactivateLoading} disabled={true}>
                      <div className='flex justify-center items-center gap-x-4'>
                        <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                      </div>
                    </Button>
                  )}
                </>
              ) : (
                <Button onClick={() => handleSubscribeClick(subscriptions[SubscriptionProductType.Pro] as SubscriptionProduct)} disabled={true}>
                  <div className='flex justify-center items-center gap-x-4'>
                    <BriefCaseIcon /> <Text size='sm'>{t('subscribe')}</Text>
                  </div>
                </Button>
              )}
            </SubscriptionItem>
          )}
        </div>
      )}
      {/* {!!subscriptions?.[SubscriptionProductType.Trial] && !currentSubscription && !subscriptions?.[SubscriptionProductType.Trial]?.isTrialUsed && (
        <div className='w-full flex justify-center items-center mt-10'>
          <Button outlined onClick={() => handleSubscribeClick(subscriptions?.[SubscriptionProductType.Trial] as SubscriptionProduct)}>
            <div className='flex justify-center items-center gap-x-4'>
              <VerifiedBadgeIcon width={16} height={16} /> <Text size='sm'>{t('startTrial')}</Text>
            </div>
          </Button>
        </div>
      )} */}

      <div className='w-full flex justify-center items-center mt-10'>
        <div className='flex flex-wrap justify-center items-center sm:divide-x sm:divide-grayscale-500 mb-4'>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.termsOfUse} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.termsOfService')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.privacyPolicy} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.privacyPolicy')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.refundPolicy} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.refundPolicy')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.acceptableUsePolicy} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.usePolicy')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.cookiesPolicy} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.cookiePolicy')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.riskWarning} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.riskWarning')}
              </Text>
            </a>
          </div>
          <div className='px-4 text-grayscale-600'>
            <a href={bottomLinks.disclaimers} target='_blank' rel='noreferrer'>
              <Text size='sm' className='cursor-pointer font-semibold'>
                {t('common:legal.disclaimer')}
              </Text>
            </a>
          </div>
        </div>
      </div>
      {/* <div className='w-full flex justify-center items-center'>
        <div className='px-4 text-grayscale-600'>
          <Link href='/'>
            <Text size='sm' className='cursor-pointer font-semibold'>
              {t('contactSupport')}
            </Text>
          </Link>
        </div>
      </div> */}
      <SubscriptionConfirmationModal isOpen={confirmationOpen} loading={purchaseLoading} onCancel={() => setConfirmationOpen(false)} onOk={handleConfirmationClick} />
      <SubscriptionSuccessModal isOpen={successOpen} onCancel={handleSuccessClick} onOk={handleSuccessClick} />
      <SubscriptionCancelModal isOpen={cancelOpen} onCancel={() => !cancelLoading && setCancelOpen(false)} onOk={handleCancelSubscription} loading={cancelLoading} />
    </div>
  );
};
