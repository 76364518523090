import { OptionType } from '@root/shared/form';

export const selectQuantityOptions: OptionType[] = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
];

export const presetsQuantityOptions: OptionType[] = [
  {
    value: 12,
    label: '12',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 36,
    label: '36',
  },
];
