import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '@root/infra/layout/containers/error';
import { SelectedBrokerProvider } from '@root/modules/orders/contexts/selected-broker.context';

import ExpertsPage from '../pages';
import CreateWizardExpertPage from '../pages/create-wizard';
import { ExpertsDetailsPage } from '../pages/expert-item/expert-page-details';
import UpdateWizardExpertPage from '../pages/update-wizard';

export const Router = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <SelectedBrokerProvider>
            <ExpertsPage />
          </SelectedBrokerProvider>
        }
        errorElement={<ErrorPage />}
      />
      <Route path='/:id' element={<ExpertsDetailsPage />} errorElement={<ErrorPage />} />
      <Route path='/create-wizard' element={<CreateWizardExpertPage />} errorElement={<ErrorPage />} />
      <Route path='/update' element={<UpdateWizardExpertPage />} errorElement={<ErrorPage />} />
      <Route path='*' element={<Navigate to='/assists' replace />} />
    </Routes>
  );
};
