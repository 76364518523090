import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { IExpert } from '@root/modules/experts/types/expert';
import { CloseOrderModal } from '@root/modules/orders/components/close-order-modal';
import { ModifyOrderModal } from '@root/modules/orders/components/update-order-modal';
import { TradesTableContent } from '@root/modules/orders/containers/trades-table-content';
import { TabType } from '@root/modules/orders/enums/orders';
import { useGetOrders } from '@root/modules/orders/hooks/use-get-orders';
import { ModalType, useOrderActions } from '@root/modules/orders/hooks/use-order-actions';
import { useGetOrdersHistory } from '@root/modules/orders/hooks/use-order-history';
import { selectQuantityOptions } from '@root/shared/constants/items-per-page-options';
import { EmptyData } from '@root/shared/ui/empty-data';
import { Select } from '@root/shared/ui/form';
import { Paginate } from '@root/shared/ui/pagination';
import { Switch, SwitchItem } from '@root/shared/ui/switch';

import { OrderEventsModal } from '../../order-events-modal';
import { ActiveTradeItem } from './active-trade-item';
import { ModifyTradeModal } from './modify-trade-modal';
import { Statistics } from './statistics';
import { ShareOrderModal } from '@root/modules/orders/components/share-order-modal/share-order-modal';
import { useAutoUpdate } from '@root/modules/experts/hooks/use-auto-update';
import { ExportToCsv } from '@root/modules/orders/components/export-to-csv';
import { ModifyPartialCloseModal } from '@root/modules/orders/components/partial-close-modal';
import { IOrder } from '@root/modules/orders/types/orders';

type Props = {
  expert: IExpert;
};

export const TradesAndHistory: FC<Props> = ({ expert }) => {
  const { t } = useTranslation('forex-experts');
  const [tab, setTab] = useState<TabType>(TabType.OPEN);

  const { data: accounts } = useGetAccounts();

  const currency = accounts?.find((item) => item.id === expert.accountId)?.currency;

  const [{ list, pageSizeInput, page, paginatedData, totalOpenOrders }, { setPage, invalidateOpenOrderQueries }] = useGetOrders({
    tab,
    expertId: expert.id,
    enabled: true,
    selectedBroker: expert.accountId,
  });
  const [{ modalType, selectedOrder }, { deleteOrder, showOrderLogs, modifyOrder, modifyTrade, modifyPartialClose, closeModal, shareOrder }] = useOrderActions();
  const [{ ordersHistoryList, historyLoading, pagination }, { changeParams }] = useGetOrdersHistory({ enabled: true, expertId: expert.id, selectedBroker: expert.accountId });
  const orders = tab === TabType.HISTORY ? ordersHistoryList : paginatedData;

  const { enabledIds, addToEnabledIds, removeFromEnabledIds } = useAutoUpdate();

  const partialCloseOrder = useMemo(() => orders?.find(order => selectedOrder?.id === order.id) || null, [orders, selectedOrder?.id])

  const tableContentProps = {
    currency,
    tab,
    page,
    historyLoading,
    orders,
    experts: [expert],
    list,
    pagination,
    paginatedData,
    pageSizeInput,
    modifyTrade,
    modifyOrder,
    modifyPartialClose,
    deleteOrder,
    shareOrder,
    changeParams,
    setPage,
    actionColumnHeaderContent: <ExportToCsv queryParams={{ experts: [expert.id] }} />,
  };

  return (
    <div className='w-full'>
      <div className='w-full mb-4'>
        <Statistics totalOpenTrades={totalOpenOrders} totalClosedTrades={pagination?.totalItems || 0} />
      </div>
      <div className='w-full'>
        <Switch className='mb-4 !grid grid-cols-2 sm:!flex text-center'>
          <SwitchItem
            value={TabType.OPEN}
            active={tab === TabType.OPEN}
            onClick={() => setTab(TabType.OPEN)}
            className='!rounded-tl-none !rounded-bl-none sm:!rounded-tl sm:!rounded-bl'
          >
            {t('trades.tabs.open')}
          </SwitchItem>
          <SwitchItem value={TabType.HISTORY} active={tab === TabType.HISTORY} onClick={() => setTab(TabType.HISTORY)} className='!rounded-tr-none !rounded-br-none'>
            {t('trades.tabs.history')}
          </SwitchItem>
        </Switch>
        {tab !== TabType.HISTORY && (
          <div className='w-full grid md:grid-cols-2 gap-x-6 gap-y-4'>
            {orders?.map((order, index) => (
              <ActiveTradeItem
                showOrderLogs={showOrderLogs}
                data={order}
                modifyOrder={modifyOrder}
                deleteOrder={deleteOrder}
                modifyTrade={modifyTrade}
                modifyPartialClose={modifyPartialClose}
                shareOrder={shareOrder}
                enabledIds={enabledIds}
                addToEnabledIds={addToEnabledIds}
                removeFromEnabledIds={removeFromEnabledIds}
                key={index}
              />
            ))}
            {!!paginatedData.length && (
              <div className='md:col-span-2'>
                <div className='flex flex-wrap justify-center items-center mt-2.5 pb-6 relative'>
                  <Paginate
                    pageCount={Math.ceil(list.length / (pageSizeInput.value?.value || 10))}
                    forcePage={page}
                    onPageChange={({ selected }) => setPage(selected)}
                    marginPagesDisplayed={1}
                    disableInitialCallback
                  />
                  <div className='w-24 ml-auto md:absolute right-0'>
                    <Select
                      value={pageSizeInput.value}
                      onFocus={pageSizeInput.onFocus}
                      onChange={pageSizeInput.onChange}
                      onBlur={pageSizeInput.onBlur}
                      options={selectQuantityOptions}
                      isSearchable={false}
                      bordered
                      tiny
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div>{!orders?.length && !historyLoading && tab !== TabType.HISTORY && <EmptyData />}</div>

        {tab === TabType.HISTORY && (
          <div className='bg-gray-800 px-5 py-4 pb-0 rounded'>
            <TradesTableContent {...tableContentProps} isExpertsDetails />
          </div>
        )}
        {modalType === ModalType.Delete && selectedOrder && (
          <CloseOrderModal isOpen closeModal={closeModal} order={selectedOrder} status={tab} onOrderClose={invalidateOpenOrderQueries} />
        )}
        {modalType === ModalType.Modify && selectedOrder && <ModifyOrderModal isOpen closeModal={closeModal} order={selectedOrder} />}
        {modalType === ModalType.EditTrade && selectedOrder && <ModifyTradeModal isOpen closeModal={closeModal} order={selectedOrder} />}
        {modalType === ModalType.Share && selectedOrder && <ShareOrderModal isOpen closeModal={closeModal} order={selectedOrder} status={tab} currency={currency} />}
        {modalType === ModalType.Logs && selectedOrder && <OrderEventsModal isOpen closeModal={closeModal} order={selectedOrder} expert={expert} />}
        {modalType === ModalType.PartialClose && selectedOrder && <ModifyPartialCloseModal isOpen closeModal={closeModal} order={partialCloseOrder as IOrder} />}
      </div>
    </div>
  );
};
