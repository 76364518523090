import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const CircleCancelIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.52853 5.5286C5.78888 5.26825 6.21099 5.26825 6.47134 5.5286L10.4713 9.5286C10.7317 9.78895 10.7317 10.2111 10.4713 10.4714C10.211 10.7318 9.78888 10.7318 9.52853 10.4714L5.52853 6.4714C5.26818 6.21105 5.26818 5.78895 5.52853 5.5286Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.5286 10.472C5.26825 10.2116 5.26825 9.7895 5.5286 9.52915L9.5286 5.52914C9.78895 5.2688 10.2111 5.2688 10.4714 5.52915C10.7318 5.78949 10.7318 6.2116 10.4714 6.47195L6.4714 10.472C6.21106 10.7323 5.78894 10.7323 5.5286 10.472Z'
        fill='currentColor'
      />
    </svg>
  );
};
