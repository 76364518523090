import { useCallback, useMemo, useState } from 'react';

import { DisplayAs } from '../components/experts-assists';
import { useGetBrokers } from './use-get-brokers';
import { useGetExperts } from './use-get-experts';
import { useSelector } from 'react-redux';
import { authSelector } from '@root/shared-files/modules/auth/store';

type ExpertsParams = {
  pair: string;
  broker: string;
};

export const useExpertsTable = () => {
  const userId = useSelector(authSelector.userId);
  const [params, setParams] = useState<ExpertsParams>({ pair: '', broker: '' });
  const savedView = localStorage.getItem(`${userId}-experts-view`) || DisplayAs.Grid;
  const [displayAs, setDisplayAs] = useState<DisplayAs>(savedView as DisplayAs);
  const { data, isLoading } = useGetExperts();
  const { data: brokersData } = useGetBrokers();

  const pairsOptions = useMemo(() => {
    const pairs = Array.from(new Set<string>(data?.flatMap((expert) => expert.symbols) || []));

    return [{ value: '', label: 'All' }, ...pairs.map((pair) => ({ value: pair, label: pair }))];
  }, [data]);

  const selectedPairsOption = useMemo(() => pairsOptions.find((option) => option.value === params.pair), [pairsOptions, params.pair]);

  const brokerOptions = useMemo(() => {
    return [{ value: '', label: 'All' }, ...brokersData?.list?.map((broker) => ({ value: broker?.id, label: `${broker?.name} - ${broker?.mtType}` })) || []];
  }, [brokersData?.list]);

  const selectedBrokersOption = useMemo(() => brokerOptions.find((option) => option.value === params.broker), [brokerOptions, params.broker]);

  const filteredData = useMemo(() => {
    let filteredData = data || [];
    if (params.pair) {
      filteredData = filteredData.filter((expert) => expert.symbols.includes(params.pair));
    }

    if (params.broker) {
      const brokerServers = brokersData?.list?.filter(broker => broker.id === params.broker).flatMap((broker) => broker?.servers || '').filter((item) => item).map(item => item.id) || [];

      filteredData = filteredData.filter((expert) => expert.account?.brokerServerId && brokerServers.includes(expert.account?.brokerServerId));
    }

    return filteredData;
  }, [data, params, brokersData]);

  const changeParams = useCallback((params: Partial<ExpertsParams>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  const changeView = useCallback((view: DisplayAs) => {
    localStorage.setItem(`${userId}-experts-view`, view);
    setDisplayAs(view)
  }, [userId])

  return {
    params,
    data: filteredData,
    isLoading,
    displayAs,
    pairsOptions,
    selectedPairsOption,
    brokerOptions,
    selectedBrokersOption,
    changeParams,
    changeView
  };
};
