import { FC } from 'react';

import { IOrder } from '@root/modules/orders/types/orders';
import { Modal } from '@root/shared/ui/modal';

import { IExpert } from '../types/expert';
import { OrderEvents } from './order-events';

type Props = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
  expert: IExpert;
};

export const OrderEventsModal: FC<Props> = ({ order, expert, isOpen, closeModal }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onCancel={closeModal} footer={null} className='!max-w-6xl'>
      <OrderEvents order={order} expert={expert} wrapperClassName='!p-0' />
    </Modal>
  );
};
