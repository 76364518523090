import { Formik, FormikProps } from 'formik';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useGetBrokers } from '@root/modules/experts/hooks/use-get-brokers';
import { PasswordField, SelectField, TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Title } from '@root/shared/ui/typography';

import { CreateAccountForm, useCreateAccount } from '../hooks/use-create-account';
import { useProxyServerOptions } from '../hooks/use-proxy-server-options';
import { Drawdown } from '@root/modules/experts/components/drawdown';

const FormComponent: FC<FormikProps<CreateAccountForm>> = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {
  const { t } = useTranslation('forex-accounts');
  const { data } = useGetBrokers();

  const { proxyServersOptions } = useProxyServerOptions();

  const mtTypeOptions = useMemo(() => {
    return [
      {
        label: 'MT4',
        value: 'MT4',
      },
      {
        label: 'MT5',
        value: 'MT5'
      }
    ]
  }, []);

  const companyOptions = useMemo(() => data?.list?.filter(item => item.mtType === values.mtType).filter((item) => item.serviceAccounts.length > 0)?.map((item) => ({ value: item.id, label: item.name })), [data, values.mtType]);

  const serverOptions = useMemo(
    () => data?.list.find((item) => item.id === values.company)?.servers?.map((item) => ({ value: item.id, label: item.server })) || [],
    [data, values.company],
  );

  const accountTypeOptions = useMemo(
    () => data?.list.find((item) => item.id === values.company)?.serviceAccounts?.map((item) => ({ value: item.id, label: item.name })) || [],
    [data, values.company],
  );

  const { hideActions } = useGhostLogin();

  const handleServerChange = useCallback(
    (option) => {
      setFieldValue('server', option.value);
    },
    [setFieldValue],
  );

  const handleAccountTypeChange = useCallback(
    (option) => {
      setFieldValue('serviceAccountId', option.value);
    },
    [setFieldValue],
  );

  const handleCompanyChange = useCallback(
    (option) => {
      setFieldValue('company', option.value);
      setFieldValue('serviceAccountId', '');
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (!values.serviceAccountId && accountTypeOptions.length === 1) {
      setTimeout(() => {
        setFieldValue('serviceAccountId', accountTypeOptions[0].value);
      });
    }
  }, [accountTypeOptions, values.serviceAccountId, setFieldValue]);

  useEffect(() => {
    if (accountTypeOptions?.length > 1) {
      setFieldValue('multipleAccounts', true);
    } else {
      setFieldValue('multipleAccounts', false);
    }
  }, [accountTypeOptions?.length, setFieldValue]);

  useEffect(() => {
    if (proxyServersOptions?.length && !values.proxyServerId) {
      setFieldValue('proxyServerId', proxyServersOptions.find((item) => item.label?.toLowerCase().includes('europe'))?.value || proxyServersOptions[0].value);
    }
  }, [proxyServersOptions, values.proxyServerId, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <div className='rounded bg-gray-800 p-6 max-w-2xl'>
        <Title level={7} className='font-bold mb-3'>
          {t('create.title')}
        </Title>
        <div className='flex flex-col gap-4'>
          <div className="grid xl:grid-cols-2 gap-4">
            <SelectField
              options={mtTypeOptions}
              name='mtType'
              label={t('fields.mtType.label')}
              placeholder={t('fields.mtType.placeholder')}
            />
            <SelectField
              options={companyOptions}
              name='company'
              onChange={handleCompanyChange}
              isDisabled={!companyOptions?.length}
              label={t('fields.company.label')}
              placeholder={t('fields.company.placeholder')}
            />
          </div>
          <SelectField
            options={serverOptions}
            onChange={handleServerChange}
            isDisabled={!serverOptions?.length}
            name='server'
            label={t('fields.server.label')}
            placeholder={t('fields.server.placeholder')}
          />
          {accountTypeOptions?.length > 1 && (
            <SelectField
              options={accountTypeOptions}
              onChange={handleAccountTypeChange}
              isDisabled={!serverOptions?.length}
              name='serviceAccountId'
              label={t('fields.serviceAccountId.label')}
              placeholder={t('fields.serviceAccountId.placeholder')}
            />
          )}
          <div className="grid xl:grid-cols-2 gap-4">
            <TextField name='username' label={t('fields.userName.label')} placeholder={t('fields.userName.placeholder')} disabled={!values.mtType}/>
            <PasswordField name='password' type='password' label={t('fields.password.label')} placeholder={t('fields.password.placeholder')} disabled={!values.mtType}/>
          </div>
          <SelectField
            options={proxyServersOptions}
            name='proxyServerId'
            label={t('fields.proxyServers.label')}
            placeholder={t('fields.proxyServers.placeholder')}
            isDisabled={!values.mtType}
          />
          <Drawdown />
          <div className='flex sm:justify-end'>
            <Button loading={isSubmitting} disabled={hideActions} size='base' type='submit' className='sm:!px-0 sm:max-w-[140px] mt-2 w-full'>
              {t('create.submit')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export const CreateAccount = () => {
  const { initialValues, onSubmit, validationSchema } = useCreateAccount();

  return <Formik<CreateAccountForm> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={FormComponent} />;
};
