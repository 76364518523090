import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { PartialCloseDto } from '../dtos/partical-close.dto';

export type ModifyExternalPartialCloseServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const modifyExternalPartialCloseService = async ({
  accountId,
  lots,
  ticket,
}: Pick<PartialCloseDto, 'lots' | 'ticket' | 'accountId'>): Promise<ModifyExternalPartialCloseServiceResponse> => {
  try {
    const data = {
      ticket,
      lots,
    };

    await fetcher.delete(`/accounts/${accountId}/orders/instant`, { data, baseURL: import.meta.env.VITE_FX_CORE_HOST });
    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
