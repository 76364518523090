import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import warning from '@root/assets/images/cases/warning.webp';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

type Props = {
  removeIsOpen: boolean;
  isLoading: boolean;
  setRemoveIsOpen: (isOpen: boolean) => void;
  onDelete: () => void;
  removeIsDisabled: boolean;
};

export const RemoveExpertAssistModal: FC<Props> = ({ removeIsOpen, isLoading, setRemoveIsOpen, onDelete, removeIsDisabled }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'item' });

  return (
    <Modal
      isOpen={removeIsOpen}
      onCancel={() => setRemoveIsOpen(false)}
      footer={
        <div className='w-full flex justify-between items-center pt-6'>
          <Button outlined onClick={() => setRemoveIsOpen(false)}>
            {t('remove.confirmation.actions.cancel')}
          </Button>
          <Button onClick={onDelete} loading={isLoading} disabled={removeIsDisabled}>
            {t('remove.confirmation.actions.submit')}
          </Button>
        </div>
      }
    >
      <div className='text-center max-w-[388px] mx-auto py-4'>
        <img src={warning} alt='Confirmation icon' className='w-[61px] mx-auto' />
        <Title level={6} className='mb-2' bold>
          {t('remove.confirmation.title')}
        </Title>
        <Text>{t('remove.confirmation.description')}</Text>
        {removeIsDisabled && (
          <div className='bg-warning-600 w-full p-2 mt-4 rounded-[8px]'>
            <Text>{t('remove.confirmation.warning')}</Text>
          </div>
        )}
      </div>
    </Modal>
  );
};
