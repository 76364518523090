import styled from 'styled-components';

import { useFormikContext } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { CreateExpertStepKey } from '../hooks/use-create-expert-steps';

type StepState = {
  isActive: boolean;
  hasError: boolean;
  hasDot: boolean;
  isValid: boolean;
};

const MenuItem = styled.button.attrs({
  type: 'button',
  className: 'w-full text-sm font-medium text-right py-3 pl-4 pr-6 md:pr-8 hover:text-primary-400 relative',
})<StepState>`
  color: ${({ theme, isActive, hasError }) => (hasError ? theme.danger[500] : isActive ? theme.primary[400] : theme.grayscale[100])};
  opacity: ${({ disabled, isActive }) => (!isActive && disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.grayscale[100] : theme.primary[400])};
  }

  &::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: calc(50% - 2px);
    right: 14px;
    background-color: ${({ isActive }) => (isActive ? 'currentColor' : 'transparent')};
  }
`;

export type CreateExpertsStepsProps = {
  value: CreateExpertStepKey;
  onChange: (value: CreateExpertStepKey) => void;
  shouldValidate: boolean;
  touched?: boolean;
};

export const CreateExpertSteps: FC<CreateExpertsStepsProps> = ({ value, onChange, shouldValidate }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'steps' });

  const { errors, touched } = useFormikContext<CreateExpertDto>();

  const getState = useCallback(
    (step: CreateExpertStepKey, error: boolean, touched = true): StepState => {
      return {
        isActive: value === step,
        hasError: error && touched && shouldValidate,
        hasDot: value === step || (error && shouldValidate),
        isValid: !error,
      };
    },
    [value, shouldValidate],
  );

  const stepsState = useMemo(() => {
    const account = getState(CreateExpertStepKey.Account, !!errors.account || !!errors.symbols, !!touched.account || !!touched.symbols);
    const orderOptions = getState(CreateExpertStepKey.OrderOptions, !!errors.orderType || !!errors.orderDirection, !!touched.orderType || !!touched.orderDirection);
    const trigger = getState(CreateExpertStepKey.Trigger, !!errors.name || !!errors.providerId || !!errors.conditionalClosure?.amount, !!touched.name || !!touched.providerId || !!touched.conditionalClosure?.amount);
    const moneyManagement = getState(
      CreateExpertStepKey.MoneyManagement,
      !!errors.manualMoneyManagement || !!errors.maxManualActiveTrades || !!errors.signalMoneyManagement || !!errors.maxSignalActiveTrades || !!errors.maxDrawDownLimit || !!errors.sessionManagement,
      !!touched.manualMoneyManagement || !!touched.maxManualActiveTrades || !!touched.signalMoneyManagement || !!touched.maxSignalActiveTrades || !!touched.maxDrawDownLimit || !!touched.sessionManagement,
    );
    const breakEven = getState(CreateExpertStepKey.BreakEven, !!errors.breakEven, !!touched.breakEven);
    const partialTakeProfits = getState(CreateExpertStepKey.PartialTakeProfits, !!errors.partialClose, !!touched.partialClose);
    const stopLossTakeProfit = getState(
      CreateExpertStepKey.StopLossTakeProfit,
      !!errors.manualSlTp || !!errors.signalSlTp || !!errors.trailStop,
      !!touched.manualSlTp || !!touched.signalSlTp || !!touched.trailStop,
    );
    const advanced = getState(CreateExpertStepKey.AdvancedSettings, !!errors?.slippage || !!errors.strategy, !!touched?.slippage || !!touched.strategy);
    const preview = getState(CreateExpertStepKey.Preview, false);

    const accountDisabled = !!(errors.providerId || errors.name || errors.conditionalClosure?.amount);
    const orderOptionsDisabled = accountDisabled;
    const moneyManagementDisabled = !!(errors.account || errors.symbols);
    const stopLossTakeProfitDisabled = moneyManagementDisabled || !!errors?.manualMoneyManagement || !!errors.signalMoneyManagement || !!errors.sessionManagement;
    const partialTakeProfitsDisabled = stopLossTakeProfitDisabled || !!errors.manualSlTp || !!errors.signalSlTp || !!errors.trailStop;
    const breakEvenDisabled = partialTakeProfitsDisabled || !!errors.partialClose;
    const advancedDisabled = breakEvenDisabled || !!errors.breakEven;
    const previewDisabled = advancedDisabled || !!errors?.slippage || !!errors.strategy;

    return {
      [CreateExpertStepKey.Trigger]: { ...trigger, disabled: false },
      [CreateExpertStepKey.Account]: { ...account, disabled: accountDisabled },
      [CreateExpertStepKey.OrderOptions]: { ...orderOptions, disabled: orderOptionsDisabled },
      [CreateExpertStepKey.MoneyManagement]: { ...moneyManagement, disabled: moneyManagementDisabled },
      [CreateExpertStepKey.StopLossTakeProfit]: { ...stopLossTakeProfit, disabled: stopLossTakeProfitDisabled },
      [CreateExpertStepKey.PartialTakeProfits]: { ...partialTakeProfits, disabled: partialTakeProfitsDisabled },
      [CreateExpertStepKey.BreakEven]: { ...breakEven, disabled: breakEvenDisabled },
      [CreateExpertStepKey.AdvancedSettings]: { ...advanced, disabled: advancedDisabled },
      [CreateExpertStepKey.Preview]: { ...preview, disabled: previewDisabled },
    };
  }, [getState, errors, touched]);

  return (
    <div className='w-full bg-gray-800 py-4 rounded-lg'>
      <MenuItem {...stepsState[CreateExpertStepKey.Trigger]} onClick={() => onChange(CreateExpertStepKey.Trigger)}>
        {t('trigger.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.Account]} onClick={() => onChange(CreateExpertStepKey.Account)}>
        {t('account.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.OrderOptions]} onClick={() => onChange(CreateExpertStepKey.OrderOptions)}>
        {t('orderOptions.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.MoneyManagement]} onClick={() => onChange(CreateExpertStepKey.MoneyManagement)}>
        {t('moneyManagement.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.StopLossTakeProfit]} onClick={() => onChange(CreateExpertStepKey.StopLossTakeProfit)}>
        {t('stopLossTakeProfit.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.PartialTakeProfits]} onClick={() => onChange(CreateExpertStepKey.PartialTakeProfits)}>
        {t('partialTakeProfits.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.BreakEven]} onClick={() => onChange(CreateExpertStepKey.BreakEven)}>
        {t('breakEven.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.AdvancedSettings]} onClick={() => onChange(CreateExpertStepKey.AdvancedSettings)}>
        {t('advanced.title')}
      </MenuItem>
      <MenuItem {...stepsState[CreateExpertStepKey.Preview]} onClick={() => onChange(CreateExpertStepKey.Preview)}>
        {t('preview.title')}
      </MenuItem>
    </div>
  );
};
