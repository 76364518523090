import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';

import { globalRound } from '@root/shared/utils/number/round';

export interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value: number;
  updateValueSign?: boolean;
  currencyPrefix?: string;
  currencySuffix?: string;
}

export const ColoredValue: FC<PropsWithChildren<Props>> = ({ value, className, currencyPrefix = '', currencySuffix = '' }) => {
  const updatedValue = `${value >= 0 ? '' : '-'}${currencyPrefix ? currencyPrefix + ' ' : ''}${Math.abs(globalRound(value, 2))}${currencySuffix ? ' ' + currencySuffix : ''}`;

  return (
    <span
      className={clsx(
        {
          'text-secondary-200': value < 0,
          'text-success-400': value > 0,
        },
        className,
      )}
    >
      {updatedValue}
    </span>
  );
};
