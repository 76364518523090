export type ColorVariant = 'danger' | 'warning' | 'primary' | 'success' | 'info' | 'muted' | 'light' | 'gray' | 'grayscale' | 'secondary' | 'pink' | 'mint';
export type ColorTone = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
export type ColorValue = string;
export type Color = Record<ColorTone, ColorValue>;
export type GradientVariant = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type GradientValue = string;
export type Gradient = Record<GradientVariant, GradientValue>;

const config = {
  transparent: 'transparent',
  'color-divider': 'rgba(255,255, 255, 0.1)',
  grayscale: {
    100: '#FCFCFC' /* Grayscale/Off-white */,
    200: '#FBFBFB' /* Grayscale/line */,
    300: '#BFBFBF' /* Grayscale/Placehold */,
    400: '#B8B8B8' /* Grayscale/Input */,
    500: '#9A9A9A' /* Grayscale/Label */,
    600: '#676767' /* Grayscale/Body */,
    700: '#2C2C2C' /* Grayscale/Ash */,
    800: '#303030' /* Grayscale/Off-Black */,
    900: '#2c2c2c' /* Grayscale/Off-Black */,
  },
  secondary: {
    100: '#FFBE8E',
    200: '#FFA462',
    300: '#FF825A',
    400: '#FF6F41',
    500: '#F95520',
    600: '#E6410D',
    700: '#C83000',
    800: '#AD2A00',
    900: '#EAAC30',
  },
  danger: {
    100: '#FFE2E5',
    200: '#FFD2D8',
    300: '#FF968F',
    400: '#FF7C8D',
    500: '#FF5466',
    600: '#F0263B',
    700: '#E6152B',
    800: '#B60417',
    900: '#B60417',
  },
  warning: {
    100: '#FFF7CC',
    200: '#FFED99',
    300: '#FFE166',
    400: '#FFD200',
    500: '#FEBF42',
    600: '#F6AF22',
    700: '#B78000',
    800: '#936400',
    900: '#936400',
  },
  primary: {
    100: '#C9EFFF',
    200: '#8CDDFF',
    300: '#40C7FF',
    400: '#50BBFF',
    500: '#0281FF',
    600: '#005CFA',
    700: '#004DC0',
    800: '#0235A1',
    900: '#0235A1',
  },
  success: {
    100: '#E1FFFC',
    200: '#A0FFF5',
    300: '#37FFEA',
    400: '#04E2CB',
    500: '#00C2AE',
    600: '#00A998',
    700: '#007E71',
    800: '#005048',
    900: '#005048',
  },
  info: {
    100: '#C9EFFF',
    200: '#8CDDFF',
    300: '#40C7FF',
    400: '#029CFF',
    500: '#0281FF',
    600: '#005CFA',
    700: '#004DC0',
    800: '#0235A1',
    900: '#0235A1',
  },
  purple: {
    100: '#EDE1FF',
    200: '#CBA6FF',
    300: '#AE75FF',
    400: '#8F41FF',
    500: '#7319DA',
    600: '#53019A',
    700: '#4A0189',
    800: '#340160',
    900: '#340160',
  },
  pink: {
    100: '#ff66e6',
    200: '#ff4de2',
    300: '#ff33de',
    400: '#ff1ada',
    500: '#FF00D6',
    600: '#e600c1',
    700: '#cc00ab',
    800: '#b30096',
    900: '#b30096',
  },
  gray: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#FAFAFA',
    400: '#E9E9E9',
    500: '#C8C8C8',
    600: '#8E8E8E',
    700: '#464646',
    800: '#202020',
    900: '#131313',
  },
  light: {
    100: '#FFFFFF',
    200: '#FFFFFF',
    300: '#FFFFFF',
    400: '#FFFFFF',
    500: '#FFFFFF',
    600: '#FFFFFF',
    700: '#FFFFFF',
    800: '#FFFFFF',
    900: '#FFFFFF',
  },
  muted: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#E9E9E9',
    400: '#C8C8C8',
    500: '#8E8E8E',
    600: '#464646',
    700: '#202020',
    800: '#131313',
    900: '#131313',
  },
  mint: {
    100: '#00E8B8',
    200: '#0095F3',
    300: '#00E8B8',
    400: '#0095F3',
    500: '#00E8B8',
    600: '#0095F3',
    700: '#00E8B8',
    800: '#0095F3',
    900: '#0095F3',
  },
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  text: {
    primary: '#FCFCFC',
    secondary: '#00C2AE',
  },
  system: {
    green: '#D0FC81',
  },
  background: '#171B21',
  surface: '#1F232A',
  'surface-item-active': '#23272F',
  spacing: (value: number) => value * 4,
};

export interface Theme extends Record<ColorVariant, Color> {
  text: {
    primary: ColorValue;
    secondary: ColorValue;
  };
  screens: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
    '2xl': string;
  };
  system: {
    green: ColorValue;
  };
  gradient: Gradient;
  surface: ColorValue;
  background: ColorValue;
  spacing: (value: number) => number;
}

export const theme: Theme = {
  ...config,
  gradient: {
    1: 'linear-gradient(90deg, #8F41FF -0.23%, #BA1C50 100.23%)',
    2: 'linear-gradient(270deg, #8C84FF -0.23%, #41C7FF 100.23%)',
    3: 'linear-gradient(90deg, #00EDB8 -0.23%, #41C7FF 100.23%)',
    4: 'linear-gradient(270deg, #FF6D41 0.86%, #FEBF42 100.23%)',
    5: 'linear-gradient(270deg, #FF8C32 -0.23%, #FF5466 100.23%)',
    6: 'linear-gradient(90deg, #0095F3 -0.23%, #00E8B8 100.23%)',
    7: 'linear-gradient(90deg, #AAF7C1 0%, #D0FC81 91.92%)',
  },
};
