

import { useQuery } from 'react-query';
import {
  GET_TRADE_IDEA_PROVIDERS_EVENT_LOGS,
  GetProviderEventLogsQueryKey,
} from '../query/get-trade-idea-provider-event-logs.query';
import { GetProviderEventLogsData, ProviderEventLogsParams } from '../services/get-provider-event-logs.service';

export const useGetTradeIdeaProvidersEventLogs = (
  { providerId, page, perPage }: ProviderEventLogsParams,
  enabled = true,
) => {
  return useQuery<GetProviderEventLogsData, Error, GetProviderEventLogsData, GetProviderEventLogsQueryKey>(
    ['trade-idea-provider-event-logs', { providerId, page, perPage }],
    GET_TRADE_IDEA_PROVIDERS_EVENT_LOGS,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!providerId && enabled,
    },
  );
};
