import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FC, memo } from 'react';
import { BaseLine, CurrentLabel, PlacedLabel, Progress, StopLossLabel, TPLabel, Wrapper } from './order-status.ui';
import Decimal from 'decimal.js';
import { useOrderStatus } from '@root/modules/experts/hooks/use-order-status';

export type OrderStatusProps = {
    targets: { reached: boolean; value: number; trailingTP: boolean }[];
    current: number;
    placed: number;
    stopLoss?: { price: number; reached: boolean };
    trailingSL: boolean;
    trailingTP: boolean;
    isSell: boolean;
    status: 'pending' | 'open';
};

export const OrderStatus: FC<OrderStatusProps> = memo(({ targets, current, placed, stopLoss, trailingSL, trailingTP, isSell, status }) => {
    const { t } = useTranslation('forex-experts');
    const { tps, reachedTps, currentPosition, placedPosition } = useOrderStatus({ targets, current, placed, stopLoss, isSell, status });

    return (
        <Wrapper tpsCount={tps.length} className='flex w-full flex-col justify-center overflow-hidden px-0.5'>
            <BaseLine>
                {!!stopLoss?.price && (
                    <>
                        <StopLossLabel index={tps?.length || 0}>
                            {trailingSL ? t('tradeItemCard.TSL') : t('tradeItemCard.SL')} <span className='text-gray-100 wrapper'>{new Decimal(Number(stopLoss?.price) || 0).toFixed()}</span>
                        </StopLossLabel>
                        {!!stopLoss?.reached && <Progress from={placedPosition} to={0} negative={true} key={'slProgress'} />}
                    </>
                )}
                <PlacedLabel position={placedPosition}>
                    <span className='wrapper'>
                        {status === 'open' ? t('tradeItemCard.placed') : t('tradeItemCard.pendingPlaced')} <span className='text-gray-100'>{new Decimal(placed).toFixed()}</span>
                    </span>
                </PlacedLabel>
                {tps.map((target, index) => (
                    <TPLabel key={target.price} position={target.position} index={index}>
                        <span className={clsx('absolute right-0 block', { '!left-0': target.position < 50 })}>
                            {trailingTP && target.trailingTP ? t('tradeItemCard.TTP') : t('tradeItemCard.TP')} <span className='text-gray-100'>{new Decimal(target.price).toFixed()}</span>
                        </span>
                    </TPLabel>
                ))}
                {!!current && (
                    <>
                        <CurrentLabel negative={isSell ? current > placed : current < placed} position={currentPosition}>
                            <span className='wrapper'>
                                {t('tradeItemCard.current')} <span className='text-gray-100'>{new Decimal(current).toFixed()}</span>
                            </span>
                        </CurrentLabel>
                        {
                            status === 'open' && <Progress from={currentPosition} to={placedPosition} negative={isSell ? current > placed : current < placed} />
                        }
                    </>
                )}
                {reachedTps.map((point) => (
                    <Progress from={point.from} to={point.to} negative={false} key={point.to} />
                ))}
            </BaseLine>
        </Wrapper>
    );
});

OrderStatus.displayName = 'OrderStatus';
