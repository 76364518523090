import { QueryFunction } from 'react-query';

import { syncClosedOrders } from '@root/modules/orders/services/sync-closed-orders.service';

import { GetOrderServiceQuery, getOrdersHistoryService } from '../services/get-orders-history.service';
import { IOrder } from '../types/orders';
import { HISTORY_SYNCRONIZED } from '@root/shared-files/modules/shared/constants/local-storage-keys';

export type GetOrdersQueryKey = ['fx', GetOrderServiceQuery, 'orders-history'];
export type GetOrdersData = {
  orders: IOrder[];
  pagination: {
    totalItems: number;
    totalPages: number;
    page: number;
    size: number;
  };
};

export const GET_ORDERS_HISTORY: QueryFunction<GetOrdersData, GetOrdersQueryKey> = async ({ queryKey }) => {
  const [, params] = queryKey;

  if (!params?.userId) {
    throw new Error('Invalid query');
  }

  if (!localStorage.getItem(HISTORY_SYNCRONIZED)) {
    const syncResult = await syncClosedOrders({ userId: params?.userId, accounts: 'all' });
    if (syncResult.status === 200) {
      localStorage.setItem(HISTORY_SYNCRONIZED, 'true');
    }
  }

  const result = await getOrdersHistoryService(params);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
