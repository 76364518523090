import { useMemo } from 'react';
import { OrderStatusProps } from '../components/details/trades-and-history/order-status';

export type Props = Omit<OrderStatusProps, 'trailingSL' | 'trailingTP'>;

export const useOrderStatus = ({ targets, current, placed, stopLoss, isSell }: Props) => {
  const targetPrices = useMemo(() => targets.sort((a, b) => (isSell ? b.value - a.value : a.value - b.value)), [targets, isSell]);
  const hasStopLoss = typeof stopLoss?.price === 'number' && !Number.isNaN(stopLoss.price);

  const prices = useMemo(() => {
    if (hasStopLoss) {
      return [...targetPrices.map((t) => t.value), placed, current, stopLoss?.price as number].filter((price) => price).sort((a, b) => (isSell ? b - a : a - b));
    } else {
      return [...targetPrices.map((t) => t.value), placed, current].filter((price) => price).sort((a, b) => (isSell ? b - a : a - b));
    }
  }, [current, isSell, targetPrices, placed, stopLoss, hasStopLoss]);

  const { range, placedPosition, mostLeftValue } = useMemo(() => {
    const mostLeftValue = prices[0];
    const range = Math.abs(prices[prices.length - 1] - mostLeftValue);
    const placedPosition = (Math.abs(placed - mostLeftValue) / range) * 100;
    return {
      range,
      placedPosition,
      mostLeftValue,
    };
  }, [prices, placed]);

  const tps = useMemo(
    () =>
      targetPrices
        .map((t) => ({
          reached: t.reached,
          price: t.value,
          trailingTP: t.trailingTP,
          position: ((t.value - mostLeftValue) / (prices[prices.length - 1] - mostLeftValue)) * 100,
        }))
        .sort((a, b) => a.position - b.position),
    [targetPrices, mostLeftValue, prices],
  );

  const currentPosition = useMemo(() => (Math.abs(current - mostLeftValue) / range) * 100, [current, mostLeftValue, range]);

  const reachedTps = useMemo<{ from: number; to: number }[]>(() => [], []);

  return { tps, reachedTps, currentPosition, placedPosition };
};
