import warningImage from '@root/assets/images/cases/warning.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Title, Text } from '@root/shared/ui/typography';
import { FC } from 'react';

type Props = ModalProps & {
  loading: boolean;
};

export const SubscriptionCancelModal: FC<Props> = ({ isOpen, loading, onOk, onCancel }) => {
  const { t } = useTranslation('subscriptions');

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} footer={null}>
      <div className='flex flex-col items-center gap-8'>
        <div className='w-16 h-16'>
          <Image src={warningImage} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
        </div>
        <div>
          <Title level={5} className='font-bold text-center mb-8'>
            {t('cancelSubscriptionModal.unsubscribeTitle')}
          </Title>
          <Text size='sm'>
            * {t('cancelSubscriptionModal.cancelInfoText')}
          </Text>
        </div>
        <div className='w-full flex justify-between items-center'>
          <Button onClick={onCancel} variant='info' outlined>
            {t('cancelSubscriptionModal.no')}
          </Button>
          <Button loading={loading} onClick={onOk}>
            {t('cancelSubscriptionModal.cancelSubscription')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
