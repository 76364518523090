import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useGetPreset } from '@root/modules/presets/hooks/use-get-preset';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';

export const PRESETS_SUBSCRIPTION_ID_KEY = 'presetsSubscriptionId';
export const PRESETS_REDIRECT_TO_ASSIST_CREATION = 'redirectToAssistCreation';

export const useCheckPresetsSubscription = () => {
  const [presetId, setPresetId] = useLocalStorage<string>(PRESETS_SUBSCRIPTION_ID_KEY, '');
  const [redirectToAssistCreation, setRedirectToAssistCreation] = useLocalStorage<boolean>(PRESETS_REDIRECT_TO_ASSIST_CREATION, false);
  const navigate = useNavigate();

  const { data: preset, isLoading } = useGetPreset(presetId || '');

  useEffect(() => {
    if (presetId && !isLoading && preset) {
      if (redirectToAssistCreation) {
        navigate(`/assists/create-wizard/?presetId=${presetId}`);
      } else {
        navigate(`/presets/?presetId=${presetId}`);
      }

      setPresetId('');
      setRedirectToAssistCreation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, navigate, preset, presetId, redirectToAssistCreation, setPresetId]);
};
