import { QueryFunction } from 'react-query';

import { Id } from '@root/shared/utils/types';

import { getSymbols } from '../services/get-symbols.service';

export type GetSymbolsQueryKey = ['fx', Id | undefined, 'symbols'];
export type GetSymbolsData = string[];

export const GET_SYMBOLS: QueryFunction<GetSymbolsData, GetSymbolsQueryKey> = async ({ queryKey }) => {
  const result = await getSymbols();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
