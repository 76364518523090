import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PresetsLoader } from '@root/modules/presets/components/presets-loader';
import { PresetList } from '@root/modules/presets/containers/preset-list';
import { useGetPresets } from '@root/modules/presets/hooks/use-get-presets';
import { IPreset } from '@root/modules/presets/types/preset';
import Link from '@root/shared/ui/common/static-locales-link';
import { Text } from '@root/shared/ui/typography';
import { notify } from '@root/shared/utils/notification';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { expertsSlice } from '../store/experts.slice';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useGetMarketplaceTradeIdeaList } from '@root/modules/marketplace/hooks/use-get-marketplace-trade-idea-list';

const controlParams = {
  page: 1,
  itemsPerPage: 99,
  search: '',
};

export const TopPresets = () => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'topPresets' });
  const dispatch = useDispatch();
  const { values } = useFormikContext<CreateExpertDto>();
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const { data: traderProvidersData } = useGetMarketplaceTradeIdeaList(controlParams);

  const { data, isLoading } = useGetPresets();

  const handleCopyPreset = useCallback(
    (preset: IPreset) => {
      dispatch(
        expertsSlice.actions.setCopyPresetData({
          preset,
          formData: {
            account: values.account,
          },
        }),
      );
      notify({
        type: 'info',
        title: t('presetCopied'),
      });
    },
    [dispatch, t, values],
  );
  
  const presetsWithMarket = useMemo(() => {
    return data?.slice().map(preset => {
      const relatedProvider = traderProvidersData?.data?.find(provider => provider.id === preset.providerId);

      return {...preset, market: relatedProvider?.market}
    }) || []
  }, [data, traderProvidersData?.data])

  const topItems = useMemo(
    () =>
      presetsWithMarket
        ?.slice()
        .filter(preset => {
          if(!preset.market) {
            return false
          }

          if(preset.market === 'synthetic') {
            if(!subscriptionInfo?.roles.includes('synthetic provider')) {
              return false
            }
          }

          if(preset.market === 'indices') {
            if(!subscriptionInfo?.roles.includes('indices provider')) {
              return false
            }
          }

          return true
        })
        .sort((a, b) => b.copies - a.copies)
        .slice(0, 5),
    [presetsWithMarket, subscriptionInfo?.roles],
  );

  if (isLoading) {
    return (
      <div className='bg-gray-800 p-4 md:p-5 rounded-lg'>
        <PresetsLoader />
      </div>
    );
  }

  if (!data?.length || !topItems?.length) {
    return null;
  }

  return (
    <div className='bg-gray-800 p-4 md:p-5 rounded-lg '>
      <div className='flex gap-4 justify-between items-center mb-2'>
        <Text className='font-bold'>🔥 {t('title')}</Text>
        <Link href='/presets' passHref>
          <Text className='text-primary-400 font-bold'>{t('seeAll')}</Text>
        </Link>
      </div>
      <Text className='text-grayscale-400 mb-2 font-medium' size='sm'>
        {t('description')}
      </Text>

      <div className='gap-2 grid md:grid-cols-2 lg:grid-cols-1'>
        <PresetList list={topItems} onPresetSelect={handleCopyPreset} redirectToAssistCreation cardClassName='!bg-grayscale-700' />
      </div>
    </div>
  );
};
