import styled from 'styled-components';

import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { Text } from '@root/shared/ui/typography';

const StyledTableContainer = styled.div`
  max-height: calc(100vh - 480px);
  overflow: auto;

  scrollbar-color: #2C2C2C #676767;
  padding-right: 0;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #2C2C2C;
  }

  &::-webkit-scrollbar-corner {
    background-color: #2C2C2C;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #2C2C2C;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #676767;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #676767;
  }
`;

const Table = ({ children, className = '' }) => {
  return <StyledTableContainer className={clsx('border radius-[4px] border-gray-700', className)}>{children}</StyledTableContainer>;
};

const Row = ({ children, className = '' }) => {
  return <div className={clsx('border-b border-gray-700 last:border-none flex grid grid-cols-2 divide-x divide-gray-700', className)}>{children}</div>;
};

interface IItemProps {
  value?: string | number;
  label?: string;
  container?: boolean;
  className?: string;
  children?: ReactNode;
}

const Item: FC<IItemProps> = ({ label = '', value = '', className = '', children, container = false }) => {
  return (
    <div className={clsx('px-4', container ? 'pr-4 py-2' : 'pr-0 py-1', className)}>
      <div className='flex justify-between items-start gap-x-2'>
        <Text className='text-grayscale-500 flex-shrink-0'>{label}</Text>
        <Text className='text-right'>{value.toString()}</Text>
      </div>

      <div className='w-full'>{children}</div>
    </div>
  );
};

export const SyncExpertUI = { Row, Item, Table };
