import { useTranslation } from 'react-i18next';
import { ChartPieIcon } from '@root/shared/icons/chart-pie-icon';
import { LightningIcon } from '@root/shared/icons/lightning-icon';
import { TradeTopIcon } from '@root/shared/icons/trade-top-icon';
import { FC } from 'react';
import clsx from 'clsx';
import { ids } from './marketplace-trade-idea-providers-table';

interface IProps {
  id: string;
  signalCount: number;
  winsCount: number;
  lossesCount: number;
  pnl: number;
  showSlWonInfo: boolean;
}

export const ProviderStatistics: FC<IProps> = ({ id, signalCount = 0, winsCount = 0, lossesCount = 0, pnl = 0, showSlWonInfo = false }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  return (
    <div className={clsx('grid gap-4 text-sm mt-4', { 'grid-cols-3': showSlWonInfo, 'grid-cols-1': !showSlWonInfo })}>
      <div className='flex gap-2'>
        <LightningIcon className='text-lg' />
        <div className='flex flex-col gap-y-1'>
          <span className='text-xs text-gray-600'>{t('table.tradeIdeasCount')}</span>
          <span>{signalCount}</span>
        </div>
      </div>
      {showSlWonInfo && (
        <>
          {
            !ids.includes(id) && <div className='flex gap-2 justify-center md:justify-start'>
              <TradeTopIcon className='text-lg ' />
              <div className='flex flex-col gap-y-1'>
                <span className='text-xs text-gray-600'>{t('table.winRatio')}</span>
                <span>{`${winsCount} ${t('table.wins')} / ${lossesCount} ${t('table.losses')}`}</span>
              </div>
            </div>
          }
          {
            !ids.includes(id) && <div className='flex gap-2 justify-end md:justify-start'>
              <ChartPieIcon className='text-lg' />
              <div className='flex flex-col gap-y-1'>
                <span className='text-xs text-gray-600'>{t('statistics.pnl')}</span>
                <span>{pnl}</span>
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
};
