import { useTranslation } from 'react-i18next';
import { ModuleTab, ModuleTabs } from '@root/shared/ui/module-tabs/module-tabs';
import { useState } from 'react';
import { ProviderLastTradeIdeas } from './provider-last-trade-ideas';
import { ProviderTraders } from './provider-traders';
import { GetMarketplaceTradeIdeaProviderData } from '../query/get-marketplace-trade-idea-provider.query';

enum ProviderDataTabs {
  TradeIdeas = 'trade-ideas',
  Traders = 'traders',
}

export const ProviderData = ({ data }: { data?: GetMarketplaceTradeIdeaProviderData, isLoading: boolean }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  const [activeTab, setActiveTab] = useState<ProviderDataTabs>(ProviderDataTabs.TradeIdeas);

  if (!data) {
    return null;
  }

  return (
    <div className='bg-gray-800 w-full rounded overflow-hidden shadow-xl p-4 pt-2'>
      <ModuleTabs className='mb-4'>
        <ModuleTab active={activeTab === ProviderDataTabs.TradeIdeas} onClick={() => setActiveTab(ProviderDataTabs.TradeIdeas)} className='pl-0'>
          {t('tableTabs.lastTradeIdeas')}
        </ModuleTab>
        <ModuleTab active={activeTab === ProviderDataTabs.Traders} onClick={() => setActiveTab(ProviderDataTabs.Traders)}>
          {t('tableTabs.traders')}
        </ModuleTab>
        <ModuleTab />
      </ModuleTabs>

      {activeTab === ProviderDataTabs.TradeIdeas && <ProviderLastTradeIdeas id={data?.id} market={data?.market}/>}
      {activeTab === ProviderDataTabs.Traders && <ProviderTraders id={data?.id} />}
    </div>
  );
};
