import clsx from 'clsx';
import { FC, Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'tailwind-styled-components';

import { GridIcon } from '@root/shared/icons/grid-icon';
import { ListIcon } from '@root/shared/icons/list-icon';
import { Select } from '@root/shared/ui/form/select';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';

import { useExpertsTable } from '../hooks/use-experts-table';
import { ExpertCard } from './expert-card';
import { ExpertsTable } from './experts-table';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';

export enum DisplayAs {
  Grid = 'grid',
  List = 'list',
}

const ItemsWrapper = tw.div`grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-10`;

export const ExpertAssists: FC = memo(() => {
  const { t } = useTranslation('forex-experts');
  const { data, isLoading, displayAs, selectedPairsOption, pairsOptions, selectedBrokersOption, brokerOptions, changeParams, changeView } = useExpertsTable();

  const { openOrders } = useGetOpenOrders({ enabled: true, selectedBroker: 'all', mtType: undefined });

  const profitsByExpertId = useMemo(() => {
    const profits: Record<string, number> = {};

    openOrders?.forEach((order) => {
      if (order?.expertId) {
        profits[order.expertId] = (profits[order.expertId] || 0) + order.profit;
      }
    });

    return profits;
  }, [openOrders]);

  return (
    <div className='w-full'>
      <div className='w-full flex justify-between items-center gap-x-4 mb-4'>
        {data?.length ? (
          <div className='flex justify-start items-center gap-x-3'>
            <GridIcon
              className={clsx('cursor-pointer', {
                'text-grayscale-500': displayAs === DisplayAs.List,
                'text-gray-100': displayAs === DisplayAs.Grid,
              })}
              onClick={() => changeView(DisplayAs.Grid)}
              outlined={displayAs !== DisplayAs.Grid}
            />
            <ListIcon
              className={clsx('cursor-pointer', {
                'text-grayscale-500': displayAs === DisplayAs.Grid,
                'text-gray-100': displayAs === DisplayAs.List,
              })}
              onClick={() => changeView(DisplayAs.List)}
              outlined={displayAs !== DisplayAs.List}
            />
          </div>
        ) : (
          <div />
        )}
        <div className='flex justify-end items-center gap-x-2'>
          <Select
            options={brokerOptions}
            customLabel={t('filters.brokerAccounts')}
            value={selectedBrokersOption}
            onChange={(option) => changeParams({ broker: option.value })}
            bordered
          />
          <Select options={pairsOptions} customLabel={t('filters.pairs')} value={selectedPairsOption} onChange={(option) => changeParams({ pair: option.value })} bordered />
        </div>
      </div>

      {displayAs === DisplayAs.Grid && (
        <ItemsWrapper>
          {isLoading && (
            <SkeletonLoader
              shapes={[
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
                { circle: false, width: '100%', height: 169 },
              ]}
            />
          )}
          {!isLoading && <Fragment>{!!data?.length && data.map((expert) => <ExpertCard key={expert.id} data={expert} pnl={profitsByExpertId[expert.id] || 0} />)}</Fragment>}
        </ItemsWrapper>
      )}
      {displayAs === DisplayAs.List && <ExpertsTable data={data} isLoading={isLoading} profitsByExpertId={profitsByExpertId} />}
    </div>
  );
});

ExpertAssists.displayName = 'ExpertAssists';

