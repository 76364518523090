import { useQuery } from 'react-query';

import { GET_SHARED_EXPERT, GetExpertData, GetExpertQueryKey } from '../queries/get-shared-expert.query';

export const useGetSharedExpert = (id?: string, enabled = true) => {
  return useQuery<GetExpertData, Error, GetExpertData, GetExpertQueryKey>(['shared-experts', id || ''], GET_SHARED_EXPERT, {
    keepPreviousData: true,
    enabled: !!id && enabled,
  });
};
