import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { useGetMarketplaceTradeIdeaProvider } from '@root/modules/marketplace/hooks/use-get-marketplace-trade-idea-provider';
import { useGetSubscribedProviders } from '@root/modules/marketplace/hooks/use-get-subscribed-providers';
import { useTradeIdeaSubscription } from '@root/modules/marketplace/hooks/use-trade-idea-provider-subscription';
import { PresetsCard } from '@root/modules/presets/components/preset-card';
import { SubscribeToCopyModal } from '@root/modules/presets/components/subscribe-to-copy-modal';
import { PRESETS_REDIRECT_TO_ASSIST_CREATION, PRESETS_SUBSCRIPTION_ID_KEY } from '@root/modules/presets/hooks/use-check-presets-subscription';
import { IPreset } from '@root/modules/presets/types/preset';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { getMarketplaceTradeIdeaProviderService } from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider.service';
import { queryClient } from '@root/infra/query';
import { notify } from '@root/shared/utils/notification';

export type PresetsListProps = {
  list: IPreset[];
  onPresetSelect: (preset: IPreset) => void;
  redirectToAssistCreation?: boolean;
  cardClassName?: string;
};

export const PresetList: FC<PresetsListProps> = ({ list, onPresetSelect, redirectToAssistCreation, cardClassName }) => {
  const [isEditSubscriptionModalOpen, setEditSubscribeModalOpen] = useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState<'month' | 'year'>('month');
  const [selectedPreset, setSelectedPreset] = useState<IPreset | null>(null);
  const [, setPresetId] = useLocalStorage<string>(PRESETS_SUBSCRIPTION_ID_KEY, '');
  const [, setRedirectToAssistCreation] = useLocalStorage<boolean>(PRESETS_REDIRECT_TO_ASSIST_CREATION, false);
  const isTokensRefreshed = useSelector(authSelector.isTokenRefreshed);
  const { data: tradeProvidersData, refetch: refetchSubscribedProviders } = useGetSubscribedProviders();
  const [searchParams, setSearchParams] = useSearchParams();
  const presetId = searchParams.get('presetId');

  const [{ loading: subscribeLoading }, { handleSubscribeClick }] = useTradeIdeaSubscription({
    subscriptionType,
  });

  const { data: presetsProvider, isLoading: presetProviderLoading } = useGetMarketplaceTradeIdeaProvider(selectedPreset?.providerId as string, {});

  const checkIsSubscribedToProvider = useCallback(
    (preset: IPreset) => {
      return !!tradeProvidersData?.data?.find((item) => item.id === preset?.providerId);
    },
    [tradeProvidersData?.data],
  );

  const handleBuySubscription = useCallback(() => {
    if (presetsProvider) {
      setPresetId(selectedPreset?.id as string);
      setRedirectToAssistCreation(!!redirectToAssistCreation);
      handleSubscribeClick(presetsProvider);
    }
  }, [redirectToAssistCreation, presetsProvider, selectedPreset?.id, setPresetId, setRedirectToAssistCreation, handleSubscribeClick]);

  const handleSubscribeToProvider = useCallback(async (preset: IPreset) => {
    if (presetsProvider) {
      const monthlyPlan = presetsProvider?.plans?.find((item) => item.recurringIntervalUnit === 'month');

      if (monthlyPlan?.price === 0) {
        setSubscriptionType('month');
        await handleSubscribeClick(presetsProvider);
        return true;
      } else {
        setEditSubscribeModalOpen(true);
        return false;
      }
    } else {
      const result = await getMarketplaceTradeIdeaProviderService(preset?.providerId);
      if (result.status === 200) {
        const provider = result.payload;
        queryClient.setQueryData(['marketplace-trade-idea-provider', preset?.providerId], result);
        const monthlyPlan = provider?.plans?.find((item) => item.recurringIntervalUnit === 'month');
        if (monthlyPlan?.price === 0) {
          setSubscriptionType('month');
          await handleSubscribeClick(provider);
          return true;
        } else {
          setEditSubscribeModalOpen(true);
          return false;
        }
      } else {
        notify({
          type: 'danger',
          title: result.payload,
        })
      }
    }
  }, [handleSubscribeClick, presetsProvider]);

  const handleCopy = useCallback(
    async (preset: IPreset) => {
      const isNotSubscribedToProvider = !checkIsSubscribedToProvider(preset);

      if (isNotSubscribedToProvider) {
        setSelectedPreset(preset);
        const isPurchased = await handleSubscribeToProvider(preset);
        if (!isPurchased) {
          return;
        }
      }

      onPresetSelect(preset);
      setSelectedPreset(null);
    },
    [checkIsSubscribedToProvider, onPresetSelect, handleSubscribeToProvider],
  );

  const applyPresetSettingsAfterSubscription = useCallback(
    async (preset: IPreset) => {
      const timeout = setTimeout(async () => {
        await refetchSubscribedProviders();
        onPresetSelect(preset);
        searchParams.delete('presetId');
        setSearchParams(searchParams);
        clearTimeout(timeout);
      }, 3000);
    },
    [refetchSubscribedProviders, onPresetSelect, searchParams, setSearchParams],
  );

  useEffect(() => {
    const preset = list.find((item) => item.id === presetId);
    if (presetId && preset && isTokensRefreshed) {
      applyPresetSettingsAfterSubscription(preset);
    }
  }, [applyPresetSettingsAfterSubscription, isTokensRefreshed, presetId]);

  return (
    <>
      {list?.map((preset) => <PresetsCard loading={preset.id === selectedPreset?.id && subscribeLoading} data={preset} className={cardClassName} handleCopy={() => handleCopy(preset)} key={preset.id} />)}
      <SubscribeToCopyModal
        isOpen={isEditSubscriptionModalOpen}
        onPeriodChange={setSubscriptionType}
        onCancel={() => setEditSubscribeModalOpen(false)}
        onOk={handleBuySubscription}
        subscriptionType={subscriptionType}
        loading={subscribeLoading || presetProviderLoading}
        provider={presetsProvider}
        onEditClick={() => onPresetSelect(selectedPreset as IPreset)}
      />
    </>
  );
};
