import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpertStatusContainer } from '@root/modules/experts/components/experts.ui';
import { ExpertStatus } from '@root/modules/experts/enums';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

export type ExpertStatusProps = {
  status: {
    text: ExpertStatus;
    explanation?: string;
  };
};

export const ExpertStatusBadge: FC<ExpertStatusProps> = ({ status }) => {
  const { t } = useTranslation('forex-experts');
  const { text, explanation } = status;

  if (explanation) {
    return (
      <InfoTooltip
        content={
          <Text className='w-full text-center' size='sm'>
            {t(explanation)}
          </Text>
        }
      >
        <ExpertStatusContainer status={text}>
          <Text size='sm' className='whitespace-nowrap'>{t(`statuses.${text}`)}</Text>
        </ExpertStatusContainer>
      </InfoTooltip>
    );
  }

  return (
    <ExpertStatusContainer status={text}>
      <Text size='sm' className='whitespace-nowrap'>{t(`statuses.${text}`)}</Text>
    </ExpertStatusContainer>
  );
};
