import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@root/shared/ui/typography';

type Props = {
  totalOpenTrades: number;
  totalClosedTrades: number;
};

export const Statistics: FC<Props> = ({ totalOpenTrades, totalClosedTrades }) => {
  const { t } = useTranslation('overall-stats');

  return (
    <div className='w-full flex flex-wrap justify-start items-start gap-x-6 font-medium mb-2'>
      <div>
        <Text size='sm' className='text-grayscale-400'>
          {t('overallStats.totalOpenTrades')}
        </Text>
        <Text size='sm'>{totalOpenTrades || 0}</Text>
      </div>
      <div>
        <Text size='sm' className='text-grayscale-400'>
          {t('overallStats.totalClosedTrades')}
        </Text>
        <Text size='sm'>{totalClosedTrades || 0}</Text>
      </div>
    </div>
  );
};
