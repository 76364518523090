import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const PencilIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3328 0.533923C11.3328 0.533923 12.9817 -0.217059 14.4183 1.2196C15.855 2.65626 15.104 4.30516 15.104 4.30516L5.5045 13.9047L1.219 14.4189L1.73327 10.1334L11.3328 0.533923ZM3.00216 10.7502L2.74503 12.8929L4.88778 12.6358L13.9324 3.59117C13.9502 3.50623 13.9651 3.40064 13.9667 3.28274C13.9708 2.98637 13.897 2.58391 13.4755 2.16241C13.054 1.74092 12.6516 1.66713 12.3552 1.67122C12.2373 1.67285 12.1317 1.68774 12.0468 1.70555L3.00216 10.7502Z'
        fill='currentColor'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M12.6318 6.41728L9.0401 2.82562L9.98291 1.88281L13.5746 5.47447L12.6318 6.41728Z' fill='currentColor' />
    </svg>
  );
};
