import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { CreateExpertStepKey } from '../hooks/use-create-expert-steps';

type ContextProps = {
  editingSteps: Record<CreateExpertStepKey, boolean>;
  setEditingSteps: Dispatch<SetStateAction<Record<CreateExpertStepKey, boolean>>>;
};

export const PartialEditContext = createContext<ContextProps>({
  editingSteps: {
    [CreateExpertStepKey.Account]: false,
    [CreateExpertStepKey.AdvancedSettings]: false,
    [CreateExpertStepKey.BreakEven]: false,
    [CreateExpertStepKey.MoneyManagement]: false,
    [CreateExpertStepKey.PartialTakeProfits]: false,
    [CreateExpertStepKey.Preview]: false,
    [CreateExpertStepKey.StopLossTakeProfit]: false,
    [CreateExpertStepKey.Trigger]: false,
    [CreateExpertStepKey.OrderOptions]: false,
  },
  setEditingSteps: () => {},
});

export const usePartialEditContext = () => useContext(PartialEditContext);
