import { useTranslation } from 'react-i18next';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { Text, Title } from '@root/shared/ui/typography';
import connectionImg from '@root/assets/images/connection-grayed.webp';

import { FC } from 'react';

export const NoAccountBanner: FC = () => {
  const { t } = useTranslation('forex-accounts');

  return (
    <div className='rounded p-4 lg:p-11'>
      <div className='flex flex-col lg:flex-row lg:items-center w-full mb-6 lg:flex-1'>
        <Image wrapperClassName='flex-shrink-0 self-center' src={connectionImg} height={124} width={124} alt='Connection' />
        <div className='flex flex-col items-start lg:ml-8 mt-4 lg:mt-0 max-w-screen-sm'>
          <Title level={6} className='mb-1'>
            {t('noAccounts')}
          </Title>
          <Text>{t('connectAccount')}</Text>
          <ButtonLink isLocalRoute href={PageLinks.createAccount} className='flex items-center gap-3 mt-4'>
            <PlusIcon />
            {t('connectButton')}
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};
