import styled from 'styled-components';

import React, { FC } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Image } from '@root/shared/ui/image';
import clsx from 'clsx';

const Wrapper = styled.div<Pick<ImageWithFallbackProps, 'width' | 'height'>>`
  display: inline-flex;
  border-radius: 50%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;

  & > span {
    height: 100% !important;
    width: 100% !important;
  }
`;

const generateColor = (str: string) => {
  const firstLetter = str.toString().charAt(0).toLowerCase();
  const code = firstLetter.charCodeAt(0);
  const colorNum = code.toString() + code.toString() + code.toString();
  const num = Math.round(0xffffff * parseInt(colorNum));
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  return `rgb(${r}, ${g}, ${b}, 0.5)`;
};

const DefaultImage = styled.div<Pick<ImageWithFallbackProps, 'width' | 'height' | 'label'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  font-size: ${({ height }) => `${Math.round((height as number) * 0.6153)}px`};
  color: #fff;
  background: ${({ label }) => generateColor(label)};
  border-radius: 50%;
`;

export interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  wrapperClassName?: string;
  src?: string;
  label: string;
}

export const ImageWithFallback: FC<ImageWithFallbackProps> = ({ wrapperClassName, className, width, height, src, label, ...props }) => {
  const [isError, setIsError] = React.useState(false);

  if (!src || isError) {
    return (
      <Wrapper width={width} height={height} className={clsx('flex-shrink-0', wrapperClassName)}>
        <DefaultImage width={width} height={height} label={label}>
          {label?.substring(0, 1).toUpperCase()}
        </DefaultImage>
      </Wrapper>
    );
  }

  return (
    <Wrapper width={width} height={height} className={clsx('flex-shrink-0', wrapperClassName)}>
      <Image className={className} src={src} width={width} height={height} {...props} onError={() => setIsError(true)} />
    </Wrapper>
  );
};
