import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';
import { IExpert } from '../types/expert';

export type GetExpertServiceResponse = IHttpResponse<200, IExpert> | IHttpResponse<400, string>;

export const getExpertService = async (expertId: string): Promise<GetExpertServiceResponse> => {
  try {
    const response = await fetcher(`/experts/${expertId}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
