import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { notify } from '@root/shared/utils/notification';

import { getQuizState } from '../services/get-quiz-state.service';
import { startQuiz } from '../services/start-quiz.service';
import { authSlice } from '../store';

export const useQuizModal = () => {
  const [url, setUrl] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const completeQuiz = useCallback(async () => {
    setStep(2);
  }, []);

  const confirmCompletion = useCallback(async () => {
    setLoading(true);
    const response = await getQuizState();
    if (response.status === 200) {
      dispatch(authSlice.actions.setQuizState(response.payload));
      if (response.payload.currentQuizVersion === response.payload.lastCompletedQuizVersion) {
        navigate('/');
      }
    }
    setLoading(false);
  }, [dispatch, navigate]);

  useEffect(() => {
    (async () => {
      const response = await startQuiz();
  
      if (response.status === 200) {
        setUrl(response.payload.quizFormUrl);
      } else {
        notify({ title: response.payload, type: 'danger' });
      }
    })();
  }, []);

  return {
    url,
    step,
    loading,
    setStep,
    completeQuiz,
    confirmCompletion,
  };
};
