import dayjs from 'dayjs';
import styled from 'styled-components';

import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { settingsSelector } from '@root/modules/app-settings/store/settings.selector';
import { ProfitabilityRange } from '@root/modules/dashboard/components/profitability-range';
import { TradeStats } from '@root/modules/orders/types/statistic';
import { STATISTIC_EXPLANATION_LINK } from '@root/shared/constants/social-links';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { Text } from '@root/shared/ui/typography';
import { getPrefix } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';

const Wrapper = ({ children, className = '' }) => (
  <div className={clsx('overflow-x-auto', className)}>
    <div className='min-w-[830px] w-full'>{children}</div>
  </div>
);

const StatItemText = styled(Text)`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.grayscale[400]};
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  font-weight: 500;

  strong {
    color: ${({ theme }) => theme.gray[400]};
    font: bold;
    margin-left: 10px;
    font-weight: 600;

    span {
      color: ${({ theme }) => theme.grayscale[400]};
    }
  }
`;

const LinkWithInfoIcon = styled.a`
  :hover {
    path {
      fill: ${({ theme }) => theme.primary[400]};
    }
  }
`;

type Props = {
  data: TradeStats;
  loading: boolean;
  currency: string;
  title?: string;
};

export const TradeStat: FC<Props> = ({ title, data, currency }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'generalStatistics' });
  const isBalanceVisible = useSelector(settingsSelector.isBalanceVisible);

  return (
    <Wrapper>
      {title && <Text bold>{t(title)}</Text>}
      <div className='flex justify-between gap-10 min-w-[830px] overflow-auto mt-2'>
        <div className='w-1/3'>
          <Text className='flex justify-between text-grayscale-400' size='sm'>
            {t('profitability')}: <ProfitabilityRange profit={data.profitabilityWin} loss={data.profitabilityLoss} />
          </Text>
          <StatItemText>
            {t('averageWin')}:
            <strong>
              {data.averageWin && isBalanceVisible ? `${getPrefix(data.averageWin)}${currency}${globalRound(Math.abs(data.averageWin), 2)}` : '-'}
            </strong>
          </StatItemText>
          <StatItemText>
            {t('averageLoss')}:
            <strong>
              {data.averageLoss && isBalanceVisible ? `${getPrefix(data.averageLoss)}${currency}${globalRound(Math.abs(data.averageLoss), 2)}` : '-'}
            </strong>
          </StatItemText>
          <StatItemText>
            {t('lots')}: <strong>{globalRound(data.lots, 2) || '-'}</strong>
          </StatItemText>
        </div>

        <div className='w-1/3'>
          <StatItemText>
            {t('commissions')}: <strong>{isBalanceVisible ? globalRound(data.commissions, 2) : '-'}</strong>
          </StatItemText>
          <StatItemText>
            {t('bestTrades')}:
            <strong>
              {data.bestTradeDate && <span>{dayjs(data.bestTradeDate).format('(MMM DD)')}</span>}{' '}
              {isBalanceVisible && data.bestTrade ? `${getPrefix(data.bestTrade)}${currency}${globalRound(Math.abs(data.bestTrade || 0), 2)}` : '-'}
            </strong>
          </StatItemText>
          <StatItemText>
            {t('worstTrades')}:
            <strong>
              {data.worstTradeDate && <span>{dayjs(data.worstTradeDate).format('(MMM DD)')}</span>}{' '}
              {isBalanceVisible && data.worstTrade ? `${getPrefix(data.worstTrade)}${currency}${globalRound(Math.abs(data.worstTrade || 0), 2)}` : '-'}
            </strong>
          </StatItemText>
        </div>

        <div className='w-1/3'>
          <StatItemText>
            {t('avgTradeLength')}: <strong>{data.averageTradeLengthSeconds ? globalRound(data.averageTradeLengthSeconds / 3600, 2) + 'h' : '-'}</strong>
          </StatItemText>
          <StatItemText>
            {t('profitFactor')}: <strong>{globalRound(data.profitFactor, 2) || '-'}</strong>
          </StatItemText>
          <StatItemText>
            {t('sharpeRatio')}: <strong>{globalRound(data.sharpeRatio, 2) || '-'}</strong>
          </StatItemText>
          <StatItemText>
            {t('expectancy')} ({currency}): <strong>{globalRound(data.expectancy, 2) || '-'}</strong>
          </StatItemText>
          <LinkWithInfoIcon
            href={STATISTIC_EXPLANATION_LINK}
            className='flex items-center justify-end gap-x-2 hover:text-primary-400 text-sm'
            target='_blank'
            rel='noreferrer'
          >
            {t('explanation')} <InfoTooltipIcon/>
          </LinkWithInfoIcon>
        </div>
      </div>
    </Wrapper>
  );
};
