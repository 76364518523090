import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IAccountBalance } from '@root/modules/accounts/types/balance';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SignInAccountServiceResponse = IHttpResponse<200, Record<IAccountBalance['accountId'], IAccountBalance>> | IHttpResponse<400, string>;

export interface SignInAccountServiceValues {
  accountId: string;
}

export const signInAccountService = async ({ accountId }: SignInAccountServiceValues): Promise<SignInAccountServiceResponse> => {
  try {
    const response = await fetcher.post(`/accounts/${accountId}/sign-in`, undefined, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: { [response.data.accountId]: response.data },
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
