import { useFormikContext } from 'formik';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Trigger as TriggerFields } from '@root/modules/experts/components/trigger';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { Text } from '@root/shared/ui/typography';
import { EditableSettingsTitle } from './editable-settings-title';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const Trigger: FC<Props> = ({ expert, editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.Trigger);
  const { values } = useFormikContext<CreateExpertDto>();
  const { traders } = useProviderTraders();
  const { strategyTypeOptions } = useCreateExpertFieldOptions();

  const isSignalStrategyType = values.strategy.type === 'signal';

  const selectedProviderOption = useMemo(() => traders.find((item) => item.providerId === expert.providerId), [traders, expert.providerId]);

  return (
    <div>
      <EditableSettingsTitle
        title={t('steps.trigger.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.Trigger}
        editDisabled={false}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <TriggerFields isPartialEdit />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.strategyTypeWebhook.label')}
            </Text>
            <Text size='base' className='text-right'>
              {strategyTypeOptions.find((item) => item.value === values.strategy.type)?.label || '-'}
            </Text>
          </div>
          {isSignalStrategyType && (
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.providerId.label')}
              </Text>
              <Text size='base' className='text-right'>
                {selectedProviderOption?.name || '-'}
              </Text>
            </div>
          )}

          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.name.label')}
            </Text>
            <Text size='base' className='text-right'>
              {values.name}
            </Text>
          </div>
          {
            !!values.description && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.description.label')}
                </Text>
                <Text size='base' className='text-right flex-grow'>
                  {values.description}
                </Text>
              </div>
            )
          }
          {values.conditionalClosure && (
              <Fragment>
                <div className='flex justify-between mb-2 w-full'>
                  <Text className='text-grayscale-400'>{t('fields.conditionalClosure.use.label')}</Text>
                  <Text className='text-right flex-grow'>
                    {values.conditionalClosure.use ? t('fields.conditionalClosure.use.options.selected.label', { point: values.unitType }) : t('fields.conditionalClosure.use.options.notSelected.label')}
                  </Text>
                </div>

                {values.conditionalClosure.use && (
                  <div className='flex justify-between mb-2 w-full'>
                    <Text className='text-grayscale-400'>{t('fields.conditionalClosure.amount.label')}</Text>
                    <Text className='text-right flex-grow'>
                      {values.conditionalClosure.amount || '-'} {values.unitType}
                    </Text>
                  </div>
                )}
              </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

