import { AxiosError } from 'axios';
import { ReactPaginateProps } from 'react-paginate';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';

import { ExpertEventsMapper } from '../mappers/export-events-dto.mapper';
import { IExpertEvent } from '../types/expert';

export type GetExpertEventsResponseData = {
  data: IExpertEvent[];
  pagination: ReactPaginateProps;
};

export type ExpertEventsServiceResponse = IHttpResponse<200, GetExpertEventsResponseData> | IHttpResponse<400, string>;

export type GetExpertEventsQuery = {
  expertId: string;
  userId: Id;
  page: number;
  itemsPerPage: number;
  orderBy?: string;
  orderDirection?: string;
  orderId?: string;
};

export const getExpertEventsService = async ({ orderId, expertId, page, itemsPerPage, orderBy, orderDirection }: GetExpertEventsQuery): Promise<ExpertEventsServiceResponse> => {
  try {
    const query = stringifyParams({
      page,
      size: itemsPerPage,
      orderBy,
      orderDirection,
      orderId,
    });

    const response = await fetcher(`/experts/${expertId}/logs?${query}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: {
        data: response.data.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((item) => ExpertEventsMapper.toDomain(item)),
        pagination: {
          pageCount: response.data.total / itemsPerPage > 1 ? Math.ceil(response.data.total / itemsPerPage) : 1,
        },
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
