import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';

import { SyncExpertUI } from '../sync-preset/sync-preset-ui';
import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';

const { Row, Item } = SyncExpertUI;

export const PlainDiffValue = ({ label, expertValue, presetValue }) => {
  return (
    <Row>
      <Item container label={label} value={expertValue} />
      <Item container label={label} value={presetValue} />
    </Row>
  );
};

export const SymbolsDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });

  const expertSymbols = expert.symbols.join(', ');
  const presetSymbols = preset.symbols.join(', ');

  return <PlainDiffValue label={tForm('symbols.text')} expertValue={expertSymbols} presetValue={presetSymbols} />;
};

export const AdvancedDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t } = useTranslation('forex-experts');

  const options = useCreateExpertFieldOptions();

  const expertFields = useMemo(() => {
    return {
      useSlippage: expert.useSlippage,
      slippage: expert.slippage,
      usePips: expert.usePips,
      usePriceTolerance: expert.strategy.usePriceTolerance,
      priceTolerance: expert.strategy.priceTolerance,
      priceToleranceTypeOption: options.priceToleranceUnitTypeOptions.find((option) => option.value === expert.strategy.priceToleranceType),
      expiryTime: expert.strategy.timeTolerance,
      expiryTimeTypeOption: options.expiryTimeTypeOptions.find((option) => option.value === expert.strategy.timeToleranceType),
      useTpProximity: expert.strategy.usePostSignalProximity,
      tpProximity: expert.strategy.tpProximity,
      tpProximityTypeOption: options.tpProximityOptions.find((option) => option.value === expert.strategy.tpProximityType),
    };
  }, [expert, options]);

  const presetFields = useMemo(() => {
    return {
      useSlippage: preset.useSlippage,
      slippage: preset.slippage,
      usePips: preset.usePips,
      usePriceTolerance: preset.strategy.usePriceTolerance,
      priceTolerance: preset.strategy.priceTolerance,
      priceToleranceTypeOption: options.priceToleranceUnitTypeOptions.find((option) => option.value === preset.strategy.priceToleranceType),
      expiryTime: preset.strategy.timeTolerance,
      expiryTimeTypeOption: options.expiryTimeTypeOptions.find((option) => option.value === preset.strategy.timeToleranceType),
      useTpProximity: preset.strategy.usePostSignalProximity,
      tpProximity: preset.strategy.tpProximity,
      tpProximityTypeOption: options.tpProximityOptions.find((option) => option.value === preset.strategy.tpProximityType),
    };
  }, [preset, options]);

  return (
    <Row>
      <Item container label={t('steps.advanced.title')}>
        <Item label={tForm('strategyType.label')} value={tForm(`strategyType.options.${expert.strategy.type}`)} />
        <Item label={tForm('slippage.use')} value={expertFields.useSlippage ? t('on') : t('off')} />
        {expertFields.useSlippage && <Item label={tForm('slippage.label')} value={expertFields.slippage} />}
        <Item label={tForm('usePips.label')} value={expertFields.usePips ? t('on') : t('off')} />
        <Item label={tForm('priceTolerance.label')} value={expertFields.usePriceTolerance ? `${expertFields.priceTolerance} ${expertFields.priceToleranceTypeOption?.label}` : t('off')} />
        {expertFields.usePriceTolerance && <Item label={tForm('expiryTime.label')} value={expertFields.expiryTime ? `${expertFields.expiryTime} ${expertFields.expiryTimeTypeOption?.label}` : t('off')} />}
        <Item label={tForm('postSignalValidation.label')} value={expertFields.useTpProximity ? t('on') : t('off')} />
        {
          expertFields.useTpProximity && (
            <Item label={tForm('tpProximity.label')} value={`${expertFields.tpProximity} ${expertFields.tpProximityTypeOption?.label}`} />
          )
        }
      </Item>
      <Item container label={t('steps.advanced.title')}>
        <Item label={tForm('strategyType.label')} value={tForm(`strategyType.options.${preset.strategy.type}`)} />
        <Item label={tForm('slippage.use')} value={presetFields.useSlippage ? t('on') : t('off')} />
        {presetFields.useSlippage && <Item label={tForm('slippage.label')} value={presetFields.slippage} />}
        <Item label={tForm('usePips.label')} value={presetFields.usePips ? t('on') : t('off')} />
        <Item label={tForm('priceTolerance.label')} value={presetFields.usePriceTolerance ? `${presetFields.priceTolerance} ${presetFields.priceToleranceTypeOption?.label}` : t('off')} />
        {presetFields.usePriceTolerance && <Item label={tForm('expiryTime.label')} value={presetFields.expiryTime ? `${presetFields.expiryTime} ${presetFields.expiryTimeTypeOption?.label}` : t('off')} />}
        <Item label={tForm('postSignalValidation.label')} value={presetFields.useTpProximity ? t('on') : t('off')} />
        {
          presetFields.useTpProximity && (
            <Item label={tForm('tpProximity.label')} value={`${presetFields.tpProximity} ${presetFields.tpProximityTypeOption?.label}`} />
          )
        }
      </Item>
    </Row>
  );
};

export const ManualMoneyManagementDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const options = useCreateExpertFieldOptions();

  return (
    <Row>
      {/* Expert */}
      <Item container label={tForm('moneyManagementMode.shortLabel')}>
        <Item
          label={tForm('manualMoneyManagement.type.shortLabel')}
          value={options.manualMoneyManagementType.find((item) => item.value === expert.manualMoneyManagement.type)?.label}
        />

        {['0', '2'].includes(expert.manualMoneyManagement.type) && <Item label={tForm('manualMoneyManagement.lotsize.label')} value={expert.manualMoneyManagement.lotsize} />}
        {['1', '2', '3', '4'].includes(expert.manualMoneyManagement.type) && (
          <Item
            label={tForm('manualMoneyManagement.basedOn.shortText')}
            value={options.manualMoneyManagementBasedOn.find((item) => item.value === expert.manualMoneyManagement.basedOn)?.label}
          />
        )}
        {['1', '3'].includes(expert.manualMoneyManagement.type) && (
          <Item label={tForm('manualMoneyManagement.riskPercent.shortLabel')} value={expert.manualMoneyManagement.riskPercent} />
        )}
        {['2'].includes(expert.manualMoneyManagement.type) && <Item label={tForm('manualMoneyManagement.k.shortLabel')} value={expert.manualMoneyManagement.k} />}
      </Item>

      {/* Preset */}
      <Item container label={tForm('moneyManagementMode.shortLabel')}>
        <Item
          label={tForm('manualMoneyManagement.type.shortLabel')}
          value={options.manualMoneyManagementType.find((item) => item.value === preset.manualMoneyManagement.type)?.label}
        />

        {['0', '2'].includes(preset.manualMoneyManagement.type) && <Item label={tForm('manualMoneyManagement.lotsize.label')} value={preset.manualMoneyManagement.lotsize} />}
        {['1', '2', '3', '4'].includes(preset.manualMoneyManagement.type) && (
          <Item
            label={tForm('manualMoneyManagement.basedOn.shortText')}
            value={options.manualMoneyManagementBasedOn.find((item) => item.value === preset.manualMoneyManagement.basedOn)?.label}
          />
        )}
        {['1', '3'].includes(preset.manualMoneyManagement.type) && (
          <Item label={tForm('manualMoneyManagement.riskPercent.shortLabel')} value={preset.manualMoneyManagement.riskPercent} />
        )}
        {['2'].includes(preset.manualMoneyManagement.type) && <Item label={tForm('manualMoneyManagement.k.shortLabel')} value={preset.manualMoneyManagement.k} />}
      </Item>
    </Row>
  );
};

export const DrawdownDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  const { drawdownBalanceTypeOptions, drawdownLimitPeriodOptions } = useCreateExpertFieldOptions();

  const use = expert.maxDrawDownLimit.use;
  const basedOn = expert.maxDrawDownLimit.basedOn;
  const balanceTypeLabel = drawdownBalanceTypeOptions.find((option) => option.value === basedOn)?.label;
  const percent = expert.maxDrawDownLimit.percent;
  const period = expert.maxDrawDownLimit.period;
  const periodLabel = drawdownLimitPeriodOptions.find((option) => option.value === period)?.label;

  const presetsUse = preset.maxDrawDownLimit.use;
  const presetsBasedOn = preset.maxDrawDownLimit.basedOn;
  const presetsBalanceTypeLabel = drawdownBalanceTypeOptions.find((option) => option.value === presetsBasedOn)?.label;
  const presetsPercent = preset.maxDrawDownLimit.percent;
  const presetsPeriod = preset.maxDrawDownLimit.period;
  const presetsPeriodLabel = drawdownLimitPeriodOptions.find((option) => option.value === presetsPeriod)?.label;

  return (
    <Row>
      <Item container label={tForm('drawdown.use.label')} value={use ? tExperts('on') : tExperts('off')}>
        {use &&
          <>
            <Item label={tForm('drawdown.balanceType.label')} value={balanceTypeLabel} />
            <Item label={tForm('drawdown.percent.label')} value={`${percent}%`} />
            <Item label={tForm('drawdown.drawdownLimitPeriod.label')} value={periodLabel} />
          </>}
      </Item>
      <Item container label={tForm('drawdown.use.label')} value={presetsUse ? tExperts('on') : tExperts('off')}>
        {presetsUse &&
          <>
            <Item label={tForm('drawdown.balanceType.label')} value={presetsBalanceTypeLabel} />
            <Item label={tForm('drawdown.percent.label')} value={`${presetsPercent}%`} />
            <Item label={tForm('drawdown.drawdownLimitPeriod.label')} value={presetsPeriodLabel} />
          </>}
      </Item>
    </Row>
  );
};

export const SignalMoneyManagementDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const options = useCreateExpertFieldOptions();

  return (
    <Row>
      {/* Expert */}
      <Item container label={tForm('moneyManagementMode.shortLabel')}>
        <Item
          label={tForm('signalMoneyManagement.type.shortLabel')}
          value={options.signalMoneyManagementType.find((item) => item.value === expert.signalMoneyManagement.type)?.label}
        />
        {['0', '2'].includes(expert.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.lotsize.label')} value={expert.signalMoneyManagement.lotsize} />}
        {['1', '2', '3', '4'].includes(expert.signalMoneyManagement.type) && (
          <Item
            label={tForm('signalMoneyManagement.basedOn.shortText')}
            value={options.signalMoneyManagementBasedOn.find((item) => item.value === expert.signalMoneyManagement.basedOn)?.label}
          />
        )}
        {['1', '3'].includes(expert.signalMoneyManagement.type) && (
          <Item label={tForm('signalMoneyManagement.riskPercent.shortLabel')} value={expert.signalMoneyManagement.riskPercent} />
        )}
        {['2'].includes(expert.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.k.shortLabel')} value={expert.signalMoneyManagement.k} />}
        {['3', '4'].includes(expert.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.xPercent.sortLabel')} value={expert.signalMoneyManagement.xPercent} />}
      </Item>

      {/* Preset */}
      <Item container label={tForm('moneyManagementMode.shortLabel')}>
        <Item
          label={tForm('signalMoneyManagement.type.shortLabel')}
          value={options.signalMoneyManagementType.find((item) => item.value === preset.signalMoneyManagement.type)?.label}
        />
        {['0', '2'].includes(preset.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.lotsize.label')} value={preset.signalMoneyManagement.lotsize} />}
        {['1', '2', '3', '4'].includes(preset.signalMoneyManagement.type) && (
          <Item
            label={tForm('signalMoneyManagement.basedOn.shortText')}
            value={options.signalMoneyManagementBasedOn.find((item) => item.value === preset.signalMoneyManagement.basedOn)?.label}
          />
        )}
        {['1', '3'].includes(preset.signalMoneyManagement.type) && (
          <Item label={tForm('signalMoneyManagement.riskPercent.shortLabel')} value={preset.signalMoneyManagement.riskPercent} />
        )}
        {['2'].includes(preset.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.k.shortLabel')} value={preset.signalMoneyManagement.k} />}
        {['3', '4'].includes(preset.signalMoneyManagement.type) && <Item label={tForm('signalMoneyManagement.xPercent.sortLabel')} value={preset.signalMoneyManagement.xPercent} />}
      </Item>
    </Row>
  );
};

export const BreakEvenDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  const unitType = expert.breakEven.type === '1' ? '%' : (expert.unitType ? 'PIPS' : 'POINTS');
  const presetsUnitType = preset.breakEven.type === '1' ? '%' : (preset.usePips ? 'PIPS' : 'POINTS');

  const options = useCreateExpertFieldOptions(expert.unitType);

  const breakEvenTypeLabel = useMemo(() => options.breakEvenTypeOptions.find((item) => item.value === expert.breakEven.type)?.label, [expert.breakEven.type, options.breakEvenTypeOptions]);
  const presetsBreakEvenTypeLabel = useMemo(() => options.breakEvenTypeOptions.find((item) => item.value === preset.breakEven.type)?.label, [options.breakEvenTypeOptions, preset.breakEven.type]);

  return (
    <Row>
      <Item container label={tForm('breakEven.use.label')} value={expert.breakEven.use ? tExperts('on') : tExperts('off')}>
        {expert.breakEven.use &&
          <>
            <Item label={tForm('breakEven.levels.label')} value={breakEvenTypeLabel} />
            {expert.breakEven.levels.map((level, index) => (
              <Item label={`#${index + 1}`} key={index}>
                <Item label={tForm('breakEven.levels.afterX.label')} value={`${level.afterX} ${unitType}`} />
                <Item label={tForm('breakEven.levels.setToY.label')} value={`${level.setToY} ${unitType}`} />
              </Item>
            ))}
          </>}
      </Item>
      <Item container label={tForm('breakEven.use.label')} value={preset.breakEven.use ? tExperts('on') : tExperts('off')}>
        {preset.breakEven.use &&
          <>
            <Item label={tForm('breakEven.levels.label')} value={presetsBreakEvenTypeLabel} />
            {preset.breakEven.levels.map((level, index) => (
              <Item label={`#${index + 1}`} key={index}>
                <Item label={tForm('breakEven.levels.afterX.label')} value={`${level.afterX} ${presetsUnitType}`} />
                <Item label={tForm('breakEven.levels.setToY.label')} value={`${level.setToY} ${presetsUnitType}`} />
              </Item>
            ))}
          </>}
      </Item>
    </Row>
  );
};

export const PartialCloseDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  const use = expert.partialClose.use;
  const calculationType = expert.partialClose.calculationType;
  const takeprofits = expert.partialClose.takeprofits;
  const unitType = expert.unitType;
  const hideDistance = expert.partialClose?.calculationType === '1';

  const presetsUse = preset.partialClose.use;
  const presetsCalculationType = preset.partialClose.calculationType;
  const presetsTakeprofits = preset.partialClose.takeprofits;
  const presetsUnitType = preset.unitType;
  const presetsHideDistance = preset.partialClose?.calculationType === '1';

  return (
    <Row>
      <Item container label={tForm('partialClose.use.label')} value={use ? tExperts('on') : tExperts('off')}>
        {use &&
          <>
            <Item label={tForm('partialClose.calculationType.label')} value={tForm(`partialClose.calculationType.options.${calculationType}`)} />
            {takeprofits.map((level, index) => (
              <Item label={`#${index + 1}`} key={index}>
                {!hideDistance && <Item label={tForm('partialClose.levels.amount.label')} value={`${level.amount} ${unitType}`} />}
                <Item label={tForm('partialClose.levels.percent.label')} value={`${level.percent} %`} />
              </Item>
            ))}
          </>}
      </Item>
      <Item container label={tForm('partialClose.use.label')} value={presetsUse ? tExperts('on') : tExperts('off')}>
        {presetsUse &&
          <>
            <Item label={tForm('partialClose.calculationType.label')} value={tForm(`partialClose.calculationType.options.${presetsCalculationType}`)} />
            {presetsTakeprofits.map((level, index) => (
              <Item label={`#${index + 1}`} key={index}>
                {!presetsHideDistance && <Item label={tForm('partialClose.levels.amount.label')} value={`${level.amount} ${presetsUnitType}`} />}
                <Item label={tForm('partialClose.levels.percent.label')} value={`${level.percent} %`} />
              </Item>
            ))}
          </>}
      </Item>
    </Row>
  );
};

export const ManualSlTpDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const options = useCreateExpertFieldOptions();

  const isExpertTpSlHidden = useMemo(
    () => expert.strategy.type === 'signal' && ['2', '3'].includes(options.signalStopLossTakeProfit.find((item) => item.value === expert.signalSlTp.type)?.value || ''),
    [options.signalStopLossTakeProfit, expert.signalSlTp.type, expert.strategy.type],
  );

  const isPresetTpSlHidden = useMemo(
    () => preset.strategy.type === 'signal' && ['2', '3'].includes(options.signalStopLossTakeProfit.find((item) => item.value === preset.signalSlTp.type)?.value || ''),
    [options.signalStopLossTakeProfit, preset.signalSlTp.type, preset.strategy.type],
  );

  return (
    <Row>
      {/* Expert */}
      <Item container label={tForm('manualStopLossTakeProfit.type.shortLabel')}>
        {!isExpertTpSlHidden && (
          <Fragment>
            <Item label={tForm('manualStopLossTakeProfit.type.label')} value={options.manualStopLossTakeProfitType.find((item) => item.value === expert.manualSlTp.type)?.label} />
            <Item label={tForm('manualStopLossTakeProfit.fixedSl.label')} value={expert.manualSlTp.fixedSl} />
            {expert.manualSlTp.type === '1' ? (
              <Item label={tForm('manualStopLossTakeProfit.tpRatio.label')} value={expert.manualSlTp.tpRatio} />
            ) : (
              <Item label={tForm('manualStopLossTakeProfit.fixedTp.label')} value={expert.manualSlTp.fixedTp} />
            )}
          </Fragment>
        )}
      </Item>
      {/* Preset */}
      <Item container label={tForm('manualStopLossTakeProfit.type.shortLabel')}>
        {!isPresetTpSlHidden && (
          <Fragment>
            <Item label={tForm('manualStopLossTakeProfit.type.label')} value={options.manualStopLossTakeProfitType.find((item) => item.value === preset.manualSlTp.type)?.label} />
            <Item label={tForm('manualStopLossTakeProfit.fixedSl.label')} value={preset.manualSlTp.fixedSl} />
            {preset.manualSlTp.type === '1' ? (
              <Item label={tForm('manualStopLossTakeProfit.tpRatio.label')} value={preset.manualSlTp.tpRatio} />
            ) : (
              <Item label={tForm('manualStopLossTakeProfit.fixedTp.label')} value={preset.manualSlTp.fixedTp} />
            )}
          </Fragment>
        )}
      </Item>
    </Row>
  );
};

export const SignalSlTpDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const options = useCreateExpertFieldOptions();

  const type = expert.signalSlTp.type;
  const profitCalculation = options.smartEntryProfitCalculationType.find((option) => option.value === expert.signalSlTp.profitCalculationType);
  const slType = options.slTypeOptions.find((option) => option.value === expert.signalSlTp.slType);
  const rrRatio = expert.signalSlTp.tpRatio ? (expert.signalSlTp.riskRatio || 1) + ':' + expert.signalSlTp.tpRatio : '';
  const tpRatio = expert.signalSlTp.tpRatio;
  const fixedSl = expert.signalSlTp.fixedSl;
  const fixedTp = expert.signalSlTp.fixedTp;

  const presetsType = preset.signalSlTp.type;
  const presetsProfitCalculation = options.smartEntryProfitCalculationType.find((option) => option.value === preset.signalSlTp.profitCalculationType);
  const presetsSlType = options.slTypeOptions.find((option) => option.value === preset.signalSlTp.slType);
  const presetsRRRatio = preset.signalSlTp.tpRatio ? (preset.signalSlTp.riskRatio || 1) + ':' + preset.signalSlTp.tpRatio : '';
  const presetsTpRatio = preset.signalSlTp.tpRatio;
  const presetsFixedSl = preset.signalSlTp.fixedSl;
  const presetsFixedTp = preset.signalSlTp.fixedTp;

  return (
    <Row>
      <Item container label={tForm('signalStopLossTakeProfit.type.shortLabel')}>
        <Item label={tForm('signalStopLossTakeProfit.type.label')} value={options.signalStopLossTakeProfit.find((item) => item.value === type)?.label} />
        {['0', '1'].includes(type) && (
          <Item label={tForm('signalStopLossTakeProfit.fixedSl.label')} value={`${fixedSl} ${expert.unitType}`} />
        )}
        {['0'].includes(type) && (
          <Item label={tForm('signalStopLossTakeProfit.fixedTp.label')} value={`${fixedTp} ${expert.unitType}`} />
        )}
        {['1'].includes(type) && (
          <Item label={tForm('signalStopLossTakeProfit.tpRatio.label')} value={tpRatio} />
        )}
        {['4'].includes(type) && (
          <>
            <Item label={tForm('signalStopLossTakeProfit.profitCalculationType.label')} value={profitCalculation?.label || ''} />
            <Item label={tForm('signalStopLossTakeProfit.slType.label')} value={slType?.label || ''} />
            {['0'].includes(profitCalculation?.value || '') && (
              <Item label={tForm('signalStopLossTakeProfit.rrRatio.label')} value={rrRatio || ''} />
            )}
            {['1'].includes(profitCalculation?.value || '') && (
              <Item label={tForm('signalStopLossTakeProfit.smartFixedTp.label')} value={`${fixedTp || ''} ${expert.unitType}`} />
            )}
          </>
        )}
      </Item>
      <Item container label={tForm('signalStopLossTakeProfit.type.shortLabel')}>
        <Item label={tForm('signalStopLossTakeProfit.type.label')} value={options.signalStopLossTakeProfit.find((item) => item.value === presetsType)?.label} />
        {['0', '1'].includes(presetsType) && (
          <Item label={tForm('signalStopLossTakeProfit.fixedSl.label')} value={`${presetsFixedSl} ${preset.unitType}`} />
        )}
        {['0'].includes(presetsType) && (
          <Item label={tForm('signalStopLossTakeProfit.fixedTp.label')} value={`${presetsFixedTp} ${preset.unitType}`} />
        )}
        {['1'].includes(presetsType) && (
          <Item label={tForm('signalStopLossTakeProfit.tpRatio.label')} value={presetsTpRatio} />
        )}
        {['4'].includes(presetsType) && (
          <>
            <Item label={tForm('signalStopLossTakeProfit.profitCalculationType.label')} value={presetsProfitCalculation?.label || ''} />
            <Item label={tForm('signalStopLossTakeProfit.slType.label')} value={presetsSlType?.label || ''} />
            {['0'].includes(presetsProfitCalculation?.value || '') && (
              <Item label={tForm('signalStopLossTakeProfit.rrRatio.label')} value={presetsRRRatio || ''} />
            )}
            {['1'].includes(presetsProfitCalculation?.value || '') && (
              <Item label={tForm('signalStopLossTakeProfit.smartFixedTp.label')} value={`${presetsFixedTp || ''} ${preset.unitType}`} />
            )}
          </>
        )}
      </Item>
    </Row>
  );
};

export const TrailStopDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  return (
    <Row>
      <Item container label={tForm('trailingStop.use.label')} value={expert.trailStop.use ? tExperts('on') : tExperts('off')}>
        {expert.trailStop.use && (
          <>
            <Item label={tForm('trailingStop.fromX.label')} value={expert.trailStop.afterX} />
            <Item label={tForm('trailingStop.toY.label')} value={expert.trailStop.followY} />
          </>
        )}
      </Item>
      <Item container label={tForm('trailingStop.use.label')} value={preset.trailStop.use ? tExperts('on') : tExperts('off')}>
        {preset.trailStop.use && (
          <>
            <Item label={tForm('trailingStop.fromX.label')} value={preset.trailStop.afterX} />
            <Item label={tForm('trailingStop.toY.label')} value={preset.trailStop.followY} />
          </>
        )}
      </Item>
    </Row>
  );
};

export const SessionManagementDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');
  const options = useCreateExpertFieldOptions();

  const use = expert.sessionManagement.use;
  const type = expert.sessionManagement.type;
  const typeLabel = options.sessionManagementTypeOptions.find((item) => item.value === type)?.label;
  const sessions = expert.sessionManagement.sessions;
  const sessionsLabels = sessions?.length !== options.sessionManagementSessionOptions.length - 1 ? options.sessionManagementSessionOptions.filter((item) => sessions?.includes(item.value))?.map(item => item.label) : [options?.sessionManagementSessionOptions[0].label];
  const threshold = expert.sessionManagement?.threshold || '-';
  const hasThreshold = expert.sessionManagement.type === FxSessionManagementActionType.CLOSE_IF_IN_PROFIT;

  const presetsUse = preset.sessionManagement.use;
  const presetsType = preset.sessionManagement.type;
  const presetsTypeLabel = options.sessionManagementTypeOptions.find((item) => item.value === presetsType)?.label;
  const presetsSessions = preset.sessionManagement.sessions;
  const presetsSessionsLabels = presetsSessions?.length !== options.sessionManagementSessionOptions.length - 1 ? options.sessionManagementSessionOptions.filter((item) => presetsSessions?.includes(item.value))?.map(item => item.label) : [options?.sessionManagementSessionOptions[0].label];
  const presetsThreshold = preset.sessionManagement?.threshold || '-';
  const presetsHasThreshold = preset.sessionManagement.type === FxSessionManagementActionType.CLOSE_IF_IN_PROFIT;

  return (
    <Row>
      <Item container label={tForm('sessionManagement.use.label')} value={use ? tExperts('on') : tExperts('off')}>
        {use &&
          <>
            <Item label={tForm('sessionManagement.sessions.label')} value={sessionsLabels.join(', ')} />
            <Item label={tForm('sessionManagement.type.shortLabel')} value={typeLabel} />
            {hasThreshold && (
              <Item label={tForm('sessionManagement.threshold.label')} value={threshold} />
            )}
          </>}
      </Item>
      <Item container label={tForm('sessionManagement.use.label')} value={presetsUse ? tExperts('on') : tExperts('off')}>
        {presetsUse &&
          <>
            <Item label={tForm('sessionManagement.sessions.label')} value={presetsSessionsLabels.join(', ')} />
            <Item label={tForm('sessionManagement.type.shortLabel')} value={presetsTypeLabel} />
            {presetsHasThreshold && (
              <Item label={tForm('sessionManagement.threshold.label')} value={presetsThreshold} />
            )}
          </>}
      </Item>
    </Row>
  );
};

export const ConditionalClosureDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  const use = expert.conditionalClosure.use;
  const amount = expert.conditionalClosure.amount;

  const presetsUse = preset.conditionalClosure.use;
  const presetsAmount = preset.conditionalClosure.amount;

  return (
    <Row>
      <Item container label={tForm('conditionalClosure.use.label')} value={use ? tExperts('on') : tExperts('off')}>
        <Item label={tForm('conditionalClosure.use.shortLabel')} value={use ? tForm('conditionalClosure.use.options.selected.label', {point: expert.unitType}) : tForm('conditionalClosure.use.options.notSelected.label')} />
        {use && (
          <Item label={tForm('conditionalClosure.amount.label')} value={`${amount} ${expert.unitType}`} />
        )}
      </Item>
      <Item container label={tForm('conditionalClosure.use.label')} value={presetsUse ? tExperts('on') : tExperts('off')}>
        <Item label={tForm('conditionalClosure.use.shortLabel')} value={presetsUse ? tForm('conditionalClosure.use.options.selected.label', {point: preset.unitType}) : tForm('conditionalClosure.use.options.notSelected.label')} />
        {presetsUse && (
          <Item label={tForm('conditionalClosure.amount.label')} value={`${presetsAmount} ${preset.unitType}`} />
        )}
      </Item>
    </Row>
  );
};

export const OrderTypeDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });

  const type = expert.orderType;

  const presetsType = preset.orderType;

  return (
    <Row>
      <Item container label={tForm('orderType.label')} value={tForm(`orderType.options.${type.toLowerCase()}`)} />
      <Item container label={tForm('orderType.label')} value={tForm(`orderType.options.${presetsType.toLowerCase()}`)} />
    </Row>
  );
};

export const OrderDirectionDiff = ({ expert, preset }) => {
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });

  const direction = expert.orderDirection;

  const presetsDirection = preset.orderDirection;

  return (
    <Row>
      <Item container label={tForm('orderDirection.label')} value={tForm(`orderDirection.options.${direction.toLowerCase()}`)} />
      <Item container label={tForm('orderDirection.label')} value={tForm(`orderDirection.options.${presetsDirection.toLowerCase()}`)} />
    </Row>
  );
};
