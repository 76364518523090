import { queryClient } from '@root/infra/query';
import { SocketEventPayloads } from '@root/infra/socket/event-payloads';
import { IAccount } from '@root/modules/accounts';

export function updateAccountStatus(payload: SocketEventPayloads.AccountStatusChanged) {
  const { accountId, status } = payload;
  queryClient.setQueryData<IAccount[] | void>(['accounts'], (oldData) => {
    if (!oldData) {
      return oldData;
    }
    return oldData.map((account) => {
      if (account.id === accountId) {
        return {
          ...account,
          status,
          isPending: false,
          shouldConnect: false,
        };
      }
      return account;
    });
  });
}
