import { put, takeLatest } from 'redux-saga/effects';
import { queryClient } from '@root/infra/query';
import { authSlice } from '@root/shared-files/modules/auth/store/auth.slice';
import { marketplaceSlice } from './marketplace.slice';
import { PROVIDER_TO_BE_FETCHED } from '@root/shared-files/modules/shared/constants/local-storage-keys';

const { refetchTIsAfterRefresh } = authSlice.actions;

function* refetchTIQueries() {
  const providerId = localStorage.getItem(PROVIDER_TO_BE_FETCHED);
  if (providerId) {
    queryClient.refetchQueries(['marketplace-trade-idea-provider', providerId], { exact: false });
    localStorage.removeItem(PROVIDER_TO_BE_FETCHED);
  }
  queryClient.refetchQueries(['marketplace-trade-idea-list'], { exact: false });
  queryClient.refetchQueries(['marketplace-subscribed-providers'], { exact: false });

  yield put(marketplaceSlice.actions.setUpdatedAfterSubscription(true));
}

export function* marketplaceSaga() {
  yield takeLatest([refetchTIsAfterRefresh], refetchTIQueries);
}
