import qs from 'querystring';

import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { Period } from '@root/modules/orders/enums/orders';
import { ChartItem } from '@root/modules/orders/types/chart';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

export type GetUsersPerformanceChartDataServiceResponse = IHttpResponse<200, ChartItem[]> | IHttpResponse<400, string>;
export type GetUsersPerformanceChartDataServiceQuery = { userId: Id; accounts: Id; period: Period; experts?: string };

export const getUsersPerformanceChartData = async ({
  accounts,
  period,
  experts,
}: GetUsersPerformanceChartDataServiceQuery): Promise<GetUsersPerformanceChartDataServiceResponse> => {
  try {
    const stringified = qs.stringify({
      period,
      accounts: accounts === 'all' || !accounts ? undefined : accounts,
      experts,
    });

    const response = await fetcher.get(`/users/orders/closed/performance-chart?${stringified}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data.data.filter((item) => item.profit !== 0),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
