import { LiveChatWidget } from '@livechat/widget-react';

import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store/auth.selector';

import { useGetCurrentSubscription } from '../layout/hooks/use-get-current-subscription';

const LIVECHAT_LICENSE = import.meta.env.VITE_LIVE_CHAT_LICENSE;

export const SageLiveChat = () => {
  const user = useSelector(authSelector.user);
  const authed = useSelector(authSelector.isAuthed);

  const { currentSubscription } = useGetCurrentSubscription();

  if (authed && user) {
    return (
      <LiveChatWidget
        customerName={user.fullName}
        customerEmail={user.email}
        license={LIVECHAT_LICENSE}
        visibility='minimized'
        sessionVariables={{
          ['Login']: user.email,
          ['Email Address']: user.email,
          ['Subscription']: currentSubscription?.name || '-',
          ['Account_ID']: (user.id as string) || '-',
        }}
      />
    );
  }

  return null;
};
