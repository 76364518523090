import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { ModifyOrderDto } from '@root/modules/orders/dtos/modify-order.dto';
import { OrderType } from '@root/modules/orders/enums/orders';
import { useModifyOrder } from '@root/modules/orders/hooks/use-modify-order';
import { IOrder } from '@root/modules/orders/types/orders';
import { isBuyOrder, isSellOrder } from '@root/modules/orders/utils/orders';
import { DateField, TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';
import { Title } from '@root/shared/ui/typography/title';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export type ModifyModalProps = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
};

const FormComponent: FC<FormikProps<ModifyOrderDto> & ModifyModalProps> = ({ isOpen, handleSubmit, closeModal, order, values, isValid }) => {
  const { t } = useTranslation('forex-orders');

  const { hideActions } = useGhostLogin();

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeModal}
      footer={null}
      title={
        <div className='flex w-full gap-x-6 mt-2 px-2'>
          <Title level={6} className='font-bold text-center'>
            {order.symbol}
          </Title>
          <Text
            size='sm'
            className={clsx('flex justify-center items-center bg-gray-700 py-0.5 px-3 rounded-full font-medium', {
              'text-danger-500': isSellOrder(order.type),
              'text-success-400': isBuyOrder(order.type),
            })}
          >
            {order.type}
          </Text>
        </div>
      }
    >
      <form className='flex flex-col -mt-4 w-full lg:px-2' onSubmit={handleSubmit}>
        <Text size='sm'>{t('CurrentValues')}</Text>

        <div className='flex justify-between px-4 py-2 w-full rounded bg-gray-900 mt-1'>
          <div className='flex justify-between pr-4 sm:pr-10 w-full'>
            <Text className='text-grayscale-400'>{t('fields.sl.label')}</Text>
            <Text>{order.stopLoss}</Text>
          </div>
          <div className='w-px h-auto bg-color-divider' />
          <div className='flex justify-between w-full pl-4 sm:pl-10'>
            <Text className='text-grayscale-400'>{t('fields.tp.label')}</Text>
            <Text>{order.takeProfit}</Text>
          </div>
        </div>

        <div className='flex flex-col gap-4 w-full mt-10'>
          <Text>{t('updateTpSl')}</Text>
          <TextField
            name='takeprofit'
            label={t('fields.tp.label')}
            placeholder={t('fields.tp.placeholder')}
          />

          <TextField
            name='stoploss'
            label={t('fields.sl.label')}
            placeholder={t('fields.sl.placeholder')}
          />

          {values.type === OrderType.PENDING && (
            <>
              <TextField
                name='price'
                label={t('manualOrders.pending.fields.price.label', { ns: 'forex-experts' })}
                placeholder={t('manualOrders.pending.fields.price.placeholder', { ns: 'forex-experts' })}
              />

              <DateField
                fullWidth
                name='expirationTime'
                label={t('manualOrders.pending.fields.expirationTime.label', { ns: 'forex-experts' })}
                placeholder={t('manualOrders.pending.fields.expirationTime.placeholder', { ns: 'forex-experts' })}
              />
            </>
          )}
        </div>

        <div className='w-full flex justify-between mt-8'>
          <Button type='button' onClick={closeModal} outlined>
            {t('Close', { ns: 'forex-common' })}
          </Button>
          <Button type='submit' variant='primary' disabled={!isValid || hideActions}>
            {t('saveChanges', { ns: 'settings' })}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const ModifyOrderModal: FC<ModifyModalProps> = memo(
  (props) => {
    const { initialValues, onSubmit, validationSchema } = useModifyOrder(props);

    return (
      <Formik<ModifyOrderDto>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}
        enableReinitialize
        component={(formProps) => <FormComponent {...formProps} {...props} />}
      />
    );
  },
  (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen && prevProps.order.ticket === nextProps.order.ticket,
);

ModifyOrderModal.displayName = 'ModifyOrderModal';
