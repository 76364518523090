import clsx from 'clsx';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import warning from '@root/assets/images/cases/warning.webp';
import { queryClient } from '@root/infra/query';
import { useConnectDisconnectAccount } from '@root/modules/accounts/hooks/use-connect-disconnect-account';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useCheckRunningExpert } from '@root/modules/experts/hooks/use-check-running-expert';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { Button } from '@root/shared/ui/button';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { AmountWithCurrency } from '@root/shared/ui/money';
import { Text, Title } from '@root/shared/ui/typography';
import { notify } from '@root/shared/utils/notification';

import { DELETE_ACCOUNT_MUTATION, DeleteAccountMutationData, DeleteAccountMutationError, DeleteAccountMutationValues } from '../mutations/delete-account.mutation';
import { accountsSlice } from '../store/accounts.slice';
import { IAccount } from '../types/account';
import { AccountStatus } from '../enums';
import { globalRound } from '@root/shared/utils/number/round';
import { useProxyServerOptions } from '../hooks/use-proxy-server-options';
import { useNavigate } from 'react-router';
import { ResetIcon } from '@root/shared/icons/reset-icon';
import { useResetAccount } from '../hooks/use-reset-account';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { RefreshIcon } from '@root/shared/icons/refresh-icon';
import { resetDrawdownLimit } from '../services/reset-drawdown-limit.service';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { TrashIcon } from '@root/shared/icons/trash-icon';
import { EditIcon } from '@root/shared/icons/edit-icon';
import { WarningCircleIcon } from '@root/shared/icons/warning-circle-icon';

export type AccountCardProps = {
  data: IAccount;
  wrapperClassName?: string;
  isPreviewCard?: boolean;
};

export const AccountCard: FC<AccountCardProps> = ({ data, wrapperClassName, isPreviewCard }) => {
  const { t } = useTranslation('forex-accounts');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(authSelector.userId);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [disconnectDialogIsOpen, setDisconnectDialogIsOpen] = useState(false);
  const { data: balances } = useSelector(accountsSelector.balance);
  const { getRunningExpertsByAccountId } = useCheckRunningExpert();

  const { getProxyServer } = useProxyServerOptions();

  const proxyServer = useMemo(() => getProxyServer(data.proxyServerId), [data.proxyServerId, getProxyServer]);

  const { disconnectAccountAsync, connectAccountAsync } = useConnectDisconnectAccount();
  const { drawdownBalanceTypeOptions, drawdownLimitPeriodOptions } = useCreateExpertFieldOptions();
  const serviceAccountName = data?.serviceAccounts?.find((item) => item.id === data?.serviceAccountId)?.name;
  const balance = balances?.[data.id]?.balance || 0;

  const mutationOptions = {
    onError: (error: Error) => {
      notify({
        type: 'danger',
        title: error.message,
      });
    },
  };

  const deleteMutation = useMutation<DeleteAccountMutationData, DeleteAccountMutationError, DeleteAccountMutationValues>(DELETE_ACCOUNT_MUTATION, mutationOptions);

  const isLoading = data.isPending;
  const disconnectOnPending = isLoading && data?.shouldConnect && data?.status === AccountStatus.Disconnected;
  const expertsCounter = getRunningExpertsByAccountId(data?.id)?.length || 0;

  const isAccountWithDeletedBroker = !data?.server && !data?.company;

  const ddEnabled = !!data?.maxDrawDownLimit;
  const drawdownBalanceType = data?.maxDrawDownLimit?.basedOn;
  const drawdownLimitPeriod = data?.maxDrawDownLimit?.period;
  const balanceTypeLabel = drawdownBalanceTypeOptions.find((item) => item.value === drawdownBalanceType?.toString())?.label;
  const limitPeriodLabel = drawdownLimitPeriodOptions.find((item) => item.value === drawdownLimitPeriod?.toString())?.label;
  const limitPercent = data?.maxDrawDownLimit?.percent || 0;

  const hasDDLimit = data?.hitMaxDrawDownLimitUntil && new Date(data?.hitMaxDrawDownLimitUntil).getTime() > new Date().getTime();

  const handleSignOut = async () => {
    if (expertsCounter) {
      setDisconnectDialogIsOpen(true);
    } else {
      await disconnectAccountAsync({ accountId: data.id, showErrorMessage: true });
    }
  };

  const stopRunningExpertsAndSignOut = async () => {
    await disconnectAccountAsync({ accountId: data.id, showErrorMessage: true });
    setDisconnectDialogIsOpen(false);
  };

  const { hideActions } = useGhostLogin();

  const { setResetAccount } = useResetAccount();

  const handleDDReset = useCallback(async () => {
    setResetLoading(true);
    const response = await resetDrawdownLimit(data.id);

    if (response.status === 200) {
      queryClient.invalidateQueries(['accounts'], { exact: false });
      notify({
        type: 'success',
        title: t('ddResetSuccessfully'),
      });
    } else {
      notify({
        type: 'danger',
        title: response.payload,
      });
    }

    setResetLoading(false);
  }, [data.id]);

  return (
    <Fragment>
      <div className={clsx('bg-gray-800 rounded relative', wrapperClassName)}>
        {isLoading && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800/50'>
            <LoadingIcon width={30} height={30} />
          </div>
        )}
        <div className='p-5 py-3'>
          <div className='flex justify-between '>
            <div className={clsx('flex items-center flex-shrink-0 gap-2', { 'text-grayscale-600': data.isSignedIn || isPreviewCard })}>
              <button
                disabled={data.isSignedIn || hideActions || isPreviewCard}
                onClick={() => dispatch(accountsSlice.actions.accountEditOpened(data.id))}
                className={clsx('text-base p-1', { 'hover:opacity-80 transition': !data.isSignedIn })}
              >
                <EditIcon />
              </button>
              <button
                disabled={data.isSignedIn || hideActions || isPreviewCard}
                onClick={() => setDeleteDialogIsOpen(true)}
                className={clsx('text-base p-1', { 'hover:opacity-80 transition': !data.isSignedIn })}
              >
                <TrashIcon />
              </button>

              {data.isInvalid && (
                <InfoTooltip
                  content={
                    <Text className='w-full text-center' size='sm'>
                      {t('item.messages.inactive')}
                    </Text>
                  }
                  positions={['bottom', 'right', 'top', 'left']}
                >
                  <button className={clsx('text-base p-1 rounded flex flex-col justify-center items-center hover:opacity-80 transition')}>
                    <WarningCircleIcon />
                  </button>
                </InfoTooltip>
              )}
              <InfoTooltip
                content={
                  <Text className='w-full text-center' size='sm'>
                    {t('item.resetAccountHint')}
                  </Text>
                }
                positions={['bottom', 'right', 'top', 'left']}
              >
                <button
                  onClick={() => setResetAccount(data?.id)}
                  className='text-base p-1 text-gray-100 hover:text-primary-400'
                >
                  <ResetIcon width={16} height={16} />
                </button>
              </InfoTooltip>
            </div>

            <div className='flex items-center min-h-[32px] min-w-[85px]'>
              <Text size='lg' className='font-semibold'>
                {!!balance && data.isSignedIn && <AmountWithCurrency currency={data.currency} value={globalRound(balance || 0, 2)} />}
              </Text>
              {/*<Text className={clsx('flex items-center font-bold ml-2', { 'text-success-400': valuePercent > 0, 'text-danger-500': valuePercent < 0 })}>*/}
              {/*  <span className='text-base'>{globalRound(valuePercent, 2)}%</span>*/}
              {/*  {valuePercent && (*/}
              {/*    <span className='ml-1'>*/}
              {/*      <ArrowIcon className={clsx({ 'transform rotate-180': valuePercent < 0 })} />*/}
              {/*    </span>*/}
              {/*  )}*/}
              {/*</Text>*/}
            </div>
          </div>

          <div className='flex justify-between items-center gap-x-2 mt-3.5'>
            <div className='flex items-center'>
              <InfoTooltip
                content={
                  <Text className='font-medium' size='sm'>
                    {t(data.isSignedIn ? 'create.signed' : 'create.notSigned')}
                  </Text>
                }
                positions={['bottom', 'right']}
              >
                <div
                  className={clsx('mr-2 h-2 w-2 rounded-full', {
                    'bg-secondary-200': !data.isSignedIn,
                    'bg-success-400': data.isSignedIn,
                  })}
                />
              </InfoTooltip>

              <Text size='lg' className='font-semibold leading-8'>
                {data.username}
              </Text>
            </div>
            {!isPreviewCard && (
              <div className='flex justify-center'>
                {!data.isSignedIn && !disconnectOnPending ? (
                  isAccountWithDeletedBroker ? <Button
                    onClick={() => navigate('/accounts/create')}
                    variant='primary'
                    className='!px-3 !py-1'
                    outlined
                  >
                    {t('item.actions.connectNew')}
                  </Button> : <Button
                    onClick={() =>
                      connectAccountAsync({
                        accountId: data.id,
                        showErrorMessage: true,
                      })
                    }
                    className='!px-3 !py-1'
                    disabled={hideActions}
                    variant='primary'
                    outlined
                  >
                    {t('item.actions.connect')}
                  </Button>
                ) : (
                  <Button
                    className={clsx('!px-3 !py-1', { 'relative z-10': disconnectOnPending })}
                    disabled={hideActions}
                    onClick={handleSignOut}
                    variant='secondary'
                    tint='200'
                    outlined
                  >
                    <div className='children bg-gray-800'>{t('item.actions.disconnect')}</div>
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className='h-px bg-color-divider mt-1.5 mb-2'></div>

          <div>
            <Text className='text-grayscale-400' size='sm'>
              {t('item.company')}: <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{data.company} {data.mtType}</strong>
            </Text>
            <Text className='text-grayscale-400' size='sm'>
              {t('item.server')}: <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{data.server}</strong>
            </Text>
            <Text className='text-grayscale-400' size='sm'>
              {t('item.proxyServer')}: <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{proxyServer?.label || '-'}</strong>
            </Text>
            {serviceAccountName && !isPreviewCard && (
              <Text className='text-grayscale-400' size='sm'>
                {t('update.brokerType')}: <strong className='ml-2.5 font-semibold text-sm text-grayscale-100 uppercase'>{serviceAccountName}</strong>
              </Text>
            )}
            <Text className='text-grayscale-400' size='sm'>
              {t('item.drawdown')}: {ddEnabled ? <>
                <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{balanceTypeLabel}</strong>
                <strong className='ml-2.5 font-semibold text-sm text-grayscale-600'>|</strong>
                <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{limitPercent}%</strong>
                <strong className='ml-2.5 font-semibold text-sm text-grayscale-600'>|</strong>
                <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>{limitPeriodLabel}</strong>
                <InfoTooltip content={<Text size='sm'>
                  <Trans i18nKey='forex-accounts:item.resetDDHint' components={{ span: <span className='font-bold text-gray-100' /> }} />
                </Text>} inline wrapperClassName='ml-2.5' positions={['bottom', 'right', 'top', 'left']}>
                  <RefreshIcon width={16} height={16} className={clsx('text-grayscale-100 hover:text-primary-400 inline-block cursor-pointer mb-0.5', { 'animate-spin': resetLoading })} onClick={handleDDReset} />
                </InfoTooltip>
                {
                  hasDDLimit && <InfoTooltip inline content={<Text size='sm'>{t('item.ddLimitHint')}</Text>} positions={['bottom', 'right', 'top', 'left']}>
                    <InfoIcon className='text-secondary-200 inline-block ml-2.5 mb-0.5' width={16} height={16} />
                  </InfoTooltip>
                }
              </> : <strong className='ml-2.5 font-semibold text-sm text-grayscale-100'>-</strong>}
            </Text>
          </div>

          {isAccountWithDeletedBroker && (
            <div className='mt-3'>
              <Text className='text-secondary-200' size='sm'>
                * {t('item.messages.deletedBrokerWarning')}
              </Text>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={deleteDialogIsOpen}
        onCancel={() => setDeleteDialogIsOpen(false)}
        footer={
          <div className='w-full flex justify-between items-center pt-6'>
            <Button outlined onClick={() => setDeleteDialogIsOpen(false)}>
              {t('remove.confirmation.actions.cancel')}
            </Button>
            <Button
              onClick={() =>
                deleteMutation.mutate(
                  { accountId: data.id, userId: userId || '' },
                  {
                    onError: mutationOptions.onError,
                    onSuccess: () => {
                      queryClient.invalidateQueries(['accounts'], { exact: false });
                      queryClient.invalidateQueries(['experts'], { exact: false });
                    },
                  },
                )
              }
              loading={deleteMutation.isLoading}
              disabled={hideActions}
            >
              {t('remove.confirmation.actions.submit')}
            </Button>
          </div>
        }
      >
        <div className='text-center max-w-[388px] mx-auto py-4'>
          <img src={warning} alt='Confirmation icon' className='w-[61px] mx-auto' />
          <Title level={6} className='mb-2' bold>
            {t('remove.confirmation.title')}
          </Title>
        </div>
      </Modal>

      <Modal
        isOpen={disconnectDialogIsOpen}
        onCancel={() => setDisconnectDialogIsOpen(false)}
        footer={
          <div className='w-full flex justify-between items-center pt-6'>
            <Button outlined onClick={() => setDeleteDialogIsOpen(false)}>
              {t('signOut.confirmation.actions.cancel')}
            </Button>
            <Button onClick={stopRunningExpertsAndSignOut} disabled={hideActions}>
              {t('signOut.confirmation.actions.submit')}
            </Button>
          </div>
        }
      >
        <div className='text-center max-w-[388px] mx-auto py-4'>
          <img src={warning} alt='Confirmation icon' className='w-[61px] mx-auto' />
          <Title level={6} className='mb-2' bold>
            {t(expertsCounter > 1 ? 'signOut.confirmation.titlePlural' : 'signOut.confirmation.title')}
          </Title>
          <Text>{t('signOut.confirmation.description')}</Text>
        </div>
      </Modal>
    </Fragment>
  );
};
