import getSymbolFromCurrency from 'currency-symbol-map';
import { FC } from 'react';

import { formatIfNegativeAmount } from '@root/shared/utils/string';

export const AmountWithCurrency: FC<{ currency?: string; value: number | string }> = ({ currency, value }) => {
  const currencySymbol = getSymbolFromCurrency(currency as string);
  const formattedPrice = formatIfNegativeAmount(Number(value), currencySymbol, currency === currencySymbol);
  return <span>{formattedPrice}</span>;
};
