import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@root/shared/ui/typography';
import { EditableSettingsFormWrapper } from './settings/editable-settings-form-wrapper';
import { AccountAndPairs } from './settings/account-and-pairs';
import { Trigger } from './settings/trigger';
import { AdvancedSettings } from './settings/advanced-settings';
import { MoneyManagement } from './settings/money-management';
import { BreakEven } from './settings/breakeven';
import { PartialTakeProfits } from './settings/partial-take-profits';
import { StopLossAndTakeProfit } from './settings/stop-loss-and-take-profit';
import { IExpert } from '@root/modules/experts/types/expert';
import { SyncPreset } from './sync-preset/sync-preset';
import { PartialEditContext } from '@root/modules/experts/context/partial-edit.context';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import {
    OrderOptionsSettings
} from '@root/modules/experts/components/details/settings-and-stats/settings/order-options-settings';

type Props = {
    expert: IExpert;
}

const initialEditingSteps = {
    [CreateExpertStepKey.Account]: false,
    [CreateExpertStepKey.AdvancedSettings]: false,
    [CreateExpertStepKey.BreakEven]: false,
    [CreateExpertStepKey.MoneyManagement]: false,
    [CreateExpertStepKey.PartialTakeProfits]: false,
    [CreateExpertStepKey.StopLossTakeProfit]: false,
    [CreateExpertStepKey.Trigger]: false,
    [CreateExpertStepKey.Preview]: false,
    [CreateExpertStepKey.OrderOptions]: false,
}

export const Settings: FC<Props> = ({ expert }) => {
    const [editingSteps, setEditingSteps] = useState(initialEditingSteps);
    const { t } = useTranslation('forex-experts');

    return <PartialEditContext.Provider value={{ editingSteps, setEditingSteps }}>
        <EditableSettingsFormWrapper id={expert.id}>
            <div className="p-5 w-full rounded bg-gray-800 flex flex-col gap-y-2.5">
                <div className="w-full flex justify-between items-start">
                    <Text size='base' className='font-semibold'>{t('settings')}</Text>
                    <SyncPreset expert={expert} />
                </div>
                <AccountAndPairs expert={expert} />
                <OrderOptionsSettings expert={expert} />
                <Trigger expert={expert} />
                <MoneyManagement expert={expert} />
                <StopLossAndTakeProfit expert={expert} />
                <PartialTakeProfits expert={expert} />
                <BreakEven expert={expert} />
                <AdvancedSettings expert={expert} />
            </div>
        </EditableSettingsFormWrapper>
    </PartialEditContext.Provider>;
}

