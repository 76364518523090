import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetOrdersHistoryCountServiceResponse =
  | IHttpResponse<
      200,
      {
        experts: Record<string, string>;
      }
    >
  | IHttpResponse<400, string>;

export const getOrderHistoryCount = async (): Promise<GetOrdersHistoryCountServiceResponse> => {
  try {
    const response = await fetcher(`/users/orders/closed/count`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
