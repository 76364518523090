import dayjs from 'dayjs';
import { groupBy } from 'lodash';

import { useEffect, useMemo, useState } from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { GetMarketplaceTradeIdeaProviderTradersServiceResponse } from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider-traders.service';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { GetClosedOrdersPnLServiceResponse, getClosedOrdersPnL } from '@root/modules/orders/services/get-closed-orders-pnl.service';
import { getOrdersHistoryService } from '@root/modules/orders/services/get-orders-history.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { globalRound } from '@root/shared/utils/number/round';
import { Id } from '@root/shared/utils/types';

type Props = {
  selectedBroker: string;
  mtType?: string;
  expertId?: string;
  isMagicTerminal?: boolean;
};

export const useGetOrdersProfit = ({ selectedBroker, mtType, expertId, isMagicTerminal }: Props) => {
  const userId = useSelector(authSelector.userId);
  const { data: accounts } = useGetAccounts();

  const [todayPnl, setTodayPnl] = useState<{ profit: number; pips: number }>({ profit: 0, pips: 0 });
  const { openOrders } = useGetOpenOrders({ enabled: true, selectedBroker, mtType });

  const accountList = Object.values(groupBy(selectedBroker === 'all' ? accounts : accounts?.filter((item) => item.id === selectedBroker), (val) => val.currency));

  const pnlQueries = useQueries<GetMarketplaceTradeIdeaProviderTradersServiceResponse[]>(
    accountList?.map((accounts) => ({
      queryKey: [
        'fx',
        {
          userId: userId as string,
          accounts: accounts.map((item) => item.id) || '',
          expertId,
          isMagicTerminal: !!isMagicTerminal,
        },
        'orders-history-pnl',
      ],
      queryFn: () =>
        getClosedOrdersPnL({
          userId: userId as string,
          accounts: accounts.map((item) => item.id) || '',
          expertId,
          isMagicTerminal: !!isMagicTerminal,
          currency: accounts[0].currency,
        }),
      enabled: true,
      staleTime: 1000 * 60 * 10,
      keepPreviousData: true,
    })) || [],
  );

  const closedOrdersData = pnlQueries.reduce((acc, query) => {
    const data = query?.data as GetClosedOrdersPnLServiceResponse;
    if (data?.status === 200) {
      return { ...acc, ...data?.payload };
    }
    return acc;
  }, {});

  const openOrdersData = useMemo(() => {
    const ordersByExpert = expertId ? (openOrders || []).filter((item) => item.expertId === expertId) : openOrders || [];

    const { profit, pips } = ordersByExpert.reduce(
      (acc, item) => ({
        profit: acc.profit + item.profit,
        pips: acc.pips + item.calculatedUnits,
      }),
      { profit: 0, pips: 0 },
    );

    return {
      profit: globalRound(profit, 2),
      pips: globalRound(pips || 0, 2),
    };
  }, [expertId, openOrders]);

  const openOrdersDataByCurrency = useMemo(() => {
    const ordersByExpert = expertId ? (openOrders || []).filter((item) => item.expertId === expertId) : openOrders || [];

    const data = {};

    ordersByExpert.forEach((order) => {
      if (order.currency && data[order.currency]) {
        data[order.currency] = {
          profit: data[order.currency].profit + order.profit,
          pips: data[order.currency].profit + order.calculatedUnits,
        };
      } else if (order.currency) {
        data[order?.currency] = {
          profit: order.profit,
          pips: order.calculatedUnits,
        };
      }
    });
    return data;
  }, [expertId, openOrders]);

  const totalOrdersData = useMemo(() => {
    return accountList.reduce((acc, accounts) => {
      const currency = accounts[0]?.currency;

      acc[currency] = {
        profit: globalRound((openOrdersDataByCurrency[currency]?.profit || 0) + (closedOrdersData[currency]?.profit || 0), 2),
        pips: globalRound((openOrdersDataByCurrency[currency]?.pips || 0) + (closedOrdersData[currency]?.pips || 0), 2),
      };

      return acc;
    }, {});
  }, [accountList, openOrdersDataByCurrency, closedOrdersData]);

  useEffect(() => {
    const params = {
      size: 1000,
      orderDirection: 'DESC',
      orderBy: 'closeTime',
      page: 1,
      userId: userId as Id,
      accounts: selectedBroker,
      expertId,
    };

    if (selectedBroker || expertId) {
      (async () => {
        const response = await getOrdersHistoryService(params);
        if (response.status === 200) {
          const todayOrders = response.payload.orders.filter((order) => {
            const filterByDate = dayjs(order.closeTime).isSame(dayjs(), 'day');
            const filterByAccountId = selectedBroker && selectedBroker !== 'all' ? order.accountId === selectedBroker : true;
            const filterByExpertId = expertId ? order.expertId === expertId : true;
            return filterByDate && filterByAccountId && filterByExpertId;
          });

          const { profit, pips } = todayOrders.reduce(
            (acc, item) => ({
              profit: acc.profit + item.profit,
              pips: acc.pips + item.calculatedUnits,
            }),
            { profit: 0, pips: 0 },
          );

          setTodayPnl({
            profit: globalRound(profit, 2),
            pips: globalRound(pips, 0),
          });
        }
      })();
    }
  }, [expertId, selectedBroker, userId]);

  return { openOrdersData: openOrdersDataByCurrency, closedOrdersData, totalOrdersData, todayPnl };
};
