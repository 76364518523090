import { useState } from 'react';
import * as React from 'react';

import { PREFERRED_MT_TYPE } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';

type SelectBroker = (broker: string) => void;
type SelectedBroker = string;
type MtType = string | undefined;
type SetMtType = (mtType: string) => void;
type CountProviderProps = { children: React.ReactNode; isMagicTerminal?: boolean };

const initialState = { selectedBroker: '', selectBroker: () => {}, mtType: '', setMtType: () => {}, isMagicTerminal: false };
const CountStateContext = React.createContext<{ selectedBroker: SelectedBroker; selectBroker: SelectBroker; mtType: MtType; setMtType: SetMtType; isMagicTerminal: boolean }>(
  initialState,
);

function SelectedBrokerProvider({ children, isMagicTerminal }: CountProviderProps) {
  const [selectedBroker, selectBroker] = useState<SelectedBroker>(isMagicTerminal ? 'all' : '');
  const [mtType, setMtType] = useLocalStorage<string | undefined>(PREFERRED_MT_TYPE, undefined);
  const value = { selectedBroker, selectBroker, mtType, setMtType, isMagicTerminal: !!isMagicTerminal };
  return <CountStateContext.Provider value={value}>{children}</CountStateContext.Provider>;
}

function useGetSelectedBroker() {
  const context = React.useContext(CountStateContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
}

export { SelectedBrokerProvider, useGetSelectedBroker };
