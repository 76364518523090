import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const HelixIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='none' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99716 7.78649C7.2951 5.16028 9.03137 2.41333 11.8698 1.64358C14.7082 0.881378 17.5769 2.38315 18.2789 5.00935C18.3469 5.27348 18.3922 5.53007 18.4223 5.7942C17.2598 4.03585 14.9272 3.1529 12.6247 3.77926C10.0656 4.47355 8.41235 6.7677 8.61618 9.13733C8.33686 8.72227 8.12549 8.27702 7.99716 7.78649Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.89991 7.06955C11.0247 4.60182 14.0972 3.58304 16.7771 4.80558C19.4494 6.02813 20.7101 9.02411 19.5928 11.4994C19.4796 11.7484 19.3513 11.9824 19.2078 12.1937C19.4192 10.0882 18.1812 7.92232 16.0071 6.92617C13.5989 5.82437 10.8662 6.54129 9.52246 8.5034C9.56776 8.00533 9.69609 7.52235 9.89991 7.06955Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8326 7.72667C14.2634 6.53431 17.2755 7.71912 18.5663 10.3755C19.8572 13.0319 18.9287 16.1486 16.4979 17.341C16.2563 17.4617 16.0072 17.5523 15.7581 17.6278C17.2528 16.1411 17.668 13.6734 16.6187 11.5226C15.4712 9.13033 12.9046 7.93797 10.6323 8.59452C10.9796 8.24738 11.3797 7.96061 11.8326 7.74176V7.72667Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9038 9.46939C15.5384 10.1033 17.1086 12.9408 16.4292 15.8161C15.7498 18.6913 13.0548 20.5025 10.4278 19.8761C10.1711 19.8157 9.91442 19.7252 9.67285 19.6195C11.7639 19.4309 13.6512 17.7933 14.2023 15.4538C14.8213 12.8729 13.5983 10.3071 11.4318 9.36374C11.9149 9.31846 12.4056 9.34864 12.9038 9.46939Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6244 11.4997C14.255 13.6656 13.6812 16.8729 11.3335 18.6463C8.98575 20.4273 5.76232 20.1103 4.13173 17.9369C3.9732 17.7256 3.82977 17.4992 3.70898 17.2577C5.45281 18.4501 7.93643 18.3897 9.83879 16.9408C11.9525 15.3409 12.6319 12.5789 11.5524 10.4658C11.9525 10.745 12.3224 11.0846 12.6244 11.4997Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1304 12.8883C11.0171 15.6051 8.54107 17.703 5.6045 17.5747C2.66793 17.4464 0.373031 15.1447 0.493815 12.428C0.508913 12.1563 0.546658 11.8922 0.599502 11.6356C1.18832 13.6656 3.14352 15.2051 5.52901 15.3108C8.17116 15.424 10.4434 13.7335 10.9492 11.4092C11.0851 11.8771 11.1455 12.3751 11.1304 12.8883Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.0921 13.0012C7.28788 15.0237 4.04936 15.0614 1.86014 13.0842C-0.321524 11.1146 -0.631034 7.87709 1.18073 5.84706C1.36191 5.65085 1.55063 5.46974 1.75446 5.30371C0.924064 7.24318 1.46004 9.68827 3.23406 11.2881C5.19681 13.0691 8.02014 13.205 9.88475 11.7409C9.69602 12.1937 9.4318 12.6163 9.0921 13.0012Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.44606 11.7867C4.77371 12.1867 2.25234 10.1566 1.80695 7.23612C1.3691 4.3156 3.18087 1.62147 5.86077 1.21396C6.12498 1.16868 6.3892 1.16113 6.64586 1.16113C4.78126 2.13464 3.64891 4.35333 3.99616 6.73805C4.38871 9.3718 6.47224 11.2811 8.84263 11.3415C8.41988 11.5603 7.9443 11.7188 7.44606 11.7867Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.97778 9.56758C4.77347 7.9828 4.37337 4.76041 6.07945 2.35306C7.78553 -0.0542991 10.9561 -0.725944 13.1604 0.858836C13.3793 1.01731 13.5832 1.19089 13.7643 1.372C11.7488 0.760731 9.39347 1.56821 8.012 3.52278C6.472 5.68864 6.65318 8.51861 8.30641 10.2166C7.83837 10.0807 7.39298 9.8619 6.97778 9.56758Z'
        fill='currentColor'
      />
    </svg>
  );
};
