import { useCallback } from 'react';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbols } from '@root/modules/accounts/hooks/use-get-symbols';

export const useGetAllowedSymbols = () => {
  const accounts = useGetAccounts();
  const { data } = useGetSymbols();

  const getSymbols = useCallback(
    (accountId) => {
      const selectedAccount = accounts.data?.find((item) => item.id === accountId);
      return data?.filter((item) => (selectedAccount?.symbolsMapping || {})[item] !== null && (selectedAccount?.symbolsMapping || {})[item] !== undefined);
    },
    [data, accounts.data],
  );

  return { getSymbols };
};
