import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const MoreIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 512 512' x='0px' y='0px' xmlSpace='preserve' {...props}>
      <path d='M60.952,195.048C27.343,195.048,0,222.391,0,256s27.343,60.952,60.952,60.952s60.952-27.343,60.952-60.952S94.562,195.048,60.952,195.048z' />
      <path d='M256,195.048c-33.609,0-60.952,27.343-60.952,60.952s27.343,60.952,60.952,60.952s60.952-27.343,60.952-60.952S289.609,195.048,256,195.048z' />
      <path d='M451.048,195.048c-33.609,0-60.952,27.343-60.952,60.952s27.343,60.952,60.952,60.952S512,289.609,512,256S484.657,195.048,451.048,195.048z' />
    </svg>
  );
};
