import { useQuery } from 'react-query';

import { GET_EXPERT, GetExpertData, GetExpertQueryKey } from '../queries/get-expert.query';

export const useGetExpert = (id?: string, enabled = true) => {
  return useQuery<GetExpertData, Error, GetExpertData, GetExpertQueryKey>(['experts', id || ''], GET_EXPERT, {
    keepPreviousData: true,
    enabled: !!id && enabled,
  });
};
