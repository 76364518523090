import React, { FC } from 'react';
import { Text, Title } from '@root/shared/ui/typography';

import { Wrapper } from '@root/infra/layout/components/global-loader/global-loader.ui';
import { Trans, useTranslation } from 'react-i18next';
import LoadingIconDots from '@root/shared/ui/common/loading-icon-dots';

export const GlobalLoader: FC = () => {
  const { t } = useTranslation('common');

  return (
    <Wrapper className={'text-5xl'}>
      <div className='flex flex-col gap-4 max-w-[800px]'>
        <Title level={4} className='text-center'>{t('maintenance.title')}...</Title>
        <Text className='text-center' size={'base'}>
          <Trans i18nKey='common:maintenance.text' components={{ i: <span className='capitalize' />, br: <br /> }} />
        </Text>
      </div>

      <div className='text-primary-400'>
        <LoadingIconDots />
      </div>
    </Wrapper>
  );
};
