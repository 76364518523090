import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { IOption } from '@root/shared/types/general';

type Props = {
  withAllOption?: boolean;
  includeSignedOut?: boolean;
  mtType?: string;
};

export const useGetAccountsOptions = ({ withAllOption, includeSignedOut, mtType }: Props) => {
  const { t } = useTranslation('dashboard');
  const { data, isFetched } = useGetAccounts();

  return useMemo(() => {
    const list: IOption[] = [];
    const accountsCurrencies: Set<string> = new Set();

    data
      ?.filter((item) => (includeSignedOut ? item : item.isSignedIn))
      ?.filter((item) => (mtType ? item.mtType === mtType : true))
      .forEach((account) => {
        if (account.currency) accountsCurrencies.add(account.currency);

        list.push({
          value: account.id,
          label: `${account.company} - ${account.mtType}`,
        });
      });

    if (withAllOption && accountsCurrencies.size === 1 && list.length > 1) {
      list.unshift({ value: 'all', label: t('filters.type.all') });
    }

    return isFetched ? list : null;
  }, [data, includeSignedOut, isFetched, mtType, withAllOption]);
};
