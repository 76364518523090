import qs from 'querystring';

export const stringifyParams = (params: Record<string, any>, includeAll?: boolean): string => {
  const filteredObject = {};

  if (includeAll) {
    for (const prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop) && params[prop] !== undefined && !params[prop]) {
        filteredObject[prop] = params[prop];
      }
    }
  } else {
    for (const prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop) && params[prop] !== undefined && (!params[prop] || params[prop] !== 'all')) {
        filteredObject[prop] = params[prop];
      }
    }
  }

  return qs.stringify(filteredObject);
};
