import {
  GetMarketplaceTradeIdeaProviderIdeasQuery,
  getMarketplaceTradeIdeaProviderIdeasService,
  MarketplaceTradeIdeaProviderIdeasResponseData,
} from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider-ideas.service';
import { QueryFunction } from 'react-query';

export type GetMarketplaceTradeIdeaProviderIdeasQueryKey = ['marketplace-trade-idea-provider-ideas', GetMarketplaceTradeIdeaProviderIdeasQuery];
export type GetMarketplaceTradeIdeaProviderIdeasData = MarketplaceTradeIdeaProviderIdeasResponseData;

export const GET_MARKETPLACE_TRADE_IDEA_PROVIDER_IDEAS: QueryFunction<GetMarketplaceTradeIdeaProviderIdeasData, GetMarketplaceTradeIdeaProviderIdeasQueryKey> = async ({
  queryKey,
}) => {
  const [, data] = queryKey;
  const result = await getMarketplaceTradeIdeaProviderIdeasService(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
