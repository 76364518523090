import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';

import { GetExpertsData } from '../queries/get-experts.query';
import { ExpertsTableRow } from './experts-table-row';

type Props = {
  data: GetExpertsData;
  isLoading: boolean;
  profitsByExpertId: Record<string, number>;
};

export const ExpertsTable: FC<Props> = ({ data, isLoading, profitsByExpertId }) => {
  const { t } = useTranslation('forex-experts');

  return (
    <div className='bg-gray-800 px-5 py-4'>
      <Table.Wrapper>
        <colgroup>
          <col style={{ width: 160, minWidth: 150 }} />
          <col />
          <col />
          <col style={{ width: 160, minWidth: 120 }} />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <Table.Head>
          <Table.Row>
            {['table.name', 'table.account', 'table.tradeProvider', 'table.orders_count', 'table.pnl', 'table.pairs', 'table.status', ''].map((item, index) => (
              <Table.HeadCell key={item} className={clsx({ 'pl-0': index === 0 })}>
                {t(item)}
              </Table.HeadCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>{isLoading ? <SkeletonTable rows={10} columns={7} /> : data?.map((item) => <ExpertsTableRow item={item} key={item.id} pnl={profitsByExpertId[item.id] || 0} />)}</Table.Body>
      </Table.Wrapper>
    </div>
  );
};
