import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { IOrder } from '@root/modules/orders/types/orders';
import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';
import { Title } from '@root/shared/ui/typography/title';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { getOrderSideColor } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';
import { CheckboxField } from '@root/shared/form';
import { ModifyPartialCloseDto, useModifyPartialClose } from '../hooks/use-modify-partial-close';
import { useOrderCurrentPrice } from '../hooks/use-order-current-price';

export type ModifyModalProps = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
};

const FormComponent: FC<FormikProps<ModifyPartialCloseDto> & ModifyModalProps> = ({ isOpen, handleSubmit, closeModal, order, values, isValid, setFieldValue }) => {
  const { t } = useTranslation('forex-orders');
  const { hideActions } = useGhostLogin();

  const { currentPrice } = useOrderCurrentPrice(order)
  const totalValue = order.lots;
  
  const handleValueChange = useCallback((value, usePercentage) => {
    if (usePercentage) {
      const absoluteValue = (totalValue * value) / 100;
      setFieldValue("closeAbsoluteValue", globalRound(absoluteValue, 2));
      setFieldValue("closePercentValue", value);
    } else {
      const percentValue = (value / totalValue) * 100;
      setFieldValue("closeAbsoluteValue", value);
      setFieldValue("closePercentValue", globalRound(percentValue, 2));
    }
  }, [totalValue, setFieldValue]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeModal}
      footer={null}
      className={"!max-w-[640px]"}
      title={
        <div className='flex w-full gap-x-6 mt-2 px-2'>
          <Title level={6} className='font-bold text-center'>
            {t('partialClose.closePartially')}
          </Title>
        </div>
      }
    >
      <form className='flex flex-col -mt-4 w-full lg:px-2' onSubmit={handleSubmit}>
        <div className='grid grid-cols-12 gap-2'>
          <div className='flex flex-col gap-1 col-span-3'>
            <Text size='sm' className='text-grayscale-400'>{t('partialClose.positionVolume')}</Text>
            <Text className="font-medium mt-[2px]">{order.lots}</Text>
          </div>
          <div className='flex flex-col gap-1 col-span-2'>
            <Text size='sm' className='text-grayscale-400'>{t('partialClose.type')}</Text>
            <Text className={clsx(`flex self-start justify-center items-center bg-gray-700 py-0.5 px-3 rounded-full font-medium ${getOrderSideColor(order.type)}`)}>{order.type}</Text>
          </div>
          <div className='flex flex-col gap-1 col-span-3'>
            <Text size='sm' className='text-grayscale-400'>{t('partialClose.pair')}</Text>
            <Text className="font-medium mt-[2px]">{order.symbol}</Text>
          </div>
          <div className='flex flex-col gap-1 col-span-2'>
            <Text size='sm' className='text-grayscale-400'>{t('partialClose.openPrice')}</Text>
            <Text className="font-medium mt-[2px]">{globalRound(order.openPrice, 6)}</Text>
          </div>
          <div className='flex flex-col gap-1 col-span-2'>
            <Text size='sm' className='text-grayscale-400'>{t('partialClose.currentPrice')}</Text>
            <Text className="font-medium mt-[2px]">{globalRound(currentPrice, 6) || '-'}</Text>
          </div>
        </div>

        <div className='flex flex-col gap-4 w-full mt-6'>
          <Text>{t('partialClose.partialClose')}</Text>

          <div className='flex align-center gap-4'>
            <CheckboxField
              type='radio'
              name='options.moneyManagement.useGeneral'
              label={<Text className='font-bold'>{t('partialClose.absolute')}</Text>}
              checked={!values.usePercentage}
              onChange={() => setFieldValue('usePercentage', false)}
            />

            <CheckboxField
              type='radio'
              name='options.moneyManagement.useGeneral'
              label={<Text className='font-bold'>{t('partialClose.percentage')}</Text>}
              checked={values.usePercentage}
              onChange={() => setFieldValue('usePercentage', true)}
            />
          </div>

          {/* Absolute value */}
          {!values.usePercentage ? (
            <div className='flex align-center gap-4'>
              <div className='w-1/2'>
                <TextField
                  name='closeAbsoluteValue'
                  label={
                    <IconLabel
                      icon={
                        <InfoTooltip content={<Text>{t('fields.lotSize.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.lotSize.label')}
                    </IconLabel>
                  }
                  value={values.closeAbsoluteValue}
                  placeholder={t('fields.lotSize.placeholder')}
                  onChange={(e) => handleValueChange(e.target.value, false)}
                />
              </div>
              <Text className='mt-9'>{`${values.closePercentValue}%`}</Text>
            </div>
          ) : (
            <div className='flex align-center gap-4'>
              <div className='w-1/2'>
              <TextField
                name='closePercentValue'
                label={
                  <IconLabel
                    icon={
                      <InfoTooltip content={<Text>{t('fields.lotSize.description')}</Text>}>
                        <InfoTooltipIcon />
                      </InfoTooltip>
                    }
                  >
                    {t('fields.lotSize.label')}
                  </IconLabel>
                }
                suffix="%"
                value={values.closePercentValue}
                placeholder={t('fields.lotSize.placeholder')}
                onChange={(e) => handleValueChange(e.target.value, true)}
              />
              </div>
              <Text className='mt-9'>
                {t('partialClose.lotSize', { count: values.closeAbsoluteValue as number })}
                </Text>
            </div>
          )}
        </div>

        <div className='w-full flex justify-between mt-8'>
          <Button type='button' onClick={closeModal} outlined>
            {t('Close', { ns: 'forex-common' })}
          </Button>
          <Button type='submit' variant='primary' disabled={!isValid || hideActions}>
            {t('partialClose.closeByMarket')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const ModifyPartialCloseModal: FC<ModifyModalProps> = memo((props) => {
  const { initialValues, onSubmit, validationSchema } = useModifyPartialClose(props);

  return (
    <Formik<ModifyPartialCloseDto>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
      enableReinitialize
      component={(formProps) => <FormComponent {...formProps} {...props} />}
    />
  );
}, (prev, next) => prev.isOpen === next.isOpen && prev.order.ticket === next.order.ticket);

ModifyPartialCloseModal.displayName = 'ModifyPartialCloseModal';