import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';

import { IOrder } from '../types/orders';

export type GetOrderServiceResponse =
  | IHttpResponse<
      200,
      {
        orders: IOrder[];
        pagination: {
          totalItems: number;
          totalPages: number;
          page: number;
          size: number;
        };
      }
    >
  | IHttpResponse<400, string>;

export type GetOrderServiceQuery = { userId: Id; expertId?: Id; accounts: string; size: number; orderBy: string; orderDirection: string; page: number; isMagic?: boolean };

export const getOrdersHistoryService = async ({ expertId, accounts, size, orderBy, orderDirection, page, isMagic }: GetOrderServiceQuery): Promise<GetOrderServiceResponse> => {
  try {
    const params = stringifyParams({
      accounts: accounts === 'all' ? undefined : accounts,
      size,
      orderBy,
      orderDirection,
      page,
      experts: expertId ? [expertId] : undefined,
      isMagic: isMagic || undefined,
    });

    const response = await fetcher(`/users/orders/closed?${params}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: {
        orders: response.data.data.map((item) => OpenOrdersDtoMapper.toDomain(item)),
        pagination: {
          totalItems: response.data.total,
          totalPages: response.data.totalPages || response.data.total_pages || 1,
          page: response.data.page,
          size: response.data.size,
        },
      },
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
