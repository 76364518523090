import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { queryClient } from '@root/infra/query';
import { signInAccountService } from '@root/modules/accounts/services/sign-in-account.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

import { CreateAccountDto } from '../dtos/create-account.dto';
import { createAccountService } from '../services/create-account.service';
import { DrawdownForm } from '../types/drawdown';

export type CreateAccountForm = Omit<CreateAccountDto, 'maxDrawDownLimit'> & {
  mtType: string;
  multipleAccounts: boolean;
  maxDrawDownLimit: DrawdownForm;
};

export const useCreateAccount = () => {
  const { t } = useTranslation('forex-accounts');
  const userId = useSelector(authSelector.userId);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const next = searchParams.get('next');

  const initialValues = useMemo<CreateAccountForm>(
    () => ({
      mtType: '',
      userId: userId?.toString() || '',
      company: '',
      server: '',
      username: '',
      password: '',
      proxyServerId: null,
      serviceAccountId: '',
      multipleAccounts: false,
      maxDrawDownLimit: {
        use: true,
        percent: '20',
        basedOn: '0',
        period: '2',
      },
    }),
    [userId],
  );

  const onSubmit = useCallback(
    async (values: CreateAccountForm, helpers: FormikHelpers<CreateAccountForm>) => {
      const data = {
        ...values,
        mtType: undefined,
        multipleAccounts: undefined,
        maxDrawDownLimit: values.maxDrawDownLimit?.use
          ? {
              percent: Number(values.maxDrawDownLimit.percent),
              basedOn: Number(values.maxDrawDownLimit.basedOn),
              period: Number(values.maxDrawDownLimit.period),
            }
          : null,
      };

      const result = await createAccountService(data);

      if (result.status === 200) {
        await signInAccountService({ accountId: result.payload.id });
        queryClient.invalidateQueries(['accounts']);
        notify({ type: 'success', text: t('create.messages.created') });

        if (next) {
          return await navigate(next.includes('?') ? next + `&account=${result.payload.id}` : next + `?account=${result.payload.id}`);
        }

        navigate(PageLinks.accounts);
      } else {
        notify({ type: 'danger', text: result.payload });
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
      }
    },
    [navigate, next, t],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        userId: yup.string().required().label('User ID'),
        company: yup.string().required().label(t('fields.company.label')),
        server: yup.string().required().label(t('fields.server.label')),
        username: yup
          .number()
          .required()
          .typeError(t('number.type', { ns: 'yup' }))
          .label(t('fields.userName.validationLabel')),
        password: yup.string().required().label(t('fields.password.label')),
        serviceAccountId: yup.string().when('multipleAccounts', {
          is: true,
          then: yup.string().required().label(t('fields.serviceAccountId.label')),
        }),
        multipleAccounts: yup.boolean(),
        proxyServerId: yup
          .string()
          .nullable()
          .test('invalid proxy', t('fields.proxyServers.invalidProxy'), (value) => !!value)
          .label(t('fields.proxyServers.label')),
        maxDrawDownLimit: yup.object().shape({
          use: yup.boolean(),
          percent: yup.number().required().min(1).max(100).label(t('fields.drawdown.percent.label')),
        }),
      }),
    [t],
  );

  return { initialValues, onSubmit, validationSchema };
};
