import { useTranslation } from 'react-i18next';
import { NoDataFound } from '@root/shared/ui/common';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';
import { FC } from 'react';
import { MarketPlaceTradeIdeaProviderTrader } from '../types/marketplace-trade-idea-provider-trader';

interface IProps {
  list?: MarketPlaceTradeIdeaProviderTrader[];
  isLoading?: boolean;
}

export const ProviderTradersTable: FC<IProps> = ({ list, isLoading = false }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  if (!list?.length && !isLoading) {
    return (
      <div className='py-20'>
        <NoDataFound />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Table.Wrapper>
        <colgroup>
          <col style={{ width: 300 }} />
          <col />
          <col />
          <col />
        </colgroup>
        <Table.Head>
          <Table.Row className='text-sm'>
            <Table.HeadCell className='pl-0'>{t('table.trader')}</Table.HeadCell>
            <Table.HeadCell>{t('table.tradeIdeasCount')}</Table.HeadCell>
            <Table.HeadCell>{t('table.winRatio')}</Table.HeadCell>
            <Table.HeadCell className='pr-0 text-right'>{t('table.pnl')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <SkeletonTable rows={4} columns={4} />
        </Table.Body>
      </Table.Wrapper>
    );
  }

  return (
    <Table.Wrapper>
      <colgroup>
        <col style={{ width: 400 }} />
        <col />
        <col />
        <col />
      </colgroup>
      <Table.Head>
        <Table.Row className='text-sm'>
          <Table.HeadCell className='pl-0'>{t('table.trader')}</Table.HeadCell>
          <Table.HeadCell>{t('table.tradeIdeasCount')}</Table.HeadCell>
          <Table.HeadCell>{t('table.winRatio')}</Table.HeadCell>
          <Table.HeadCell className='pr-0 text-right'>{t('table.pnl')}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {list?.map((item) => (
          <Table.Row key={item.id} className='border-t border-grayscale-600 text-base'>
            <Table.DataCell className='pl-0'>
              <Table.DataFlex>
                <ImageWithFallback label={item.name} src={item.logo} width={26} height={26} wrapperClassName='shrink-0' />
                <span className='text-base pl-2'>{item.name}</span>
              </Table.DataFlex>
            </Table.DataCell>
            <Table.DataCell>{item.tradeIdeasCount}</Table.DataCell>
            <Table.DataCell>{`${item.winsCount} ${t('table.wins')} / ${item.lossesCount} ${t('table.losses')}`}</Table.DataCell>
            <Table.DataCell className='text-right pr-0'>{item.pnl}</Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Wrapper>
  );
};
