import React from 'react';
import LoadingIconDots from './loading-icon-dots';

export const LoadingSection = () => {
  return (
    <div className='flex justify-center p-8 text-3xl text-info-300 py-20'>
      <LoadingIconDots />
    </div>
  );
};
