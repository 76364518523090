import styled from 'styled-components';

export const SelectButton = styled.button<{ selected: boolean, disabled?: boolean; labeled?:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid ${({ theme, selected, disabled, labeled }) => (selected && !disabled ? theme.primary[400] : theme.grayscale[500])};
  border-radius: 13px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20px;
  line-height: 32px;
  background: transparent;
  color: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.primary[400] : theme.grayscale[100])};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;

  &::before {
    display: ${({labeled, selected}) => (labeled && selected ? 'block': 'none')};
    content: "";
    width: 18px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 9px);
    background: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.primary[400] : theme.grayscale[100])};
    border-radius: 10px;
  }

  &:hover {
    color: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.gray[100] : theme.primary[400])};
    border-color: ${({ theme, disabled }) => disabled ? theme.grayscale[500] : theme.primary[400]};

    &::before {
      display: ${({labeled}) => (labeled ? 'block': 'none')};
      background: ${({ theme, selected, disabled }) => (disabled ? theme.grayscale[500] : selected ? theme.gray[100] : theme.primary[400])};
    }
  }
`;