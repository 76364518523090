import dayjs from 'dayjs';

import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { getChartPoints } from '@root/modules/experts/utils';
import { Period } from '@root/modules/orders/enums/orders';
import { GET_USER_PERFORMANCE_CHART_DATA } from '@root/modules/orders/queries/get-users-performance-data.query';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { ChartType } from '@root/shared/constants/chart';
import { getWeekNumber } from '@root/shared/utils/dates/get-week-number';
import { globalRound } from '@root/shared/utils/number/round';

type Props = {
  selectedBroker: string;
  expertId?: string;
};

export const usePerformanceChart = ({ selectedBroker, expertId }: Props) => {
  const userId = useSelector(authSelector.userId);
  const { data: accounts } = useGetAccounts();

  const currency = useMemo(() => getSymbolFromCurrency(accounts?.find((item) => item.id === selectedBroker)?.currency || 'USD'), [accounts, selectedBroker]);

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [period, setPeriod] = useState<Period>(Period.Week);
  const { data, isLoading } = useQuery(
    [
      'fx',
      {
        userId: userId as string,
        accounts: selectedBroker || '',
        period,
        experts: expertId,
      },
      'performance-chart-data',
    ],
    GET_USER_PERFORMANCE_CHART_DATA,
  );
  const { t } = useTranslation('dashboard', { keyPrefix: 'performance' });

  const [chartType, setChartType] = useState<ChartType>(ChartType.Profit);

  const performanceData = useMemo(() => {
    return (
      data?.slice().sort((a, b) => {
        const aDate = new Date(a.closeTime).getTime();
        const bDate = new Date(b.closeTime).getTime();

        return aDate - bDate;
      }) || []
    ).filter((item) => {
      let isValid = true;
      if (fromDate) {
        isValid = new Date(item.closeTime) >= dayjs(fromDate).startOf('day').toDate();
      }

      if (toDate) {
        isValid = isValid && new Date(item.closeTime) <= dayjs(toDate).endOf('day').toDate();
      }

      return isValid;
    });
  }, [data, fromDate, toDate]);

  useEffect(() => {
    if (performanceData.length && !fromDate && !toDate) {
      const firstDate = dayjs(performanceData[0].closeTime).startOf('day').toDate();
      const lastDate = dayjs(new Date()).endOf('day').toDate();
      setFromDate(firstDate);
      setToDate(lastDate);
    }
  }, [fromDate, performanceData, toDate]);

  const periodOptions = useMemo(
    () => [
      { value: Period.Day, label: t('filters.date.day') },
      { value: Period.Week, label: t('filters.date.week') },
      { value: Period.Month, label: t('filters.date.month') },
      // { value: Period.Year, label: t('filters.date.year') },
      { value: Period.All, label: t('filters.date.all') },
    ],
    [t],
  );

  const selectedPeriod = periodOptions.find((item) => item.value === period);

  const chartData = useMemo(() => {
    return {
      profit: [
        {
          name: 'Profit',
          type: 'column',
          data: performanceData?.map((item) => globalRound(item.profit, 5)),
        },
      ],
      growth: [
        {
          name: `Growth in ${currency}`,
          type: 'column',
          data: performanceData?.map((item) => globalRound(item.profit, 5)),
        },
        {
          name: 'Growth in %',
          type: 'line',
          data: performanceData?.map((item) => globalRound(item.profit, 5)),
        },
      ],
    }[chartType];
  }, [performanceData, chartType, currency]);

  const labels = performanceData?.map((item) => {
    const closeTime = item?.closeTime;
    switch (selectedPeriod?.value as Period) {
      case Period.Week:
        return `Week ${getWeekNumber(closeTime)}`;
      case Period.Month:
        return dayjs(closeTime).format('MMMM');
      case Period.Day:
        return dayjs(closeTime).format('DD MMMM');
      default:
        return dayjs(closeTime).format('YYYY');
    }
  });

  const series = useMemo(() => {
    return chartData
      ?.map((item) => ({
        name: item.name,
        data: getChartPoints({ values: item?.data, chartType, growthPercent: item.name.includes('%') })?.map((value, index) => ({
          x: labels?.[index],
          y: globalRound(value, 2),
          fillColor: value > 0 ? '#4ECDC4FF' : '#FFA462',
        })),
        type: item.type,
      }))
      .map((item, index, arr) => {
        if (index === 1) {
          return {
            ...item,
            data: item.data.map((dataItem, dataIndex) => {
              const absValue = arr[0]?.data?.[dataIndex]?.y;
              const absFillColor = arr[0]?.data?.[dataIndex]?.fillColor;

              return {
                ...dataItem,
                y: Math.abs(dataItem.y) * (absValue > 0 ? 1 : -1),
                fillColor: absFillColor,
              };
            }),
          };
        }

        return item;
      });
  }, [chartData, chartType, labels]);

  const state = {
    selectedPeriod,
    periodOptions,
    chartData,
    labels,
    series,
    isLoading,
    chartType,
    currency,
    fromDate,
    toDate,
    dataLength: performanceData?.length || 0,
  };

  const handlers = { setPeriod, setChartType, setFromDate, setToDate };

  return [state, handlers] as [typeof state, typeof handlers];
};
