import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { FullStatsData } from '@root/modules/orders/types/statistic';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';

export type GetUserStatServiceResponse = IHttpResponse<200, FullStatsData> | IHttpResponse<400, string>;
export type GetUsersStatsServiceQuery = { accounts: Id; providerId?: string; fromDate?: string; toDate?: string };

export const getUserStatistic = async ({ accounts, providerId, fromDate, toDate }: GetUsersStatsServiceQuery): Promise<GetUserStatServiceResponse> => {
  try {
    const query = stringifyParams({
      accounts: accounts === 'all' || !accounts ? undefined : accounts,
      providers: providerId ? providerId : undefined,
      fromDate: fromDate ? fromDate : undefined,
      toDate: toDate ? toDate : undefined,
    });

    const response = await fetcher.get(`/users/orders/closed/dashboard-stats?${query}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
