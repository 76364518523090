import { QueryFunction } from 'react-query';

import { notify } from '@root/shared/utils/notification';

import { getPreset } from '../services/get-preset.service';
import { IPreset } from '../types/preset';

export type GetPresetQueryKey = ['presets', string];
export type GetPresetData = IPreset;

export const GET_PRESET: QueryFunction<GetPresetData, GetPresetQueryKey> = async ({ queryKey }) => {
  const [, data] = queryKey;
  const result = await getPreset(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    notify({
      title: result.payload,
      type: 'danger',
    });

    throw new Error(result.payload);
  }
};
