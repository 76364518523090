import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useCheckActiveBrokersQuota = () => {
  const { data: accounts } = useGetAccounts();
  const maxActiveBrokerCount = useSelector(authSelector.activeBrokerCount);

  const possibleActiveBrokersLeft = useMemo(() => {
    if (maxActiveBrokerCount && accounts) {
      return maxActiveBrokerCount - accounts.filter((item) => item.isSignedIn).length + 1;
    }
    return 0;
  }, [maxActiveBrokerCount, accounts]);

  return { possibleActiveBrokersLeft };
};
