import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAccount } from '@root/modules/accounts';
import { IAccountBalance } from '@root/modules/accounts/types/balance';

export type IAccountsState = {
  updateAccountId: string | null;
  symbolMapperModalData: (IAccount & { unsupportedSymbols?: string[] }) | null;
  serviceAccountModalOpened: boolean;
  resetAccountId: string | null;
  balance: {
    data: {
      [accountId: string]: IAccountBalance | undefined;
    };
    isBalanceFetchedData: {
      [accountId: string]: boolean;
    };
    balanceCurrency: {
      [accountId: string]: string;
    };
    error: string | null;
    loading: boolean;
  };
};

export namespace FXAccountActions {
  export type AccountEditOpened = PayloadAction<string>;
  export type AccountEditClosed = PayloadAction;

  export type ServiceAccountModalOpened = PayloadAction<boolean>;

  export type SetResetAccountId = PayloadAction<string | null>;

  export type FetchBalancePending = PayloadAction;
  export type FetchBalanceFulfilled = PayloadAction<{ list: IAccountBalance[]; source?: IAccountBalance['source'] }>;
  export type FetchBalanceRejected = PayloadAction<string>;

  export type SymbolMapperModalOpened = PayloadAction<IAccountsState['symbolMapperModalData']>;
  export type SetAccountBalanceFetched = PayloadAction<string>;
}

export type AccountsSliceReducer = {
  accountEditOpened: CaseReducer<IAccountsState, FXAccountActions.AccountEditOpened>;
  accountEditClosed: CaseReducer<IAccountsState, FXAccountActions.AccountEditClosed>;

  serviceAccountModalOpened: CaseReducer<IAccountsState, FXAccountActions.ServiceAccountModalOpened>;

  setResetAccountId: CaseReducer<IAccountsState, FXAccountActions.SetResetAccountId>;

  fetchBalancePending: CaseReducer<IAccountsState, FXAccountActions.FetchBalancePending>;
  fetchBalanceFulfilled: CaseReducer<IAccountsState, FXAccountActions.FetchBalanceFulfilled>;
  fetchBalanceRejected: CaseReducer<IAccountsState, FXAccountActions.FetchBalanceRejected>;
  partialUpdateBalance: CaseReducer<IAccountsState, PayloadAction<Record<string, IAccountBalance> | void>>;

  setSymbolMapperModalData: CaseReducer<IAccountsState, FXAccountActions.SymbolMapperModalOpened>;
  clearAccountBalance: CaseReducer<IAccountsState, PayloadAction<{ id: string }>>;
  setAccountBalanceFetched: CaseReducer<IAccountsState, FXAccountActions.SetAccountBalanceFetched>;
};

const initialState: IAccountsState = {
  updateAccountId: null,
  symbolMapperModalData: null,
  serviceAccountModalOpened: false,
  resetAccountId: null,

  balance: {
    data: {},
    isBalanceFetchedData: {},
    balanceCurrency: {},
    error: null,
    loading: false,
  },
};

export const accountsSlice = createSlice<IAccountsState, AccountsSliceReducer, 'accounts'>({
  name: 'accounts',
  initialState,
  reducers: {
    setSymbolMapperModalData: (state, action) => {
      state.symbolMapperModalData = action.payload;
    },
    accountEditOpened: (state, action) => {
      state.updateAccountId = action.payload;
    },
    serviceAccountModalOpened: (state, action) => {
      state.serviceAccountModalOpened = action.payload;
    },
    accountEditClosed: (state) => {
      state.updateAccountId = null;
    },
    setResetAccountId: (state, action) => {
      state.resetAccountId = action.payload;
    },
    fetchBalancePending: (state) => {
      state.balance.error = null;
      state.balance.loading = true;
    },
    setAccountBalanceFetched: (state, action) => {
      state.balance.isBalanceFetchedData = { ...state.balance.isBalanceFetchedData, [action.payload]: true };
    },
    fetchBalanceFulfilled: (state, action) => {
      if (action.payload.source === 'rest') {
        const fetchedData = {};
        const balanceCurrency = {};

        action.payload.list.forEach((item) => {
          fetchedData[item.accountId] = true;
          balanceCurrency[item.accountId] = item.currency;
        });

        state.balance.isBalanceFetchedData = { ...state.balance.isBalanceFetchedData, ...fetchedData };
        state.balance.balanceCurrency = { ...state.balance.balanceCurrency, ...balanceCurrency };
      }

      action.payload.list.forEach((balance) => {
        state.balance.data[balance.accountId] = balance;
      });
    },
    fetchBalanceRejected: (state, action) => {
      state.balance.error = action.payload;
      state.balance.loading = false;
    },
    partialUpdateBalance: (state, action) => {
      state.balance.data = { ...state.balance.data, ...action.payload };
    },
    clearAccountBalance: (state, action) => {
      const { [action.payload.id]: _, ...rest } = state.balance.data;
      const { [action.payload.id]: __, ...restBalance } = state.balance.isBalanceFetchedData;

      state.balance.data = rest;
      state.balance.isBalanceFetchedData = restBalance;
    },
  },
});
