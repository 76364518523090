import MarketplacePage from '@root/modules/marketplace/pages';
import MarketplaceTradeIdeaProviderPage from '@root/modules/marketplace/pages/provider';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorPage } from '@root/infra/layout/containers/error';
import React from 'react';

export const Router = () => {
  return (
    <Routes>
      <Route index element={<MarketplacePage />} errorElement={<ErrorPage />} />
      <Route path='provider/:id' element={<MarketplaceTradeIdeaProviderPage />} errorElement={<ErrorPage />} />
      <Route path='*' element={<Navigate to={PageLinks.providers} replace />} />
    </Routes>
  );
};
