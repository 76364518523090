import { MutationFunction } from 'react-query';
import { deleteAccountService, DeleteAccountServiceValues } from '../services/delete-account.service';

export type DeleteAccountMutationData = void;
export type DeleteAccountMutationValues = DeleteAccountServiceValues;
export type DeleteAccountMutationError = Error;

export const DELETE_ACCOUNT_MUTATION: MutationFunction<DeleteAccountMutationData, DeleteAccountMutationValues> = async (values) => {
  const result = await deleteAccountService(values);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }
};
