import { usePopover } from '@root/shared/hooks/use-popover';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Popover } from 'react-tiny-popover';

export const SimplePopover: FC<PropsWithChildren & { content: ReactNode }> = ({ children, content }) => {
  const popover = usePopover({ trigger: 'click' });

  return (
    <Popover {...popover.props} positions={['bottom']} align='end' padding={4} content={<div>{content}</div>}>
      <div {...popover.childrenProps}>{children}</div>
    </Popover>
  );
};
