import clsx from 'clsx';
import { FC } from 'react';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { CrossIcon } from '@root/shared/icons/cross-icon';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { Text } from '@root/shared/ui/typography';
import { PencilIcon } from '@root/shared/icons/pencil-icon';
import { CheckIcon } from '@root/shared/icons/check-icon';

type Props = {
  title: string;
  loading: boolean;
  editing: boolean;
  section: CreateExpertStepKey;
  setEditing: (editing: boolean) => void;
  saveSettings: (part: CreateExpertStepKey) => void;
  cancelChanges: () => void;
  editDisabled?: boolean;
  editable?: boolean;
};

export const EditableSettingsTitle: FC<Props> = ({ editDisabled, editable = true, title, loading, editing, section, saveSettings, setEditing, cancelChanges }) => {
  return (
    <div className={clsx('w-full flex gap-x-2 items-center mb-1', { 'justify-start': !editing, 'justify-between': editing })}>
      <Text size='sm'>{title}</Text>
      {editing ? (
        <div className='flex justify-end gap-x-1'>
          <button
            type='button'
            className='w-6 h-6 flex justify-center items-center p-0 rounded border border-transparent hover:border-gray-100 hover:border-opacity-30 bg-gray-100 bg-opacity-30 hover:bg-transparent'
            onClick={cancelChanges}
          >
            <CrossIcon width={12} height={12} />
          </button>
          {loading ? (
            <div className='h-6 w-6 flex justify-center items-center'>
              <LoadingIcon />
            </div>
          ) : (
            <button
              type='button'
              className={clsx(
                'w-6 h-6 flex justify-center items-center p-0 rounded border border-transparent hover:border-gray-100 hover:border-opacity-30 bg-gray-100 bg-opacity-30 hover:bg-transparent',
              )}
              onClick={() => saveSettings(section)}
            >
              <CheckIcon width={16} height={16} />
            </button>
          )}
        </div>
      ) : (
        editable ? <button disabled={editDisabled}>
          <PencilIcon
            width={16}
            height={16}
            className={clsx('cursor-pointer hover:text-primary-400', { '!cursor-not-allowed !text-grayscale-600 hover:!text-grayscale-600': editDisabled })}
            onClick={() => setEditing(true)}
          />
        </button> : null
      )}
    </div>
  );
};
