import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLinks } from '@root/shared/constants/pageLinks';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { Button } from '@root/shared/ui/button';
import { PlusIcon } from '@root/shared/icons/plus-icon';

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 250px;
  box-shadow: 0 10px 24px rgba(12, 12, 12, 0.6);
  z-index: 40;
`;

type Props = {
  buttonText?: string;
  buttonClassName?: string;
};

export const CreateExpertDropdown: FC<Props> = ({ buttonText, buttonClassName = {} }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [isCreationOpen, setIsCreationOpen] = useState(false);

  const creationRef = useRef<HTMLDivElement>(null);

  useOutsideClick(creationRef, () => setIsCreationOpen(false));

  const handleRedirectExpert = useCallback(() => {
    navigate(PageLinks.createExpert);
  }, [navigate]);

  const handleRedirectGuide = useCallback(() => {
    navigate(PageLinks.eaGuide);
  }, [navigate]);

  return (
    <div className='relative'>
      <div ref={creationRef}>
        <Button className={clsx('flex items-center gap-x-2 !px-2 !py-0 rounded h-[38px]', buttonClassName)} outlined={false} onClick={handleRedirectExpert}>
          <PlusIcon />
          {buttonText && <span className='whitespace-nowrap leading-5 py-px'>{buttonText}</span>}
        </Button>
      </div>
      {/* {isCreationOpen && (
        <Wrapper className=''>
          <div className='mb-1 rounded bg-gray-800 hover:bg-grayscale-700 p-2.5 px-3 flex gap-x-3 shadow-md cursor-pointer' onClick={handleRedirectGuide}>
            <SvgSpriteIcon id='simple-ea' className='mt-1' />
            <div>
              <Text className='!text-[14px] font-bold'>{t('assistWidget.simpleEa.title')}</Text>
              <Text className='!text-[12px] text-grayscale-600'>{t('assistWidget.simpleEa.description')}</Text>
            </div>
          </div>
          <div className='rounded bg-gray-800 hover:bg-grayscale-700 p-2.5 px-3 flex gap-x-3 shadow-md cursor-pointer' onClick={handleRedirectExpert}>
            <SvgSpriteIcon id='advanced-ea' className='mt-1' />
            <div>
              <Text className='!text-[14px] font-bold'>{t('assistWidget.advancedEa.title')}</Text>
              <Text className='!text-[12px] text-grayscale-600'>{t('assistWidget.advancedEa.description')}</Text>
            </div>
          </div>
        </Wrapper>
      )} */}
    </div>
  );
};
