import { useTranslation } from 'react-i18next';
import { FileIcon } from '@root/shared/icons/file-icon';
import { FC, ReactNode } from 'react';

type Props = {
  text?: ReactNode;
  withIcon?: boolean;
};

export const NoDataFound: FC<Props> = ({ text, withIcon = true }) => {
  const { t } = useTranslation('common');

  return (
    <div className='flex justify-center'>
      <span className='flex items-center'>
        {withIcon && <FileIcon className='mr-2.5' />}
        {text ? text : t('noDataFound')}
      </span>
    </div>
  );
};
