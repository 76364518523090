import styled from 'styled-components';

export const BaseLine = styled.div`
  width: 100%;
  height: 1px;
  position: relative;
  background: ${({ theme }) => theme.gray[200]};
`;

export const Progress = styled.div<{ from: number; to: number; negative: boolean }>`
  width: ${({ from, to }) => Math.abs(to - from)}%;
  height: 3px;
  background: ${({ theme, negative }) => (negative ? theme.danger[500] : theme.success[500])};
  position: absolute;
  top: -2px;
  left: ${({ from, to }) => Math.min(from, to)}%;
`;

export const BaseLabel = styled.div`
  position: absolute;
  left: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    left: 0;
  }
`;

export const StopLossLabel = styled(BaseLabel)<{ index: number }>`
  padding-top: ${({ index }) => 32 + (index > 2 ? 12 : 0)}px;
  color: ${({ theme }) => theme.danger[500]};
  top: 0;

  &::before {
    background: ${({ theme }) => theme.danger[500]};
    top: 0;
    height: ${({ index }) => 32 + (index > 2 ? 12 : 0)}px;
  }
`;

export const PlacedLabel = styled(BaseLabel)<{ position: number }>`
  padding-bottom: 32px;
  color: ${({ theme }) => theme.primary[600]};
  bottom: 0;
  left: ${({ position }) => position}%;

  ${({ position }) => (position >= 50 ? `span.wrapper { position: relative; left: -100%; }` : '')};

  &::before {
    background: ${({ theme }) => theme.primary[600]};
    bottom: 0;
  }
`;

export const TPLabel = styled(BaseLabel)<{ position: number; index: number }>`
  position: absolute;
  color: ${({ theme }) => theme.success[500]};
  top: 0;
  left: ${({ position }) => position}%;
  padding-top: ${({ index }) => 10 + index * 12}px;

  &::before {
    height: ${({ index }) => 8 + 12 * index}px;
    background: ${({ theme }) => theme.success[500]};
    top: 0;
    right: 0;
  }
`;

export const CurrentLabel = styled(BaseLabel)<{ position: number; negative: boolean }>`
  bottom: 0;
  left: ${({ position }) => position}%;
  color: ${({ theme, negative }) => (negative ? theme.danger[500] : theme.success[500])};
  padding-bottom: 10px;

  ${({ position }) => (position >= 50 ? `span.wrapper { position: relative; left: -100%; }` : '')};

  &::before {
    height: 8px;
    background: ${({ theme, negative }) => (negative ? theme.danger[500] : theme.success[500])};
    bottom: 0;
    right: 0;
  }
`;

export const Wrapper = styled.div<{ tpsCount: number }>`
  height: ${({ tpsCount }) => 112 + tpsCount * 12}px;
`;
