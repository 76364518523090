import { Text } from '@root/shared/ui/typography';
import styled from 'styled-components';

export const MenuWrapper = styled.div<{ isGhostLogin: boolean; isGuestView?: boolean }>`
  position: fixed;
  left: ${({ isGhostLogin }) => (isGhostLogin ? `3px` : '0')};
  top: ${({ isGhostLogin }) => (isGhostLogin ? `${72 + 60}px` : '72px')};
  width: ${({ isGhostLogin }) => (isGhostLogin ? `calc(100% - 7px)` : '100%')};
  background: ${({ theme }) => theme.gray[800]};
  border-top: 2px solid ${({ theme }) => theme.gray[900]};
  z-index: 11;
  box-shadow: 0px 10px 24px rgba(12, 12, 12, 0.6);
  height: ${({ isGuestView }) => (isGuestView ? 'unset' : '80px')};
`;

export const MenuItemWrapper = styled(Text) <{ active: boolean }>`
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: visible;
  color: ${({ theme, active }) => (active ? theme.primary[400] : theme.gray[100])};
  height: 32px;

  &::after {
    content: ${({ active }) => (active ? '""' : 'none')};
    position: absolute;
    border-radius: 4px;
    z-index: 2;
    background-color: ${({ theme }) => theme.primary[400]};
    height: 4px;
    width: 20px;
    bottom: 0;
    left: calc(50% - 10px);
  }
`;
