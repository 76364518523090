import { useQuery } from 'react-query';
import {
  GetMarketplaceTradeIdeaProviderIdeasData,
  GetMarketplaceTradeIdeaProviderIdeasQueryKey,
  GET_MARKETPLACE_TRADE_IDEA_PROVIDER_IDEAS,
} from '../query/get-marketplace-trade-idea-provider-ideas.query';

export const useGetMarketplaceTradeIdeaProviderIdeas = (params) => {
  return useQuery<GetMarketplaceTradeIdeaProviderIdeasData, Error, GetMarketplaceTradeIdeaProviderIdeasData, GetMarketplaceTradeIdeaProviderIdeasQueryKey>(
    ['marketplace-trade-idea-provider-ideas', params],
    GET_MARKETPLACE_TRADE_IDEA_PROVIDER_IDEAS,
    {
      keepPreviousData: true,
      enabled: !!params?.id,
    },
  );
};
