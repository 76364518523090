export type MarketplaceProviderLog = {
  createdAt: string;
  data: {
    [key: string]: any;
  };
  id: string;
  message: string;
  providerId: string;
  updatedAt: string;
};

export class MarketplaceProviderLogsMapper {
  public static toDomain(data): MarketplaceProviderLog {
    return {
      id: data.id,
      providerId: data.provider_id,
      message: data.message,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      data: data.data,
    };
  }
}
