import { PERMISSION } from '@3lgn/shared/dist/constants/permissions';
import { useInterval } from '@chakra-ui/hooks';

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { settingsSlice } from '@root/modules/app-settings/store/settings.slice';
import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';

import { clearLocalStorage, removeTokens } from '../helpers';
import { endGhostSession } from '../services/end-ghost-session.service';
import { authSelector, authSlice } from '../store';
import { GHOST_LOGIN_SESSION_START, GHOST_LOGIN_TOKEN, GHOST_SESSION_PAGE_LEAVE_TIME } from '../../shared/constants/local-storage-keys';

export const useCheckGhostLogin = () => {
  const { t } = useTranslation('common');
  const ghostToken = useSelector(authSelector.ghostToken);
  const user = useSelector(authSelector.user);
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);
  const [storedGhostToken, saveGhostToken] = useLocalStorage<string | null>(GHOST_LOGIN_TOKEN, null);
  const [, setGhostSessionPageLeaveTime] = useLocalStorage<string | null>(GHOST_SESSION_PAGE_LEAVE_TIME, null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (ghostToken && !storedGhostToken) {
        saveGhostToken(ghostToken);
      }

      if (!ghostToken && !!storedGhostToken) {
        const ghostSessionPageLeaveTime = localStorage.getItem(GHOST_SESSION_PAGE_LEAVE_TIME);
        if (ghostSessionPageLeaveTime) {
          const pageLeaveTime = new Date(JSON.parse(ghostSessionPageLeaveTime));
          const now = new Date();
          if (now.getTime() - pageLeaveTime.getTime() >= 1000 * 3) {
            await endGhostSession();
            dispatch(authSlice.actions.finalizeGhostLogin(null));
            queryClient.clear();
            removeTokens();
            clearLocalStorage();
            window.location.reload();
            return;
          }
        }
        dispatch(authSlice.actions.finalizeGhostLogin(storedGhostToken));
      }
    })();
  }, [dispatch, ghostToken, saveGhostToken, storedGhostToken]);

  useEffect(() => {
    const canReadBalance = subscriptionInfo?.permissions?.[PERMISSION.BALANCE_READ]?.value;
    if (user?.id && subscriptionInfo?.permissions && ghostToken) {
      if (canReadBalance) {
        dispatch(settingsSlice.actions.setCanViewBalance(true));
      } else {
        dispatch(settingsSlice.actions.setBalanceVisibility(false));
        dispatch(settingsSlice.actions.setCanViewBalance(false));
      }
    }
  }, [dispatch, ghostToken, subscriptionInfo?.permissions, user?.id]);

  const logoutOnExpiration = useCallback(async () => {
    const ghostLoginSessionStart = localStorage.getItem(GHOST_LOGIN_SESSION_START);

    if (ghostLoginSessionStart) {
      const sessionStart = new Date(JSON.parse(ghostLoginSessionStart));
      const now = new Date();

      if (now.getTime() - sessionStart.getTime() >= 1000 * 60 * 30) {
        notify({
          type: 'danger',
          title: t('sessionFinished'),
        });

        await endGhostSession();

        const timeout = setTimeout(() => {
          dispatch(authSlice.actions.signedOut());
          dispatch(authSlice.actions.finalizeGhostLogin(null));
          queryClient.clear();
          clearTimeout(timeout);
        }, 1000 * 5);
      }
    }
  }, [dispatch, t]);

  useInterval(() => {
    logoutOnExpiration();
  }, 1000 * 60);

  useEffect(() => {
    logoutOnExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ghostToken) {
      window.addEventListener('unload', (e) => {
        e.preventDefault();
        setGhostSessionPageLeaveTime(new Date().toISOString());
        e.returnValue = true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ghostToken]);
};
