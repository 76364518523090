import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { PlayIcon } from '@root/shared/icons/play-icon';
import { StopIcon } from '@root/shared/icons/stop-icon';
import { Button } from '@root/shared/ui/button';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';

import { useExpertItem } from '../../hooks/use-expert-item';
import { useGetExpertStatus } from '../../hooks/use-get-expert-status';
import { IExpert } from '../../types/expert';
import { RemoveExpertAssistModal } from '../remove-expert-assist-modal';
import { StopExpertAssistModal } from '../stop-expert-assist-modal';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { TrashIcon } from '@root/shared/icons/trash-icon';
import { PencilIcon } from '@root/shared/icons/pencil-icon';
import { ShareAssistButton } from '@root/modules/experts/components/details/share-assist-button';

type ControlProps = {
  data: IExpert;
};

export const AssistControlButtons: FC<ControlProps> = ({ data }) => {
  const { t } = useTranslation('forex-experts');
  const { hideActions } = useGhostLogin();
  const { removeIsOpen, setRemoveIsOpen, stopIsOpen, setStopIsOpen, deleteMutation, stopRunMutation, mutationOptions, isLoading, runExpert, handleDelete, handleStopExpert, handleDeleteExpert, removeIsDisabled } = useExpertItem(data);
  const navigate = useNavigate();

  const { isEnabled, isPaused } = useGetExpertStatus(data);

  return (
    <div className='w-full flex items-center justify-center gap-x-4'>
      <InfoTooltip
        content={
          <Text size='sm' className='text-center'>
            {t('actions.copy')}
          </Text>
        }
        positions={['bottom', 'left', 'right', 'top']}
      >
        <Button outlined variant='success' className='cursor-pointer !px-3 text-success-400 hover:text-gray-100 disabled:opacity-50' onClick={() => copyToClipboard(data?.id)}>
          <CopyIcon className='flex-shrink-0' width={13} height={13} />
        </Button>
      </InfoTooltip>
      {isEnabled ? (
        <InfoTooltip
          content={
            <Text size='sm' className='text-center'>
              {t('actions.stop')}
            </Text>
          }
          positions={['bottom', 'left', 'right', 'top']}
        >
          <Button
            outlined
            variant='secondary'
            tint='200'
            className='cursor-pointer !px-3 text-secondary-200 hover:text-gray-100 disabled:opacity-50'
            onClick={handleStopExpert}
            disabled={hideActions}
            loading={stopRunMutation.isLoading}
          >
            <StopIcon className='flex-shrink-0' width={13} height={13} />
          </Button>
        </InfoTooltip>
      ) : (
        <InfoTooltip
          content={
            <Text size='sm' className='text-center'>
              {t('actions.run')}
            </Text>
          }
          positions={['bottom', 'left', 'right', 'top']}
        >
          <Button
            outlined
            variant='success'
            tint='400'
            className='cursor-pointer !px-3 text-success-400 hover:text-gray-100 disabled:opacity-50'
            onClick={() => runExpert(data)}
            disabled={hideActions || isPaused}
            loading={isLoading}
          >
            <PlayIcon className='flex-shrink-0' width={13} height={13} />
          </Button>
        </InfoTooltip>
      )}
      <InfoTooltip
        content={
          <Text size='sm' className='text-center'>
            {t('actions.deleteExpert')}
          </Text>
        }
        positions={['bottom', 'left', 'right', 'top']}
      >
        <Button outlined disabled={isEnabled || isPaused} variant='danger' tint='500' className='!px-3 text-danger-500 hover:text-gray-100' onClick={handleDeleteExpert}>
          <TrashIcon className='flex-shrink-0' width={13} height={13} />
        </Button>
      </InfoTooltip>
      <InfoTooltip
        content={
          <Text size='sm' className='text-center'>
            {t('actions.editExpert')}
          </Text>
        }
        positions={['bottom', 'left', 'right', 'top']}
      >
        <Button
          outlined
          disabled={false}
          className='!px-3 text-primary-400 hover:text-gray-100'
          onClick={() => navigate(PageLinks.updateExpert + `?id=${data?.id}`)}
        >
          <PencilIcon className='flex-shrink-0' width={13} height={13} />
        </Button>
      </InfoTooltip>
      {data && <ShareAssistButton bot={data} />}
      <RemoveExpertAssistModal removeIsOpen={removeIsOpen} setRemoveIsOpen={setRemoveIsOpen} onDelete={handleDelete} isLoading={deleteMutation.isLoading} removeIsDisabled={removeIsDisabled} />
      <StopExpertAssistModal
        stopIsOpen={stopIsOpen}
        setStopIsOpen={setStopIsOpen}
        onStop={() => {
          stopRunMutation.mutate(data.id, mutationOptions)
          setStopIsOpen(false)
        }}
        isLoading={stopRunMutation.isLoading}
      />
    </div>
  );
};
