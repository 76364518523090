import React, { FC, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExpertStatusContainer } from '@root/modules/experts/components/experts.ui';
import { ExpertStatus } from '@root/modules/experts/enums';
import { BriefCaseIcon } from '@root/shared/icons/briefcase';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { ThreeDotsIcon } from '@root/shared/icons/three-dots';
import { Button } from '@root/shared/ui/button/button';
import Link from '@root/shared/ui/common/static-locales-link';
import { ImageWithFallback } from '@root/shared/ui/image/image-with-fallback';
import { Table } from '@root/shared/ui/table';
import { Text } from '@root/shared/ui/typography';
import { MarketplaceActionsTooltip } from './marketplace-actions-tooltip';
import { MarketPlaceTradeIdeaItem } from '@root/modules/marketplace/types/marketplace-trade-idea';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useProviderSubscriptionState } from '@root/modules/marketplace/hooks/use-provider-subscription-state';
import { Link as RouterLink } from 'react-router-dom';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nb';
import 'dayjs/locale/pt';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';

type Props = {
    item: MarketPlaceTradeIdeaItem;
    subscriptionType: 'month' | 'year';
    setSelectedProvider: (item: MarketPlaceTradeIdeaItem) => void;
    handleRetryPayment: (item: MarketPlaceTradeIdeaItem) => void;
    retryPaymentLoadingId: string;
    handleConnectAssistClick: (item: MarketPlaceTradeIdeaItem) => void;
    ids: string[];
    handleSubscribeClick: (item: MarketPlaceTradeIdeaItem) => void;
    loading: boolean
}

export const MarketPlaceTradeIdeaPrice: FC<{
    item: MarketPlaceTradeIdeaItem;
    subscriptionType: 'month' | 'year';
}> = ({ item, subscriptionType }) => {
    const { t } = useTranslation('marketplace-trade-idea-providers');

    const price = useMemo(() => item.plans?.find((item) => item.recurringIntervalUnit === subscriptionType)?.price || 0, [item, subscriptionType]);

    if (item.market !== 'forex') {
        return <Text size='base'>N/A</Text>;
    }

    return <Text size='base'>{price > 0 ? t(subscriptionType === 'month' ? 'table.month' : 'table.year', { price }) : t('table.free')}</Text>;
};

export const ProvidersTableRow: FC<Props> = ({ item, ids, setSelectedProvider, subscriptionType, retryPaymentLoadingId, handleRetryPayment, handleConnectAssistClick, handleSubscribeClick, loading }) => {
    const { t } = useTranslation('marketplace-trade-idea-providers');
    const { hideActions } = useGhostLogin();

    const {
        isActive,
        isCancelled,
        isPaused,
    } = useProviderSubscriptionState(item);

    const handleSubscribeItem = useCallback((provider: MarketPlaceTradeIdeaItem) => {
        const selectedPlan = provider?.plans?.find((plan) => plan.recurringIntervalUnit === subscriptionType);  

        if (selectedPlan?.price === 0) {
            handleSubscribeClick(provider)
        } else {
            setSelectedProvider(item)
        }
    }, [handleSubscribeClick, setSelectedProvider, item, subscriptionType])

    return <Table.Row key={item.id} className='align-top border-t border-grayscale-600'>
        <Table.DataCell className='!pl-0'>
            <div className='flex flex-col gap-2'>
                <Link href={`/marketplace/provider/${item.id}`} passHref>
                    <div className='flex gap-2 cursor-pointer'>
                        <div className='shrink-0 -mb-2'>
                            <ImageWithFallback src={item.logo} alt='Provider logo' width={26} height={26} label={item.name} />
                        </div>
                        <div className='flex flex-col'>
                            <Text size='base' className='flex gap-1'>
                                {item.name}
                            </Text>
                            <ExpertStatusContainer status={ExpertStatus.Stopped}>
                                <Text size='sm' className='whitespace-nowrap'>
                                    {item.marketLabel}
                                </Text>
                            </ExpertStatusContainer>
                        </div>
                    </div>
                </Link>
            </div>
        </Table.DataCell>
        <Table.DataCell>
            <Text size='base'>{ids.includes(item.id as string) ? '-' : item.statistics.pnl || '-'}</Text>
        </Table.DataCell>
        {/* <Table.DataCell>
            <Text size='base'>{item.statistics.profitFactor || '-'}</Text>
        </Table.DataCell> */}
        <Table.DataCell>
            <Text size='base'>{item.statistics.avgTradeDuration || '-'}</Text>
        </Table.DataCell>
        <Table.DataCell>
            <MarketPlaceTradeIdeaPrice item={item} subscriptionType={subscriptionType} />
        </Table.DataCell>
        <Table.DataCell>
            <Text size='base'>{item.statistics.subscribersCount.toLocaleString()}</Text>
        </Table.DataCell>
        <Table.DataCell className='text-right !pr-0'>
            <div className='flex flex-col gap-2 items-end'>
                {(!isActive ? (
                    <>
                        {isCancelled && item.subscription?.expiresAt ? (
                            <>
                                <Text size='sm' className='flex justify-end items-center mb-4'>
                                    {t('table.stillActive', {
                                        date: dayjs(item.subscription.expiresAt).format('DD MMMM YYYY'),
                                    })}
                                </Text>
                                <Button
                                    className='!p-0 !font-bold text-right flex items-center'
                                    variant='info'
                                    link
                                    prefix={<BriefCaseIcon width={16} height={16} className='mb-1 text-primary-400' />}
                                    onClick={() => handleSubscribeItem(item)}
                                    disabled={hideActions || loading}
                                >
                                    <span className='text-primary-400'>{t('table.subscribe')}</span>
                                </Button>
                            </>
                        ) : isPaused ? (
                            <div>
                                <MarketplaceActionsTooltip
                                    content={
                                        <div className='flex flex-col gap-y-2 items-end'>
                                            <Button
                                                className='!p-0 !font-bold flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline whitespace-nowrap'
                                                link
                                                onClick={() => handleRetryPayment(item)}
                                                loading={retryPaymentLoadingId === item.id}
                                                disabled={hideActions}
                                            >
                                                {t('table.retryPayment')}
                                            </Button>
                                            <Button
                                                className='!p-0 !font-bold flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline'
                                                link
                                                onClick={() => setSelectedProvider(item)}
                                                disabled={hideActions}
                                            >
                                                {t('table.unsubscribe')}
                                            </Button>
                                        </div>
                                    }
                                >
                                    <Button variant='gray' tint='700' className='border-none !p-2 !px-3 !font-bold'>
                                        <ThreeDotsIcon />
                                    </Button>
                                </MarketplaceActionsTooltip>
                                <Text size='sm' className='text-right text-danger-500 font-medium'>
                                    <Trans i18nKey='marketplace-trade-idea-providers:table.unsuccessfulPayment' components={{ br: <br /> }} />
                                </Text>
                            </div>
                        ) : (
                            <Button
                                className='!p-0 !font-bold text-right flex items-center'
                                variant='info'
                                link
                                prefix={<BriefCaseIcon width={16} height={16} className='mb-1 text-primary-400' />}
                                onClick={() => handleSubscribeItem(item)}
                                disabled={hideActions || loading}
                            >
                                <span className='text-primary-400'>{t('table.subscribe')}</span>
                            </Button>
                        )}
                    </>
                ) : (
                    <MarketplaceActionsTooltip
                        showOnClick
                        content={
                            <Button
                                className='!p-0 !font-bold text-right flex items-center !text-gray-100 !hover:text-gray-100 !hover:no-underline'
                                link
                                onClick={() => setSelectedProvider(item)}
                                disabled={hideActions}
                            >
                                {t('table.unsubscribe')}
                            </Button>
                        }
                    >
                        <Button variant='gray' tint='700' className='border-none !p-2 !px-3 !font-bold'>
                            <ThreeDotsIcon />
                        </Button>
                    </MarketplaceActionsTooltip>
                ))}
                {isActive ? (
                    <Button
                        className='!p-0 !font-bold text-right !cursor-pointer !leading-5'
                        value='info'
                        link
                        prefix={<PlusIcon width={16} height={16} className='text-primary-400' />}
                        onClick={() => handleConnectAssistClick(item)}
                        disabled={hideActions}
                    >
                        <span className='text-primary-400'>{t('table.startAIAdvisor')}</span>
                    </Button>
                ) : (
                    <Button
                        className='!p-0 !font-bold text-right !leading-5 !cursor-not-allowed'
                        value='info'
                        link
                        prefix={<PlusIcon width={16} height={16} className='text-grayscale-600' />}
                        disabled={item.subscriptionStatus !== 'active' || hideActions}
                    >
                        <span className='text-grayscale-600'>{t('table.startAIAdvisor')}</span>
                    </Button>
                )}
            </div>
        </Table.DataCell>
    </Table.Row>
}

