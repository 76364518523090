import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PartialTakeProfits as PartialTakeProfitsFields } from '@root/modules/experts/components/partial-take-profits';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';

import { EditableSettingsTitle } from './editable-settings-title';
import { CreateExpertDtoMapper } from '@root/modules/experts/mappers/create-expert-dto.mapper';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const PartialTakeProfits: FC<Props> = ({ expert, editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { values } = useFormikContext<CreateExpertDto>();
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.PartialTakeProfits);

  const use = values.partialClose.use;
  const takeprofits = values.partialClose.takeprofits;
  const unitType = values.unitType;

  const hideDistance = useMemo(() => {
    const createExpertDto = CreateExpertDtoMapper.toDomain(expert);

    if (createExpertDto.partialClose?.calculationType === '1') {
      return true;
    }

    return false;
  }, [expert]);

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.partialTakeProfits.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.PartialTakeProfits}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <PartialTakeProfitsFields isPartialEdit />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('steps.partialTakeProfits.title')}
            </Text>
            <Text size='base' className='text-right'>
              {use ? t('on') : t('off')}
            </Text>
          </div>
          {
            use && <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.partialClose.calculationType.label')}
              </Text>
              <Text size='base' className='text-right'>
                {t(`fields.partialClose.calculationType.options.${values.partialClose.calculationType}`)}
              </Text>
            </div>
          }
          {use && takeprofits?.length
            ? takeprofits.map((profit, index) => (
              <div className='w-full' key={index}>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  #{index + 1}
                </Text>
                <div className='pl-4 w-full'>
                  {
                    !hideDistance && <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                      <Text size='base' className='text-gray-500 flex-shrink-0'>
                        {t('fields.partialClose.levels.amount.label')}
                      </Text>
                      <Text size='base' className='text-right'>
                        {profit?.amount} {unitType}
                      </Text>
                    </div>
                  }
                  <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.partialClose.levels.percent.label')}
                    </Text>
                    <Text size='base' className='text-right'>
                      {profit?.percent} %
                    </Text>
                  </div>
                </div>
              </div>
            ))
            : null}
        </div>
      )}
    </div>
  );
};
