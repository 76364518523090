import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { BrokersDtoMapper } from '@root/modules/accounts/mappers/brokers-dto.mapper';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { IGetBrokersServiceResponse } from '../types/servers-with-company';

export type GetSupportedBrokersServiceResponse = IHttpResponse<200, IGetBrokersServiceResponse> | IHttpResponse<400, string>;

export const getSupportedBroker = async (): Promise<GetSupportedBrokersServiceResponse> => {
  try {
    const response = await fetcher(`/brokers`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: BrokersDtoMapper.toDomain(response.data),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
