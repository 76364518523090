import axios from 'axios';

import { fetcherRequestInterceptor, fetcherResponseInterceptor } from './fetcher.interceptors';

export const fetcher = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
});

if (typeof document !== 'undefined') {
  fetcher.interceptors.request.use(fetcherRequestInterceptor.onFulfilled);
  fetcher.interceptors.response.use(undefined, fetcherResponseInterceptor.onRejected);
}
