import styled from 'styled-components';

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import infoImage from '@root/assets/images/cases/info.webp';
import { MarketPlaceTradeIdeaProvider } from '@root/modules/marketplace/types/marketplace-trade-idea-provider';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

export const SubscriptionPeriodButton = styled.div<{ isActive: boolean }>`
  padding: 10px 20px;
  border-radius: 13px;
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.primary[400] : theme.grayscale[500])};
  color: ${({ theme, isActive }) => (isActive ? theme.primary[400] : theme.gray[100])};
  cursor: pointer;
`;

type Props = ModalProps & {
  loading: boolean;
  provider?: MarketPlaceTradeIdeaProvider;
  onEditClick: () => void;
  onPeriodChange: (period: 'month' | 'year') => void;
  editLoading?: boolean;
  subscriptionType: 'month' | 'year';
};

export const SubscribeToCopyModal: FC<Props> = ({ isOpen, provider, loading, editLoading, subscriptionType, onCancel, onOk, onEditClick, onPeriodChange }) => {
  const { t } = useTranslation('copy-presets');

  const monthPrice = useMemo(() => {
    return provider?.plans?.find((plan) => plan.recurringIntervalUnit === 'month')?.price || 0;
  }, [provider]);

  const yearPrice = useMemo(() => {
    return provider?.plans?.find((plan) => plan.recurringIntervalUnit === 'year')?.price || 0;
  }, [provider]);

  return (
    <Modal onCancel={onCancel} footer={null} isOpen={isOpen}>
      <div className='flex flex-col items-center'>
        <Image src={infoImage} height={80} width={80} placeholder='blur' alt='Error' objectFit='contain' unoptimized={true} />
        <Title className='mt-8 mb-5 text-center font-bold' level={5}>
          {t('subscribeToStart.copyTitle')}
        </Title>
        <div className='flex justify-center items-center gap-x-4 mb-2'>
          <Trans
            i18nKey='copy-presets:subscribeToStart.tradeIdeaProvider'
            components={{ b: <Text className='text-grayscale-500' />, a: <Text size='xl' className='text-gray-400 font-normal'></Text> }}
            values={{ name: provider?.name }}
          />
        </div>
        <div className='flex justify-center items-center gap-x-4 mb-4'>
          <SubscriptionPeriodButton isActive={subscriptionType === 'month'} onClick={() => onPeriodChange('month')}>
            {t('subscribeToStart.period.month', { price: monthPrice })}
          </SubscriptionPeriodButton>
          <SubscriptionPeriodButton isActive={subscriptionType === 'year'} onClick={() => onPeriodChange('year')}>
            {t('subscribeToStart.period.year', { price: yearPrice })}
          </SubscriptionPeriodButton>
        </div>
        <Text size='sm' className='font-semibold text-gray-400 mb-10 text-center'>
          {t('subscribeToStart.text')}
        </Text>
        <div className='w-full flex items-center justify-between'>
          {/*<Button onClick={onEditClick} outlined loading={editLoading}>*/}
          {/*  {t('subscribeToStart.editAnyways')}*/}
          {/*</Button> */}
          <Button onClick={onCancel} outlined>
            {t('subscribeToStart.cancel')}
          </Button>
          <Button onClick={onOk} loading={loading}>
            {t('subscribeToStart.buySubscription')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
