import { MutationFunction } from 'react-query';
import { signOutAccountService, SignOutAccountServiceValues } from '../services/sign-out-account.service';

export type SignOutAccountMutationData = void;
export type SignOutAccountMutationValues = SignOutAccountServiceValues;
export type SignOutAccountMutationError = Error;

export const SIGN_OUT_ACCOUNT_MUTATION: MutationFunction<SignOutAccountMutationData, SignOutAccountMutationValues> = async (values) => {
  const result = await signOutAccountService(values);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }
};
