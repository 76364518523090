export const INSTAGRAM_LINK = 'https://www.instagram.com/sagemaster_/';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UC7zOZH8XXXzCk4eFjwHJeew';
export const TELEGRAM_LINK = 'https://t.me/sagemasterofficial';
export const KNOWLEDGEBASE_LINK = 'https://sagemaster.notion.site/111d10619e77802ba49edc211328b1b2?v=111d10619e778104a98a000c11680bfe';
export const STATISTIC_EXPLANATION_LINK = 'https://sagemaster.notion.site/Dashboard-Assist-Statistics-21ee06a7e75f49a29856af9fca0be5a6';

export const helpLinks = {
  submitFeature: 'https://voice.sagemaster.io/?b=654a3f387130c8704fc74f23 ',
  roadmap: 'https://voice.sagemaster.io/roadmap',
  knowbase: KNOWLEDGEBASE_LINK,
  link: 'https://www.notion.so/sagemaster/Knowledge-base-4ae6122fc4ba4d0a8c41c28e532cada8',
  telegram: 'https://t.me/sagemasterofficial ',
  youtube: 'https://youtube.com/@sagemaster2801',
  discord: 'https://discord.gg/uBQRmmUPXd',
};
