import clsx from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Layout } from '@root/infra/layout/containers';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useQuoteSubscriber } from '@root/modules/orders/hooks/use-quote-subscriber';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { BackButton } from '@root/shared/ui/back-button/back-button';
import { Text } from '@root/shared/ui/typography';

import { AssistControlButtons } from '../../components/details/assist-control-buttons';
import { SettingsAndStats } from '../../components/details/settings-and-stats';
import { TradesAndHistory } from '../../components/details/trades-and-history';
import { MenuItemWrapper, MenuWrapper } from '../../components/menu.ui';
import { useGetExpert } from '../../hooks/use-get-expert';
import { MenuItems } from '../../types/menu-items';
import { EditCopiedMasterExpert } from '../../containers/edit-copied-master-expert';
import { Alerts } from '../../components/details/alerts';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { checkIsPersonalWebhookAvailable } from '@root/shared/utils/helpers/check-is-personal-webhook-available';

export const ExpertsDetailsPage: FC = () => {
  const [activeTab, setActiveTab] = useState<MenuItems>(MenuItems.SettingsAndStats);
  const { t } = useTranslation('forex-experts');
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const { id } = useParams();
  const { isGhostLogin } = useGhostLogin();
  const { data } = useGetExpert(id);
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const isPersonalWebhookAvailable = checkIsPersonalWebhookAvailable(subscriptionInfo?.roles);

  useQuoteSubscriber(data?.accountId, data?.isEnabled);

  return (
    <Layout>
      <div className='w-full mt-20'>
        <MenuWrapper isGhostLogin={isGhostLogin}>
          <div
            className={clsx('w-full flex items-center justify-between overflow-x-auto whitespace-nowrap gap-x-5 px-4 my-2', {
              'lg:pl-[240px]': menuOpened,
              'lg:pl-[88px]': !menuOpened,
            })}
          >
            <div className='flex justify-start items-center gap-x-2'>
              <BackButton className='!px-0 !py-2' failureLink='/assists' />
              <Text className='font-semibold border-l border-gray-700 ml-4 pl-4'>{data?.name}</Text>
            </div>
            {data && (
              <div className='flex justify-end gap-x-3 items-center'>
                <AssistControlButtons data={data} />
              </div>
            )}
          </div>
          <div
            className={clsx('w-full flex items-center justify-between overflow-x-auto whitespace-nowrap gap-x-5 px-4', {
              'lg:pl-[240px]': menuOpened,
              'lg:pl-[88px]': !menuOpened,
            })}
          >
            <div className='flex h-full items-end gap-x-4 sm:gap-x-6 md:gap-x-12'>
              <MenuItemWrapper size='sm' active={activeTab === MenuItems.SettingsAndStats} onClick={() => setActiveTab(MenuItems.SettingsAndStats)}>
                {t('tabs.settings')}
              </MenuItemWrapper>
              <MenuItemWrapper size='sm' active={activeTab === MenuItems.TradesAndHistory} onClick={() => setActiveTab(MenuItems.TradesAndHistory)}>
                {t('tabs.tradesAndHistory')}
              </MenuItemWrapper>
              {isPersonalWebhookAvailable && (
                <MenuItemWrapper size='sm' active={activeTab === MenuItems.Alerts} onClick={() => setActiveTab(MenuItems.Alerts)}>
                  {t('tabs.alerts')}
                </MenuItemWrapper>
              )}
            </div>
          </div>
        </MenuWrapper>
        {activeTab === MenuItems.SettingsAndStats && data && <SettingsAndStats expert={data} />}
        {activeTab === MenuItems.TradesAndHistory && data && <TradesAndHistory expert={data} />}
        {activeTab === MenuItems.Alerts && isPersonalWebhookAvailable && data && <Alerts expert={data} />}
        <EditCopiedMasterExpert />
      </div>
    </Layout>
  );
};
