import debounce from 'lodash/debounce';

import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PropsValue } from 'react-select';

import { selectQuantityOptions } from '@root/shared/constants/items-per-page-options';
import { OptionType, Select } from '@root/shared/ui/form';
import { SearchInput } from '@root/shared/ui/form/search-input/search-input';
import { Paginate } from '@root/shared/ui/pagination';
import { Switch, SwitchItem } from '@root/shared/ui/switch';
import { Table } from '@root/shared/ui/table';
import { Title } from '@root/shared/ui/typography';

import { MarketplaceTradeIdeaProvidersTable } from '../components/marketplace-trade-idea-providers-table';
import { useMarketplaceTradeIdeaList } from '../hooks/use-marketplace-trade-idea-list';

export const MarketplaceTradeIdeaProviders: FC = () => {
  const { t } = useTranslation('marketplace-trade-idea-providers');
  const [{ isLoading, list, controlParams, pageCount, subscriptionType }, { changeControlParam, handleSubscriptionTypeChange }] = useMarketplaceTradeIdeaList();

  const quantityValue = selectQuantityOptions.find((option) => option.value === controlParams.itemsPerPage);

  return (
    <Table.Container className=''>
      <div className='flex flex-wrap items-center justify-between md:grid grid-cols-4 w-full px-5 pt-5 pb-4 gap-4'>
        <Title level={6}>{t('tradeIdeaProviders')}</Title>
        <div className='w-full sm:w-auto flex justify-end items-center col-start-4 flex-wrap sm:flex-nowrap gap-2'>
          <div className='w-full sm:w-auto flex justify-end items-center min-w-[270px]'>
            <SearchInput onChange={debounce((e: ChangeEvent<HTMLInputElement>) => changeControlParam({ search: e.target.value }), 500)} className='sm:pl-12' />
          </div>

          <div className='flex justify-end items-center'>
            <Switch>
              <SwitchItem active={subscriptionType === 'month'} onClick={() => handleSubscriptionTypeChange('month')}>
                {t('monthly')}
              </SwitchItem>
              <SwitchItem active={subscriptionType === 'year'} onClick={() => handleSubscriptionTypeChange('year')}>
                {t('yearly')}
              </SwitchItem>
            </Switch>
          </div>
        </div>
      </div>

      <MarketplaceTradeIdeaProvidersTable list={list} isLoading={isLoading} subscriptionType={subscriptionType} />

      <div className='flex flex-wrap justify-center items-center my-5 px-5 pb-5 relative'>
        <Paginate
          pageCount={pageCount || 1}
          forcePage={0}
          marginPagesDisplayed={1}
          disableInitialCallback
          onPageChange={(pageData) => changeControlParam({ page: pageData.selected + 1 })}
        />
        <div className='w-20 ml-auto md:absolute right-5'>
          <Select
            value={quantityValue}
            onChange={(option: PropsValue<OptionType>) => changeControlParam({ itemsPerPage: (option as OptionType).value as number })}
            options={selectQuantityOptions}
            isSearchable={false}
            bordered
            tiny
            square
          />
        </div>
      </div>
    </Table.Container>
  );
};
