import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accounts } from '@root/modules/experts/components/accounts';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateAccountStep } from '@root/modules/experts/hooks/use-create-account-step';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { HidePasswordIcon } from '@root/shared/icons/hide-password-icon';
import { ShowPasswordIcon } from '@root/shared/icons/show-password-icon';
import { Text } from '@root/shared/ui/typography';
import { EditableSettingsTitle } from './editable-settings-title';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { InfoIcon } from '@root/shared/icons/info-icon';

type Props = {
  expert: IExpert;
};

export const AccountAndPairs: FC<Props> = () => {
  const [isAllPairsVisible, setAllPairsVisible] = useState<boolean>(false);
  const { t } = useTranslation('forex-experts');
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.Account);
  const { values } = useFormikContext<CreateExpertDto>();

  const visiblePairs = values.symbols?.slice(0, 3);
  const hiddenPairs = values.symbols?.slice(3);

  const { data: accounts } = useGetAccounts();
  const [{ account, accountOptions }] = useCreateAccountStep(values.account);

  const accountName = accountOptions?.find((option) => option.value === account)?.label;

  const selectedAccount = accounts?.find((item) => item.id === values.account);
  const hasDDLimit = selectedAccount?.hitMaxDrawDownLimitUntil && new Date(selectedAccount?.hitMaxDrawDownLimitUntil).getTime() > new Date().getTime();

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.account.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.Account}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <Accounts isPartialEdit={true} />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <div className='flex flex-wrap justify-between items-center gap-4 mb-2'>
            <Text size='sm'>{t('fields.account.label')}</Text>
            <div className="flex justify-end items-center gap-2">
              <Text size='sm' className='text-right'>
                {accountName}
              </Text>
              {
                hasDDLimit && <InfoTooltip inline content={<Text size='sm'>{t('statuses.ddLimitHint')}</Text>} positions={['bottom', 'right', 'top', 'left']}>
                  <InfoIcon className='text-secondary-200 inline-block' width={16} height={16} />
                </InfoTooltip>
              }
            </div>
          </div>
          <Text size='sm' className='mb-1'>
            {t('fields.pairs.label')}
          </Text>
          <div className='flex flex-wrap justify-start items-center gap-2'>
            {(isAllPairsVisible ? values.symbols : visiblePairs)?.map((symbol) => (
              <div className='flex justify-start items-center gap-x-4 rounded-[100px] bg-gray-100 bg-opacity-10 px-3 py-1' key={symbol}>
                <Text size='sm' className='font-medium'>
                  {symbol}
                </Text>
              </div>
            ))}
            {!!hiddenPairs?.length && !isAllPairsVisible && (
              <div className='flex justify-start items-center gap-x-4 rounded-[100px] bg-gray-100 bg-opacity-10 px-3 py-1'>
                <Text size='sm' className='font-medium'>
                  +{hiddenPairs.length}
                </Text>
              </div>
            )}
            {!!hiddenPairs?.length &&
              (isAllPairsVisible ? (
                <div className='flex justify-between items-center gap-x-2 rounded cursor-pointer text-primary-400' onClick={() => setAllPairsVisible(false)}>
                  <HidePasswordIcon width={16} height={16} />
                  <Text size='sm'>{t('common:hideAllPairs')}</Text>
                </div>
              ) : (
                <div className='flex justify-between items-center gap-x-2 rounded cursor-pointer text-primary-400' onClick={() => setAllPairsVisible(true)}>
                  <ShowPasswordIcon width={16} height={16} />
                  <Text size='sm'>{t('common:showAllPairs')}</Text>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
