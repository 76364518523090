import styled, { DefaultTheme, StyledComponentProps } from 'styled-components';

import { FC, ReactNode } from 'react';

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid ${({ theme }) => theme.gray['500']};
  box-sizing: border-box;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    left: -2px;
    bottom: -2px;
    border: 2px solid ${({ theme }) => theme.gray['500']};
    background-color: ${({ theme }) => theme.gray['500'] + '80'};
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 21px;

  ${Input}:checked + ${Slider} {
    border-color: ${({ theme }) => theme.primary['400']};
  }

  ${Input}:checked + ${Slider}:before {
    transform: translateX(21px);
    border-color: ${({ theme }) => theme.primary['400']};
    background-color: ${({ theme }) => theme.primary['400'] + '80'};
  }

  ${Input}:disabled + ${Slider} {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
`;

const Title = styled.span`
  margin-right: 0.5rem;
`;

export type SwitchProps = StyledComponentProps<'input', DefaultTheme, any, any> & {
  label?: ReactNode;
};

export const Switch: FC<SwitchProps> = ({ label, ...inputProps }) => {
  return (
    <Label>
      <Title>{label}</Title>
      <Wrapper>
        <Input type='checkbox' {...inputProps} />
        <Slider />
      </Wrapper>
    </Label>
  );
};
