import clsx from 'clsx';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import tw from 'tailwind-styled-components';

export interface TagProps extends PropsWithChildren {
  suffix?: ReactNode;
  button?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onSuffixClick?: () => void;
  className?: string;
}

export const Tag: FC<TagProps> = ({ children, className: cls, onClick, onSuffixClick, suffix, button, disabled }) => {
  const className = clsx('flex items-center text-[15px] rounded-sm m-0.5 bg-[#ffffff17] disabled:opacity-50', cls);

  const content = (
    <Fragment>
      <span className={clsx('py-3', { 'p-4': !suffix, 'pl-4': suffix })}>{children}</span>
      {!!suffix && !button && (
        <button type='button' className='ml-2 text-xs pr-5 py-3' disabled={disabled} onClick={onSuffixClick}>
          {suffix}
        </button>
      )}
      {!!suffix && button && (
        <span className='ml-2 text-xs pr-5 py-3' onClick={onSuffixClick}>
          {suffix}
        </span>
      )}
    </Fragment>
  );

  if (button) {
    return (
      <button type='button' className={className} disabled={disabled} onClick={onClick}>
        {content}
      </button>
    );
  }

  return (
    <div className={className} onClick={onClick}>
      {content}
    </div>
  );
};

export const TagsWrapper = tw.div`flex flex-wrap -m-0.5 mb-1`;
