import styled from 'styled-components';

const InfoContainer = styled.div`
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 0 40px;
    padding-left: ${({ theme }) => `${theme.spacing(10)}px`};
  }
`;

const Banner = styled.div`
  height: 120px;
  width: 100%;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 200px;
  }
`;

const ProviderImage = styled.div`
  width: 140px;
  height: 140px;
  border: ${({ theme }) => `8px solid ${theme.gray[900]}`};
  position: relative;
  z-index: 1;
  background: ${({ theme }) => theme.gray[900]};
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-top: -50px;

  @media screen and (min-width: 1024px) {
    width: 200px;
    height: 200px;
    margin: 0;
    margin-top: -100px;
  }
`;

export const ProviderInfoUI = { Banner, InfoContainer, ProviderImage };
