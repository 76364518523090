import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';

export type PurchaseTradeIdeaProviderResponse = IHttpResponse<200, any> | IHttpResponse<400, string>;

export const purchaseTradeIdeaProviderSubscription = async ({ providerId, planId, customRedirectionPath }: { providerId: string; planId: string, customRedirectionPath?: string }): Promise<PurchaseTradeIdeaProviderResponse> => {
  try {
    const response = await fetcher.post(`/trade-ideas/providers/${providerId}/plans/${planId}/purchase`, {
      sourceApp: 'forex',
      customRedirectionPath: customRedirectionPath || undefined
    });

    if (response.data.purchaseType === 'common-purchase') {
      return {
        status: 200,
        payload: response.data.data,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
