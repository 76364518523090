import React, { FC } from 'react';

import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Button, ButtonProps } from '@root/shared/ui/button/button';

export const ResponsiveAddButton: FC<ButtonProps> = (props) => {
  return (
    <Button outlined className='flex items-center gap-3 mt-[26px]' {...props}>
      {props.prefix || <PlusIcon className='block sm:hidden' height={24} />}
      <span className=' hidden sm:block'>{props.children}</span>
    </Button>
  );
};
