import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const YoutubeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 12' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M15.8406 2.79922C15.8406 2.79922 15.6844 1.69609 15.2031 1.21172C14.5938 0.574219 13.9125 0.571094 13.6 0.533594C11.3625 0.371094 8.00313 0.371094 8.00313 0.371094H7.99687C7.99687 0.371094 4.6375 0.371094 2.4 0.533594C2.0875 0.571094 1.40625 0.574219 0.796875 1.21172C0.315625 1.69609 0.1625 2.79922 0.1625 2.79922C0.1625 2.79922 0 4.09609 0 5.38984L0 6.60234C0 7.89609 0.159375 9.19297 0.159375 9.19297C0.159375 9.19297 0.315625 10.2961 0.79375 10.7805C1.40313 11.418 2.20313 11.3961 2.55938 11.4648C3.84063 11.5867 8 11.6242 8 11.6242C8 11.6242 11.3625 11.618 13.6 11.4586C13.9125 11.4211 14.5937 11.418 15.2031 10.7805C15.6844 10.2961 15.8406 9.19297 15.8406 9.19297C15.8406 9.19297 16 7.89922 16 6.60234L16 5.38984C16 4.09609 15.8406 2.79922 15.8406 2.79922ZM6.34688 8.07422V3.57734L10.6687 5.83359L6.34688 8.07422Z' />
    </svg>
  );
};
