import { authSlice } from '@root/shared-files/modules/auth/store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SHOULD_REFRESH_TOKEN } from '@root/shared-files/modules/shared/constants/local-storage-keys';

export const useRefreshSession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage?.getItem(SHOULD_REFRESH_TOKEN)) {
      const timeout = setTimeout(() => {
        dispatch(authSlice.actions.refreshSession());
        localStorage.removeItem(SHOULD_REFRESH_TOKEN);
        localStorage.removeItem('isUpgradedSubscription');
        clearTimeout(timeout);
      }, 2000);
    }
  }, [dispatch]);
};
