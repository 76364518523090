import { useTranslation } from 'react-i18next';
import { Text } from '@root/shared/ui/typography';
import { FC, ReactNode, useCallback, useState } from 'react';
import { InfoTooltip } from '../info-tooltip';

type Props = {
  onCopy: () => void;
  children?: ReactNode;
  className?: string;
  hoverText?: string;
};

export const CopyWrapper: FC<Props> = ({ children, onCopy, className, hoverText }) => {
  const { t } = useTranslation('common');
  const [isCopied, setCopied] = useState<boolean>(false);

  const handleCopy = useCallback(() => {
    onCopy();
    setCopied(true);

    const timeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeout);
    }, 3000);
  }, [onCopy]);

  return (
    <InfoTooltip
      content={
        <Text className='w-full text-center' size='sm'>
          {(isCopied ? t('copied') : hoverText || t('clickToCopy')) as string}
        </Text>
      }
    >
      <div className={className} onClick={handleCopy}>
        {children}
      </div>
    </InfoTooltip>
  );
};
