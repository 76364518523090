import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const WarningIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.89429 1.95603C9.23143 1.76622 9.6118 1.6665 9.9987 1.6665C10.3856 1.6665 10.766 1.76622 11.1031 1.95603C11.4402 2.14584 11.7228 2.41934 11.9234 2.75014L11.9252 2.75306L18.8638 14.3365C19.0603 14.6769 19.1643 15.0628 19.1654 15.4559C19.1665 15.849 19.0646 16.2355 18.87 16.577C18.6753 16.9185 18.3946 17.2031 18.0559 17.4024C17.7171 17.6017 17.332 17.7089 16.939 17.7132L16.9322 17.7133L3.05843 17.7133C2.66538 17.7089 2.28031 17.6017 1.94154 17.4024C1.60276 17.2031 1.32208 16.9185 1.12743 16.577C0.932781 16.2355 0.830939 15.849 0.83204 15.4559C0.833141 15.0628 0.937145 14.6769 1.13371 14.3365L1.13863 14.328L8.07221 2.75306L8.5989 3.06855L8.07397 2.75014C8.27463 2.41934 8.55715 2.14584 8.89429 1.95603ZM9.1246 3.38568C9.12434 3.38611 9.12408 3.38653 9.12382 3.38696L2.19488 14.9543C2.10694 15.1081 2.06043 15.2821 2.05993 15.4593C2.05943 15.638 2.10572 15.8137 2.1942 15.9689C2.28268 16.1241 2.41026 16.2535 2.56425 16.3441C2.71738 16.4342 2.89131 16.4829 3.06892 16.4854H16.9285C17.1061 16.4829 17.28 16.4342 17.4331 16.3441C17.5871 16.2535 17.7147 16.1241 17.8032 15.9689C17.8917 15.8137 17.938 15.638 17.9375 15.4593C17.937 15.2821 17.8905 15.1081 17.8025 14.9543L10.8736 3.38696C10.8733 3.38653 10.8731 3.38611 10.8728 3.38568C10.7816 3.23589 10.6535 3.11204 10.5007 3.026C10.3475 2.93973 10.1746 2.8944 9.9987 2.8944C9.82283 2.8944 9.64994 2.93973 9.4967 3.026C9.34389 3.11204 9.21576 3.23589 9.1246 3.38568Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99676 6.66211C10.3358 6.66211 10.6107 6.93698 10.6107 7.27606V10.5504C10.6107 10.8895 10.3358 11.1644 9.99676 11.1644C9.65769 11.1644 9.38281 10.8895 9.38281 10.5504V7.27606C9.38281 6.93698 9.65769 6.66211 9.99676 6.66211Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.38281 13.8249C9.38281 13.4858 9.65769 13.2109 9.99676 13.2109H10.0049C10.344 13.2109 10.6189 13.4858 10.6189 13.8249C10.6189 14.164 10.344 14.4388 10.0049 14.4388H9.99676C9.65769 14.4388 9.38281 14.164 9.38281 13.8249Z'
        fill='currentColor'
      />
    </svg>
  );
};
