import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { PresetsDtoMapper } from '@root/modules/presets/mappers/preset-dto.mapper';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { IPreset } from '../types/preset';

export type GetPresetsServiceResponse = IHttpResponse<200, IPreset[]> | IHttpResponse<400, string>;

export const getPresetsService = async (): Promise<GetPresetsServiceResponse> => {
  try {
    const response = await fetcher(`/experts/presets`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data.map(PresetsDtoMapper.toDomain),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
