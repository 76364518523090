import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

export type SyncClosedOrdersServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;
export type SyncClosedOrdersServiceQuery = { userId: Id; accounts: Id };

export const syncClosedOrders = async ({ userId, accounts }: SyncClosedOrdersServiceQuery): Promise<SyncClosedOrdersServiceResponse> => {
  try {
    await fetcher.post(
      `/users/orders/closed/sync${accounts === 'all' ? '' : `?accounts=${accounts}`}`,
      {
        userId,
      },
      {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      },
    );

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
