import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { SelectField, TextField } from '@root/shared/form';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import clsx from 'clsx';
import { notionLinks } from '@root/infra/constants/links';
import { useFormikContext } from 'formik';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const SignalMoneyManagement: FC<{
  type: CreateExpertDto['signalMoneyManagement']['type'];
  isPartialEdit?: boolean;
}> = ({ type, isPartialEdit }) => {
  const { t } = useTranslation('forex-experts');
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const options = useCreateExpertFieldOptions();

  const onTypeChange = useCallback((option) => {
    const value = option.value;
    const basedOn = values.signalMoneyManagement.basedOn;
    setFieldValue('signalMoneyManagement.type', value);
    setFieldValue('maxDrawDownLimit.basedOn', basedOn);
  }, [setFieldValue, values.signalMoneyManagement.basedOn]);

  const onBasedOnChange = useCallback((option) => {
    const value = option.value;
    setFieldValue('signalMoneyManagement.basedOn', value);
    setFieldValue('maxDrawDownLimit.basedOn', value);
  }, [setFieldValue]);

  return (
    <div>
      {
        !isPartialEdit && <Title level={7} className='mb-4'>
          {t('steps.moneyManagement.title')}
          </Title>
      }

      <SelectField
        name='signalMoneyManagement.type'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.type.description')}</Text>}>
                <InfoTooltipIcon/>
              </InfoTooltip>
            }
          >
            {t('fields.signalMoneyManagement.type.label')}
          </IconLabel>
        }
        wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
        placeholder={t('fields.signalMoneyManagement.type.placeholder')}
        options={options.signalMoneyManagementType}
        onChange={onTypeChange}
      />
      {['0', '2'].includes(type) && (
        <TextField
          name='signalMoneyManagement.lotsize'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.lotsize.description')}</Text>}>
                  <InfoTooltipIcon/>
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.lotsize.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
          placeholder={t('fields.signalMoneyManagement.lotsize.placeholder')}
        />
      )}
      {['1'].includes(type) && (
        <TextField
          name='signalMoneyManagement.riskPercent'
          label={
            <IconLabel
              icon={
                <InfoTooltip
                  content={<Text size='sm'>{t('fields.signalMoneyManagement.riskPercent.description')}</Text>}>
                  <InfoTooltipIcon/>
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.riskPercent.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
          placeholder={t('fields.signalMoneyManagement.riskPercent.placeholder')}
          suffix="%"
        />
      )}
      {['1', '2', '3', '4'].includes(type) && (
        <SelectField
          name='signalMoneyManagement.basedOn'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.basedOn.description')}</Text>}>
                  <InfoTooltipIcon/>
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.basedOn.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
          placeholder={t('fields.signalMoneyManagement.basedOn.placeholder')}
          options={options.signalMoneyManagementBasedOn}
          onChange={onBasedOnChange}
        />
      )}
      {['2'].includes(type) && (
        <TextField
          name='signalMoneyManagement.k'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.k.description')}</Text>}>
                  <InfoTooltipIcon/>
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.k.label')}
            </IconLabel>
          }
          wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
          placeholder={t('fields.signalMoneyManagement.k.placeholder')}
        />
      )}
      {['3', '4'].includes(type) && (
        <TextField
          name='signalMoneyManagement.xPercent'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.xPercent.description')}</Text>}>
                  <InfoTooltipIcon/>
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.xPercent.label')}
            </IconLabel>
          }
          placeholder={t('fields.signalMoneyManagement.xPercent.placeholder')}
          wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
          suffix='%'
        />
      )}

      <TextField
        name='maxSignalActiveTrades'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>
                <Trans
                  i18nKey={'forex-templates:form.fields.manualMoneyManagement.maxActiveTrades.description'}
                />
              </Text>} keepOnMouseLeave>
                <InfoTooltipIcon/>
              </InfoTooltip>
            }
          >
            {t('fields.manualMoneyManagement.maxActiveTrades.label')}
          </IconLabel>
        }
        wrapperClassName={clsx({'mb-4': !isPartialEdit, 'mb-2': isPartialEdit})}
        placeholder={t('fields.manualMoneyManagement.maxActiveTrades.placeholder')}
      />
    </div>
  );
};
