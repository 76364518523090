import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '@root/shared/ui/back-button/back-button';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { Title } from '@root/shared/ui/typography';

export const CreateWizardExpertHeader: FC = () => {
  const { t } = useTranslation('forex-experts');

  return (
    <div className='flex items-center mb-4'>
      <div>
        <BackButton failureLink={PageLinks.experts} />
      </div>
      <div className='flex-1 md:-ml-[110px]'>
        <Title className='font-bold text-center text-sm lg:text-lg'>{t('create')}</Title>
      </div>
    </div>
  );
};
