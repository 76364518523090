import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import clsx from 'clsx';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const TrailingStop: FC<{
  use: CreateExpertDto['trailStop']['use'];
  unitType?: CreateExpertDto['unitType'];
  isTradeUpdate?: boolean;
}> = ({ use, unitType = TemplateUnitType.POINTS, isTradeUpdate }) => {
  const { t } = useTranslation('forex-experts');

  return (
    <div>
      <SwitchField
        name='trailStop.use'
        label={
          <IconLabel
            wrapperClassName='mb-0'
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.use.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            <Title level={7}>{t('fields.trailingStop.use.label')}</Title>
          </IconLabel>
        }
        wrapperClassName={isTradeUpdate ? '' : 'mb-4'}
      />
      {
        !isTradeUpdate && (
          <Text size='sm' className={clsx('text-gray-500', { 'mb-4': use })}>
            {t('fields.trailingStop.use.description')}
          </Text>
        )
      }
      {use && (
        <div className='grid sm:grid-cols-2 gap-4'>
          <TextField
            name='trailStop.afterX'
            type='number'
            disabled={!use}
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.fromX.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.trailingStop.fromX.label')}
              </IconLabel>
            }
            placeholder={t('fields.trailingStop.fromX.placeholder')}
            suffix={unitType}
            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
          />
          <TextField
            name='trailStop.followY'
            disabled={!use}
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.toY.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.trailingStop.toY.label')}
              </IconLabel>
            }
            placeholder={t('fields.trailingStop.toY.placeholder')}
            suffix={unitType}
            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
          />
        </div>
      )}
    </div>
  );
};
