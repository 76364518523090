import { all, call } from '@redux-saga/core/effects';

import { put, select, takeLatest } from 'redux-saga/effects';

import { emitSocketEvent } from '@root/infra/socket/socket-action-creator';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';

const { unsubscribe, subscribe, unsubscribeAll, setSymbolsSubscribed } = quotesSlice.actions;

function* handleUnSubscribe(symbol) {
  yield put(emitSocketEvent({ eventName: 'leave', params: symbol }));
  yield put(unsubscribe(symbol));
}

function* handleSubscribe(symbol) {
  yield put(emitSocketEvent({ eventName: 'join', params: symbol }));
}

function* handleSubscribeSymbols() {
  const symbolsToSubscribe = yield select(quotesSelector.symbolsToSubscribe);
  const symbolsSubscribed = yield select(quotesSelector.subscribedSymbols);
  const newSymbolsToSubscribe = symbolsToSubscribe.filter((symbol) => !symbolsSubscribed.includes(symbol));

  if (newSymbolsToSubscribe.length) {
    yield all(newSymbolsToSubscribe.map((symbol) => call(handleSubscribe, symbol)));
    yield put(setSymbolsSubscribed(newSymbolsToSubscribe));
  }
}

function* handleUnSubscribeFromAll() {
  const symbolsSubscribed = yield select(quotesSelector.subscribedSymbols);
  if (symbolsSubscribed.length) {
    yield all(symbolsSubscribed.map((symbol) => call(handleUnSubscribe, symbol)));
  }
}

export function* quotesSaga() {
  yield takeLatest(subscribe.type, handleSubscribeSymbols);
  yield takeLatest(unsubscribeAll.type, handleUnSubscribeFromAll);
}
