import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import warning from '@root/assets/images/cases/warning.webp';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

type Props = {
  stopIsOpen: boolean;
  isLoading: boolean;
  setStopIsOpen: (isOpen: boolean) => void;
  onStop: () => void;
};

export const StopExpertAssistModal: FC<Props> = ({ stopIsOpen, isLoading, setStopIsOpen, onStop }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'item' });

  return (
    <Modal
      isOpen={stopIsOpen}
      onCancel={() => setStopIsOpen(false)}
      footer={
        <div className='w-full flex justify-between items-center pt-6'>
          <Button outlined onClick={() => setStopIsOpen(false)}>
            {t('stop.confirmation.actions.cancel')}
          </Button>
          <Button onClick={onStop} loading={isLoading}>
            {t('stop.confirmation.actions.submit')}
          </Button>
        </div>
      }
    >
      <div className='text-center max-w-[588px] mx-auto py-4'>
        <img src={warning} alt='Confirmation icon' className='w-[61px] mx-auto' />
        <Title level={6} className='mb-2' bold>
          {t('stop.confirmation.title')}
        </Title>
        <Text>{t('stop.confirmation.description')}</Text>
      </div>
    </Modal>
  );
};
