import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCheckActiveBrokersQuota } from '@root/modules/accounts/hooks/use-check-active-brokers-quota';
import { useCheckActiveExpertsQuota } from '@root/modules/experts/hooks/use-check-active-experts-quota';
import { createExpertValidation } from '@root/modules/experts/validations/common.validation';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { checkExpertPresetIsUsed } from '../helpers/check-expert-preset-used';
import { makeExpertFormValues } from '../helpers/make-expert-form-values';
import { CreateExpertDtoMapper } from '../mappers/create-expert-dto.mapper';
import { createExpertService } from '../services/create-expert.service';
import { expertsSelector } from '../store/experts.selector';
import { expertsSlice } from '../store/experts.slice';
import { IExpert } from '../types/expert';
import { CreateExpertStepKey } from './use-create-expert-steps';
import { useGetExpert } from './use-get-expert';

export const useCreateWizardExpert = (updatingId?: string, editable = true, sharedExpert?: IExpert) => {
  const { t } = useTranslation('forex-experts');
  const { t: yupT } = useTranslation('yup');
  const navigate = useNavigate();
  const { possibleActiveBrokersLeft } = useCheckActiveBrokersQuota();
  const { possibleActiveExpertsLeft } = useCheckActiveExpertsQuota();

  const dispatch = useDispatch();
  const copyPresetData = useSelector(expertsSelector.copyPresetData);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const cacheId = searchParams.get('cache');
  const accountId = searchParams.get('account');
  const providerId = searchParams.get('providerId');

  const expertId = updatingId ? updatingId : typeof id === 'string' ? id : undefined;

  const { data: updatingExpert } = useGetExpert(expertId || '', editable);

  const expert = useMemo(() => {
    if (!editable && sharedExpert) {
      return sharedExpert;
    }

    return updatingExpert;
  }, [editable, sharedExpert, updatingExpert]);

  const initialValues = useMemo<CreateExpertDto>(() => {
    if (cacheId) {
      const stringCache = localStorage.getItem(cacheId);
      localStorage.removeItem(cacheId);

      if (stringCache) {
        const cache = JSON.parse(stringCache);

        return {
          ...cache,
          account: accountId || cache.account,
        };
      }
    }

    if (copyPresetData) {
      const data = {
        ...copyPresetData.preset,
        id: expert?.id,
        accountId: copyPresetData.formData.account || '',
        expertPresetId: expert?.expertPresetId || null,
        allowClone: false,
        allowShare: false,
      };

      const step = !copyPresetData.formData.account ? CreateExpertStepKey.Account : CreateExpertStepKey.Preview;

      return {
        ...CreateExpertDtoMapper.toDomain(data as Omit<IExpert, 'isEnabled'>),
        meta: {
          step,
          shouldPresetValidate: true,
        },
      };
    }

    if (!expertId) {
      return makeExpertFormValues(providerId as string);
    }

    if (expert) {
      return CreateExpertDtoMapper.toDomain(expert);
    }
  }, [accountId, cacheId, expertId, expert, copyPresetData, providerId]);

  const onSubmit = useCallback(
    async (values: CreateExpertDto, helpers: FormikHelpers<CreateExpertDto>) => {
      const expertPresetId = checkExpertPresetIsUsed(values, copyPresetData?.preset);

      const result = await createExpertService({ ...values, expertPresetId });

      if (result.status === 200) {
        helpers.setStatus({ type: 'success', message: 'Expert successfully created' } as IFormStatus);
        notify({
          type: 'success',
          text: values.id ? t('notifications.expertEdited') : t('notifications.expertCreated'),
        });

        if (possibleActiveExpertsLeft && possibleActiveBrokersLeft && !expert?.isEnabled) {
          dispatch(
            expertsSlice.actions.runExpertOpened({
              id: result.payload.id,
              symbols: result.payload.symbols,
            }),
          );
        } else {
          setTimeout(() => navigate(PageLinks.experts));
        }

        dispatch(expertsSlice.actions.setCopyPresetData(null));
        helpers.setStatus({
          type: 'success',
          message: values.id ? t('notifications.expertEdited') : t('notifications.expertCreated'),
        } as IFormStatus);
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({ type: 'danger', text: result.payload });
      }
    },
    [dispatch, copyPresetData?.preset, possibleActiveExpertsLeft, navigate, possibleActiveBrokersLeft, expert?.isEnabled, t],
  );

  useEffect(() => {
    return () => {
      dispatch(expertsSlice.actions.setCopyPresetData(null));
    };
  }, [dispatch]);

  return { initialValues, onSubmit, validationSchema: createExpertValidation(t, yupT) };
};
