import { MutationFunction } from 'react-query';
import { stopExpertService } from '../services/stop-expert.service';

export type StopExpertMutationData = void;
export type StopExpertMutationValues = string;
export type StopExpertMutationError = Error;

export const STOP_EXPERT_MUTATION: MutationFunction<StopExpertMutationData, StopExpertMutationValues> = async (expertId: string) => {
  const result = await stopExpertService(expertId);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }
};
