import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IOrder } from '@root/modules/orders/types/orders';

export type IOrdersState = {
  orders: [];
  shouldListenOrders: boolean;
};

export namespace OrdersActions {
  export type Subscribe = PayloadAction;
  export type UnSubscribe = PayloadAction;
  export type SetOrdersData = PayloadAction<IOrder[]>;
}

export type OrdersSliceReducer = {
  subscribe: CaseReducer<IOrdersState, OrdersActions.Subscribe>;
  unsubscribe: CaseReducer<IOrdersState, OrdersActions.UnSubscribe>;
  setOrdersData: CaseReducer<IOrdersState, OrdersActions.SetOrdersData>;
};

const initialState: IOrdersState = {
  orders: [],
  shouldListenOrders: false,
};

export const ordersSlice = createSlice<IOrdersState, OrdersSliceReducer, 'orders'>({
  name: 'orders',
  initialState,
  reducers: {
    subscribe: (state, action) => {
      state.shouldListenOrders = true;
    },
    unsubscribe: (state, action) => {
      state.shouldListenOrders = false;
    },
    setOrdersData: (state, action) => {
      state.orders = [];
    },
  },
});
