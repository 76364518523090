import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbols } from '@root/modules/accounts/hooks/use-get-symbols';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { runExpertService } from '@root/modules/experts/services/run-expert.service';
import { stopExpertService } from '@root/modules/experts/services/stop-expert.service';
import { IExpert } from '@root/modules/experts/types/expert';
import { getCorrectExpertsSymbols } from '@root/modules/experts/utils';

export const useCheckRunningExpert = () => {
  const userId = useSelector(authSelector.userId);
  const experts = useGetExperts();
  const { data: accounts } = useGetAccounts();
  const { data: supportedSymbols } = useGetSymbols();

  const getRunningExpertsByTemplateId = useCallback(
    (templateId: string): IExpert[] | void => {
      return experts.data?.filter((expert) => expert?.expertPresetId === templateId && expert?.isEnabled);
    },
    [experts.data],
  );

  const getRunningExpertsByAccountId = useCallback(
    (accountId: string): IExpert[] | void => {
      return experts.data?.filter((expert) => expert?.isEnabled && expert.accountId === accountId);
    },
    [experts.data],
  );

  const runStoppedExperts = useCallback(
    async (ids: string[], freshAccounts: IAccount[] | void): Promise<void> => {
      try {
        const accountId = experts.data?.find((expert) => expert?.id === ids[0])?.accountId;
        const account = (freshAccounts || accounts)?.find((item) => item.id === accountId);

        const params = ids.map((id) => ({
          userId: userId?.toString() || '',
          expertId: id,
          symbols: getCorrectExpertsSymbols(account, experts.data?.find((expert) => expert.id === id) as IExpert, supportedSymbols as string[]),
        }));

        if (params?.length) {
          await Promise.all(params.map((item) => runExpertService({ id: item.expertId, symbols: item.symbols })));
        }
      } catch (e) {
        console.log('stop running expert error', e);
      } finally {
        experts.refetch();
      }
    },
    [experts, accounts, userId, supportedSymbols],
  );

  const stopRunningExperts = useCallback(
    async (entity: 'account' | 'template', id: string): Promise<void> => {
      try {
        const ids = experts.data?.filter((expert) => expert?.isEnabled && expert[entity]?._id === id)?.map((expert) => expert.id);
        if (ids?.length) {
          await Promise.all(ids.map((id) => stopExpertService(id)));
        }
      } catch (e) {
        console.log('stop running expert error', e);
      } finally {
        entity === 'account' && experts.refetch();
      }
    },
    [experts, userId],
  );

  return {
    getRunningExpertsByTemplateId,
    getRunningExpertsByAccountId,
    runStoppedExperts,
    stopRunningExperts,
  };
};
