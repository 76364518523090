import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const ChartPieIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.14217 1.71532C5.29661 2.04955 5.15086 2.4457 4.81662 2.60014C3.92815 3.01067 3.1536 3.63232 2.56049 4.41087C1.96739 5.18943 1.57375 6.10125 1.4139 7.06685C1.25405 8.03244 1.33284 9.02247 1.64339 9.95063C1.95395 10.8788 2.48685 11.7169 3.19562 12.3918C3.90439 13.0668 4.76751 13.5581 5.70973 13.823C6.65195 14.0878 7.64465 14.1181 8.60128 13.9113C9.55791 13.7045 10.4494 13.2668 11.1981 12.6363C11.9467 12.0059 12.5298 11.2019 12.8964 10.2944C13.0344 9.95306 13.4229 9.78813 13.7643 9.92606C14.1057 10.064 14.2706 10.4525 14.1327 10.7939C13.6846 11.903 12.9719 12.8857 12.0569 13.6562C11.1419 14.4267 10.0523 14.9617 8.88305 15.2145C7.71383 15.4673 6.50053 15.4302 5.34893 15.1065C4.19733 14.7828 3.1424 14.1823 2.27612 13.3574C1.40985 12.5325 0.758529 11.5081 0.37896 10.3737C-0.000608683 9.23929 -0.0969036 8.02925 0.0984722 6.84908C0.293848 5.66891 0.774962 4.55446 1.49986 3.60289C2.22477 2.65132 3.17144 1.89153 4.25735 1.38977C4.59159 1.23533 4.98774 1.38108 5.14217 1.71532Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.33337 1.71378C7.33337 0.825605 8.07846 -0.0243187 9.09593 0.129458C9.68192 0.218023 10.2564 0.377499 10.8064 0.605328C11.6961 0.973863 12.5045 1.51403 13.1855 2.195C13.8665 2.87596 14.4066 3.68438 14.7752 4.5741C15.003 5.12413 15.1625 5.69857 15.251 6.28456C15.4048 7.30202 14.5549 8.04711 13.6667 8.04711L9.00004 8.04711C8.07957 8.04711 7.33337 7.30092 7.33337 6.38044V1.71378ZM8.75368 1.49542C8.70575 1.54116 8.66671 1.61835 8.66671 1.71378V6.38044C8.66671 6.56454 8.81595 6.71378 9.00004 6.71378L13.6667 6.71378C13.7621 6.71378 13.8393 6.67473 13.8851 6.62681C13.9264 6.58351 13.9408 6.53741 13.9327 6.48381C13.8602 6.00436 13.7297 5.53437 13.5433 5.08434C13.2418 4.35639 12.7998 3.69496 12.2427 3.1378C11.6855 2.58065 11.0241 2.1387 10.2961 1.83717C9.84612 1.65076 9.37612 1.52028 8.89667 1.44782C8.84308 1.43972 8.79698 1.45409 8.75368 1.49542Z'
        fill='currentColor'
      />
    </svg>
  );
};
