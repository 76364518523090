import { diff as deepDiff } from 'deep-object-diff';

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

import {
  BreakEvenDiff,
  ManualMoneyManagementDiff,
  ManualSlTpDiff,
  PartialCloseDiff,
  PlainDiffValue,
  SignalMoneyManagementDiff,
  SignalSlTpDiff,
  AdvancedDiff,
  SymbolsDiff,
  TrailStopDiff,
  DrawdownDiff,
  SessionManagementDiff,
  ConditionalClosureDiff,
  OrderTypeDiff,
  OrderDirectionDiff
} from './sync-diff-preset-elements';
import { SyncExpertUI } from './sync-preset-ui';

const { Table } = SyncExpertUI;

export const SyncDifferencesPresetModal = ({ closeModal, handleSubmit, expert: expertValues, preset: presetValues }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'syncExpert' });
  const { t: tForm } = useTranslation('forex-experts', { keyPrefix: 'fields' });
  const { t: tExperts } = useTranslation('forex-experts');

  const { traders } = useProviderTraders();

  const diff = deepDiff(expertValues, presetValues);
  const isExpertEqualToPreset = !Object.keys(diff).length;

  console.log('diff', diff)

  if (!expertValues || !presetValues) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onCancel={closeModal}
      footer={null}
      title={
        <div className='flex flex-col items-start w-full gap-x-6 mt-2'>
          <Title level={6} className='font-bold text-center mb-2'>
            {t('title')}
          </Title>
          <Text className='font-normal text-grayscale-200'>{t('description')}</Text>
        </div>
      }
      className='!max-w-[1000px]'
    >
      <div>
        {isExpertEqualToPreset ? (
          <Text className='font-semibold py-10 text-center'>{t('no-diff')}</Text>
        ) : (
          <Fragment>
            <Text className='font-semibold'>{t('differences.title')}</Text>

            <div className='flex items-center gap-x-1 mt-2 mb-1'>
              <Text size='sm' className='w-1/2 text-grayscale-500'>
                {t('differences.current')}
              </Text>
              <Text size='sm' className='w-1/2 text-grayscale-500'>
                {t('differences.preset')}
              </Text>
            </div>
            <Table>
              {diff['name'] && <PlainDiffValue label={tForm('name.text')} expertValue={expertValues.name} presetValue={presetValues.name} />}
              {diff['description'] && <PlainDiffValue label={tForm('description.text')} expertValue={expertValues.description} presetValue={presetValues.description} />}
              {diff['providerId'] && (
                <PlainDiffValue
                  label={tForm('providerId.text')}
                  expertValue={traders.find((trader) => trader.id === expertValues.providerId)?.name}
                  presetValue={traders.find((trader) => trader.id === presetValues.providerId)?.name}
                />
              )}
              {diff['symbols'] && <SymbolsDiff expert={expertValues} preset={presetValues} />}
              {diff['usePips'] && (
                <PlainDiffValue
                  label={tForm('usePips.label')}
                  expertValue={expertValues.usePips ? tExperts('On') : tExperts('Off')}
                  presetValue={presetValues.usePips ? tExperts('On') : tExperts('Off')}
                />
              )}
              {diff['conditionalClosure'] && <ConditionalClosureDiff expert={expertValues} preset={presetValues} />}
              {diff['orderType'] && <OrderTypeDiff expert={expertValues} preset={presetValues} />}
              {diff['orderDirection'] && <OrderDirectionDiff expert={expertValues} preset={presetValues} />}
              {/* TODO - add check what type was used */}
              {diff['manualMoneyManagement'] && <ManualMoneyManagementDiff expert={expertValues} preset={presetValues} />}
              {diff['signalMoneyManagement'] && <SignalMoneyManagementDiff expert={expertValues} preset={presetValues} />}
              {diff['maxDrawDownLimit'] && <DrawdownDiff expert={expertValues} preset={presetValues} />}
              {diff['sessionManagement'] && <SessionManagementDiff expert={expertValues} preset={presetValues} />}


              {diff['maxManualActiveTrades'] && (
                <PlainDiffValue
                  label={tForm('manualMoneyManagement.maxActiveTrades.shortLabel')}
                  expertValue={expertValues.maxManualActiveTrades}
                  presetValue={presetValues.maxManualActiveTrades}
                />
              )}
              {diff['maxSignalActiveTrades'] && (
                <PlainDiffValue
                  label={tForm('manualMoneyManagement.maxActiveTrades.shortLabel')}
                  expertValue={expertValues.maxSignalActiveTrades}
                  presetValue={presetValues.maxSignalActiveTrades}
                />
              )}
              {diff['manualSlTp'] && <ManualSlTpDiff expert={expertValues} preset={presetValues} />}
              {diff['signalSlTp'] && <SignalSlTpDiff expert={expertValues} preset={presetValues} />}
              {diff['trailStop'] && <TrailStopDiff expert={expertValues} preset={presetValues} />}
              {diff['partialClose'] && <PartialCloseDiff expert={expertValues} preset={presetValues} />}
              {diff['breakEven'] && <BreakEvenDiff expert={expertValues} preset={presetValues} />}
              {(diff['strategy'] || diff['useSlippage'] || diff['slippage']) && <AdvancedDiff expert={expertValues} preset={presetValues} />}
            </Table>
          </Fragment>
        )}

        <div className='flex items-center justify-between gap-4 mt-6'>
          <Button outlined onClick={closeModal}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleSubmit} disabled={isExpertEqualToPreset}>
            {t('actions.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
