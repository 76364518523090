import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

import { IAccount } from '../types/account';

export type GetAccountServiceResponse = IHttpResponse<200, IAccount> | IHttpResponse<400, string>;
export type GetAccountServiceQuery = { userId: Id; accountId: string };

export const getAccountService = async ({ userId, accountId }: GetAccountServiceQuery): Promise<GetAccountServiceResponse> => {
  try {
    const response = await fetcher(`/accounts/${accountId}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
