import { FC, SVGProps } from 'react';

export const GridIcon: FC<SVGProps<SVGSVGElement> & { outlined: boolean }> = (props) => {
    if (props.outlined) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 2.5C1.875 2.15482 2.15482 1.875 2.5 1.875H8.33333C8.67851 1.875 8.95833 2.15482 8.95833 2.5V8.33333C8.95833 8.67851 8.67851 8.95833 8.33333 8.95833H2.5C2.15482 8.95833 1.875 8.67851 1.875 8.33333V2.5ZM3.125 3.125V7.70833H7.70833V3.125H3.125Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0415 2.5C11.0415 2.15482 11.3213 1.875 11.6665 1.875H17.4998C17.845 1.875 18.1248 2.15482 18.1248 2.5V8.33333C18.1248 8.67851 17.845 8.95833 17.4998 8.95833H11.6665C11.3213 8.95833 11.0415 8.67851 11.0415 8.33333V2.5ZM12.2915 3.125V7.70833H16.8748V3.125H12.2915Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 11.667C1.875 11.3218 2.15482 11.042 2.5 11.042H8.33333C8.67851 11.042 8.95833 11.3218 8.95833 11.667V17.5003C8.95833 17.8455 8.67851 18.1253 8.33333 18.1253H2.5C2.15482 18.1253 1.875 17.8455 1.875 17.5003V11.667ZM3.125 12.292V16.8753H7.70833V12.292H3.125Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0415 11.667C11.0415 11.3218 11.3213 11.042 11.6665 11.042H17.4998C17.845 11.042 18.1248 11.3218 18.1248 11.667V17.5003C18.1248 17.8455 17.845 18.1253 17.4998 18.1253H11.6665C11.3213 18.1253 11.0415 17.8455 11.0415 17.5003V11.667ZM12.2915 12.292V16.8753H16.8748V12.292H12.2915Z" fill="currentColor" />
        </svg>;
    }

    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 0.875C1.15482 0.875 0.875 1.15482 0.875 1.5V7.33333C0.875 7.67851 1.15482 7.95833 1.5 7.95833H7.33333C7.67851 7.95833 7.95833 7.67851 7.95833 7.33333V1.5C7.95833 1.15482 7.67851 0.875 7.33333 0.875H1.5Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6667 0.875C10.3216 0.875 10.0417 1.15482 10.0417 1.5V7.33333C10.0417 7.67851 10.3216 7.95833 10.6667 7.95833H16.5001C16.8453 7.95833 17.1251 7.67851 17.1251 7.33333V1.5C17.1251 1.15482 16.8453 0.875 16.5001 0.875H10.6667Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 10.042C1.15482 10.042 0.875 10.3218 0.875 10.667V16.5003C0.875 16.8455 1.15482 17.1253 1.5 17.1253H7.33333C7.67851 17.1253 7.95833 16.8455 7.95833 16.5003V10.667C7.95833 10.3218 7.67851 10.042 7.33333 10.042H1.5Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6667 10.042C10.3216 10.042 10.0417 10.3218 10.0417 10.667V16.5003C10.0417 16.8455 10.3216 17.1253 10.6667 17.1253H16.5001C16.8453 17.1253 17.1251 16.8455 17.1251 16.5003V10.667C17.1251 10.3218 16.8453 10.042 16.5001 10.042H10.6667Z" fill="currentColor" />
    </svg>;
}



