import { groupBy } from 'lodash';

import clsx from 'clsx';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { settingsSelector } from '@root/modules/app-settings/store/settings.selector';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';
import { getGainLostColor } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export type BalanceWidgetProps = {
  wrapperClassName?: string;
};

const loader = <LoadingIcon width={18} height={18} />;

export const BalanceWidget: FC<BalanceWidgetProps> = ({ wrapperClassName }) => {
  const isBalanceVisible = useSelector(settingsSelector.isBalanceVisible);
  const canViewBalance = useSelector(settingsSelector.canViewBalance);
  const { t } = useTranslation('common');
  const { data } = useSelector(accountsSelector.balance);
  const { data: accounts } = useGetAccounts();

  const balanceData = Object.values(groupBy(accounts?.filter((item) => item.isSignedIn && data?.[item.id]), 'currency')).map((accounts) => {
    const totalBalance = accounts.reduce((acc, curr) => acc + (data[curr.id]?.balance || 0), 0);
    const totalProfit = accounts.reduce((acc, curr) => acc + (data[curr.id]?.profit || 0), 0);
    const totalEquityPercent = (totalProfit * 100) / totalBalance;
    return { currencySymbol: getSymbolFromCurrency(accounts[0].currency as string) || accounts[0].currency, value: totalBalance, profitPercent: totalEquityPercent, precision: 4 };
  });

  const loading = false;

  if (!balanceData.length) return null;

  return (
    <div
      className={clsx('bg-white bg-opacity-5 py-4 lg:py-1.5 px-4 lg:px-2 rounded text-base lg:text-xs flex items-center gap-4 justify-between lg:justify-start', wrapperClassName)}
    >
      <div className='flex items-center justify-between gap-4'>
        {balanceData?.map((balance) => (
          <div key={balance.currencySymbol} className='leading-4'>
            <p className='flex items-center gap-x-2'>
              {balance.currencySymbol} {isBalanceVisible && canViewBalance ? (loading ? loader : globalRound(balance.value || 0, balance.precision)) : '******'}
            </p>
            <p className={`${getGainLostColor(balance.profitPercent)}  flex items-center gap-x-2`}>
              <span className='block min-w-[40px]'>{isBalanceVisible && canViewBalance ? (loading ? loader : globalRound(balance.profitPercent || 0, 2)) + '%' : '******'}</span>
              <InfoTooltip content={<Text size='sm'>{t('header.widget.tooltip')}</Text>} positions={['bottom']}>
                <div className='flex items-center gap-x-1 text-grayscale-100'>
                  <InfoTooltipIcon width={8} height={8} className='flex-shrink-0' />
                </div>
              </InfoTooltip>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
