import { FC } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '@root/shared/ui/typography';
import { ExclamationPointIcon } from '@root/shared/icons/exclamation-point-icon';

const Wrapper = styled.div`
    background: ${({ theme }) => theme.gradient[5]};
    height: 60px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
`;

export const GhostLoginBanner: FC = () => {
    return <Wrapper className='flex flex-col justify-center items-center'>
        <Text className='font-normal text-center mx-auto text-xs md:text-sm lg:text-base'>
            <Trans i18nKey='common:ghostModeText' components={{ icon: <ExclamationPointIcon className='inline-block' height={20} width={20} />, br: <br />, b: <span className='uppercase font-bold' /> }} />
        </Text>
    </Wrapper>;
}

