import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IQuote } from '@root/modules/quotes/types';

export type IQuotesState = {
  quotesData: Record<string, Record<string, IQuote>>;
  symbolsToSubscribe: string[];
  symbolsSubscribed: string[];
};

export namespace QuotesActions {
  export type Subscribe = PayloadAction<string[]>;
  export type UnSubscribe = PayloadAction<string>;
  export type UnsubscribeAll = PayloadAction;
  export type SetQuotesData = PayloadAction<IQuote[]>;
  export type SetSymbolsSubscribed = PayloadAction<string[]>;
}

export type QuotesSliceReducer = {
  subscribe: CaseReducer<IQuotesState, QuotesActions.Subscribe>;
  unsubscribe: CaseReducer<IQuotesState, QuotesActions.UnSubscribe>;
  unsubscribeAll: CaseReducer<IQuotesState, QuotesActions.UnsubscribeAll>;
  setQuotesData: CaseReducer<IQuotesState, QuotesActions.SetQuotesData>;
  setSymbolsSubscribed: CaseReducer<IQuotesState, QuotesActions.SetSymbolsSubscribed>;
};

const initialState: IQuotesState = {
  quotesData: {},
  symbolsToSubscribe: [],
  symbolsSubscribed: [],
};

export const quotesSlice = createSlice<IQuotesState, QuotesSliceReducer, 'quotes'>({
  name: 'quotes',
  initialState,
  reducers: {
    subscribe: (state, action) => {
      state.symbolsToSubscribe = [...new Set([...state.symbolsToSubscribe, ...action.payload])];
    },
    setSymbolsSubscribed: (state, action) => {
      state.symbolsSubscribed = [...new Set([...state.symbolsSubscribed, ...action.payload])];
      state.symbolsToSubscribe = state.symbolsToSubscribe.filter((symbol) => !action.payload.includes(symbol));
    },
    unsubscribe: (state, action) => {
      state.symbolsSubscribed = state.symbolsSubscribed.filter((symbol) => symbol !== action.payload);
    },
    unsubscribeAll: () => {},
    setQuotesData: (state, action) => {
      state.quotesData = action.payload.reduce((acc, item) => {
        acc[item.brokerId] = {
          ...acc[item.brokerId],
          [item.symbol]: item,
        };
        return acc;
      }, {});
    },
  },
});
