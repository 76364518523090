import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { PartialCloseDto } from '../dtos/partical-close.dto';

export type ModifyPartialCloseServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const modifyPartialCloseService = async ({ accountId, expertId, lots, ticket, symbol, isMagic }: PartialCloseDto): Promise<ModifyPartialCloseServiceResponse> => {
  try {
    const data = {
      ticket,
      lots,
      symbol,
    };

    if (isMagic) {
      await fetcher.delete(`/accounts/${accountId}/magic`, { data, baseURL: import.meta.env.VITE_FX_CORE_HOST });
    } else {
      await fetcher.delete(`/experts/${expertId}/orders/instant`, {
        data,
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      });
    }
    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
