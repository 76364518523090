import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { ColoredValue } from '@root/shared/ui/colored-value';
import { Text } from '@root/shared/ui/typography';

type Props = {
  today: { profit: number; pips: number };
  total: { profit: number; pips: number };
  currency: string;
  isLoading: boolean;
};

export const ProfitInfo: FC<Props> = ({ isLoading, today, total, currency }) => {
  const { t } = useTranslation('forex-experts');

  return (
    <div className='py-6 px-5 bg-gray-800 flex flex-wrap gap-y-2'>
      <div className=''>
        <Text className='mb-1 text-grayscale-500'>{t('profit.todaysProfit')}</Text>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <div className='flex justify-start items-end gap-x-4'>
            <ColoredValue className='font-semibold whitespace-nowrap text-base' value={today.profit} currencyPrefix={currency} />
          </div>
        )}
      </div>
      <div className='w-px h-[52px] bg-color-divider mx-6' />
      <div className=''>
        <Text className='mb-1 text-grayscale-500'>{t('profit.lifetimeProfit')}</Text>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <div className='flex justify-start items-end gap-x-4 text-sm'>
            <ColoredValue className='font-semibold whitespace-nowrap text-base' value={total?.profit || 0} currencyPrefix={currency} />
          </div>
        )}
      </div>
    </div>
  );
};
