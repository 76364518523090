import { QueryFunction } from 'react-query';

import { GetUsersPerformanceChartDataServiceQuery, getUsersPerformanceChartData } from '@root/modules/orders/services/get-users-performance-chart-data.service';
import { ChartItem } from '@root/modules/orders/types/chart';

export type GetUsersPerformanceChartDataQueryKey = ['fx', GetUsersPerformanceChartDataServiceQuery, 'performance-chart-data'];
export type GetUsersPerformanceChartDataData = ChartItem[];

export const GET_USER_PERFORMANCE_CHART_DATA: QueryFunction<GetUsersPerformanceChartDataData, GetUsersPerformanceChartDataQueryKey> = async ({ queryKey }) => {
  const [, params] = queryKey;

  if (!params?.userId) {
    throw new Error('Invalid query');
  }

  const result = await getUsersPerformanceChartData(params);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
