import {
  GetMarketplaceTradeIdeaProviderTradersQuery,
  getMarketplaceTradeIdeaProviderTradersService,
  MarketplaceTradeIdeaProviderTradersResponseData,
} from '@root/modules/marketplace/services/get-marketplace-trade-idea-provider-traders.service';
import { QueryFunction } from 'react-query';

export type GetMarketplaceTradeIdeaProviderTradersQueryKey = ['marketplace-trade-idea-provider-traders', GetMarketplaceTradeIdeaProviderTradersQuery];
export type GetMarketplaceTradeIdeaProviderTradersData = MarketplaceTradeIdeaProviderTradersResponseData;

export const GET_MARKETPLACE_TRADE_IDEA_PROVIDER_TRADERS: QueryFunction<GetMarketplaceTradeIdeaProviderTradersData, GetMarketplaceTradeIdeaProviderTradersQueryKey> = async ({
  queryKey,
}) => {
  const [, data] = queryKey;
  const result = await getMarketplaceTradeIdeaProviderTradersService(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
