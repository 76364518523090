import { useCallback, useMemo, useState } from 'react';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSubscribedProviders } from '@root/modules/marketplace/hooks/use-get-subscribed-providers';
import { Id } from '@root/shared/utils/types';

import { GetExpertEventsQuery } from '../services/get-expert-events.service';
import { useGetExpertEvents } from './use-get-expert-events';

export type ExpertEventsParams = Omit<GetExpertEventsQuery, 'expertId' | 'userId'>;

const initialData: ExpertEventsParams = {
  page: 1,
  itemsPerPage: 10,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export const useExpertEvents = (expertId: string, userId: Id) => {
  const [controlParams, setControlParam] = useState<ExpertEventsParams>(initialData);

  const { data, isFetching } = useGetExpertEvents({ expertId, userId, ...controlParams });

  const accounts = useGetAccounts();
  const providers = useGetSubscribedProviders();

  const list = useMemo(() => {
    try {
      return (data?.data || []).map((item) => {
        if (item.code === 'EXPERT_CONFIGURATION_UPDATED') {
          let message = item.message;

          if (item.metaData.accountId) {
            const accountBefore = accounts.data.find((account) => account.id === item.metaData.accountId?.before);
            const accountAfter = accounts.data.find((account) => account.id === item.metaData.accountId?.after);

            if (accountAfter && accountBefore) {
              message = message
                .replace(accountBefore?.id, `${accountBefore?.id} (${accountBefore?.username} ${accountBefore?.company} - ${accountBefore?.mtType})`)
                .replace(accountAfter?.id, `${accountAfter?.id} (${accountAfter?.username} ${accountAfter?.company} - ${accountAfter?.mtType})`);
            }
          }

          if (item.metaData.providerId) {
            const providerBefore = providers?.data?.data.find((provider) => provider.id === item.metaData.providerId?.before);
            const providerAfter = providers.data?.data.find((provider) => provider.id === item.metaData.providerId?.after);

            if (providerBefore && providerAfter) {
              message = message
                .replace(providerBefore?.id, `${providerBefore?.id} (${providerBefore?.name})`)
                .replace(providerAfter?.id, `${providerAfter?.id} (${providerAfter?.name})`);
            }
          }

          return { ...item, message };
        }

        return item;
      });
    } catch {
      return data?.data || [];
    }
  }, [providers.data?.data?.length, accounts.data?.length, data?.data]);

  const changeControlParam = useCallback(
    (params: Partial<ExpertEventsParams>) => {
      setControlParam((values) => ({ ...values, ...params }));
    },
    [setControlParam],
  );

  const state = {
    list,
    controlParams,
    isLoading: isFetching,
    pageCount: data?.pagination.pageCount,
  };

  const handlers = { changeControlParam };

  return [state, handlers] as [typeof state, typeof handlers];
};
