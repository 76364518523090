import round from 'lodash/round';

// we used it to change round values in one place

export const globalRound = (number: number, precision: number) => {
  return round(number, precision);
};

export const roundUntilZero = (value: number, precision: number) => {
  if (typeof value !== 'number') {
    return value;
  }

  const result = round(value, precision);
  if (value !== 0 && result === 0) {
    let rounded = result;
    let morePrecision = precision;
    while (!rounded) {
      rounded = round(value, morePrecision);
      morePrecision += 1;
    }
    return rounded;
  } else {
    return result;
  }
};

export const globalUsdRound = (number: number) => {
  return round(number, 2);
};

export const globalCryptoRound = (number: number) => {
  return round(number, 5);
};
