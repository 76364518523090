import { IAccountBalance } from '@root/modules/accounts/types/balance';
import { MutationFunction } from 'react-query';
import { signInAccountService, SignInAccountServiceValues } from '../services/sign-in-account.service';

export type SignInAccountMutationData = Record<string, IAccountBalance>;
export type SignInAccountMutationValues = SignInAccountServiceValues;
export type SignInAccountMutationError = Error;

export const SIGN_IN_ACCOUNT_MUTATION: MutationFunction<SignInAccountMutationData, SignInAccountMutationValues> = async (values) => {
  const result = await signInAccountService(values);

  if (result.status === 200) {
    return result.payload;
  }

  throw new Error(result.payload);
};
