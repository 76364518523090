import { Layout } from '@root/infra/layout/containers';
import { ProviderContainer } from '@root/modules/marketplace/containers/provider-container';
import { useParams } from 'react-router';

const MarketplaceTradeIdeaProviderPage = () => {
  const { id } = useParams();

  return (
    <Layout>
      <ProviderContainer id={id || ''} />
    </Layout>
  );
};

export default MarketplaceTradeIdeaProviderPage;
