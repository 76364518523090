import { CreateAccountDto } from '../dtos/create-account.dto';

export class CreateAccountDtoMapper {
  public static toDomain() {
    throw new Error('Not Implemented');
  }

  public static toPersistence({ userId, username, server, company, ...values }: CreateAccountDto) {
    return { ...values, user: Number(username), brokerServerId: server, isServiceAccountAssigned: !!values.serviceAccountId };
  }
}
