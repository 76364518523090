import { Layout } from '@root/infra/layout/containers';
import { MarketplaceBanner } from '@root/modules/marketplace/components/marketplace-banner';
import { MarketplaceTradeIdeaProviders } from '@root/modules/marketplace/containers/marketplace-trade-idea-providers';

const MarketplacePage = () => {
  return (
    <Layout>
      <div className='mb-6'>
        <MarketplaceBanner />
      </div>
      <MarketplaceTradeIdeaProviders />
    </Layout>
  );
};

export default MarketplacePage;
