import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';

export const useCheckActiveExpertsQuota = () => {
  const maxEACount = useSelector(authSelector.maxEACount);

  const { data: experts } = useGetExperts();

  const possibleActiveExpertsLeft = useMemo(() => {
    if (maxEACount && experts) {
      return maxEACount - experts.filter((item) => item.isEnabled).length;
    }
    return 0;
  }, [maxEACount, experts]);

  return { possibleActiveExpertsLeft };
};
