import dayjs from 'dayjs';

import clsx from 'clsx';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSyncExpert } from '@root/modules/experts/hooks/use-sync-expert';
import { IExpert } from '@root/modules/experts/types/expert';
import { RefreshIcon } from '@root/shared/icons/refresh-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

import { SyncDifferencesPresetModal } from './sync-diff-preset-modal';

export const SyncPreset: FC<{ expert: IExpert }> = ({ expert }) => {
  const { t } = useTranslation('forex-experts');
  const { preset, hideActions, openModal, closeModal, isModalOpen, expertValues, presetValues, isSyncAvailable, submitSync } = useSyncExpert(expert);

  if (!expert.expertPresetId) {
    return null;
  }

  return (
    <div>
      <div className='flex items-center gap-x-1 justify-end'>
        <Text size='sm' className='text-[#B8B8B8] flex items-center'>
          <Trans i18nKey={'forex-experts:actions.presetName'} components={{ b: <b className='text-white ml-1'></b> }} values={{ preset: preset?.name }} />
          {isSyncAvailable && (
            <div>
              <div className='w-1.5 h-1.5 bg-[#FF6F41] rounded-full ml-0.5 -mt-2'></div>
            </div>
          )}
        </Text>
        <InfoTooltip content={<Text size='sm'>{t('syncExpert', { keyPrefix: 'actions' })}</Text>}>
          <button
            type='button'
            className={clsx('rounded bg-transparent flex justify-center items-center', { 'opacity-40': !isSyncAvailable })}
            onClick={openModal}
            disabled={hideActions || !isSyncAvailable}
          >
            <RefreshIcon width={16} height={16} />
          </button>
        </InfoTooltip>
      </div>
      {expert?.lastPresetSyncDate && (
        <Text size='sm' className='text-gray-600 text-[10px]'>
          {t('lastSync', { keyPrefix: 'actions', date: dayjs(expert.lastPresetSyncDate).format('ddd MM YYYY hh:mm A') })}
        </Text>
      )}

      {isModalOpen && <SyncDifferencesPresetModal closeModal={closeModal} handleSubmit={submitSync} expert={expertValues} preset={presetValues} />}
    </div>
  );
};
