import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { SelectField, TextField } from '@root/shared/form';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

export const ManualStopLossTakeProfit: FC<{
  type: CreateExpertDto['manualSlTp']['type'];
  unitType?: CreateExpertDto['unitType'];
  isPartialEdit?: boolean;
  showDescription?: boolean
}> = ({ type, unitType = TemplateUnitType.POINTS, showDescription = true }) => {
  const { t } = useTranslation('forex-experts');

  const options = useCreateExpertFieldOptions();

  return (
    <div>
      {showDescription && (
        <>
          <Title level={7} className='mb-4'>
            {t('steps.stopLossTakeProfit.title')}
          </Title>
          <Text size='sm' className='mb-4 text-gray-500'>
            {t('fields.manualStopLossTakeProfit.use.description')}
          </Text>
        </>
      )}

      <SelectField
        name='manualSlTp.type'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.manualStopLossTakeProfit.type.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t('fields.manualStopLossTakeProfit.type.label')}
          </IconLabel>
        }
        wrapperClassName='mb-4'
        placeholder={t('fields.manualStopLossTakeProfit.type.placeholder')}
        options={options.manualStopLossTakeProfitType}
      />
      <div className='grid sm:grid-cols-2 gap-4'>
        <TextField
          name='manualSlTp.fixedSl'
          type='number'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.manualStopLossTakeProfit.fixedSl.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualStopLossTakeProfit.fixedSl.label')}
            </IconLabel>
          }
          placeholder={t('fields.manualStopLossTakeProfit.fixedSl.placeholder')}
          suffix={unitType}
          suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
        />
        {['0'].includes(type) && (
          <TextField
            name='manualSlTp.fixedTp'
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.manualStopLossTakeProfit.fixedTp.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.manualStopLossTakeProfit.fixedTp.label')}
              </IconLabel>
            }
            placeholder={t('fields.manualStopLossTakeProfit.fixedTp.placeholder')}
            suffix={unitType}
            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
          />
        )}
        {['1'].includes(type) && (
          <TextField
            name='manualSlTp.tpRatio'
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.manualStopLossTakeProfit.tpRatio.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.manualStopLossTakeProfit.tpRatio.label')}
              </IconLabel>
            }
            placeholder={t('fields.manualStopLossTakeProfit.tpRatio.placeholder')}
          />
        )}
      </div>
    </div>
  );
};
