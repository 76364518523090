import de from 'apexcharts/dist/locales/de.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';
import fr from 'apexcharts/dist/locales/fr.json';
import it from 'apexcharts/dist/locales/it.json';
import no from 'apexcharts/dist/locales/nb.json';
import pt from 'apexcharts/dist/locales/pt.json';
import th from 'apexcharts/dist/locales/th.json';
import zh from 'apexcharts/dist/locales/zh-cn.json';

const ja = {
  name: 'ja',
  options: {
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    days: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    shortDays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    toolbar: {
      exportToSVG: 'SVG をダウンロード',
      exportToPNG: 'PNGをダウンロード',
      exportToCSV: 'CSVをダウンロード',
      menu: 'メニュー',
      selection: '選択',
      selectionZoom: '選択ズーム',
      zoomIn: 'ズームイン',
      zoomOut: 'ズームアウトする',
      pan: 'パンニング',
      reset: 'ズームをリセット',
    },
  },
};

export const chartLocales = [en, fr, es, it, de, zh, pt, no, th, ja];
