import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const FeedbackIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1224 8.22023C11.0704 7.6181 10.5489 7.16314 9.94215 7.19675C9.91583 7.19821 9.88946 7.19839 9.86312 7.1973C9.30461 7.17412 8.80865 7.55167 8.68231 8.0962C8.58036 8.53558 8.14152 8.80913 7.70213 8.70719C7.26274 8.60524 6.98919 8.1664 7.09114 7.72701C7.392 6.43034 8.56437 5.52738 9.89135 5.5639C11.3867 5.50219 12.6615 6.64767 12.7548 8.14621C12.7558 8.16311 12.7563 8.18004 12.7563 8.19697C12.7563 9.30818 11.9445 9.98547 11.4789 10.364C11.4142 10.4166 11.3551 10.4641 11.3006 10.5078C11.1176 10.6547 10.988 10.7587 10.8828 10.8686C10.8376 10.9159 10.8144 10.947 10.8033 10.9639C10.7808 11.3948 10.4243 11.7372 9.98777 11.7372C9.53671 11.7372 9.17105 11.3716 9.17105 10.9205C9.17105 10.3798 9.46625 9.98635 9.70268 9.73928C9.89017 9.54335 10.1316 9.35038 10.326 9.195C10.3696 9.16018 10.4108 9.12724 10.4485 9.09658C10.9432 8.69431 11.1125 8.46534 11.1224 8.22023ZM10.7945 10.9791C10.7945 10.9792 10.7947 10.9785 10.7954 10.9771C10.7949 10.9785 10.7945 10.9791 10.7945 10.9791Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.209 4.79103C12.5079 2.08998 8.19028 1.90175 5.26441 4.3575C2.33854 6.81325 1.77528 11.0981 3.96709 14.2266C4.0986 14.4143 4.14519 14.6486 4.09547 14.8724L3.80059 16.1994L5.12761 15.9045C5.35135 15.8548 5.58569 15.9014 5.7734 16.0329C8.90188 18.2247 13.1868 17.6615 15.6425 14.7356C18.0982 11.8097 17.91 7.49209 15.209 4.79103ZM4.21429 3.10635C7.78895 0.106064 13.064 0.33603 16.364 3.63602C19.664 6.93602 19.8939 12.2111 16.8936 15.7857C13.9722 19.2664 8.93208 20.0103 5.1405 17.5749L2.90209 18.0723C2.6295 18.1329 2.34487 18.05 2.14742 17.8526C1.94997 17.6551 1.86708 17.3705 1.92766 17.0979L2.42506 14.8595C-0.0103345 11.0679 0.733625 6.02776 4.21429 3.10635Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.03078 13.6816C9.02697 13.1452 9.46205 12.7188 9.98772 12.7188C10.5178 12.7188 10.9466 13.1484 10.9466 13.6766C10.9466 14.2058 10.5178 14.6345 9.98866 14.6345C9.46114 14.6345 9.03345 14.2085 9.03078 13.6816Z'
        fill='currentColor'
      />
    </svg>
  );
};
