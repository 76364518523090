import { FC, useCallback, useEffect, useMemo } from 'react';
import { useGetAccountsOptions } from '@root/modules/accounts/hooks/use-get-accounts-options';
import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { Select } from '@root/shared/ui/form';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { PREFERRED_BROKER_KEY } from '@root/shared-files/modules/shared/constants/local-storage-keys';

type Props = {
  tiny?: boolean;
};

export const BrokerAccountSelect: FC<Props> = ({ tiny }) => {
  const { t } = useTranslation('dashboard');
  const { selectedBroker, selectBroker, mtType, setMtType } = useGetSelectedBroker();
  const [initialBroker, setInitialBroker] = useLocalStorage(PREFERRED_BROKER_KEY, selectedBroker);

  const options = useGetAccountsOptions({ withAllOption: true, includeSignedOut: true, mtType });
  const selectedOption = useMemo(() => options?.find((option) => option.value === selectedBroker), [options, selectedBroker]);

  const mtTypeOptions = useMemo(() => {
    return [
      { value: undefined, label: t('filters.mtType.all') },
      { value: 'MT4', label: 'MT4' },
      { value: 'MT5', label: 'MT5' },
    ];
  }, [t]);

  const selectedMtTypeOption = mtTypeOptions?.find((option) => option.value === mtType);

  const handleBrokerChange = useCallback(
    (option) => {
      selectBroker(option.value);
      setInitialBroker(option.value);
    },
    [selectBroker, setInitialBroker],
  );

  useEffect(() => {
    const brokerOptions = options?.filter(option => option?.value !== 'all');
    if (brokerOptions?.length === 1) {
      handleBrokerChange(brokerOptions?.[0]);
    }
  }, [options, handleBrokerChange]);

  useEffect(() => {
    if (!selectedOption) {
      if (initialBroker && options?.some((option) => option.value === initialBroker)) {
        selectBroker(initialBroker);
      } else if (options && options?.length > 0) {
        selectBroker(options?.[0]?.value);
      }
    }
  }, [options, selectedOption, selectBroker, initialBroker]);

  return <div className="flex justify-end items-center gap-x-2">
    <Select tiny={tiny} onChange={(option) => setMtType(option.value)} options={mtTypeOptions || []} value={selectedMtTypeOption} alignMenuToRight />
    <Select tiny={tiny} onChange={handleBrokerChange} options={options || []} value={selectedOption} alignMenuToRight />
  </div>;
};
