import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useGetSharedExpert } from '@root/modules/experts/hooks/use-get-shared-expert';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';

export const SHARED_BOT_SUBSCRIPTION_ID_KEY = 'sharedBotSubscriptionId';
export const SHARED_BOT_CONTINUE_COPY_FLOW = 'sharedBotContinueCopyFlow';

export const useCheckSharedBotSubscription = () => {
  const [botId, setSharedBotId] = useLocalStorage<string>(SHARED_BOT_SUBSCRIPTION_ID_KEY, '');
  const [, setContinueCopyFlow] = useLocalStorage<boolean>(SHARED_BOT_CONTINUE_COPY_FLOW, false);
  const { data: expert, isLoading } = useGetSharedExpert(botId || '');

  const navigate = useNavigate();

  useEffect(() => {
    if (botId && !isLoading && expert) {
      navigate(`/share/${botId}`);
      setSharedBotId('');
      setContinueCopyFlow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, navigate, expert, botId, setSharedBotId]);
};
