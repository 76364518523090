import dayjs from 'dayjs';
import styled from 'styled-components';

import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '@root/assets/images/logo.webp';
import closedOrderBackground from '@root/assets/images/share-closed-order-bg.png';
import openOrderBackground from '@root/assets/images/share-open-order-bg.png';
import { ShareItemCard } from '@root/modules/orders/components/share-order-modal/share-item-card';
import { ShareTpSlCard } from '@root/modules/orders/components/share-order-modal/share-tp-sl-card';
import { TradeTime } from '@root/modules/orders/components/share-order-modal/trade-time';
import { useShareOrder } from '@root/modules/orders/hooks/use-share-order';
import { IOrder } from '@root/modules/orders/types/orders';
import { isBuyOrder, isSellOrder } from '@root/modules/orders/utils/orders';
import { ArrowGoUpAltIcon } from '@root/shared/icons/arrow-go-up-alt';
import { ExitPriceIcon } from '@root/shared/icons/exit-price-icon';
import { PositionSizeIcon } from '@root/shared/icons/position-size-icon';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { AmountWithCurrency } from '@root/shared/ui/money';
import { Toggler } from '@root/shared/ui/toggler/toggler';
import { Text } from '@root/shared/ui/typography';
import { Title } from '@root/shared/ui/typography/title';
import { globalRound } from '@root/shared/utils/number/round';

import { TabType } from '../../enums/orders';


type Props = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
  status: TabType;
  currency?: string;
};

const PreviewBuilderContainer = styled.div<{ isClosedOrder: boolean }>`
  background: ${({ isClosedOrder }) => `url(${isClosedOrder ? closedOrderBackground : openOrderBackground}) 50% / cover no-repeat`};
  display: none;
  position: absolute;
  aspect-ratio: 16/9;
  width: 575px;
  z-index: -999;
`;

const PreviewContainer = styled.div<{ preview?: string }>`
  background: ${({ preview }) => `url(${preview}) 50% / cover no-repeat`};
  aspect-ratio: 16/9;
  width: 100%;
`;

export const ShareOrderModal: FC<Props> = ({ isOpen, status, closeModal, order, currency }) => {
  const { t } = useTranslation('forex-orders');

  const [{ preview, elementRef, itemsToShow, tpLevels, slLevels }, { onDownload, updatePreview, onShowItemsChanged }] = useShareOrder(order);
  const openTime = useMemo(() => dayjs(order.openTime).format('DD.MM.YYYY h:mm'), [order.openTime]);
  const closeTime = useMemo(() => dayjs(order.closeTime).format('DD.MM.YYYY h:mm'), [order.closeTime]);
  const unitType = order.expertConfiguration?.usePips ? 'pips' : 'points';

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeModal}
      footer={null}
      title={
        <div className='flex w-full gap-x-6 mt-2 px-2 max-sm:pr-10'>
          <Title level={6} className='font-bold text-center'>
            {t('share.title')}
          </Title>
        </div>
      }
    >
      <div className='relative -ml-8'>
        <PreviewBuilderContainer ref={elementRef} className='rounded border border-gray-600 pt-5 pb-1 pl-8 pr-4 gap-2' isClosedOrder={status === TabType.HISTORY}>
          <div className='flex justify-between mb-4'>
            <Image className='h-fit' src={logo} height={20} width={120} alt='SageMaster Logo' unoptimized />
            <div className='flex'>
              <TradeTime label={t('share.openTime')} value={openTime} />
              {status === TabType.HISTORY && order.closeTime && <TradeTime label={t('share.closeTime')} value={closeTime} />}
            </div>
          </div>
          <div className='flex w-full gap-x-4 mb-2'>
            <Title level={6} className='text-center !leading-6'>
              {order.symbol}
            </Title>
            <Text
              size='sm'
              className={clsx('flex justify-center items-center bg-gray-700 px-3 rounded-full font-semibold', {
                'text-danger-500': isSellOrder(order.type),
                'text-success-400': isBuyOrder(order.type),
              })}
            >
              {order.type}
            </Text>
          </div>
          {itemsToShow?.pnl && (
            <div
              className={clsx('whitespace-nowrap font-bold mb-2', {
                'text-success-900': order.profit > 0,
                'text-danger-500': order.profit < 0,
                'text-gray-400': order.profit === 0,
              })}
            >
              <span className='text-3xl'>
                <AmountWithCurrency currency={currency} value={globalRound(order.profit, 2)} />
              </span>
              <span className='ml-1 text-2xl'>({Math.round(Math.abs(order.calculatedUnits))} {unitType})</span>
            </div>
          )}
          {(itemsToShow?.positionSize || itemsToShow?.price) && (
            <div className='flex gap-2 mb-2'>
              {itemsToShow?.positionSize && <ShareItemCard Icon={PositionSizeIcon} label={t('share.positionSize')} value={order.lots} />}
              {itemsToShow?.price && <ShareItemCard Icon={ArrowGoUpAltIcon} label={t('share.entryPrice')} value={globalRound(order.openPrice || 0, 6)} />}
              {itemsToShow?.price && order.closePrice && status === TabType.HISTORY && (
                <ShareItemCard Icon={ExitPriceIcon} label={t('share.exitPrice')} value={globalRound(order.closePrice || 0, 6)} />
              )}
            </div>
          )}
          {itemsToShow?.tpAndSl && (tpLevels || slLevels) && (
            <div className='flex'>
              {tpLevels && <ShareTpSlCard isTp label={t('share.takeProfit')} currentLevel={order?.additionalDetails?.options?.partialClose?.level} levels={tpLevels} unitType={unitType}/>}
              {slLevels && <ShareTpSlCard label={t('share.stopLoss')} levels={slLevels} unitType={unitType}/>}
            </div>
          )}
          <div className="mt-8 w-96 align-bottom absolute bottom-8 left-8">
            <Text className="text-red-600 text-sm">
              {t('share.disclaimerLabel')}:
            </Text>
            <Text className="text-xs">
              {t('share.disclaimer')}
            </Text>
          </div>
        </PreviewBuilderContainer>
      </div>
      <PreviewContainer preview={preview} className='mb-3' />
      <div>
        <Text size='sm' className='mb-2.5 text-grayscale-400'>
          {t('share.informationToShare')}
        </Text>
        <div className='flex flex-wrap'>
          {Object.entries(itemsToShow || {}).filter(([key]) => key !== 'tpAndSl').map(([key, value], index) => (
            <div key={key} className='flex align-middle mb-1 min-w-8'>
              <Toggler checked={value} onChange={(checked) => onShowItemsChanged?.(key as keyof typeof itemsToShow, checked)} />
              <Text size='sm' className='mx-2'>
                {t(`share.${key}`)}
              </Text>
              {index < 2 && <div className='mr-2 h-full bg-gray-700 w-px' />}
            </div>
          ))}
        </div>
      </div>

      <div className='w-full flex justify-between mt-8'>
        <Button type='button' onClick={closeModal} outlined>
          {t('Close', { ns: 'forex-common' })}
        </Button>
        <div className='flex'>
          <InfoTooltip content={<Text>{t('share.refreshTooltip')}</Text>}>
            <Button variant='muted' onClick={updatePreview} outlined className='mr-2'>
              {t('share.refresh')}
            </Button>
          </InfoTooltip>
          <Button variant='primary' onClick={onDownload}>
            {t('download', { ns: 'forex-common' })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ShareOrderModal.displayName = 'ShareOrderModal';
