import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { useGetHistoryCount } from '@root/modules/orders/hooks/use-order-history-count';

export const useGetOrdersCount = (expertId?: string) => {
  const { data: history } = useGetHistoryCount();
  const { openOrders } = useGetOpenOrders({ enabled: true, selectedBroker: 'all', mtType: undefined });

  const groupedOpenOrders = openOrders?.reduce(
    (acc, item) => {
      if (item.expertId) {
        acc.experts[item.expertId] = acc.experts[item.expertId] ? acc.experts[item.expertId] + 1 : 1;
      }
      return acc;
    },
    { experts: {} },
  );

  return {
    history: history?.experts,
    historyOrdersCountByExpertId: expertId ? history?.experts[expertId] || 0 : null,
    activeOrders: groupedOpenOrders?.experts,
    activeOrdersCountByExpertId: expertId ? groupedOpenOrders?.experts[expertId] || 0 : null,
  };
};
