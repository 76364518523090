import { QueryFunction } from 'react-query';

import { GetUsersStatsServiceQuery, getUserStatistic } from '@root/modules/orders/services/get-users-statistic.service';
import { FullStatsData } from '@root/modules/orders/types/statistic';
import { Id } from '@root/shared/utils/types';

export type GetUsersStatsDataQueryKey = ['fx', GetUsersStatsServiceQuery, 'user-statistic'];
export type GetUsersStatsChartDataData = FullStatsData;
export const createKey = ({ accounts, providerId, fromDate, toDate }: { accounts: Id; providerId?: string; fromDate?: string; toDate?: string }): GetUsersStatsDataQueryKey => [
  'fx',
  { accounts, providerId, fromDate, toDate },
  'user-statistic',
];

export const GET_USER_STATS_DATA: QueryFunction<GetUsersStatsChartDataData, GetUsersStatsDataQueryKey> = async ({ queryKey }) => {
  const [, params] = queryKey;

  const result = await getUserStatistic(params);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
