export const FileIcon = (props) => {
  return (
    <svg width='1em' height='1em' {...props} viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 4.33301C4 3.22844 4.89543 2.33301 6 2.33301H7.68421C9.34106 2.33301 10.6842 3.67616 10.6842 5.33301V6.49967C10.6842 7.88039 11.8035 8.99967 13.1842 8.99967H14C15.6569 8.99967 17 10.3428 17 11.9997V16.6663C17 17.7709 16.1046 18.6663 15 18.6663H6C4.89543 18.6663 4 17.7709 4 16.6663V4.33301Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M4 4.33301C4 3.22844 4.89543 2.33301 6 2.33301H7.49708C9.8569 2.33301 12.0963 3.37487 13.6164 5.17992L15.1193 6.96463C16.3339 8.407 17 10.2321 17 12.1177V16.6663C17 17.7709 16.1046 18.6663 15 18.6663H6C4.89543 18.6663 4 17.7709 4 16.6663V4.33301Z'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
