import AccountsPage from '@root/modules/accounts/pages';
import CreateAccountPage from '@root/modules/accounts/pages/create';
import SymbolRemapPage from '@root/modules/accounts/pages/symbol-remap';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorPage } from '@root/infra/layout/containers/error';

export const Router = () => {
  return (
    <Routes>
      <Route index element={<AccountsPage />} errorElement={<ErrorPage />} />
      <Route path='/create' element={<CreateAccountPage />} errorElement={<ErrorPage />} />
      <Route path='/symbol-remap' element={<SymbolRemapPage />} errorElement={<ErrorPage />} />
      <Route path='*' element={<Navigate to='/accounts' replace />} />
    </Routes>
  );
};
