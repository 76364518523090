import { usePageLeave } from '@root/shared/hooks/use-page-leave';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

export const useFormLeave = <T>(shouldCheckLeave = true) => {
  const { initialValues, values, touched } = useFormikContext<T>();
  const isChanged = useMemo(() => JSON.stringify(initialValues) !== JSON.stringify(values) && !!Object.keys(touched).length, [initialValues, values, touched]);
  const leaveProps = usePageLeave(isChanged && shouldCheckLeave);

  return leaveProps;
};
