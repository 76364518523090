import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ProvidersIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.56571 5.70202C6.56571 4.05911 7.89755 2.72727 9.54046 2.72727C11.1834 2.72727 12.5152 4.05911 12.5152 5.70202C12.5152 7.34493 11.1834 8.67677 9.54046 8.67677C7.89755 8.67677 6.56571 7.34493 6.56571 5.70202ZM9.54046 1C6.9436 1 4.83843 3.10517 4.83843 5.70202C4.83843 8.29888 6.9436 10.404 9.54046 10.404C12.1373 10.404 14.2425 8.29888 14.2425 5.70202C14.2425 3.10517 12.1373 1 9.54046 1ZM13.1763 14.8715C13.1763 13.4641 14.3172 12.3232 15.7246 12.3232C17.1319 12.3232 18.2728 13.4641 18.2728 14.8715C18.2728 16.2789 17.1319 17.4198 15.7246 17.4198C14.3172 17.4198 13.1763 16.2789 13.1763 14.8715ZM15.7246 10.5959C13.3632 10.5959 11.449 12.5102 11.449 14.8715C11.449 15.7398 11.7079 16.5476 12.1526 17.2221L10.849 18.5257C10.5117 18.8629 10.5117 19.4098 10.849 19.747C11.1862 20.0843 11.7331 20.0843 12.0703 19.747L13.3739 18.4434C14.0484 18.8882 14.8562 19.147 15.7246 19.147C18.0859 19.147 20.0001 17.2328 20.0001 14.8715C20.0001 12.5102 18.0859 10.5959 15.7246 10.5959ZM1 17.2172C1 14.6203 3.10517 12.5152 5.70202 12.5152H9.54041C10.0174 12.5152 10.404 12.9018 10.404 13.3788C10.404 13.8558 10.0174 14.2424 9.54041 14.2424H5.70202C4.05911 14.2424 2.72727 15.5743 2.72727 17.2172V18.1768C2.72727 18.6538 2.34061 19.0404 1.86364 19.0404C1.38666 19.0404 1 18.6538 1 18.1768V17.2172Z'
        fill='currentColor'
      />
    </svg>
  );
};
