import { createSelector, Selector } from '@reduxjs/toolkit';
import { IAppState } from '@root/infra/store';
import { IMarketplaceState } from './marketplace.slice';

const getMarketplace = (state: IAppState) => state.marketplace;
const getUpdatedAfterSubscription = (marketplace: IMarketplaceState) => marketplace.isUpdatedAfterSubscription;

export const marketplaceSelector = {
  isUpdatedAfterSubscription: createSelector<[Selector<IAppState, IMarketplaceState>], IMarketplaceState['isUpdatedAfterSubscription']>(
    [getMarketplace],
    getUpdatedAfterSubscription,
  ),
};
