import { useField } from 'formik';
import { ChangeEventHandler, FC, ReactNode } from 'react';

import { Field, FieldHint } from '@root/shared/ui/form';
import { Switch } from '@root/shared/ui/form/switch';

export type SwitchFieldProps = {
  name: string;
  label?: ReactNode;
  wrapperClassName?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const SwitchField: FC<SwitchFieldProps> = ({ name, label, wrapperClassName, disabled, onChange }) => {
  const [inputProps, meta] = useField({ type: 'checkbox', name });

  return (
    <Field className={wrapperClassName}>
      <Switch label={label} {...inputProps} onChange={onChange || inputProps.onChange} disabled={disabled} />
      <FieldHint variant='danger'>{!!meta.error && meta.touched && meta.error}</FieldHint>
    </Field>
  );
};
