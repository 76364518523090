import debounce from 'lodash/debounce';
import styled from 'styled-components';

import React, { FC, Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronIcon } from '@root/shared/icons/chevron-icon';

import { Button } from '../button';

const Container = styled.div<{ buttonAtNewLine?: boolean }>`
  position: relative;

  .show-more-button {
    display: ${({ buttonAtNewLine }) => (buttonAtNewLine ? 'block' : 'unset')};
    margin-top: ${({ buttonAtNewLine }) => (buttonAtNewLine ? '8px' : '0')};
  }
`;

export interface StepShowMoreProps {
  disabled?: boolean;
  lines?: number;
  truncatedEndingComponent?: ReactNode;
  buttonAtNewLine?: boolean;
  gradientOverlay?: boolean;
  text: string | null;
}

export const ShowMore: FC<StepShowMoreProps> = ({ truncatedEndingComponent, text, gradientOverlay, buttonAtNewLine, lines = 4 }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [linesCount, setLinesCount] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');

  const handleExpandClick = useCallback(() => setExpanded((prev) => !prev), []);

  const calculateLinesCount = useCallback(() => {
    if (ref.current && window) {
      const divHeight = +ref.current.offsetHeight;
      const lineHeight = +window.getComputedStyle(ref.current)?.lineHeight.replace('px', '');
      const countedLines = divHeight / lineHeight;
      setLinesCount(countedLines);
    }
  }, [ref]);

  const calculateWithPending = useRef(debounce(() => calculateLinesCount(), 500));

  useEffect(() => {
    calculateLinesCount();
    window.addEventListener('resize', calculateWithPending.current);

    return () => window.removeEventListener('resize', calculateWithPending.current);
  }, [calculateLinesCount, calculateWithPending]);

  const height = useMemo(() => {
    if (ref.current) {
      if (linesCount > lines && !expanded) {
        const lineHeight = +window.getComputedStyle(ref.current)?.lineHeight.replace('px', '');
        return lineHeight * lines;
      } else {
        return 'unset';
      }
    }
    return 'unset';
  }, [expanded, lines, linesCount]);

  return (
    <Container buttonAtNewLine={buttonAtNewLine}>
      <div className='relative'>
        <div dangerouslySetInnerHTML={{ __html: text || '' }} ref={ref} style={{ height }} className='overflow-hidden' />
        {text &&
          linesCount > lines &&
          (expanded ? (
            <>
              {truncatedEndingComponent || null}
              <Button className='!font-bold !p-0 z-2' link prefix={<ChevronIcon />} onClick={handleExpandClick}>
                {t('showLess')}
              </Button>
            </>
          ) : (
            <Fragment>
              <Button link className='!font-bold !p-0 z-2' prefix={<ChevronIcon className='transform rotate-180' />} onClick={handleExpandClick}>
                {t('showMore')}
              </Button>
              {gradientOverlay && !expanded && <div className='absolute top-0 left-0 right-0 bottom-0 pointer-events-none bg-gradient-to-t from-gray-900 to-transparent z-1' />}
            </Fragment>
          ))}
      </div>
    </Container>
  );
};

ShowMore.defaultProps = {
  disabled: false,
};
