import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
  const [navigationPath, setNavigationPath] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (navigationPath) {
      navigate(navigationPath);
    }
  }, [navigationPath, navigate]);

  return { push: setNavigationPath };
};
