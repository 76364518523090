import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { usePaginationPreferences } from '@root/shared/hooks/use-pagination-preferences';

import { GetMarketplaceTradeIdeaListQuery } from '../services/get-marketplace-trade-idea-list.service';
import { useGetMarketplaceTradeIdeaList } from './use-get-marketplace-trade-idea-list';

export type MarketplaceTradeIdeaTableParams = GetMarketplaceTradeIdeaListQuery;

const initialData: MarketplaceTradeIdeaTableParams = {
  page: 1,
  itemsPerPage: 10,
  search: '',
};

export const useMarketplaceTradeIdeaList = () => {
  const { preferences, handlePageChange } = usePaginationPreferences();
  const [controlParams, setControlParam] = useState<MarketplaceTradeIdeaTableParams>({
    ...initialData,
    itemsPerPage: preferences.marketplace.tiProviders,
  });
  const [subscriptionType, setSubscriptionType] = useState<'month' | 'year'>('year');

  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const markets = useMemo(() => {
    const marketTypes: string[] = ['forex'];
    if (subscriptionInfo?.roles.includes('synthetic provider')) {
      marketTypes.push('synthetic');
    }

    if (subscriptionInfo?.roles.includes('indices provider')) {
      marketTypes.push('indices');
    }

    return marketTypes;
  }, [subscriptionInfo?.roles]);

  const { data, isFetching: isLoading } = useGetMarketplaceTradeIdeaList({ ...controlParams, markets }, !!subscriptionInfo);

  const changeControlParam = useCallback(
    (params: Partial<MarketplaceTradeIdeaTableParams>) => {
      if (params.itemsPerPage) {
        handlePageChange('marketplace.tiProviders', params.itemsPerPage);
      }
      setControlParam((values) => ({ ...values, ...params }));
    },
    [setControlParam, handlePageChange],
  );

  const handleSubscriptionTypeChange = useCallback((type) => {
    setSubscriptionType(type);
  }, []);

  const state = {
    list: data?.data,
    controlParams,
    isLoading,
    pageCount: data?.pagination.pageCount,
    subscriptionType,
  };

  const handlers = { changeControlParam, handleSubscriptionTypeChange };

  return [state, handlers] as [typeof state, typeof handlers];
};
