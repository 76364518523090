import { IExpertEvent } from '../types/expert';

export class ExpertEventsMapper {
  public static toDomain(data): IExpertEvent {
    const trailStop = data?.metaData?.trailStop?.use;

    const formattedMessage = data.message
      ? data.message
          .replace(/%(\d+)/g, '$1%')
          .replace('afterX', trailStop ? 'Activate After Profit' : 'Profit Distance')
          .replace('setToY', 'StopLoss Adjustment')
          .replace('followY', 'Trailing Distance')
      : data.message;

    return {
      _id: data._id,
      userId: data.userId,
      expertId: data.expertId,
      level: data.level,
      message: formattedMessage,
      metaData: data.metaData,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      code: data.code,
    };
  }
}
