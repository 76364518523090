import { QueryFunction } from 'react-query';
import {
  getProviderEventLogs,
  ProviderEventLogsParams,
  GetProviderEventLogsData,
} from '../services/get-provider-event-logs.service';

export type GetProviderEventLogsQueryKey = ['trade-idea-provider-event-logs', ProviderEventLogsParams];

export const GET_TRADE_IDEA_PROVIDERS_EVENT_LOGS: QueryFunction<
  GetProviderEventLogsData,
  GetProviderEventLogsQueryKey
> = async ({ queryKey }) => {
  const [, data] = queryKey;
  const result = await getProviderEventLogs(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
