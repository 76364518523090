import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { CheckIcon } from '@root/shared/icons/check-icon';

type Props = PropsWithChildren & {
    checked: boolean;
    className?: string;
}

export const TradeItemBullet: FC<Props> = ({ checked, className, children }) => {
    return <div className={clsx("w-full flex justify-start items-start gap-x-2", className)}>
        <CheckIcon className={clsx('mt-0.5', { 'text-gray-100': checked, 'text-gray-700': !checked })} height={12} />
        {children}
    </div>;
}




