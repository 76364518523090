import { FC, SVGProps } from 'react';

export const ListIcon: FC<SVGProps<SVGSVGElement> & { outlined: boolean }> = (props) => {
    if (props.outlined) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 3.125C3.21851 3.125 3.125 3.21851 3.125 3.33333V5C3.125 5.11482 3.21851 5.20833 3.33333 5.20833H16.6667C16.7815 5.20833 16.875 5.11482 16.875 5V3.33333C16.875 3.21851 16.7815 3.125 16.6667 3.125H3.33333ZM1.875 3.33333C1.875 2.52816 2.52816 1.875 3.33333 1.875H16.6667C17.4718 1.875 18.125 2.52816 18.125 3.33333V5C18.125 5.80518 17.4718 6.45833 16.6667 6.45833H3.33333C2.52816 6.45833 1.875 5.80518 1.875 5V3.33333Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 8.95801C3.21851 8.95801 3.125 9.05152 3.125 9.16634V10.833C3.125 10.9478 3.21851 11.0413 3.33333 11.0413H16.6667C16.7815 11.0413 16.875 10.9478 16.875 10.833V9.16634C16.875 9.05152 16.7815 8.95801 16.6667 8.95801H3.33333ZM1.875 9.16634C1.875 8.36116 2.52816 7.70801 3.33333 7.70801H16.6667C17.4718 7.70801 18.125 8.36116 18.125 9.16634V10.833C18.125 11.6382 17.4718 12.2913 16.6667 12.2913H3.33333C2.52816 12.2913 1.875 11.6382 1.875 10.833V9.16634Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 14.792C3.21851 14.792 3.125 14.8855 3.125 15.0003V16.667C3.125 16.7818 3.21851 16.8753 3.33333 16.8753H16.6667C16.7815 16.8753 16.875 16.7818 16.875 16.667V15.0003C16.875 14.8855 16.7815 14.792 16.6667 14.792H3.33333ZM1.875 15.0003C1.875 14.1951 2.52816 13.542 3.33333 13.542H16.6667C17.4718 13.542 18.125 14.1951 18.125 15.0003V16.667C18.125 17.4722 17.4718 18.1253 16.6667 18.1253H3.33333C2.52816 18.1253 1.875 17.4722 1.875 16.667V15.0003Z" fill="currentColor" />
        </svg>
    }

    return <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="currentColor" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.33333 0.875C1.52816 0.875 0.875 1.52816 0.875 2.33333V4C0.875 4.80518 1.52816 5.45833 2.33333 5.45833H15.6667C16.4718 5.45833 17.125 4.80518 17.125 4V2.33333C17.125 1.52816 16.4718 0.875 15.6667 0.875H2.33333Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.33333 6.70801C1.52816 6.70801 0.875 7.36116 0.875 8.16634V9.83301C0.875 10.6382 1.52816 11.2913 2.33333 11.2913H15.6667C16.4718 11.2913 17.125 10.6382 17.125 9.83301V8.16634C17.125 7.36116 16.4718 6.70801 15.6667 6.70801H2.33333Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.33333 12.542C1.52816 12.542 0.875 13.1951 0.875 14.0003V15.667C0.875 16.4722 1.52816 17.1253 2.33333 17.1253H15.6667C16.4718 17.1253 17.125 16.4722 17.125 15.667V14.0003C17.125 13.1951 16.4718 12.542 15.6667 12.542H2.33333Z" fill="currentColor" />
    </svg>;
}


