import { useFormikContext } from 'formik';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ManualMoneyManagement } from '@root/modules/experts/components/manual-money-management';
import { SignalMoneyManagement } from '@root/modules/experts/components/signal-money-management';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';
import { EditableSettingsTitle } from './editable-settings-title';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { WarningIcon } from '@root/shared/icons/warning-icon';
import { Drawdown } from '../../../drawdown';
import { RefreshIcon } from '@root/shared/icons/refresh-icon';
import clsx from 'clsx';
import { useDrawdownReset } from '@root/modules/experts/hooks/use-drawdown-reset';
import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';
import { SessionManagement } from '../../../session-management';

type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const MoneyManagement: FC<Props> = ({ editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { values } = useFormikContext<CreateExpertDto>();
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.MoneyManagement);

  const options = useCreateExpertFieldOptions();

  const strategyType = values.strategy.type;
  const type = strategyType === 'manual' ? values.manualMoneyManagement.type : values.signalMoneyManagement.type;

  const selectedManagementType = options?.[strategyType === 'manual' ? 'manualMoneyManagementType' : 'signalMoneyManagementType'].find((option) => option.value === type)?.label;
  const managementKey = strategyType === 'manual' ? 'manualMoneyManagement' : 'signalMoneyManagement';
  const selectedBasedOn = options?.[strategyType === 'manual' ? 'manualMoneyManagementBasedOn' : 'signalMoneyManagementBasedOn'].find((option) => option.value === values[managementKey]?.basedOn)?.label;

  const balanceType = options?.drawdownBalanceTypeOptions.find((option) => option.value === values.maxDrawDownLimit.basedOn)?.label;
  const drawdownLimitPeriod = options?.drawdownLimitPeriodOptions.find((option) => option.value === values.maxDrawDownLimit.period)?.label;
  const sessionManagementType = options?.sessionManagementTypeOptions.find((option) => option.value === values.sessionManagement?.type)?.label;
  const sessionManagementSessions = values.sessionManagement?.sessions.length !== options.sessionManagementSessionOptions.length - 1 ? options.sessionManagementSessionOptions.filter((item) => values.sessionManagement?.sessions?.includes(item.value))?.map(item => item.label) : [options.sessionManagementSessionOptions[0].label];

  const { resetLoading, handleResetDrawdown } = useDrawdownReset(values);

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('fields.moneyManagementMode.label')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.MoneyManagement}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <Fragment>
            {strategyType === 'manual' && <ManualMoneyManagement type={values.manualMoneyManagement.type} isPartialEdit />}
            {strategyType === 'signal' && <SignalMoneyManagement type={values.signalMoneyManagement.type} isPartialEdit />}
            <SessionManagement isPartialEdit/>
            <Drawdown isPartialEdit />
          </Fragment>
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          {strategyType === 'signal' ? <Fragment>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.units.label')}
              </Text>
              <Text size='base' className='text-right'>
                {values.usePips ? 'PIPS' : 'POINTS'}
              </Text>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              {
                type === '1' ? <InfoTooltip content={t('riskPercentageWarning')} positions={['right', 'bottom']}>
                  <div className="flex justify-start items-center gap-x-2">
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                      {t('fields.signalMoneyManagement.type.label')}
                    </Text>
                    <WarningIcon width={20} height={20}/>
                  </div>
                </InfoTooltip> : <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.type.label')}
                </Text>
              }
              <Text size='base' className='text-right'>
                {selectedManagementType}
              </Text>
            </div>
            {['0', '2'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.lotsize.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.signalMoneyManagement.lotsize}
                </Text>
              </div>
            )}
            {['1'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.riskPercent.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.signalMoneyManagement.riskPercent}%
                </Text>
              </div>
            )}
            {['1', '2', '3', '4'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.basedOn.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {selectedBasedOn}
                </Text>
              </div>
            )}
            {['2'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.k.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.signalMoneyManagement.k}
                </Text>
              </div>
            )}
            {['3', '4'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.xPercent.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.signalMoneyManagement.xPercent}%
                </Text>
              </div>
            )}
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.manualMoneyManagement.maxActiveTrades.label')}
              </Text>
              <Text size='base' className='text-right'>
                {values.maxSignalActiveTrades}
              </Text>
            </div>
          </Fragment> : <Fragment>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              {
                type === '1' ? <InfoTooltip content={t('riskPercentageWarning')} positions={['right', 'bottom']}>
                  <div className="flex justify-start items-center gap-x-2">
                    <Text size='base' className='text-gray-500 flex-shrink-0'>
                    {t('fields.manualMoneyManagement.type.label')}
                    </Text>
                    <WarningIcon width={20} height={20} />
                  </div>
                </InfoTooltip> : <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.signalMoneyManagement.type.label')}
                </Text>
              }
              <Text size='base' className='text-right'>
                {selectedManagementType}
              </Text>
            </div>
            {['0', '2'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualMoneyManagement.lotsize.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.manualMoneyManagement.lotsize}
                </Text>
              </div>
            )}
            {['1'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualMoneyManagement.riskPercent.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.manualMoneyManagement.riskPercent}%
                </Text>
              </div>
            )}
            {['1', '2'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualMoneyManagement.basedOn.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {selectedBasedOn}
                </Text>
              </div>
            )}
            {['2'].includes(type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
                <Text size='base' className='text-gray-500 flex-shrink-0'>
                  {t('fields.manualMoneyManagement.k.label')}
                </Text>
                <Text size='base' className='text-right'>
                  {values.manualMoneyManagement.k}
                </Text>
              </div>
            )}
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.manualMoneyManagement.maxActiveTrades.label')}
              </Text>
              <Text size='base' className='text-right'>
                {values.maxManualActiveTrades}
              </Text>
            </div>
          </Fragment>
          }
          {values.maxDrawDownLimit?.use ? <Fragment>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.drawdown.use.label')}
              </Text>
              <Text size='base' className='text-right'>
                {t('on')}
              </Text>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.drawdown.balanceType.label')}
              </Text>
              <Text size='base' className='text-right'>
                {balanceType}
              </Text>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.drawdown.percent.label')}
              </Text>
              <div className="flex justify-end items-center gap-x-2">
                <Text size='base' className='text-right'>
                  {values.maxDrawDownLimit.percent}%
                </Text>
                {values.maxDrawDownLimit?.use && <InfoTooltip content={<Text size='sm'>{t('resetAccountHint')}</Text>} positions={['bottom', 'right', 'top', 'left']}>
                  <RefreshIcon width={16} height={16} className={clsx('hover:text-primary-400', { 'animate-spin': resetLoading })} onClick={handleResetDrawdown} />
                </InfoTooltip>}
              </div>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.drawdown.drawdownLimitPeriod.label')}
              </Text>
              <Text size='base' className='text-right'>
                {drawdownLimitPeriod}
              </Text>
            </div>
          </Fragment> : <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.drawdown.use.label')}
            </Text>
            <Text size='base' className='text-right'>
              {t('off')}
            </Text>
          </div>}

          { values.sessionManagement?.use ? <Fragment>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.sessionManagement.use.label')}
              </Text>
              <Text size='base' className='text-right'>
                {t('on')}
              </Text>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.sessionManagement.sessions.label')}
              </Text>
              <Text size='base' className='text-right'>
                {sessionManagementSessions.join(', ')}
              </Text>
            </div>
            <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.sessionManagement.type.shortLabel')}
              </Text>
              <div className="flex justify-end items-center gap-x-2">
                <Text size='base' className='text-right'>
                {sessionManagementType}
                </Text>
              </div>
            </div>
            {[FxSessionManagementActionType.CLOSE_IF_IN_PROFIT as string].includes(values.sessionManagement.type) && (
              <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
              <Text size='base' className='text-gray-500 flex-shrink-0'>
                {t('fields.sessionManagement.threshold.label')}
              </Text>
              <Text size='base' className='text-right'>
                {values?.sessionManagement?.threshold}
              </Text>
            </div>
            )}
            
          </Fragment> : <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.sessionManagement.use.label')}
            </Text>
            <Text size='base' className='text-right'>
              {t('off')}
            </Text>
          </div>}
        </div>)}
    </div>
  );
};
