import { useTheme } from 'styled-components';

import { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
import { FC, memo, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { usePerformanceChart } from '@root/modules/orders/hooks/use-performance-chart';
import { ChartType, customLegendCheckboxOff, customLegendCheckboxOn } from '@root/shared/constants/chart';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { Select } from '@root/shared/ui/form';
import { Switch, SwitchItem } from '@root/shared/ui/switch';
import { Text } from '@root/shared/ui/typography';

type Props = {
  expertId?: string;
  selectedBroker: string;
  height?: number;
};

const Component: FC<Props> = ({ expertId, selectedBroker, height = 343 }) => {
  const { t } = useTranslation('forex-experts');
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const theme = useTheme();

  const [{ isLoading, series, chartType, periodOptions, selectedPeriod }, { setPeriod, setChartType }] = usePerformanceChart({
    selectedBroker: selectedBroker,
    expertId: expertId,
  });

  const options = useMemo<ApexOptions>(
    () => ({
      noData: {
        text: 'No data found',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: -20,
        style: {
          // color: string
          fontSize: '16px',
        },
      },
      theme: {
        mode: 'dark',
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },
      grid: {
        borderColor: theme.gray['700'],
      },
      chart: {
        background: 'transparent',
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        width: [0, 4, 4],
        curve: 'straight',
      },
      markers: {
        size: 4,
        strokeWidth: 0,
      },
      tooltip: {
        custom: ({ seriesIndex, w, dataPointIndex }) => {
          const series = w.config.series[seriesIndex];
          const hoveredData = series.data[dataPointIndex];
          const currency = series.name === 'Pips' ? series.name : '';

          return renderToString(
            <div className='p-2 bg-grayscale-800'>
              <p className='text-sm'>
                <div className='inline-block mr-1 w-2 h-2 rounded-full' style={{ background: hoveredData.fillColor }} />
                {series.name}
              </p>
              <p className='text-sm'>
                {hoveredData.y} {currency}
              </p>
              {/*<p className='text-sm'>Equity Growth: {equity} Pips</p>*/}
              {/*<p className='text-sm'>Overall Growth: {overall} Pips</p>*/}
              <p className='text-gray-600'>{hoveredData.x}</p>
            </div>,
          );
        },
      },
      legend: {
        position: 'bottom',
        itemMargin: {
          vertical: 0,
        },
        markers: {
          width: 0,
        },
        formatter: (val, opt) => {
          const visible = opt.w.config.series[opt.seriesIndex].data.length;
          const checkbox = visible ? customLegendCheckboxOn : customLegendCheckboxOff;
          return `<div style="display:flex; align-items: center; "> ${checkbox} ${val}</div>`;
        },
      },
    }),
    [theme.gray],
  );

  return (
    <div className='py-2.5 rounded bg-gray-800'>
      <div className='flex justify-between items-center gap-5 px-5'>
        <div className='flex justify-start items-center gap-x-2 cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
          <ChevronIcon className={clsx({ 'rotate-180 -mt-1': !isOpen })} />
          <Text size='base' className='font-semibold'>
            {t('pnlSection.performance')}
          </Text>
        </div>
        {isOpen && (
          <div className='flex flex-wrap justify-end items-start gap-2'>
            <Select tiny value={selectedPeriod} onChange={(option) => setPeriod(option.value)} options={periodOptions} alignMenuToRight bordered />
          </div>
        )}
      </div>

      {isOpen && (
        <div>
          <div className='flex items-center px-5 py-4'>
            <Switch>
              <SwitchItem active={chartType === ChartType.Profit} onClick={() => setChartType(ChartType.Profit)}>
                {t('profitTab')}
              </SwitchItem>
              <SwitchItem active={chartType === ChartType.Growth} onClick={() => setChartType(ChartType.Growth)}>
                {t('growthTab')}
              </SwitchItem>
            </Switch>
          </div>

          {isLoading ? (
            <Skeleton height={343} className='p-4' />
          ) : (
            <div className='bg-gray-800 rounded px-1'>
              <div className='flex items-center justify-between relative z-10'></div>
              <Chart options={options} series={series} height={height} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const PnlChart = memo(Component, (prev, next) => prev.expertId === next.expertId && prev.selectedBroker === next.selectedBroker);
