export enum PageLinks {
  home = '/',
  accounts = '/accounts',
  createAccount = '/accounts/create',
  experts = '/assists',
  createExpert = '/assists/create-wizard',
  updateExpert = '/assists/update',
  createTemplates = '/templates/create',
  updateTemplates = '/templates/update',
  templates = '/templates',
  templatesCreate = '/templates/create',
  providers = '/marketplace',
  signals = '/signals',
  symbolRemap = '/symbol-remap',
  eaGuide = '/ea-guide',
  presets = '/presets',
  magicTerminal = '/magic-terminal'
}
