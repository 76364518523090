import React from 'react';
import LoadingIconDots from './loading-icon-dots';

export const LoadingScreen = () => {
  return (
    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-primary-400'>
      <LoadingIconDots />
    </div>
  );
};
