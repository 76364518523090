import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { useCallback } from 'react';
const key = 'autoUpdateEnabledTrades';

export const useAutoUpdate = () => {
  const [enabledIds, setEnabledIds] = useLocalStorage<number[]>(key, []);

  const addToEnabledIds = useCallback(
    (id: number) => {
      setEnabledIds([...enabledIds, id]);
    },
    [enabledIds, setEnabledIds],
  );

  const removeFromEnabledIds = useCallback(
    (id: number) => {
      setEnabledIds(enabledIds.filter((enabledId) => enabledId !== id));
    },
    [enabledIds, setEnabledIds],
  );

  return { enabledIds, addToEnabledIds, removeFromEnabledIds };
};
