export enum ExpertTypes {
  MY_EXPERTS = 'own',
  MASTER_EXPERTS = 'master',
}

export enum PendingOrderType {
  BUY_LIMIT = 0,
  SELL_LIMIT = 1,
  BUY_STOP = 2,
  SELL_STOP = 3,
}

export enum MoneyManagementType {
  FIXED_LOT = 0,
  RISK_PERCENTAGE = 1,
  RATIO_AND_MULTIPLIER = 2,
}

export enum ExpertStatus {
  Running = 'running',
  Stopped = 'stopped',
  Paused = 'paused',
  DDLimit = 'ddLimit'
}
