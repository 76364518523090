import isNull from 'lodash/isNull';

import { IAccount } from '@root/modules/accounts';
import { IExpert } from '@root/modules/experts/types/expert';
import { ChartType } from '@root/shared/constants/chart';

export const getCorrectExpertsSymbols = (brokerAccount: IAccount | void, expert: IExpert, supportedSymbols: string[]) => {
  if (!brokerAccount || !brokerAccount.symbolsMapping) {
    return expert.symbols;
  }

  //1. we need to filter out symbols that maybe deleted from supportedSymbols
  //2. we need to filter out symbols that are not in brokerAccount.symbolsMapping (value === null)
  return expert.symbols
    .filter((item) => supportedSymbols.includes(item as string))
    .filter((item) => !isNull(brokerAccount?.symbolsMapping?.[item]))
    .map((item) => item.toUpperCase());
};

export const getChartPoints = ({ values, chartType, growthPercent }) => {
  if (chartType === ChartType.Profit) {
    return values;
  }
  if (growthPercent) {
    return calculateGrowthPercent(values);
  }
  return calculateGrowth(values);
};

export const calculateGrowthPercent = (data) => {
  const growthData: number[] = [];

  for (let i = 1; i < data.length; i++) {
    const previousValue = data[i - 1];
    const currentValue = data[i];
    const growthPercentage = ((currentValue - previousValue) / previousValue) * 100;
    growthData.push(growthPercentage);
  }

  return growthData;
};

export const calculateGrowth = (data: number[]): number[] => {
  const growthData: number[] = [];

  for (let i = 1; i < data.length; i++) {
    const previousValue = data[i - 1];
    const currentValue = data[i];
    const growth = currentValue - previousValue;
    growthData.push(growth);
  }

  return growthData;
};
