import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useServiceAccountChecker = () => {
  const dispatch = useDispatch();
  const user = useSelector(authSelector.user);
  const { data: accounts } = useGetAccounts(!!user);

  useEffect(() => {
    if (accounts) {
      const accountsWithoutServiceAccount = accounts.filter((account) => !account?.isServiceAccountAssigned && account?.serviceAccounts?.length > 1);

      const accountsWithWrongMapping = accounts.filter((account) => {
        const symbolsInMapping = Object.values(account.symbolsMapping as Record<string, string>);
        return symbolsInMapping.some((symbol) => symbol && !account.symbols.includes(symbol));
      });

      if (accountsWithoutServiceAccount.length || accountsWithWrongMapping.length) {
        dispatch(accountsSlice.actions.serviceAccountModalOpened(true));
      }
    }
  }, [accounts, dispatch]);
};
