import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { useGetOrdersCount } from '@root/modules/orders/hooks/use-get-orders-count';
import { ColoredValue } from '@root/shared/ui/colored-value';
import { Text } from '@root/shared/ui/typography';

type Props = {
  openOrdersProfit: number;
  openOrdersPips: number;
  currency: string;
};

const Component: FC<Props> = ({ openOrdersProfit, currency }) => {
  const { t } = useTranslation('forex-experts');
  const { data } = useGetExperts();
  const { activeOrders, history } = useGetOrdersCount();
  const { selectedBroker } = useGetSelectedBroker();

  const { closedOrdersCount, activeOrdersCount, assistsWithoutActiveTradesCount, activeExperts } = useMemo(() => {
    let closedOrdersCount = 0;
    let activeOrdersCount = 0;
    let assistsWithoutTradesCount = 0;
    let assistsWithoutActiveTradesCount = 0;
    let activeExperts = 0;

    if (data?.length) {
      data
        .filter((item) => (selectedBroker !== 'all' ? item.accountId === selectedBroker : item))
        .forEach((item) => {
          const historyCount = history?.[item.id] || 0;
          const openCount = activeOrders?.[item.id] || 0;

          if (!historyCount && !openCount) {
            assistsWithoutTradesCount++;
          }

          if (!openCount) {
            assistsWithoutActiveTradesCount++;
          }

          if (item.isEnabled) {
            activeExperts++;
          }

          closedOrdersCount = closedOrdersCount + Number(historyCount);
          activeOrdersCount = activeOrdersCount + Number(openCount);
        });
    }
    return { closedOrdersCount, activeOrdersCount, assistsWithoutTradesCount, assistsWithoutActiveTradesCount, activeExperts };
  }, [data, selectedBroker, history, activeOrders]);

  return (
    <div className='p-5 bg-gray-800 rounded'>
      <div className='w-full grid md:grid-cols-2 gap-x-2.5 gap-y-3 md:gap-y-6'>
        <div className='py-2 px-4 bg-grayscale-700 rounded'>
          <div className='w-full flex justify-between items-center gap-x-2 mb-1'>
            <div className='flex justify-start items-center gap-x-2'>
              <Text className='text-grayscale-500'>{t('fundsInfo.unrealizedPnl')}</Text>
            </div>
          </div>
          <Text className='flex items-end font-semibold gap-4'>
            <ColoredValue value={openOrdersProfit || 0} currencyPrefix={currency} />
          </Text>
        </div>
        <div className='py-2 px-4 bg-grayscale-700 rounded'>
          <Text className='text-grayscale-500 mb-1'>{t('fundsInfo.activeExpertAssists')}</Text>
          <Text className='font-semibold'>{activeExperts}</Text>
        </div>
        <div className='md:col-span-2'>
          <div className='w-full flex justify-between items-center gap-x-2 mb-2'>
            <Text className='text-grayscale-400'>{t('fundsInfo.activeTrades')}</Text>
            <Text>{activeOrdersCount}</Text>
          </div>
          <div className='w-full flex justify-between items-center gap-x-2 mb-2'>
            <Text className='text-grayscale-400'>{t('fundsInfo.expertsWithoutActiveTrades')}</Text>
            <Text>{assistsWithoutActiveTradesCount}</Text>
          </div>
          <div className='w-full flex justify-between items-center gap-x-2'>
            <Text className='text-grayscale-400'>{t('fundsInfo.closedTrades')}</Text>
            <Text>{closedOrdersCount}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FundsInfo = memo(Component);
