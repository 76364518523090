import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';
import { AxiosError } from 'axios';

export type DeleteExpertServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export interface DeleteExpertServiceValues {
  userId: Id;
  expertId: string;
}

export const deleteExpertService = async (expertId: string): Promise<DeleteExpertServiceResponse> => {
  try {
    await fetcher.delete(`/experts/${expertId}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
