import { QueryFunction } from 'react-query';

import { Id } from '@root/shared/utils/types';

import { getSymbolsWithType } from '../services/get-symbols.service';
import { ISymbolWithType } from '@root/modules/accounts/types/symbol-with-type';

export type GetSymbolsWithTypeQueryKey = ['fx', Id | undefined, 'symbols-with-type'];
export type GetSymbolsWithTypeData = ISymbolWithType[];

export const GET_SYMBOLS_WITH_TYPE: QueryFunction<GetSymbolsWithTypeData, GetSymbolsWithTypeQueryKey> = async ({ queryKey }) => {
  const result = await getSymbolsWithType();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
