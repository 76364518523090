import { MarketPlaceTradeIdeaProviderLastIdea } from '../types/marketplace-trade-idea-provider-last-ideas';

const signalSidesMap = {
  buy: 'buy',
  sell: 'sell',
  long: 'buy',
  short: 'sell',
};

export class MarketplaceTradeIdeaProviderLastIdeaMapper {
  public static toDomain(data): MarketPlaceTradeIdeaProviderLastIdea {
    const symbol = data.signal?.market || data.signal?.symbol || '';
    const { base: baseSymbol, quote: quoteSymbol, settle: settleSymbol } = {
      base: symbol.split('/')[0],
      quote: symbol.split('/')[1]?.split(':')[0],
      settle: symbol.split(':')[1],
    };
    const side = data.version === 2 ? data.signal.side : data.signal.type;
    const signalSide = signalSidesMap[side] || side || '-';

    return {
      id: data.id,
      pair: {
        baseSymbol: data.signal.currencyFrom?.symbolID || baseSymbol || '',
        quoteSymbol:
          (settleSymbol && quoteSymbol ? `${quoteSymbol}:${settleSymbol}` : data.signal.currencyTo?.symbolID || quoteSymbol) || '',
      },
      side: signalSide,
      exchange: data.signal.exchange,
      gain: data.signal.pips,
      status: data.version === 2 ? (data.closedAt ? 'Closed' : 'Active') : data.signal.status,
      date: data.created_at,
      version: data.version,
    };
  }
}
