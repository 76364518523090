import { SocketEventPayloads } from '@root/infra/socket/event-payloads';
import { IQuote } from '@root/modules/quotes/types';

export class QuoteDataMapper {
  public static toDomain(data: SocketEventPayloads.QuoteMessage): IQuote {
    const { message, pattern, brokerId } = data;

    return {
      ask: message.data.ask,
      bid: message.data.bid,
      symbol: message.data.symbol,
      time: message.data.time,
      low: message.data.low,
      high: message.data.high,
      pattern,
      brokerId,
    };
  }
}
