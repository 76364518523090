import { FormikErrors, setNestedObjectValues, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CreateExpertDto } from '../dtos/create-expert.dto';

export enum CreateExpertStepKey {
  Account = 'account',
  OrderOptions = 'order-options',
  Trigger = 'trigger',
  AdvancedSettings = 'advanced-settings',
  MoneyManagement = 'money-management',
  BreakEven = 'break-even',
  PartialTakeProfits = 'partial-take-profits',
  StopLossTakeProfit = 'stop-loss-take-profit',
  Preview = 'preview',
}

export const createExpertSteps = [
  CreateExpertStepKey.Trigger,
  CreateExpertStepKey.Account,
  CreateExpertStepKey.OrderOptions,
  CreateExpertStepKey.MoneyManagement,
  CreateExpertStepKey.StopLossTakeProfit,
  CreateExpertStepKey.PartialTakeProfits,
  CreateExpertStepKey.BreakEven,
  CreateExpertStepKey.AdvancedSettings,
  CreateExpertStepKey.Preview,
];

export const useCreateExpertSteps = () => {
  const { values, validateForm, setFieldValue, setTouched } = useFormikContext<CreateExpertDto>();
  const [step, setStep] = useState(values?.meta?.step || CreateExpertStepKey.Trigger);

  const { canGoNext, canGoPrev } = useMemo(
    () => ({
      canGoNext: createExpertSteps.indexOf(step) < createExpertSteps.length - 1,
      canGoPrev: createExpertSteps.indexOf(step) > 0,
    }),
    [step],
  );

  const onChange = useCallback(
    (value: CreateExpertStepKey) => {
      setStep(value);
      setFieldValue('meta.step', value);
    },
    [setFieldValue],
  );

  const getStepErrors = useCallback((errors: FormikErrors<CreateExpertDto>, step) => {
    if (step === CreateExpertStepKey.Trigger && (errors?.conditionalClosure?.amount)) {
      return {
        conditionalClosure: errors?.conditionalClosure,
      };
    }

    if (step === CreateExpertStepKey.Account && (errors?.account || errors?.symbols)) {
      return {
        account: errors?.account,
        symbols: errors?.symbols,
      };
    }

    if (step === CreateExpertStepKey.OrderOptions && (errors?.orderType || errors?.orderDirection)) {
      return {
        orderType: errors?.orderType,
        orderDirection: errors?.orderDirection,
      };
    }

    if (step === CreateExpertStepKey.Trigger && (errors?.name || errors?.description || errors?.providerId)) {
      return {
        name: errors?.name,
        description: errors?.description,
        providerId: errors?.providerId,
      };
    }

    if (step === CreateExpertStepKey.MoneyManagement && (errors?.signalMoneyManagement || errors?.manualMoneyManagement || errors?.maxDrawDownLimit || errors.sessionManagement)) {
      return {
        signalMoneyManagement: errors?.signalMoneyManagement,
        manualMoneyManagement: errors?.manualMoneyManagement,
        maxDrawDownLimit: errors?.maxDrawDownLimit,
        sessionManagement: errors?.sessionManagement
      };
    }

    if (step === CreateExpertStepKey.StopLossTakeProfit && (errors?.signalSlTp || errors?.manualSlTp)) {
      return {
        signalSlTp: errors?.signalSlTp,
        manualSlTp: errors?.manualSlTp,
      };
    }

    if (step === CreateExpertStepKey.PartialTakeProfits && errors?.partialClose) {
      return {
        partialClose: errors?.partialClose,
      };
    }

    if (step === CreateExpertStepKey.BreakEven && errors?.breakEven) {
      return {
        breakEven: errors?.breakEven,
      };
    }

    if (step === CreateExpertStepKey.AdvancedSettings && (errors?.strategy || errors?.slippage)) {
      return {
        strategy: errors?.strategy,
        slippage: errors?.slippage,
      };
    }

    return {};
  }, []);

  const onNext = useCallback(async () => {
    const currentIndex = createExpertSteps.indexOf(step);
    const errors = await validateForm();

    if (Object.keys(errors || {}).length) {
      const stepErrors = getStepErrors(errors, step);
      if (Object.keys(stepErrors).length) {
        setTouched(setNestedObjectValues(stepErrors, true));
        return;
      }
    }

    const nextIndex = Math.min(currentIndex + 1, createExpertSteps.length - 1);
    const nextStep = createExpertSteps[nextIndex];

    if (nextStep) {
      setStep(nextStep);
      setFieldValue('meta.step', nextStep);
    }
  }, [step, validateForm, getStepErrors, setTouched, setFieldValue]);

  const onPrev = useCallback(() => {
    const currentIndex = createExpertSteps.indexOf(step);
    const prevIndex = Math.max(currentIndex - 1, 0);

    const prevStep = createExpertSteps[prevIndex];

    if (prevStep) {
      setStep(prevStep);
      setFieldValue('meta.step', prevStep);
    }
  }, [step, setFieldValue]);

  useEffect(() => {
    if (values?.meta?.step && values.meta.step !== step) {
      setStep(values.meta.step);
    }
  }, [step, setFieldValue, values?.meta?.step]);

  const state = { step, canGoNext, canGoPrev };
  const handlers = { onChange, onNext, onPrev };

  return [state, handlers] as [typeof state, typeof handlers];
};
