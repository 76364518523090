import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { notify } from '@root/shared/utils/notification';

import { resetTradeHistory } from '../services/reset-trade-history.service';
import { accountsSelector } from '../store/accounts.selector';
import { accountsSlice } from '../store/accounts.slice';

export const useResetAccount = () => {
  const { t } = useTranslation('forex-accounts');
  const [loading, setLoading] = useState<boolean>(false);
  const resetAccountId = useSelector(accountsSelector.resetAccountId);

  const dispatch = useDispatch();

  const setResetAccount = useCallback(
    (id: string | null) => {
      dispatch(accountsSlice.actions.setResetAccountId(id));
    },
    [dispatch],
  );

  const resetAccount = useCallback(async () => {
    if (resetAccountId) {
      const response = await resetTradeHistory(resetAccountId);

      if (response.status === 200) {
        dispatch(accountsSlice.actions.setResetAccountId(null));
        queryClient.invalidateQueries(['fx', { accounts: resetAccountId }, 'orders-history'], { exact: false });

        notify({
          title: t('resetModal.successMessage'),
          type: 'success',
        });
      }
    } else {
      notify({
        title: t('resetModal.errorMessage'),
        type: 'danger',
      });
    }
  }, [dispatch, resetAccountId, t]);

  return {
    resetAccountId,
    loading,
    resetAccount,
    setLoading,
    setResetAccount,
  };
};
