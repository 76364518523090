import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const YoutubeWhiteIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width={40} height={40} viewBox='0 0 40 40' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.3418 21.7573H12.6922V28.4354H13.9872V21.7573H15.3376V20.6288H11.3418V21.7573ZM18.1679 27.048C17.9459 27.307 17.1875 27.825 17.1875 27.085V22.6637H16.0405V27.492C16.0405 28.9534 17.502 28.5649 18.1864 27.8065V28.4354H19.3334V22.6637H18.1864V27.048H18.1679ZM22.4412 22.6267C21.7752 22.6267 21.3497 23.1262 21.3497 23.1262V20.6104H20.1843V28.4354H21.3497V27.9914C21.3497 27.9914 21.7382 28.5094 22.4412 28.5094C23.0516 28.5094 23.5141 27.9729 23.5141 27.233V24.0327C23.5141 23.1632 23.1071 22.6267 22.4412 22.6267ZM22.3117 27.085C22.3117 27.5105 22.0157 27.714 21.6272 27.5475C21.5223 27.4984 21.4281 27.4293 21.3497 27.344V23.7552C21.4237 23.6812 21.5162 23.6257 21.5902 23.5702C21.9972 23.3667 22.3117 23.6812 22.3117 24.1067V27.085ZM26.585 26.789C26.585 27.233 26.3445 27.529 26.067 27.5475C25.771 27.566 25.475 27.3255 25.475 26.789V25.6976H27.6764V24.2177C27.682 23.9923 27.6424 23.7681 27.5598 23.5584C27.4771 23.3487 27.3532 23.1577 27.1954 22.9967C26.8772 22.7064 26.4607 22.5478 26.03 22.5527C25.623 22.5527 25.1975 22.6822 24.8645 22.9412C24.5131 23.2187 24.2911 23.6442 24.2911 24.2177V26.8075C24.2911 27.3255 24.4576 27.7325 24.7166 28.0284C25.031 28.3614 25.4565 28.5464 25.9005 28.5649C26.437 28.5834 27.0104 28.3614 27.3434 27.8989C27.5469 27.6215 27.6764 27.2515 27.6764 26.8075V26.5115H26.585V26.789ZM25.475 24.2177C25.475 23.9032 25.66 23.5332 26.0115 23.5332C26.363 23.5332 26.585 23.8662 26.585 24.2177V24.8096H25.475V24.2177Z'
        fill='currentColor'
      />
      <path
        d='M19.6491 3C17.4626 3.00012 15.2975 3.43091 13.2775 4.26776C11.2574 5.10461 9.42203 6.33114 7.87602 7.87733C6.33001 9.42351 5.10368 11.2591 4.26705 13.2792C3.43042 15.2993 2.99988 17.4644 3 19.6509C3.00012 21.8374 3.43091 24.0025 4.26776 26.0225C5.10461 28.0426 6.33114 29.878 7.87733 31.424C9.42351 32.97 11.2591 34.1963 13.2792 35.0329C15.2993 35.8696 17.4644 36.3001 19.6509 36.3C24.0668 36.2998 28.3017 34.5453 31.424 31.4227C34.5463 28.3 36.3002 24.0649 36.3 19.6491C36.2998 15.2332 34.5453 10.9983 31.4227 7.87602C28.3 4.75371 24.0649 2.99975 19.6491 3ZM22.5719 9.23415H24.0518V14.1919C24.0518 14.5064 24.1998 14.5064 24.2553 14.5064C24.4773 14.5064 24.8103 14.2659 24.9768 14.0994V9.21565H26.4567V15.7643H24.9768V15.1908C24.7733 15.3758 24.5698 15.5238 24.3663 15.6348C24.0888 15.7828 23.8298 15.8383 23.5709 15.8383C23.4291 15.8451 23.2875 15.8204 23.1564 15.766C23.0253 15.7117 22.9078 15.6289 22.8124 15.5238C22.6459 15.3203 22.5719 15.0058 22.5719 14.6174V9.23415ZM17.8547 10.9546C17.8547 9.93712 18.6871 9.10466 19.7046 9.10466C20.722 9.10466 21.5545 9.93712 21.5545 10.9546V14.0994C21.5545 15.1168 20.722 15.9493 19.7046 15.9493C18.6871 15.9493 17.8547 15.1168 17.8547 14.0994V10.9546ZM14.0069 6.97728L14.8948 10.2331L15.8013 6.97728H17.4847L15.7458 12.12V15.7643H14.0994V12.12L12.3235 6.97728H14.0069ZM28.6211 30.175C27.6776 31.0999 19.6861 31.1184 19.6861 31.1184C19.6861 31.1184 11.713 31.0999 10.7511 30.175C9.80762 29.25 9.80762 24.6438 9.80762 24.6068C9.80762 24.5883 9.80762 19.9821 10.7511 19.0386C11.6945 18.1137 19.6861 18.0952 19.6861 18.0952C19.6861 18.0952 27.6591 18.1137 28.6211 19.0386C29.5645 19.9636 29.583 24.5698 29.583 24.6068C29.583 24.6068 29.583 29.2315 28.6211 30.175Z'
        fill='currentColor'
      />
      <path
        d='M19.6666 14.7464C19.9811 14.7464 20.1291 14.5614 20.1476 14.2654V10.7506C20.1476 10.5101 19.9071 10.3066 19.6851 10.3066C19.4631 10.3066 19.2227 10.4916 19.2227 10.7506V14.2654C19.2227 14.5429 19.3521 14.7279 19.6666 14.7464Z'
        fill='currentColor'
      />
    </svg>
  );
};
