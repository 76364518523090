import { SelectField } from '@root/shared/form/fields';
import { CloseMenuIcon } from '@root/shared/icons/close-menu-icon';
import { DeleteIcon } from '@root/shared/icons/delete-icon';
import { IOption } from '@root/shared/types/general';
import { Label } from '@root/shared/ui/form';
import { Tag, TagsWrapper } from '@root/shared/ui/tags';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

type Props = {
  formFieldName?: string;
  symbols: string[];
  selectAllSymbols: () => void;
  removeSymbolByIndex: (index: number) => void;
  clearAllSymbols: () => void;
  onSymbolChange: (symbols: string[]) => void;
  symbolOptions: IOption[];
  isDisabled: boolean;
  wrapperClassName?: string;
};

export const ExpertSymbolSelect: FC<Props> = ({ wrapperClassName, symbols, selectAllSymbols, removeSymbolByIndex, clearAllSymbols, onSymbolChange, symbolOptions, isDisabled, formFieldName = 'symbols' }) => {
  const { t } = useTranslation('forex-experts');

  return (
    <div className={wrapperClassName}>
      <Label>{t('fields.symbols.label')}</Label>
      {!!symbols?.length && (
        <TagsWrapper>
          <Tag disabled={symbols.length >= 25} onClick={selectAllSymbols} button>
            {t('fields.symbols.actions.selectAll')}
          </Tag>

          {symbols.map((symbol, index) => (
            <Tag suffix={<CloseMenuIcon />} onSuffixClick={() => removeSymbolByIndex(index)} key={symbol}>
              {symbol}
            </Tag>
          ))}
          <Tag
            button
            onClick={clearAllSymbols}
            className='text-gray-500'
            suffix={
              <span className='text-sm'>
                <DeleteIcon />
              </span>
            }
          >
            {t('fields.symbols.actions.clearAll')}
          </Tag>
        </TagsWrapper>
      )}
      <SelectField
        name={formFieldName}
        placeholder={t('fields.symbols.placeholder')}
        wrapperClassName='min-w-[260px]'
        onChange={onSymbolChange}
        options={symbolOptions}
        isDisabled={isDisabled}
        forceHideValue
        forcePlaceholder
        isMulti
      />
    </div>
  );
};
