import { useTranslation } from 'react-i18next';
import { NoDataFound } from '@root/shared/ui/common';
import { ChangeWidget } from '@root/shared/ui/financial-widgets';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';
import dayjs from 'dayjs';
import { FC } from 'react';
import { MarketPlaceTradeIdeaProviderLastIdea } from '../types/marketplace-trade-idea-provider-last-ideas';

interface IProps {
  list?: MarketPlaceTradeIdeaProviderLastIdea[];
  isLoading?: boolean;
  market: 'crypto' | 'forex' | 'indices' | 'synthetic';
}

export const ProviderLastTradeIdeasTable: FC<IProps> = ({ list, isLoading = false, market }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  if (!list?.length && !isLoading) {
    return (
      <div className='py-20'>
        <NoDataFound />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Table.Wrapper>
        <colgroup>
          <col style={{ width: 250 }} />
          <col />
          <col />
          <col />
          <col style={{ width: 180 }} />
        </colgroup>
        <Table.Head>
          <Table.Row className='text-sm'>
            <Table.HeadCell className='pl-0'>{t('table.pair')}</Table.HeadCell>
            <Table.HeadCell>{t('table.side')}</Table.HeadCell>
            <Table.HeadCell>{t('table.gain')}</Table.HeadCell>
            <Table.HeadCell>{t('table.status')}</Table.HeadCell>
            <Table.HeadCell className='pr-0'>{t('table.date')}</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <SkeletonTable columns={6} rows={15} />
        </Table.Body>
      </Table.Wrapper>
    );
  }

  return (
    <Table.Wrapper>
      <colgroup>
        <col style={{ width: 150 }} />
        <col />
        <col />
        <col />
        <col style={{ width: 180 }} />
      </colgroup>
      <Table.Head>
        <Table.Row className='text-sm'>
          <Table.HeadCell className='pl-0'>{t('table.pair')}</Table.HeadCell>
          <Table.HeadCell>{t('table.side')}</Table.HeadCell>
          <Table.HeadCell>{t('table.gain')}</Table.HeadCell>
          <Table.HeadCell>{t('table.status')}</Table.HeadCell>
          <Table.HeadCell className='pr-0'>{t('table.date')}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {list?.map((item) => (
          <Table.Row key={item.id} className='border-t border-grayscale-600'>
            <Table.DataCell className='pl-0'>
              <Table.DataFlex>
                <span className='text-base pl-2'>
                  {(item.pair.baseSymbol && item.pair.quoteSymbol && `${item.pair.baseSymbol}/${item.pair.quoteSymbol}`) || item.pair.baseSymbol || item.pair.quoteSymbol}
                </span>
              </Table.DataFlex>
            </Table.DataCell>

            <Table.DataCell>
              <Table.ItemWithBackground type={item.side.toLowerCase()}>{item.side}</Table.ItemWithBackground>
            </Table.DataCell>

            <Table.DataCell>
              <ChangeWidget value={item.gain} units={market === 'forex' ? 'PIPS' : 'POINTS'}/>
            </Table.DataCell>

            <Table.DataCell className='capitalize'>{item.status}</Table.DataCell>

            <Table.DataCell className='pr-0'>
              {!!item.date && (
                <span>
                  {dayjs(item.date).format('MMMM DD')} <br /> {dayjs(item.date).format('hh:mm:ss A')}
                </span>
              )}
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Wrapper>
  );
};
