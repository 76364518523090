import dayjs from 'dayjs';

import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type SelectProvider = (providerId?: string) => void;
type SelectDate = (fromDate?: Date, toDate?: Date) => void;
type StatProviderProps = { children: React.ReactNode };

const initialParamsState = { isCustomRange: false, providerId: undefined, fromDate: undefined, toDate: undefined, selectProvider: () => {}, selectDate: () => {} };
const StatsFilterContext = createContext<{ isCustomRange: boolean; providerId?: string; fromDate?: Date; toDate?: Date; selectProvider: SelectProvider; selectDate: SelectDate }>(
  initialParamsState,
);

function SelectedStatsProvider({ children }: StatProviderProps) {
  const [providerId, setProviderId] = useState<string | undefined>(undefined);
  const [fromDate, setFromDate] = useState<Date | undefined>(new Date('2024-06-01T00:00:00.000Z'));
  const [toDate, setToDate] = useState<Date | undefined>(new Date(new Date().setHours(23, 59, 59, 999)));

  const selectDate = useCallback((fromDate?: Date, toDate?: Date) => {
    setFromDate(fromDate ? new Date(fromDate.setUTCHours(0, 0, 0, 0)) : fromDate);
    setToDate(toDate ? dayjs(toDate).endOf('day').toDate() : toDate);
  }, []);

  const isCustomRange = useMemo(() => {
    if (fromDate && dayjs(fromDate).startOf('day').toDate().getTime() !== dayjs('2024-06-01').startOf('day').toDate().getTime()) {
      return true;
    }

    if (toDate && dayjs(toDate).endOf('day').toDate().getTime() !== dayjs().endOf('day').toDate().getTime()) {
      return true;
    }

    return false;
  }, [fromDate, toDate]);

  const value = {
    isCustomRange,
    providerId,
    fromDate,
    toDate,
    selectProvider: setProviderId,

    selectDate,
  };

  return <StatsFilterContext.Provider value={value}>{children}</StatsFilterContext.Provider>;
}

function useGetSelectedStatFilters() {
  const context = useContext(StatsFilterContext);
  if (context === undefined) {
    throw new Error('useStatFilter must be used within a StatsFilterContext');
  }
  return context;
}

export { SelectedStatsProvider, useGetSelectedStatFilters };
