import { FC, PropsWithChildren } from 'react';
import { Popover } from 'react-tiny-popover';

import { usePopover } from '@root/shared/hooks/use-popover';

export const SymbolsPopover: FC<PropsWithChildren & { symbols: string[] }> = ({ symbols, children }) => {
  const popover = usePopover({ trigger: 'click' });

  return (
    <Popover
      {...popover.props}
      positions={['bottom']}
      align='start'
      padding={4}
      content={
        <div className='bg-grayscale-700 p-4 max-w-xl' {...popover.contentProps}>
          <div className='-m-1 flex flex-wrap'>
            {symbols.map((symbol) => (
              <div key={symbol} className='m-1 text-[15px] bg-gray-700 px-2 py-px rounded-full'>
                {symbol}
              </div>
            ))}
          </div>
        </div>
      }
    >
      <div {...popover.childrenProps}>{children}</div>
    </Popover>
  );
};
