import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const StopIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.816406 2.16039C0.816406 1.60258 1.2686 1.15039 1.82641 1.15039H9.50641C10.0642 1.15039 10.5164 1.60258 10.5164 2.16039V9.84039C10.5164 10.3982 10.0642 10.8504 9.50641 10.8504H1.82641C1.2686 10.8504 0.816406 10.3982 0.816406 9.84039V2.16039Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
