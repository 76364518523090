import { rgba } from 'polished';
import styled from 'styled-components';

import { ExpertStatus } from '@root/modules/experts/enums';

export const StatusBagde = styled.div<{ isRunning: boolean }>`
  padding: 2px 8px;
  border-radius: 15px;
  background: ${({ theme, isRunning }) => (isRunning ? rgba(theme.success[400], 0.2) : rgba(theme.grayscale[500], 0.2))};
  width: min-content;
`;

export const ExpertStatusContainer = styled.div<{ status: ExpertStatus }>`
  padding: 2px 8px;
  border-radius: 15px;
  background: ${({ theme, status }) => {
    switch (status) {
      case ExpertStatus.Running:
        return rgba(theme.success[400], 0.2);
      case ExpertStatus.Stopped:
        return rgba(theme.grayscale[500], 0.2);
      case ExpertStatus.DDLimit:
        return rgba(theme.grayscale[500], 0.2);
      case ExpertStatus.Paused:
        return rgba(theme.grayscale[500], 0.2);
    }
  }};
  width: min-content;
  color: ${({ theme, status }) => (status === ExpertStatus.Paused ? 'rgb(255, 164, 98)' : theme.gray[100])};
  margin-top: ${({ status }) => (status === ExpertStatus.Paused ? 5 : 0)}px;
  text-transform: capitalize;
`;
