import { useFormikContext } from 'formik';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { Text, Title } from '@root/shared/ui/typography';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';

export const Preview = () => {
  const { t } = useTranslation('forex-experts');
  const { traders } = useProviderTraders();
  const { data: accounts } = useGetAccounts();

  const { values } = useFormikContext<CreateExpertDto>();
  const options = useCreateExpertFieldOptions(values?.unitType);

  const provider = useMemo(() => traders.find((item) => item.providerId === values.providerId), [values.providerId, traders]);
  const selectedAccount = useMemo(() => accounts?.find((item) => (item.id as string) === values.account), [accounts, values.account]);

  const isSignalStrategyType = values.strategy.type === 'signal';
  const accountName = selectedAccount?.username + ' ' + selectedAccount?.company;
  const providerName = provider?.name;
  const description = values.description || '';

  const isTpSlHidden = useMemo(
    () => values.strategy.type === 'signal' && ['2', '3', '4'].includes(options.signalStopLossTakeProfit.find((item) => item.value === values.signalSlTp.type)?.value || ''),
    [options.signalStopLossTakeProfit, values.signalSlTp.type, values.strategy.type],
  );

  const unitType = values.unitType;

  const tpProximityUnitOption = options.tpProximityOptions.find((option) => option.value === values.strategy.tpProximityType);
  const priceToleranceTypeOption = options.priceToleranceUnitTypeOptions.find((option) => option.value === values.strategy.priceToleranceType);
  const expiryTimeTypeOption = options.expiryTimeTypeOptions.find((option) => option.value === values.strategy.timeToleranceType);

  const ddUse = values.maxDrawDownLimit.use;
  const ddPercent = values.maxDrawDownLimit.percent;
  const ddPeriod = values.maxDrawDownLimit.period;
  const ddPeriodLabel = options.drawdownLimitPeriodOptions.find((item) => item.value === ddPeriod)?.label;
  const ddBasedOn = values.maxDrawDownLimit.basedOn;
  const ddBasedOnLabel = options.drawdownBalanceTypeOptions.find((item) => item.value === ddBasedOn)?.label;
  const moneyManagement = values.strategy.type === 'manual' ? values.manualMoneyManagement : values.signalMoneyManagement;
  const hasDifferentBaseForDD = moneyManagement.type === '0';

  const sessionManagementUse = values.sessionManagement?.use || false;
  const sessionManagementType = values.sessionManagement?.type;
  const sessionManagementTypeLabel = options.sessionManagementTypeOptions.find((item) => item.value === sessionManagementType)?.label;
  const sessionManagementSessions = values.sessionManagement?.sessions;
  const sessionManagementSessionsLabels = sessionManagementSessions?.length !== options.sessionManagementSessionOptions.length - 1 ? options.sessionManagementSessionOptions.filter((item) => sessionManagementSessions?.includes(item.value))?.map(item => item.label) : [options?.sessionManagementSessionOptions[0].label];
  const hasSessionManagementThreshold = sessionManagementType === FxSessionManagementActionType.CLOSE_IF_IN_PROFIT;
  const sessionManagementThreshold = values.sessionManagement?.threshold || '-';
  const orderType = options.orderTypeOptions.find((item) => item.value === values.orderType)?.label;
  const orderDirection = options.orderDirectionOptions.find((item) => item.value === values.orderDirection)?.label;

  return (
    <div>
      <Title level={7} className='mb-4'>
        {t('steps.preview.settingsPreview')}
      </Title>

      {/* General settings */}
      <Text size='sm' className='mb-2 font-medium'>
        {t('steps.preview.generalSettings')}
      </Text>
      <div className='bg-grayscale-700 p-4 rounded-md mb-4'>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.name.text')}</Text>
          <Text>{values.name}</Text>
        </div>
        {description && (
          <div className='flex justify-between mt-2 mb-2'>
            <Text className='text-grayscale-400'>{t('fields.description.text')}</Text>
            <Text>{description}</Text>
          </div>
        )}
        <div className='flex justify-between mb-2 gap-x-2'>
          <Text className='text-grayscale-400'>{t('fields.account.text')}</Text>
          <Text className='text-right'>{accountName}</Text>
        </div>

        <div className='flex justify-between mb-2 gap-x-2'>
          <Text className='text-grayscale-400'>{t('fields.strategyTypeWebhook.label')}</Text>
          <Text className='text-right'>{options.strategyTypeOptions.find((item) => item.value === values.strategy.type)?.label}</Text>
        </div>

        {isSignalStrategyType && (
          <div className='flex justify-between mt-2 mb-2'>
            <Text className='text-grayscale-400'>{t('fields.providerId.text')}</Text>
            <Text>{providerName}</Text>
          </div>
        )}
        <div className='flex justify-between mb-2'>
          <Text className='text-gray-500'>{t('fields.units.label')}</Text>
          <Text>{values.unitType}</Text>
        </div>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.slippage.use')}</Text>
          <Text>{Number(values.slippage) || t('actions.off')}</Text>
        </div>
        {isSignalStrategyType && (
          <>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.priceTolerance.label')}</Text>
              <Text>
                {values.strategy.usePriceTolerance ? `${values.strategy.priceTolerance} ${priceToleranceTypeOption?.label}` : t('actions.off')}
              </Text>
            </div>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.expiryTime.label')}</Text>
              <Text>
                {values.strategy.usePriceTolerance && Number(values.strategy.timeTolerance) ? `${values.strategy.timeTolerance} ${expiryTimeTypeOption?.label}` : t('actions.off')}
              </Text>
            </div>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.postSignalValidation.label')}</Text>
              <Text>
                {values.strategy.usePostSignalProximity ? t('actions.on') : t('actions.off')}
              </Text>
            </div>
            {
              values.strategy.usePostSignalProximity && (
                <div className='flex justify-between mb-2'>
                  <Text className='text-grayscale-400'>{t('fields.tpProximity.label')}</Text>
                  <Text>
                    {values.strategy.tpProximity} {tpProximityUnitOption?.label}
                  </Text>
                </div>
              )
            }
            {values.conditionalClosure && (
              <Fragment>
                <div className='flex justify-between mb-2'>
                  <Text className='text-grayscale-400'>{t('fields.conditionalClosure.use.label')}</Text>
                  <Text>
                    {values.conditionalClosure.use ? t('fields.conditionalClosure.use.options.selected.label', {point: unitType}) : t('fields.conditionalClosure.use.options.notSelected.label')}
                  </Text>
                </div>

                {values.conditionalClosure.use && (
                  <div className='flex justify-between mb-2'>
                    <Text className='text-grayscale-400'>{t('fields.conditionalClosure.amount.label')}</Text>
                    <Text>
                      {values.conditionalClosure.amount || '-'} {unitType}
                    </Text>
                  </div>
                )}
              </Fragment>
            )}
          </>
        )}
      </div>

      {/* Conditional settings */}
      <Text size='sm' className='mb-2 font-medium'>
        {t('steps.preview.conditionalSettings')}
      </Text>
      <div className='bg-grayscale-700 p-4 rounded-md mb-4'>
        <div className='flex justify-between mb-2 gap-x-2'>
          <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.type.label')}</Text>
          {values.strategy.type === 'manual' && <Text className='text-right'>{options.manualMoneyManagementType.find((item) => item.value === values.manualMoneyManagement.type)?.label}</Text>}
          {values.strategy.type === 'signal' && <Text className='text-right'>{options.signalMoneyManagementType.find((item) => item.value === values.signalMoneyManagement.type)?.label}</Text>}
        </div>

        {values.strategy.type === 'manual' && (
          <Fragment>
            {['0', '2'].includes(values.manualMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.lotsize.label')}</Text>
                <Text>{values.manualMoneyManagement.lotsize}</Text>
              </div>
            )}
            {['1', '3'].includes(values.manualMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.riskPercent.shortLabel')}</Text>
                <Text>{values.manualMoneyManagement.riskPercent} %</Text>
              </div>
            )}
            {['1', '2', '3', '4'].includes(values.manualMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.basedOn.label')}</Text>
                <Text>{options.manualMoneyManagementBasedOn.find((item) => item.value === values.manualMoneyManagement.basedOn)?.label}</Text>
              </div>
            )}
            {['2'].includes(values.manualMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.k.shortLabel')}</Text>
                <Text>{values.manualMoneyManagement.k}</Text>
              </div>
            )}
          </Fragment>
        )}

        {values.strategy.type === 'signal' && (
          <Fragment>
            {['0', '2'].includes(values.signalMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.signalMoneyManagement.lotsize.label')}</Text>
                <Text>{values.signalMoneyManagement.lotsize}</Text>
              </div>
            )}
            {['1', '3'].includes(values.signalMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.signalMoneyManagement.riskPercent.shortLabel')}</Text>
                <Text>{values.signalMoneyManagement.riskPercent} %</Text>
              </div>
            )}
            {['1', '2', '3', '4'].includes(values.signalMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.signalMoneyManagement.basedOn.label')}</Text>
                <Text>{options.signalMoneyManagementBasedOn.find((item) => item.value === values.signalMoneyManagement.basedOn)?.label}</Text>
              </div>
            )}
            {['2'].includes(values.signalMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.signalMoneyManagement.k.shortLabel')}</Text>
                <Text>{values.signalMoneyManagement.k}</Text>
              </div>
            )}
            {['3', '4'].includes(values.signalMoneyManagement.type) && (
              <div className='flex justify-between mb-2'>
                <Text className='text-grayscale-400'>{t('fields.signalMoneyManagement.xPercent.sortLabel')}</Text>
                <Text>{values.signalMoneyManagement.xPercent}</Text>
              </div>
            )}
          </Fragment>
        )}
        <div className="flex justify-between mb-2">
          <Text className='text-grayscale-400'>{t('fields.drawdown.use.label')}</Text>
          <Text>{ddUse ? t('actions.on') : t('actions.off')}</Text>
        </div>
        {
          ddUse && (
            <Fragment>
              {hasDifferentBaseForDD && (
                <div className="flex justify-between mb-2">
                  <Text className='text-grayscale-400'>{t('fields.drawdown.balanceType.extendedLabel')}</Text>
                  <Text>{ddBasedOnLabel}</Text>
                </div>
              )}
              <div className="flex justify-between mb-2">
                <Text className='text-grayscale-400'>{t('fields.drawdown.percent.label')}</Text>
                <Text>{ddPercent}%</Text>
              </div>
              <div className="flex justify-between mb-2">
                <Text className='text-grayscale-400'>{t('fields.drawdown.drawdownLimitPeriod.label')}</Text>
                <Text>{ddPeriodLabel}</Text>
              </div>
            </Fragment>
          )
        }
        <div className="flex justify-between mb-2">
          <Text className='text-grayscale-400'>{t('fields.sessionManagement.use.label')}</Text>
          <Text>{sessionManagementUse ? t('actions.on') : t('actions.off')}</Text>
        </div>
        {
          sessionManagementUse && (
            <Fragment>
              <div className="flex justify-between mb-2">
                <Text className='text-grayscale-400'>{t('fields.sessionManagement.sessions.label')}</Text>
                <Text className='text-right'>{sessionManagementSessionsLabels.join(', ')}</Text>
              </div>
              <div className="flex justify-between mb-2">
                <Text className='text-grayscale-400'>{t('fields.sessionManagement.type.shortLabel')}</Text>
                <Text>{sessionManagementTypeLabel}</Text>
              </div>
              {hasSessionManagementThreshold && (
                <div className="flex justify-between mb-2">
                  <Text className='text-grayscale-400'>{t('fields.sessionManagement.threshold.label')}</Text>
                  <Text>{sessionManagementThreshold}</Text>
                </div>
              )}
            </Fragment>
          )
        }

        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.manualMoneyManagement.maxActiveTrades.label')}</Text>
          {values.strategy.type === 'manual' && <Text>{values.maxManualActiveTrades}</Text>}
          {values.strategy.type === 'signal' && <Text>{values.maxSignalActiveTrades}</Text>}
        </div>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.breakEven.use.label')}</Text>
          <Text>{values.breakEven.use ? t('actions.on') : t('actions.off')}</Text>
        </div>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.partialClose.use.label')}</Text>
          <Text>{values.partialClose.use ? t('actions.on') : t('actions.off')}</Text>
        </div>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.trailingStop.use.label')}</Text>
          <Text>{values.trailStop.use ? t('actions.on') : t('actions.off')}</Text>
        </div>
        {values.trailStop.use && (
          <>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.trailingStop.fromX.label')}</Text>
              <Text>
                {values.trailStop.afterX || '-'} {unitType}
              </Text>
            </div>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.trailingStop.toY.label')}</Text>
              <Text>
                {values.trailStop.followY || '-'} {unitType}
              </Text>
            </div>
          </>
        )}
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.manualStopLossTakeProfit.use.label')}</Text>
          {values.strategy.type === 'manual' && <Text>{options.manualStopLossTakeProfitType.find((item) => item.value === values.manualSlTp.type)?.label}</Text>}
          {values.strategy.type === 'signal' && <Text>{options.signalStopLossTakeProfit.find((item) => item.value === values.signalSlTp.type)?.label}</Text>}
        </div>
        {!isTpSlHidden && (
          <>
            <div className='flex justify-between mb-2'>
              <Text className='text-grayscale-400'>{t('fields.fixedStopLoss.label')}</Text>
              {values.strategy.type === 'manual' && <Text>{values.manualSlTp.fixedSl}</Text>}
              {values.strategy.type === 'signal' && <Text>{values.signalSlTp.fixedSl}</Text>}
            </div>
            <div className='flex justify-between mb-2'>
              {values.strategy.type === 'manual' && (
                <Fragment>
                  {/* Fixed SL with TP ratio */}
                  {values.manualSlTp.type === '1' ? (
                    <Fragment>
                      <Text className='text-grayscale-400'>{t('fields.ratioTakeProfit.label')}</Text>
                      <Text>{values.manualSlTp.tpRatio}</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text className='text-grayscale-400'>{t('fields.ratioTakeProfit.label')}</Text>
                      <Text>{values.manualSlTp.fixedTp}</Text>
                    </Fragment>
                  )}
                </Fragment>
              )}

              {values.strategy.type === 'signal' && (
                <Fragment>
                  {/* Fixed SL with TP ratio */}
                  {values.signalSlTp.type === '1' ? (
                    <Fragment>
                      <Text className='text-grayscale-400'>{t('fields.ratioTakeProfit.label')}</Text>
                      <Text>{values.signalSlTp.tpRatio}</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text className='text-grayscale-400'>{t('fields.ratioTakeProfit.label')}</Text>
                      <Text>{values.signalSlTp.fixedTp}</Text>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </>
        )}
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.orderDirection.label')}</Text>
          <Text>{orderDirection}</Text>
        </div>
        <div className='flex justify-between mb-2'>
          <Text className='text-grayscale-400'>{t('fields.orderType.label')}</Text>
          <Text>{orderType}</Text>
        </div>
      </div>

      {/* Currency pairs */}
      <Text size='sm' className='mb-2 font-medium'>
        {t('steps.preview.currencyPairs')}
      </Text>
      <div className='p-4 bg-grayscale-700 mb-4 flex flex-wrap -m-1 rounded'>
        {values.symbols.map((symbol) => (
          <div className='bg-gray-700 m-1 px-2 py-1 rounded-sm' key={symbol}>
            {symbol}
          </div>
        ))}
      </div>
    </div>
  );
};
