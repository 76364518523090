import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const TelegramIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13.0751 8.56648L4.99422 11.896C3.57225 12.4509 4.40462 12.9711 4.40462 12.9711C4.40462 12.9711 5.6185 13.3873 6.65896 13.6994C7.69942 14.0116 8.25433 13.6647 8.25433 13.6647L13.1445 10.3699C14.8786 9.19075 14.4624 10.1619 14.0462 10.578C13.1445 11.4798 11.6532 12.9017 10.4046 14.0462C9.84971 14.5318 10.1272 14.948 10.3699 15.1561C11.0757 15.7533 12.7375 16.8391 13.493 17.3327C13.7028 17.4698 13.8427 17.5612 13.8728 17.5838C14.0462 17.7225 15.0173 18.3468 15.6069 18.2081C16.1965 18.0694 16.2659 17.2717 16.2659 17.2717L17.1329 11.8266C17.21 11.3161 17.2871 10.8163 17.3589 10.3503C17.5458 9.13815 17.6975 8.15417 17.7225 7.80347C17.8266 6.62428 16.578 7.10983 16.578 7.10983C16.578 7.10983 13.8728 8.21966 13.0751 8.56648Z'
      />
    </svg>
  );
};
