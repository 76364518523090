import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { TradeStats } from '@root/modules/orders/types/statistic';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetExpertStatServiceResponse = IHttpResponse<200, TradeStats> | IHttpResponse<400, string>;
export type GetExpertsStatsServiceQuery = { id: string };

export const getExpertStatistic = async ({ id }: GetExpertsStatsServiceQuery): Promise<GetExpertStatServiceResponse> => {
  try {
    const response = await fetcher.get(`/experts/${id}/stats`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data.stats,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
