import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'tailwind-styled-components';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TextField } from '@root/shared/form';
import { ButtonRadioField, ButtonRadioGroup } from '@root/shared/form/fields/button-radio-field';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { IconLabel, Select } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';
import { useCreateExpertFieldOptions } from '../hooks/use-create-expert-options';
import RelativePortal from 'react-relative-portal';
import { makeExpertFormValues } from '../helpers/make-expert-form-values';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';

const LabelText = tw(Text)`!text-[16px] text-gray-100`;

export const AdvancedSettings: FC<{ isPartialEdit?: boolean }> = ({
  isPartialEdit,
}) => {
  const { t } = useTranslation('forex-experts');

  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();

  const strategyType = values.strategy.type;
  const usePriceTolerance = values.strategy.usePriceTolerance;
  const usePostSignalProximity = values.strategy.usePostSignalProximity;

  const options = useCreateExpertFieldOptions(values?.unitType);
  const priceToleranceUnitOption = options.priceToleranceUnitTypeOptions.find((option) => option.value === values.strategy.priceToleranceType);
  const expiryTimeUnitOption = options.expiryTimeTypeOptions.find((option) => option.value === values.strategy.timeToleranceType);
  const tpProximityUnitOption = options.tpProximityOptions.find((option) => option.value === values.strategy.tpProximityType);

  const onUseSlippageChange = useCallback((event) => {
    const checked = event.target.checked;
    const defaultValues = makeExpertFormValues();

    if (checked) {
      setFieldValue('useSlippage', true);
      setFieldValue('slippage', defaultValues.slippage);
    } else {
      setFieldValue('useSlippage', false);
    }
  }, [setFieldValue]);

  const onUsePriceToleranceChange = useCallback((event) => {
    const checked = event.target.checked;
    const defaultValues = makeExpertFormValues();

    if (checked) {
      setFieldValue('strategy.usePriceTolerance', true);
      setFieldValue('strategy.priceTolerance', defaultValues.strategy.priceTolerance);
      setFieldValue('strategy.priceToleranceType', defaultValues.strategy.priceToleranceType);
      setFieldValue('strategy.timeTolerance', defaultValues.strategy.timeTolerance);
      setFieldValue('strategy.timeToleranceType', defaultValues.strategy.timeToleranceType);
    } else {
      setFieldValue('strategy.usePriceTolerance', false);
    }
  }, [setFieldValue]);

  const onUseTpProximityChange = useCallback((event) => {
    const checked = event.target.checked;
    const defaultValues = makeExpertFormValues();

    if (checked) {
      setFieldValue('strategy.usePostSignalProximity', true);
      setFieldValue('strategy.tpProximity', defaultValues.strategy.tpProximity);
      setFieldValue('strategy.tpProximityType', defaultValues.strategy.tpProximityType);
    } else {
      setFieldValue('strategy.usePostSignalProximity', false);
    }
  }, [setFieldValue]);

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.advanced.title')}
        </Title>
      )}

      <SwitchField
        label={
          <IconLabel
            wrapperClassName='mb-0'
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.slippage.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            <LabelText>{t('fields.slippage.use')}</LabelText>
          </IconLabel>
        }
        name='useSlippage'
        onChange={onUseSlippageChange}
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
      />

      {values.useSlippage && (
        <TextField
          name='slippage'
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          label={
            <IconLabel
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.slippage.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.slippage.label')}
            </IconLabel>
          }
          placeholder={t('fields.slippage.placeholder')}
        />
      )}

      {/* <SwitchField
        label={
          <IconLabel
            wrapperClassName='mb-0'
            icon={
              <InfoTooltip content={t('fields.usePips.description')}>
                <SvgSpriteIcon id='info-tooltip' />
              </InfoTooltip>
            }
          >
            <LabelText>{t('fields.usePips.label')}</LabelText>
          </IconLabel>
        }
        name='usePips'
        disabled={unitType === TemplateUnitType.PIPS}
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
      />

      {/* TODO - hide manual strategy - only signal will be available for now */}
      {/* {strategyType !== 'signal' && (
        <Fragment>
          <Title level={8}>{t('fields.strategyType.label')}</Title>
          <Text className='text-grayscale-400 mb-4 font-medium' size='sm'>
            {t('fields.strategyType.description')}
          </Text>
          <ButtonRadioGroup className={clsx('mb-4 !gap-x-4', { 'mb-14': strategyType !== 'signal' })}>
            <ButtonRadioField name='strategy.type' value='manual'>
              {t('fields.strategyType.options.manual')}
            </ButtonRadioField>

            <ButtonRadioField name='strategy.type' value='signal'>
              {t('fields.strategyType.options.signal')}
            </ButtonRadioField>
          </ButtonRadioGroup>
        </Fragment>
      )} */}

      {strategyType === 'signal' && (
        <SwitchField
          label={
            <IconLabel
              wrapperClassName='mb-0'
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.priceTolerance.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              <LabelText>{t('fields.priceTolerance.label')}</LabelText>
            </IconLabel>
          }
          name='strategy.usePriceTolerance'
          onChange={onUsePriceToleranceChange}
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        />
      )}

      {strategyType === 'signal' && usePriceTolerance && (
        <div className={clsx({ 'mb-8': !isPartialEdit, 'mb-2': isPartialEdit })}>
          <TextField
            name='strategy.priceTolerance'
            type='number'
            className='!py-1.5'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={t('fields.priceTolerance.description')}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.priceTolerance.label')}
              </IconLabel>
            }
            placeholder={t('fields.priceTolerance.placeholder')}
            suffix={
              <div className='relative h-6 w-[80px]'>
                <RelativePortal>
                  <div className="w-[80px]">
                    <Select
                      value={priceToleranceUnitOption}
                      options={options.priceToleranceUnitTypeOptions}
                      onChange={(option) => setFieldValue('strategy.priceToleranceType', option.value)}
                      isSearchable={false}
                      xs
                    />
                  </div>
                </RelativePortal>
              </div>
            }
            suffixClassName='sm:max-w-none text-sm sm:text-[16px] truncate !z-10'
            wrapperClassName='mb-4'
          />
          <TextField
            name='strategy.timeTolerance'
            wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
            type='number'
            label={
              <IconLabel
                icon={
                  <InfoTooltip content={t('fields.expiryTime.description')}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.expiryTime.label')}
              </IconLabel>
            }
            placeholder={t('fields.expiryTime.placeholder')}
            suffix={
              <div className='relative h-6 w-[72px]'>
                <RelativePortal>
                  <div className="w-[72px]">
                    <Select
                      value={expiryTimeUnitOption}
                      options={options.expiryTimeTypeOptions}
                      onChange={(option) => setFieldValue('strategy.timeToleranceType', option.value)}
                      isSearchable={false}
                      xs
                    />
                  </div>
                </RelativePortal>
              </div>
            }
          />
        </div>
      )}

      {strategyType === 'signal' && (
        <SwitchField
          label={<LabelText>{t('fields.postSignalValidation.label')}</LabelText>}
          name='strategy.usePostSignalProximity'
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          onChange={onUseTpProximityChange}
        />
      )}

      {strategyType === 'signal' && usePostSignalProximity && (
        <TextField
          name='strategy.tpProximity'
          type='number'
          label={
            <IconLabel
              icon={
                <InfoTooltip content={t('fields.tpProximity.description')}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.tpProximity.label')}
            </IconLabel>
          }
          placeholder={t('fields.tpProximity.placeholder')}
          suffix={
            <div className='relative h-6 w-[80px]'>
              <RelativePortal>
                <div className="w-[80px]">
                  <Select
                    value={tpProximityUnitOption}
                    options={options.tpProximityOptions}
                    onChange={(option) => setFieldValue('strategy.tpProximityType', option.value)}
                    isSearchable={false}
                    xs
                  />
                </div>
              </RelativePortal>
            </div>
          }
          suffixClassName='sm:max-w-none text-sm sm:text-[16px] truncate'
          wrapperClassName='mb-4'
        />
      )}
    </div>
  );
};
