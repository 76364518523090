import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const ThreeDotsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='4' height='16' viewBox='0 0 4 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.48552 0.673096C1.66206 0.673096 0.982181 1.34327 0.988323 2.17877L0.988313 2.1733H1.61942L0.988323 2.17877C0.991277 3.00366 1.66294 3.67351 2.48852 3.67351C3.31592 3.67351 3.98873 3.0007 3.98873 2.1733C3.98873 1.34202 3.31202 0.673096 2.48552 0.673096ZM0.988323 8.18121C0.982181 7.34571 1.66206 6.67554 2.48552 6.67554C3.31202 6.67554 3.98873 7.34446 3.98873 8.17575C3.98873 9.00315 3.31592 9.67595 2.48852 9.67595C1.66294 9.67595 0.991277 9.00611 0.988323 8.18121ZM0.988323 8.18121L1.61942 8.17575H0.988313L0.988323 8.18121ZM0.988323 14.1837C0.982181 13.3482 1.66206 12.678 2.48552 12.678C3.31202 12.678 3.98873 13.3469 3.98873 14.1782C3.98873 15.0056 3.31592 15.6784 2.48852 15.6784C1.66294 15.6784 0.991277 15.0085 0.988323 14.1837ZM0.988323 14.1837L1.61942 14.1782H0.988313L0.988323 14.1837Z'
        fill='currentColor'
      />
    </svg>
  );
};
