export enum TemplateUnitType {
  POINTS = 'POINTS',
  PIPS = 'PIPS',
}

export enum ExpertOrderDirection {
  BUY = 'BUY',
  SELL = 'SELL',
  NEUTRAL = 'NEUTRAL',
}

export enum ExpertOrderType {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  NEUTRAL = 'NEUTRAL',
}