import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import infoImg from '@root/assets/images/cases/info.webp';
import { SelectField } from '@root/shared/form';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { CopyWrapper } from '@root/shared/ui/copy-wrapper/copy-wrapper';
import { Label } from '@root/shared/ui/form';
import { FieldWrapper } from '@root/shared/ui/form/field/field-wrapper';
import { Image } from '@root/shared/ui/image';
import { Text, Title } from '@root/shared/ui/typography';

import { HOW_IT_WORKS_LINK } from '../../constants/alert-links';
import { useAlertFields } from '../../hooks/use-alert-fields';
import { useAlertForm } from '../../hooks/use-alert-form';
import { Alert } from '../../types/alert-form';
import { IExpert } from '../../types/expert';

type Props = {
  expert: IExpert;
};

type AlertFormProps = FormikProps<Alert> & {
  url: string;
  expertId: string;
  isFormDisabled: boolean;
};

export const AlertsForm: FC<AlertFormProps> = ({ expertId, url, handleSubmit, isFormDisabled }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('forex-experts');
  const [{ generatedData, nameDescriptionKey, alertNameOptions, symbolOptions, isLoading, isSymbolAvailable }, { copyElement }] = useAlertFields(expertId);

  const jsonText = useMemo(() => (Object.keys(generatedData || {}).length ? JSON.stringify(generatedData, null, 2) : ''), [generatedData]);
  const getTextColor = (originalColor) => (isFormDisabled ? 'text-grayscale-600' : originalColor);

  const prettyJson = useMemo(
    () =>
      `{<br /> <p class="pl-4">${Object.keys(generatedData)
        .map((key) => `<span>&quot;${key}&quot;: &quot;${generatedData[key]}&quot;</span>`)
        .join(',<br/>')}<br/></p>}`,
    [generatedData],
  );

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      {url && (
        <div className='flex items-center gap-x-3 cursor-pointer mb-2' onClick={() => copyElement(url)}>
          <Text size='sm' className={getTextColor('text-success-400')}>
            {url}
          </Text>
          <CopyWrapper onCopy={() => copyElement(url)}>
            <CopyIcon className='text-grayscale-600' />
          </CopyWrapper>
        </div>
      )}
      <div className={clsx('grid md:grid-cols-2 gap-8', { 'text-grayscale-600': isFormDisabled })}>
        <div>
          <Label>{t('alerts.fields.name.label')}</Label>
          <Text size='sm' className={getTextColor('text-grayscale-400')}>
            {t('alerts.fields.name.hint')}
          </Text>
          <SelectField isDisabled={isFormDisabled} name='type' label={null} placeholder={t('alerts.fields.name.placeholder') as string} options={alertNameOptions} />
          <Text size='sm' className={clsx('mt-3 text-grayscale-400', { 'text-grayscale-600': isFormDisabled })}>
            <Trans i18nKey={`forex-experts:${nameDescriptionKey}`} components={{ i: <i /> }} />
          </Text>
        </div>
        <div ref={ref}>
          {isSymbolAvailable && (
            <FieldWrapper name='symbol' variant='danger' className='-mb-5' showIfTouched={true}>
              <Text size='sm' className={clsx('mb-1', { 'text-grayscale-600 cursor-not-allowed': isFormDisabled })}>
                {t('alerts.fields.pair.label')}
              </Text>
              <Text size='sm' className={clsx('text-grayscale-400', { 'text-grayscale-600 cursor-not-allowed': isFormDisabled })}>
                {t('alerts.fields.pair.hint')}
              </Text>
              <div className='flex-1 w-full'>
                <SelectField
                  options={symbolOptions}
                  isDisabled={isLoading || !symbolOptions.length || isFormDisabled}
                  name='symbol'
                  wrapperClassName='mb-6'
                  placeholder={t('alerts.fields.pair.placeholder')}
                />
              </div>
            </FieldWrapper>
          )}
        </div>
      </div>
      <div className={clsx('grid md:grid-cols-2 gap-8', { 'mt-5': !!jsonText, 'text-grayscale-600': isFormDisabled })}>
        {!!jsonText && (
          <div
            className='w-full flex justify-start items-start gap-x-5 cursor-pointer bg-grayscale-100 bg-opacity-10 p-5 rounded overflow-hidden'
            onClick={() => copyElement(jsonText)}
          >
            <Text
              size='sm'
              className={clsx('flex-grow overflow-hidden text-ellipsis', { 'text-grayscale-600': isFormDisabled, 'text-success-400': !isFormDisabled })}
              dangerouslySetInnerHTML={{ __html: prettyJson }}
            />
            <CopyWrapper onCopy={() => copyElement(jsonText)}>
              <div
                className={clsx('rounded p-2', {
                  'bg-gray-700 cursor-not-allowed pointer-events-none': isFormDisabled,
                  'bg-opacity-10 bg-success-400': !isFormDisabled,
                })}
              >
                <CopyIcon className={`flex-shrink-0 ${getTextColor('text-success-400')}`} />
              </div>
            </CopyWrapper>
          </div>
        )}
      </div>
    </form>
  );
};

export const Alerts: FC<Props> = ({ expert }) => {
  const { t } = useTranslation('forex-experts');
  const [{ initialValues, url, schema }] = useAlertForm(expert);
  const isDisabled = !expert.providerId;
  const getTextColor = (originalColor) => (isDisabled ? 'text-grayscale-600' : originalColor);

  return (
    <div className='w-full'>
      {isDisabled && (
        <div className='flex items-center px-3 py-5 rounded-top rounded-left gap-2 bg-grayscale-700'>
          <div className='w-[28px] h-[28px] -mb-1 flex-shrink-0'>
            <Image src={infoImg} width={220} height={220} alt='warning' />
          </div>
          <span className='text-sm'>{t('alerts.hint')}</span>
          <a href={HOW_IT_WORKS_LINK} className='ml-auto' target='_blank' rel='noreferrer'>
            <Text size='sm' className='underline cursor-pointer text-primary-400'>
              {t('alerts.howItWorks')}
            </Text>
          </a>
        </div>
      )}

      <div className='p-5 bg-gray-800 rounded'>
        <div className='w-full flex justify-start items-center gap-x-3 mb-2'>
          <Title className={clsx('font-semibold', { 'text-grayscale-600': isDisabled })} level={7}>
            {t('alerts.createAlert')}
          </Title>

          {!isDisabled && (
            <a href={HOW_IT_WORKS_LINK} target='_blank' rel='noreferrer'>
              <Text size='sm' className={`underline cursor-pointer ${getTextColor('text-primary-400')}`}>
                {t('alerts.howItWorks')}
              </Text>
            </a>
          )}
        </div>
        <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={schema} enableReinitialize validateOnChange>
          {(props) => <AlertsForm {...props} url={url} expertId={expert.id} isFormDisabled={!!isDisabled} />}
        </Formik>
      </div>
    </div>
  );
};
