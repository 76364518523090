import { WS_EVENT_TG_ACCOUNT_LINKED, WS_EVENT_TG_ACCOUNT_UNLINKED } from '@3lgn/shared/dist/constants/ws_event';
import { SagaReturnType, call, fork, race, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { Socket } from 'socket.io-client';

import { END, eventChannel } from 'redux-saga';
import { delay, put, select } from 'redux-saga/effects';

import { queryClient } from '@root/infra/query';
import { updateAccountStatus } from '@root/infra/socket/helpers';
import { SocketDispatchAction } from '@root/infra/socket/socket-action-creator';
import { createSocket } from '@root/infra/socket/socket-instance';
import { SocketActionTypes } from '@root/infra/socket/socket-types';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { IOrder } from '@root/modules/orders/types/orders';
import { QuoteDataMapper } from '@root/modules/quotes/mappers/quote-data.mapper';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { currentSiteMaintenanceKey } from '@root/shared-files/modules/maintenance/mappers/maintenance.mapper';
import { maintenanceSlice } from '@root/shared-files/modules/maintenance/store/maintenance.slice';
import { notify } from '@root/shared/utils/notification';

import { SocketEventPayloads } from './event-payloads';
import { SocketEventTypes } from './event-types';
import { SocketEvents } from './events';

interface ICache {
  orders: Record<string, SocketEventPayloads.OpenOrders['res']>;
  quotes: Record<string, SocketEventPayloads.QuoteMessage>;
}

export const cache: ICache = {
  orders: {},
  quotes: {},
};

type SocketChannel = SagaReturnType<typeof socketChannel>;
type SocketAction =
  | SocketEvents.AccountInfoChange
  | SocketEvents.OpenOrdersListChange
  | SocketEvents.OnQuote
  | SocketEvents.OnMaintenanceModeChange
  | SocketEvents.OnTelegramLinked
  | SocketEvents.OnTelegramUnlinked;

function* handleOpenOrdersUpdate() {
  const nextOrders: Array<Record<string, IOrder[]>> = [];

  Object.keys(cache.orders).forEach((key) => {
    const [accountId, symbol] = key.split('___');
    nextOrders.push({ [symbol]: cache.orders[key]?.map((item) => ({ ...item, accountId })) || [] });
  });

  if (nextOrders.length) {
    queryClient.setQueryData(['open-orders'], () => nextOrders);
  }

  // TODO: think about updating current price here

  yield delay(1000);
  yield call(handleOpenOrdersUpdate);
}

function* handleDebouncedQuotesUpdate() {
  const quotes = Object.values(cache.quotes);
  if (quotes.length) {
    yield put(quotesSlice.actions.setQuotesData(quotes.map(QuoteDataMapper.toDomain)));
  }
  yield delay(1000);
  yield call(handleDebouncedQuotesUpdate);
}

function handleAccountConnectionError(payload: SocketEventPayloads.AccountConnectError) {
  notify({
    title: payload.errorMessage,
    type: 'danger',
  });
}

function socketChannel(socket: Socket) {
  return eventChannel<SocketAction>((emit) => {
    const accountInfoChangeHandler = (payload: SocketEventPayloads.AccountInfoChange) => {
      emit({ type: SocketEventTypes.AccountInfoChange, payload: { ...payload, source: 'socket' } });
    };
    const openOrdersListChangeHandler = (payload: SocketEventPayloads.OpenOrders) => {
      if (payload.res) {
        cache.orders[`${payload.accountId}___${payload.symbol}`] = payload.res;
      }
    };
    const quoteTickChangeHandler = (payload: SocketEventPayloads.QuoteMessage) => {
      cache.quotes[payload.pattern] = payload;
    };
    const maintenanceModeChangeHandler = (payload: SocketEventPayloads.MaintenanceMode) => {
      emit({ type: SocketEventTypes.MaintenanceMode, payload });
    };

    const errorHandler = () => emit(END);

    socket.on(SocketEventTypes.AccountStatusChanged, updateAccountStatus);
    socket.on(SocketEventTypes.AccountConnectError, handleAccountConnectionError);
    socket.on(SocketEventTypes.AccountInfoChange, accountInfoChangeHandler);
    socket.on(SocketEventTypes.OpenOrdersListChange, openOrdersListChangeHandler);
    socket.on(SocketEventTypes.Quote, quoteTickChangeHandler);
    socket.on(SocketEventTypes.MaintenanceMode, maintenanceModeChangeHandler);
    socket.on(
      WS_EVENT_TG_ACCOUNT_LINKED,
      debounce((payload) => {
        return emit({
          type: SocketEventTypes.TelegramLinked,
          payload,
        });
      }, 2000),
    );

    socket.on(
      WS_EVENT_TG_ACCOUNT_UNLINKED,
      debounce(
        (payload) =>
          emit({
            type: SocketEventTypes.TelegramUnlinked,
            payload,
          }),
        2000,
      ),
    );
    socket.on('connect_error', errorHandler);

    return () => {
      socket.off(SocketEventTypes.AccountStatusChanged, updateAccountStatus);
      socket.off(SocketEventTypes.AccountConnectError, handleAccountConnectionError);
      socket.off(SocketEventTypes.AccountInfoChange, accountInfoChangeHandler);
      socket.off(SocketEventTypes.OpenOrdersListChange, openOrdersListChangeHandler);
      socket.off(SocketEventTypes.Quote, quoteTickChangeHandler);
      socket.off(SocketEventTypes.MaintenanceMode, maintenanceModeChangeHandler);
      socket.off('connect_error', errorHandler);
      socket.off('disconnect', errorHandler);
    };
  });
}

function* socketActionHandler({ type, payload }: SocketAction) {
  switch (type) {
    case SocketEventTypes.AccountInfoChange: {
      const { accountId } = payload;
      const isBalanceFetchedData = yield select(accountsSelector.isBalanceFetchedData);

      if (!isBalanceFetchedData[accountId]) {
        yield put(accountsSlice.actions.setAccountBalanceFetched(accountId));
        yield put(accountsSlice.actions.fetchBalanceFulfilled({ list: [payload], source: 'rest' }));
      } else {
        yield put(accountsSlice.actions.fetchBalanceFulfilled({ list: [payload] }));
      }
      return;
    }
    case SocketEventTypes.MaintenanceMode: {
      if (payload.key === currentSiteMaintenanceKey) {
        yield put(maintenanceSlice.actions.setIsMaintenance(payload.value));
      }
      return;
    }

    default: {
      yield put({ type, payload });
      return;
    }
  }
}

function* socketChannelHandler(socket: Socket, channel: SocketChannel) {
  while (true) {
    const { socketAction, shouldCancel }: { socketAction?: SocketAction; shouldCancel?: PayloadAction } = yield race({
      socketAction: take(channel),
      shouldCancel: take([authSlice.actions.signedOut]), // add more actions here to cancel socket
    });

    if (socketAction) {
      yield socketActionHandler(socketAction);
    }

    if (shouldCancel) {
      cache.orders = {};

      channel.close();
      socket.disconnect();
    }
  }
}

function* writeWorker(socket: Socket) {
  while (true) {
    const action: SocketDispatchAction = yield take(SocketActionTypes.EMMIT_EVENT);
    const { eventName, params } = action.payload;
    socket.emit(eventName, params);
  }
}

export function* socketSaga() {
  while (true) {
    yield take(authSlice.actions.fetchProfileFulfilled);

    const socket = createSocket();
    const channel: SocketChannel = yield call(socketChannel, socket);

    yield fork(writeWorker, socket);
    yield fork(socketChannelHandler, socket, channel);
    yield fork(handleOpenOrdersUpdate);
    yield fork(handleDebouncedQuotesUpdate);
  }
}
