import { isBuyOrder, isSellOrder } from '@root/modules/orders/utils/orders';

export const getGainLostColor = (value) => {
  if (!value) {
    return 'text-gray-400';
  } else if (value > 0) {
    return 'text-success-400';
  }
  return 'text-secondary-200';
};

export const getOrderSideColor = (value) => {
  if (isBuyOrder(value)) {
    return 'text-success-400';
  } else if (isSellOrder(value)) {
    return 'text-danger-500';
  }
};

export const getPrefix = (value) => {
  if (!value) {
    return '';
  } else if (value > 0) {
    return '+';
  }
  return '-';
};
