import { Layout } from '@root/infra/layout/containers';
import { CreateWizardExpert } from '../containers/create-wizard-expert';

const UpdateWizardExpertPage = () => {
  return (
    <Layout>
      <CreateWizardExpert />
    </Layout>
  );
};

export default UpdateWizardExpertPage;
