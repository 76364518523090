import { diff as deepDiff } from 'deep-object-diff';

import { useCallback, useMemo, useState } from 'react';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { useGetPresets } from '@root/modules/presets/hooks/use-get-presets';
import { notify } from '@root/shared/utils/notification';

import { CreateExpertDtoMapper } from '../mappers/create-expert-dto.mapper';
import { IExpert } from '../types/expert';
import { useExpertItem } from './use-expert-item';
import { useTranslation } from 'react-i18next';

export const useSyncExpert = (expert: IExpert) => {
  const { t } = useTranslation('forex-experts');

  const { data: presets } = useGetPresets();
  const { hideActions } = useGhostLogin();
  const { handleSync } = useExpertItem(expert);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const submitSync = useCallback(async () => {
    await handleSync();
    closeModal();
    notify({ type: 'success', text: t('actions.syncSuccess') });
  }, [handleSync, closeModal, t]);

  const preset = useMemo(() => presets?.find((preset) => preset.id === expert.expertPresetId), [presets, expert.expertPresetId]);

  const expertValues = (expert && CreateExpertDtoMapper.toDomain({ ...expert, id: undefined } as any)) || null;
  let expertValuesToCompare = {};
  if (expertValues) {
    expertValuesToCompare = { ...expertValues } as any;
    delete expertValuesToCompare['id'];
    delete expertValuesToCompare['account'];
    delete expertValuesToCompare['name'];
    delete expertValuesToCompare['expertPresetId'];
    delete expertValuesToCompare['magicNumber'];
    delete expertValuesToCompare['hitMaxDrawDownLimitUntil'];
    delete expertValuesToCompare['allowClone'];
    delete expertValuesToCompare['allowShare'];
  }

  const presetValues = (preset && CreateExpertDtoMapper.toDomain({ ...preset, accountId: undefined } as any)) || null;
  let presetValuesToCompare = {};
  if (presetValues) {
    presetValuesToCompare = { ...presetValues } as any;
    delete presetValuesToCompare['id'];
    delete presetValuesToCompare['account'];
    delete presetValuesToCompare['name'];
    delete presetValuesToCompare['expertPresetId'];
    delete presetValuesToCompare['magicNumber'];
    delete presetValuesToCompare['hitMaxDrawDownLimitUntil'];
    delete presetValuesToCompare['allowClone'];
    delete presetValuesToCompare['allowShare'];
  }

  const isSyncAvailable = expert && preset && !!Object.keys(deepDiff(expertValuesToCompare, presetValuesToCompare)).length;

  return {
    isModalOpen,
    preset,
    hideActions,
    openModal,
    closeModal,
    expertValues: expertValuesToCompare,
    presetValues: presetValuesToCompare,
    isSyncAvailable,
    submitSync,
  };
};
