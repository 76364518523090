import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSubscribedProviders } from '@root/modules/marketplace/hooks/use-get-subscribed-providers';
import { useGetSelectedStatFilters } from '@root/modules/orders/contexts/stat-filters.context';
import { DateInput, DateInputProps, Select } from '@root/shared/ui/form';
import { Text } from '@root/shared/ui/typography';

export const DashboardStatsFilters: FC = () => {
  const { t } = useTranslation('dashboard');
  const providers = useGetSubscribedProviders();

  const { providerId, selectProvider, fromDate, toDate, selectDate } = useGetSelectedStatFilters();

  const providerOptions = useMemo(() => {
    return [
      {
        value: undefined,
        label: t('filters.providers.all'),
      },
      ...(providers?.data?.data?.map((provider) => ({
        value: provider.id,
        label: provider.name,
      })) || []),
    ];
  }, [providers?.data?.data, t]);

  const selectedProviderOption = providerOptions?.find((option) => option.value === providerId);

  const onDateChange = useCallback<DateInputProps['onChange']>(
    (date) => {
      if (date instanceof Array) {
        const [from, to] = date;
        selectDate(from || undefined, to || undefined);
      }
    },
    [selectDate],
  );

  const openToDate = useMemo(() => {
    const openToDate = toDate ? new Date(toDate.getTime()) : new Date();
    openToDate.setMonth(openToDate.getMonth() - 1);
    return openToDate;
  }, [toDate]);

  return (
    <div>
      <div className='flex justify-end items-start gap-x-2'>
        <Select onChange={(option) => selectProvider(option.value)} options={providerOptions} value={selectedProviderOption} alignMenuToRight tiny />
        <DateInput
          size='small'
          value={[fromDate || null, toDate || null]}
          onChange={onDateChange}
          monthsShown={2}
          className='!py-[3px]'
          minDate={new Date('2024-06-01')}
          maxDate={new Date()}
          withRange
          inputReadonly={true}
          openToDate={openToDate}
        />
      </div>
      <Text className='text-secondary-200 text-right' size='sm'>
        * {t('statsDatePickerHint')}
      </Text>
    </div>
  );
};
