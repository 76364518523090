import { getSubscribedProviders, GetSubscribedProvidersResponseData } from '@root/modules/marketplace/services/get-subscribed-providers.service';
import { QueryFunction } from 'react-query';

export type GetMarketplaceSubscribedProvidersQueryKey = ['marketplace-subscribed-providers'];
export type GetMarketplaceTradeIdeaListData = GetSubscribedProvidersResponseData;

export const GET_MARKETPLACE_SUBSCRIBED: QueryFunction<GetMarketplaceTradeIdeaListData, GetMarketplaceSubscribedProvidersQueryKey> = async ({ queryKey }) => {
  const result = await getSubscribedProviders();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
