import logo from '@root/assets/images/logo.webp';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { BalanceWidget } from '@root/shared/ui/financial-widgets';
import { Image } from '@root/shared/ui/image';
import { useSelector } from 'react-redux';
import { LayoutElements } from '../components';
import { HeaderDropdown as Dropdown } from '../components/header-dropdown';
import { HeaderLinksDropdown } from '../components/header-links-dropdown';
import { AssistWidget } from './assist-widget';
import { MasterGptLink } from './master-gpt-link';

const { Wrapper, Logo } = LayoutElements.Header;

export const Header = () => {
  const isVerified = useSelector(authSelector.isEmailVerified);

  return (
    <Wrapper>
      <div className='flex items-center flex-grow h-full flex-shrink'>
        {isVerified && <HeaderLinksDropdown />}
        <Logo>
          <Image src={logo} height={29} width={174} alt='SageMaster Logo' unoptimized={true} />
        </Logo>
      </div>
      <div className='flex items-center flex-shrink-0 gap-x-2 flex-grow-0 ml-2'>
        <div className='hidden lg:block'>
          <AssistWidget />
        </div>
        {isVerified && <BalanceWidget />}
        <MasterGptLink />
        <Dropdown />
      </div>
    </Wrapper>
  );
};
