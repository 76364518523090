import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { ExpertStatusBadge } from '@root/modules/experts/components/expert-status';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { PlayIcon } from '@root/shared/icons/play-icon';
import { StopIcon } from '@root/shared/icons/stop-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

import { useExpertItem } from '../hooks/use-expert-item';
import { useGetExpertStatus } from '../hooks/use-get-expert-status';
import { IExpert } from '../types/expert';
import { RemoveExpertAssistModal } from './remove-expert-assist-modal';
import { StopExpertAssistModal } from './stop-expert-assist-modal';

export type ExpertCardProps = {
  data: IExpert;
};

export const ExpertCardHeader: FC<ExpertCardProps> = ({ data }) => {
  const { t } = useTranslation('forex-experts');
  const { cardActions, stopRunMutation, mutationOptions, removeIsOpen, deleteMutation, isLoading, stopIsOpen, removeIsDisabled, setStopIsOpen, setRemoveIsOpen, runExpert, handleStopExpert } = useExpertItem(data);
  const { hideActions } = useGhostLogin();

  const { isEnabled, status } = useGetExpertStatus(data);

  return (
    <>
      <div className='flex justify-between items-center px-5 pt-2.5 pb-1'>
        <div className={clsx('flex flex-shrink-0 gap-x-1')}>
          {cardActions.map((button, index) => (
            <InfoTooltip
              key={index}
              content={
                button.hoverText ? (
                  <Text className='w-full text-center' size='sm'>
                    {button.hoverText}
                  </Text>
                ) : null
              }
              positions={['bottom', 'right', 'top', 'left']}
            >
              <button
                disabled={button.isDisabled}
                onClick={button.onClick}
                className={clsx('text-base p-1 rounded flex flex-col justify-center items-center', {
                  'hover:opacity-80 transition': !button.isDisabled,
                  'text-grayscale-600': button.isDisabled,
                })}
              >
                <button.icon/>
              </button>
            </InfoTooltip>
          ))}
        </div>
        <div className='flex justify-center items-center gap-x-4'>
          <ExpertStatusBadge status={status} />
          <div className='flex items-center'>
            {isEnabled ? (
              <InfoTooltip
                content={
                  <Text className='w-full text-center' size='sm'>
                    {t('item.actions.stop')}
                  </Text>
                }
              >
                <button
                  className='w-8 h-8 rounded bg-gray-100 bg-opacity-10 flex justify-center items-center'
                  onClick={handleStopExpert}
                  disabled={hideActions}
                >
                  {stopRunMutation.isLoading ? <LoadingIcon /> : <StopIcon />}
                </button>
              </InfoTooltip>
            ) : (
              <InfoTooltip
                content={
                  <Text className='w-full text-center' size='sm'>
                    {t('item.actions.run')}
                  </Text>
                }
              >
                <button
                  className='w-8 h-8 rounded bg-gray-100 bg-opacity-10 flex justify-center items-center'
                  onClick={() => runExpert(data)}
                  disabled={hideActions || isLoading}
                >
                  {isLoading ? <LoadingIcon /> : <PlayIcon />}
                </button>
              </InfoTooltip>
            )}
          </div>
        </div>
      </div>
      <RemoveExpertAssistModal
        removeIsOpen={removeIsOpen}
        setRemoveIsOpen={setRemoveIsOpen}
        onDelete={() => deleteMutation.mutate(data.id, mutationOptions)}
        isLoading={deleteMutation.isLoading}
        removeIsDisabled={removeIsDisabled}
      />
      <StopExpertAssistModal
        stopIsOpen={stopIsOpen}
        setStopIsOpen={setStopIsOpen}
        isLoading={stopRunMutation.isLoading}
        onStop={() => {
          stopRunMutation.mutate(data.id, mutationOptions)
          setStopIsOpen(false)
        }}
      />
    </>
  );
};
