import { Formik } from 'formik';
import { FC, FormEvent, PropsWithChildren } from 'react';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateWizardExpert } from '@root/modules/experts/hooks/use-create-wizard-expert';
import { useSymbolUpdater } from '@root/modules/experts/hooks/use-symbol-updater';
import { IExpert } from '@root/modules/experts/types/expert';

type Props = PropsWithChildren & {
  id: string;
  editable?: boolean;
  sharedExpert?: IExpert;
};

const FormComponent: FC<
  PropsWithChildren & {
    onSubmit: (e: FormEvent<HTMLFormElement> | undefined) => void;
  }
> = ({ children, onSubmit }) => {
  useSymbolUpdater();
  return (
    <form className='w-full' onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export const EditableSettingsFormWrapper: FC<Props> = ({ id, children, editable = true, sharedExpert }) => {
  const { initialValues, onSubmit, validationSchema } = useCreateWizardExpert(id, editable, sharedExpert);

  if (!id || !initialValues) {
    return null;
  }

  return (
    <Formik<CreateExpertDto> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize validateOnBlur validateOnChange>
      {({ handleSubmit }) => <FormComponent onSubmit={handleSubmit}>{children}</FormComponent>}
    </Formik>
  );
};
