import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { SelectField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabel } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

type Props = {
  isPartialEdit?: boolean;
};

export const OrderOptions: FC<Props> = ({ isPartialEdit }) => {
  const { t } = useTranslation('forex-experts');

  const { orderTypeOptions, orderDirectionOptions } = useCreateExpertFieldOptions();

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.orderOptions.title')}
        </Title>
      )}

      <SelectField
        name='orderType'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.orderType.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t('fields.orderType.label')}
          </IconLabel>
        }
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        placeholder={t('fields.orderType.placeholder')}
        options={orderTypeOptions}
      />

      <SelectField
        name='orderDirection'
        label={
          <IconLabel
            icon={
              <InfoTooltip content={<Text size='sm'>{t('fields.orderDirection.description')}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t('fields.orderDirection.label')}
          </IconLabel>
        }
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        placeholder={t('fields.orderDirection.placeholder')}
        options={orderDirectionOptions}
      />
    </div>
  );
};
