import { Paginate } from '@root/shared/ui/pagination';
import { Fragment } from 'react';
import { ProviderTradersTable } from '../components/provider-traders-table';
import { useMarketplaceTradeIdeaProviderTraders } from '../hooks/use-marketplace-trade-provider-traders';

export const ProviderTraders = ({ id }) => {
  const [{ list, isLoading, pageCount }, { changeControlParam }] = useMarketplaceTradeIdeaProviderTraders(id);

  return (
    <Fragment>
      <ProviderTradersTable list={list} isLoading={isLoading} />

      {!!pageCount && pageCount > 1 && (
        <div className='flex flex-wrap justify-center items-center my-5 px-5 relative'>
          <Paginate
            pageCount={pageCount || 1}
            forcePage={0}
            marginPagesDisplayed={1}
            disableInitialCallback
            onPageChange={(pageData) => changeControlParam({ page: pageData.selected + 1 })}
          />
        </div>
      )}
    </Fragment>
  );
};
