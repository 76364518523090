import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { CreateExpertDtoMapper } from '../mappers/create-expert-dto.mapper';

export type CreateExpertServiceResponse = IHttpResponse<200, IExpert> | IHttpResponse<400, string>;

export const createExpertService = async (values: CreateExpertDto, partiallyEdit?: boolean): Promise<CreateExpertServiceResponse> => {
  try {
    let response;

    if (values.id) {
      response = await fetcher.patch(`/experts/${values.id}`, partiallyEdit ? values : CreateExpertDtoMapper.toPersistence(values), {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      });
    } else {
      response = await fetcher.post(`/experts`, CreateExpertDtoMapper.toPersistence(values), {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      });
    }

    return {
      status: 200,
      payload: response?.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
