import { queryClient } from '@root/infra/query';
import { settingsSelector } from '@root/modules/app-settings/store/settings.selector';
import { settingsSlice } from '@root/modules/app-settings/store/settings.slice';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { endGhostSession } from '@root/shared-files/modules/auth/services/end-ghost-session.service';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useDropdown = () => {
  const isBalanceVisible = useSelector(settingsSelector.isBalanceVisible);
  const { isGhostLogin } = useGhostLogin();
  const dispatch = useDispatch();

  const toggleVisibility = useCallback(() => {
    dispatch(settingsSlice.actions.setBalanceVisibility(!isBalanceVisible));
  }, [dispatch, isBalanceVisible]);

  const logout = useCallback(async () => {
    if (isGhostLogin) {
      await endGhostSession();
      dispatch(authSlice.actions.finalizeGhostLogin(null));
    }

    dispatch(authSlice.actions.signedOut());
    queryClient.clear();
  }, [isGhostLogin, dispatch]);

  const state = { isBalanceVisible };
  const handlers = { hideBalance: toggleVisibility, logout };

  return [state, handlers] as [typeof state, typeof handlers];
};
