export enum OrderType {
  PENDING = 'pending',
  INSTANT = 'instant',
}

export enum TabType {
  OPEN = 'open',
  PENDING = 'pending',
  HISTORY = 'history',
}

export enum MainOrderType {
  BUY,
  SELL,
  BUY_LIMIT,
  SELL_LIMIT,
  BUY_STOP,
  SELL_STOP,
}

export enum Period {
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Day = 'day',
  All = 'all',
}
