import { usePopover } from '@root/shared/hooks/use-popover';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Popover } from 'react-tiny-popover';

export const DescriptionPopover: FC<PropsWithChildren & { description: string; showPopover: boolean }> = ({ showPopover, description, children }) => {
  const popover = usePopover();

  if (!showPopover) {
    return children as ReactElement;
  }

  return (
    <Popover
      {...popover.props}
      positions={['bottom']}
      align='start'
      padding={4}
      content={
        <div className='bg-grayscale-700 p-4 max-w-xl text-sm' {...popover.contentProps}>
          {description}
        </div>
      }
    >
      <div {...popover.childrenProps}>{children}</div>
    </Popover>
  );
};
