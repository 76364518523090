import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const PlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.17198 1.48434C1.94982 1.35045 1.66659 1.51045 1.66659 1.76984V10.2293C1.66659 10.4887 1.94982 10.6487 2.17198 10.5148L9.19023 6.28508C9.40526 6.15549 9.40526 5.84369 9.19023 5.7141L2.17198 1.48434ZM0.333252 1.76984C0.333252 0.472882 1.74941 -0.327093 2.86022 0.342371L9.87848 4.57212C10.9536 5.22009 10.9536 6.77908 9.87848 7.42705L2.86022 11.6568C1.7494 12.3263 0.333252 11.5263 0.333252 10.2293V1.76984Z'
        fill='currentColor'
      />
    </svg>
  );
};
