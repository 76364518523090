import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@root/shared/icons/calendar-icon';
import { Select } from '@root/shared/ui/form';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Title } from '@root/shared/ui/typography';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nb';
import 'dayjs/locale/pt';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
import { ProviderAnalyticsChart } from '../components/provider-analytics-chart';
import { useProviderAnalyticsChart } from '../hooks/use-provider-analytics-chart';
import { GetMarketplaceTradeIdeaProviderData } from '../query/get-marketplace-trade-idea-provider.query';

export const ProviderAnalytics = ({ data, isLoading }: { data?: GetMarketplaceTradeIdeaProviderData, isLoading: boolean }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  const [{ chartData, yearsOptions, yearsValue }, { onYearChange }] = useProviderAnalyticsChart({ data, isLoading });

  return data?.showSlWonInfo ? (
    <div className='bg-gray-800 p-4 mb-5'>
      <div className='flex items-center justify-between'>
        <Title level={6}>{t('analytics.title')}</Title>
        {isLoading ? (
          <SkeletonLoader shapes={[{ circle: false, width: 100, height: 30 }]} />
        ) : (
          <div className='ml-auto'>
            <Select value={yearsValue} onChange={onYearChange} options={yearsOptions} isSearchable={false} tiny customLabel={<CalendarIcon />} />
          </div>
        )}
      </div>
      <div>
        {isLoading ? (
          <div className='p-4'>
            <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 250 }]} />
          </div>
        ) : (
          <ProviderAnalyticsChart data={chartData} />
        )}
      </div>
    </div>
  ) : null;
};
