import React, { FC } from 'react';

import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';

export const PresetsLoader: FC = () => {
  return (
    <SkeletonLoader
      shapes={[
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
        { circle: false, width: '100%', height: 169 },
      ]}
    />
  );
};
