import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';

import { GET_PRESET, GetPresetData, GetPresetQueryKey } from '../queries/get-preset.query';

export const useGetPreset = (id: string) => {
  const userId = useSelector(authSelector.userId);

  return useQuery<GetPresetData, Error, GetPresetData, GetPresetQueryKey>(['presets', id], GET_PRESET, {
    keepPreviousData: false,
    enabled: !!id && !!userId,
  });
};
