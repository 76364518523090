import { useCallback, useState } from 'react';
import { GetMarketplaceTradeIdeaProviderIdeasQuery } from '../services/get-marketplace-trade-idea-provider-ideas.service';
import { useGetMarketplaceTradeIdeaProviderIdeas } from './use-get-marketplace-trade-idea-provider-ideas';

export type MarketplaceTradeIdeaProviderIdeasParams = Omit<GetMarketplaceTradeIdeaProviderIdeasQuery, 'id'>;

const initialData: MarketplaceTradeIdeaProviderIdeasParams = {
  page: 1,
  itemsPerPage: 20,
};

export const useMarketplaceTradeIdeaProviderIdeas = (id) => {
  const [controlParams, setControlParam] = useState<MarketplaceTradeIdeaProviderIdeasParams>(initialData);

  const { data, isFetching } = useGetMarketplaceTradeIdeaProviderIdeas({ id, ...controlParams });

  const changeControlParam = useCallback(
    (params: Partial<MarketplaceTradeIdeaProviderIdeasParams>) => {
      setControlParam((values) => ({ ...values, ...params }));
    },
    [setControlParam],
  );

  const state = {
    list: data?.data,
    controlParams,
    isLoading: isFetching,
    pageCount: data?.pagination.pageCount,
  };

  const handlers = { changeControlParam };

  return [state, handlers] as [typeof state, typeof handlers];
};
