import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { EmptyData } from '@root/shared/ui/empty-data';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';

import { AccountCard } from '../components/account-card';
import { useResetAccount } from '../hooks/use-reset-account';
import { ResetAccountModal } from '../components/reset-account';

export const Accounts = () => {
  const { data, isLoading } = useGetAccounts();
  const { loading, resetAccountId, setResetAccount, resetAccount } = useResetAccount();

  return (
    <div>
      <div className='grid gap-6 grid-cols-1 lg:grid-cols-3'>
        {isLoading && (
          <SkeletonLoader
            shapes={[
              { circle: false, width: '100%', height: 169 },
              { circle: false, width: '100%', height: 169 },
              { circle: false, width: '100%', height: 169 },
              { circle: false, width: '100%', height: 169 },
            ]}
          />
        )}
        {!isLoading && !!data?.length && data?.map((account) => <AccountCard key={account.id} data={account} />)}
      </div>
      {!isLoading && !data?.length && <EmptyData />}
      {resetAccountId && <ResetAccountModal loading={loading} isOpen={!!resetAccountId} onOk={resetAccount} onCancel={() => setResetAccount(null)} />}
    </div>
  );
};
