import Decimal from 'decimal.js';

import { SubscriptionProductType } from '../types/subscription-product-type';
import { SubscriptionProduct, SubscriptionProductOption } from '../types/subscription-products';

const productsStartWith = ['FX_TRIAL_', 'FX_BASIC_', 'FX_ADVANCED_', 'FX_PRO_'];
const optionsTypes = {
  [SubscriptionProductType.Basic]: 'FX_BASIC_',
  [SubscriptionProductType.Advanced]: 'FX_ADVANCED_',
  [SubscriptionProductType.Pro]: 'FX_PRO_',
  [SubscriptionProductType.Trial]: 'FX_TRIAL_',
};

const getProductType = (options): SubscriptionProductType => {
  if (options[`${productsStartWith[1]}NAME`]) {
    return SubscriptionProductType.Basic;
  }
  if (options[`${productsStartWith[2]}NAME`]) {
    return SubscriptionProductType.Advanced;
  }
  if (options[`${productsStartWith[3]}NAME`]) {
    return SubscriptionProductType.Pro;
  }
  return SubscriptionProductType.Trial;
};

const getOptions = (options): SubscriptionProductOption => {
  const productType = getProductType(options);
  const startWith = optionsTypes[productType];

  return {
    brokers: options?.[`${startWith}BROKER`],
    ea: options[`${startWith}EA`],
    name: options[`${startWith}NAME`],
    priceMonth: new Decimal(options[`${startWith}PRICE_MONTH`] || 0).div(100).toNumber(),
    priceYear: new Decimal(options[`${startWith}PRICE_YEAR`] || 0).div(100).toNumber(),
    productId: options[`${startWith}PRODUCT_ID`],
    maxProviders: options[`${startWith}FREE_PROVIDERS`],
  } as SubscriptionProductOption;
};

export class ForexSubscriptionProductsMapper {
  public static toDomain(data): SubscriptionProduct {
    return {
      createdAt: data.createdAt,
      description: data.description || null,
      id: data.id,
      moduleId: data.moduleId,
      name: data.name,
      options: getOptions(data.options),
      plans: data.plans,
      updatedAt: data.updatedAt,
      isTrialUsed: data.isTrialUsed,
      type: getProductType(data.options),
    };
  }
}
