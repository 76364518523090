import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DetailedStats } from '@root/modules/experts/components/details/settings-and-stats/detailed-stats';
import { PnlChart } from '@root/modules/experts/components/details/settings-and-stats/settings/pnl-chart';
import { IExpert } from '@root/modules/experts/types/expert';
import { StatisticTable } from '@root/modules/orders/components/statistic-table';
import { SelectedStatsProvider } from '@root/modules/orders/contexts/stat-filters.context';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { Text } from '@root/shared/ui/typography';
import { checkIsQaMember } from '@root/shared/utils/helpers/check-is-qa-member';

import { ExpertEvents } from '../../expert-events';
import { MainInfo } from './main-info';
import { Settings } from './settings';

type Props = {
  expert: IExpert;
};

export const SettingsAndStats: FC<Props> = ({ expert }) => {
  const userId = useSelector(authSelector.userId);
  const { t } = useTranslation('forex-experts');
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);
  const isQaMember = useMemo(() => checkIsQaMember(subscriptionInfo?.roles || []), [subscriptionInfo]);

  return (
    <div className='w-full grid grid-cols-12 gap-3'>
      <div className='col-span-12 md:col-span-7 flex flex-col gap-y-3'>
        <MainInfo expert={expert} />
        {isQaMember && (
          <SelectedStatsProvider>
            <DetailedStats expert={expert} />
          </SelectedStatsProvider>
        )}
        <PnlChart expertId={expert.id} selectedBroker={expert.accountId} />
        <ExpertEvents
          expertId={expert.id}
          userId={userId || ''}
          wrapperClassName='!px-5 !py-2'
          title={
            <Text size='base' className='font-semibold'>
              {t('lastEvents')}
            </Text>
          }
          expandable
        />
        <div className='block md:hidden'>
          <Settings expert={expert} />
        </div>
      </div>
      <div className='hidden md:block col-span-5'>
        <Settings expert={expert} />
      </div>
    </div>
  );
};
