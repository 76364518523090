import { MarketPlaceTradeIdeaProviderTrader } from '../types/marketplace-trade-idea-provider-trader';

export class MarketplaceTradeIdeaProviderTraderMapper {
  public static toDomain(data, providerId): MarketPlaceTradeIdeaProviderTrader {
    return {
      id: data.id,
      name: data.name,
      logo: data.image_url,
      tradeIdeasCount: data.signal_count,
      winsCount: data.wins,
      lossesCount: data.losses,
      pnl: data.pnl,
      winLossRatio: data.winLossRatio,
      netPips: data.netPips,
      externalId: data.external_id,
      providerId: providerId,
    };
  }
}
