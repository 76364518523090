import { all } from '@redux-saga/core/effects';

import { socketSaga } from '@root/infra/socket/socket.saga';
import { accountsSaga } from '@root/modules/accounts/store/accounts.saga';
import { countriesSaga } from '@root/modules/countries/store';
import { expertsSaga } from '@root/modules/experts/store/experts.saga';
import { marketplaceSaga } from '@root/modules/marketplace/store/marketplace.saga';
import { quotesSaga } from '@root/modules/quotes/store/quotes.saga';
import { userSettingsSaga } from '@root/modules/user-settings/store/user-settings.saga';
import { authSaga } from '@root/shared-files/modules/auth/store/auth.saga';
import { subscriptionsSaga } from '@root/shared-files/modules/subscriptions/store';

export function* rootSaga() {
  yield all([authSaga(), socketSaga(), subscriptionsSaga(), countriesSaga(), marketplaceSaga(), accountsSaga(), expertsSaga(), quotesSaga(), userSettingsSaga()]);
}
