import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const TradeTopIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.28852 3.54865C8.28853 3.18046 8.587 2.88198 8.95519 2.88199L14.9551 2.88202C15.3233 2.88202 15.6218 3.18049 15.6218 3.54868L15.6218 9.79112C15.6218 10.1593 15.3233 10.4578 14.9551 10.4578C14.5869 10.4578 14.2885 10.1593 14.2885 9.79112L14.2885 4.21535L8.95519 4.21532C8.587 4.21532 8.28852 3.91684 8.28852 3.54865Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.87448 10.3331C8.69372 10.3307 8.52169 10.255 8.39782 10.1233L5.86878 7.43532L1.16619 12.1379C0.905839 12.3983 0.483728 12.3983 0.223379 12.1379C-0.0369709 11.8776 -0.0369705 11.4554 0.22338 11.1951L5.41196 6.00652C5.53947 5.87901 5.71321 5.80859 5.89352 5.81134C6.07383 5.81408 6.24534 5.88976 6.36891 6.0211L8.89615 8.7072L14.3606 3.201C14.6199 2.93966 15.042 2.93805 15.3034 3.19741C15.5647 3.45676 15.5663 3.87887 15.3069 4.14021L9.35656 10.1361C9.22922 10.2644 9.05524 10.3355 8.87448 10.3331Z'
      />
    </svg>
  );
};
