import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';
import { AxiosError } from 'axios';
import { ReactPaginateProps } from 'react-paginate';
import { MarketplaceTradeIdeaProviderLastIdeaMapper } from '../mappers/marketplace-trade-idea-provider-last-idea.mapper';
import { MarketPlaceTradeIdeaProviderLastIdea } from '../types/marketplace-trade-idea-provider-last-ideas';

export type MarketplaceTradeIdeaProviderIdeasResponseData = {
  data: MarketPlaceTradeIdeaProviderLastIdea[];
  pagination: ReactPaginateProps;
};

export type GetMarketplaceTradeIdeaProviderIdeasServiceResponse = IHttpResponse<200, MarketplaceTradeIdeaProviderIdeasResponseData> | IHttpResponse<400, string>;

export type GetMarketplaceTradeIdeaProviderIdeasQuery = {
  id: Id;
  page: number;
  itemsPerPage: number;
};

export const getMarketplaceTradeIdeaProviderIdeasService = async ({
  id,
  page,
  itemsPerPage,
}: GetMarketplaceTradeIdeaProviderIdeasQuery): Promise<GetMarketplaceTradeIdeaProviderIdeasServiceResponse> => {
  try {
    const query = stringifyParams({
      page,
      size: itemsPerPage,
    });

    const response = await fetcher(`/trade-ideas/providers/${id}/trade-ideas/delayed?${query}`);

    return {
      status: 200,
      payload: {
        data: response.data.data.map((item) => MarketplaceTradeIdeaProviderLastIdeaMapper.toDomain(item)),
        pagination: {
          pageCount: response.data.total / itemsPerPage > 1 ? Math.ceil(response.data.total / itemsPerPage) : 1,
        },
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
