import { useMemo } from 'react';

export type UsePaginatedListOptions = {
  page: number;
  pageSize: number;
};

export const usePaginatedList = <Data = any>(data: Data[], options: UsePaginatedListOptions) => {
  return useMemo(() => {
    return data.slice(options.page * options.pageSize, (options.page + 1) * options.pageSize);
  }, [data, options.page, options.pageSize]);
};
