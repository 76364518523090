import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import infoImage from '@root/assets/images/cases/info.webp';
import { MarketPlaceTradeIdeaItem } from '@root/modules/marketplace/types/marketplace-trade-idea';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

type Props = ModalProps & {
  loading: boolean;
  marketType?: MarketPlaceTradeIdeaItem['market'];
};

const getModalContent = (marketType) => {
  switch (marketType) {
    case 'indices':
    case 'synthetic': {
      return {
        title: 'completeSubscription',
        text: 'explanation',
        okButtonText: 'proceed',
        cancelButtonText: 'cancel',
      };
    }
    default: {
      return {
        title: 'subscribeTitle',
        text: null,
        okButtonText: 'subscribe',
        cancelButtonText: 'no',
      };
    }
  }
};

export const MarketPlaceTradeIdeaSubscribeModal: FC<Props> = ({ marketType, isOpen, loading, onOk, onCancel }) => {
  const { t } = useTranslation('marketplace-trade-idea-modals');
  const { hideActions } = useGhostLogin();

  const { okButtonText, cancelButtonText, text, title } = getModalContent(marketType);

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} footer={null}>
      <div className='flex flex-col items-center '>
        <div className='w-16 h-16 mb-6'>
          <Image src={infoImage} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
        </div>
        <Title level={4} className='font-bold text-center mb-4'>
          {t(title)}
        </Title>
        {text && (
          <div>
            <Text size='sm' className='text-center text-grayscale-400'>
              {t(text)}
            </Text>
          </div>
        )}
        <div className='w-full flex justify-between items-center mt-8'>
          <Button onClick={onCancel} variant='info' outlined>
            {t(cancelButtonText)}
          </Button>
          <Button loading={loading} onClick={onOk} disabled={hideActions}>
            {t(okButtonText)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
