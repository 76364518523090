import dayjs from 'dayjs';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MarketPlaceTradeIdeaItem } from '@root/modules/marketplace/types/marketplace-trade-idea';
import { MarketPlaceTradeIdeaProvider } from '@root/modules/marketplace/types/marketplace-trade-idea-provider';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useProviderSubscriptionState = (data?: MarketPlaceTradeIdeaProvider | MarketPlaceTradeIdeaItem) => {
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const { isActive, isStillActive, isCancelled, isPaused, expiresAt } = useMemo(() => {
    if (data) {
      if (!data?.subscription && !data?.subscriptionStatus && data?.market !== 'forex') {
        const hasProvider = subscriptionInfo?.providers?.find((provider) => provider === data?.id);
        if (hasProvider) {
          return {
            isActive: true,
            isStillActive: false,
            isCancelled: false,
            isPaused: false,
            expiresAt: new Date().toISOString(),
          };
        } else {
          return {
            isActive: false,
            isStillActive: false,
            isCancelled: false,
            isPaused: false,
            expiresAt: new Date().toISOString(),
          };
        }
      }

      const isActive = data.subscriptionStatus === 'active';
      const isCancelled = data.subscriptionStatus === 'canceled';
      const isPaused = data.subscriptionStatus === 'paused';
      const isStillActive = !!data.subscription?.expiresAt && dayjs(new Date()).isBefore(data.subscription.expiresAt);
      const expiresAt = data.subscription?.expiresAt;

      return {
        isActive,
        isCancelled,
        isStillActive,
        isPaused,
        expiresAt,
      };
    }

    return {
      isActive: false,
      isStillActive: false,
      isCancelled: false,
      isPaused: false,
      expiresAt: new Date().toISOString(),
    };
  }, [data, subscriptionInfo?.providers]);

  return {
    isActive,
    isStillActive,
    isCancelled,
    isPaused,
    expiresAt,
  };
};
