import { useTranslation } from 'react-i18next';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { Text, Title } from '@root/shared/ui/typography';
import { GetMarketplaceTradeIdeaProviderData } from '../query/get-marketplace-trade-idea-provider.query';

export const ProviderDisclaimer = ({ data, isLoading }: { data?: GetMarketplaceTradeIdeaProviderData, isLoading: boolean }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details');

  return (
    <div className='bg-gray-800 w-full rounded overflow-hidden shadow-xl mt-5 p-4'>
      <Title className='mb-4' level={6}>
        {t('disclaimer.title')}
      </Title>
      {isLoading ? (
        <SkeletonLoader shapes={[{ circle: false, width: '100%', height: 48 }]} />
      ) : (
        <Text size='base' className='text-grayscale-300'>
          {data?.disclaimer || ''}
        </Text>
      )}
    </div>
  );
};
