import { MaintenanceSettingsKeys } from '@3lgn/shared/dist/constants/maintenance_settings';

export const maintenanceKeys = {
  forex: MaintenanceSettingsKeys.FOREX_MAINTENANCE_MODE_ENABLED,
  crypto: MaintenanceSettingsKeys.CRYPTO_MAINTENANCE_MODE_ENABLED,
};

export const currentSiteMaintenanceKey = maintenanceKeys[import.meta.env.VITE_APP_TYPE];

export class MaintenanceMapper {
  public static toDomain(settings): boolean {
    const current = settings.find((item) => item.key === currentSiteMaintenanceKey);

    if (!current) {
      throw new Error('no maintenance key');
    }

    return current?.value;
  }
}
