import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';

export const useQuoteSubscriber = (selectedBroker = 'all', enabled?: boolean) => {
  const dispatch = useDispatch();
  const { data: accounts } = useGetAccounts();
  const { openOrders } = useGetOpenOrders({ enabled, selectedBroker, mtType: undefined });
  const subscribedSymbols = useSelector(quotesSelector.subscribedSymbols);
  const list = openOrders?.map((item) => item.symbol);

  // Subscribing to quotes
  useEffect(() => {
    const notSubscribedSymbols = list?.filter((symbol) => !subscribedSymbols.some((item) => item.includes(symbol)));

    if (notSubscribedSymbols?.length && selectedBroker) {
      const toSubscribe: string[] = [];

      if (selectedBroker === 'all') {
        accounts?.forEach((account) => {
          list?.forEach((symbol) => {
            toSubscribe.push(`quote-${account?.companyId}-${symbol}`);
          });
        });
      } else {
        const companyId = accounts?.find((account) => account?.id === selectedBroker)?.companyId;
        list?.forEach((symbol) => {
          toSubscribe.push(`quote-${companyId}-${symbol}`);
        });
      }
      dispatch(quotesSlice.actions.subscribe(toSubscribe));
    }
  }, [selectedBroker, list, accounts, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(quotesSlice.actions.unsubscribeAll());
    };
  }, [dispatch]);
};
