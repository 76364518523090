import isEqual from 'lodash/isEqual';

import { IPreset } from '@root/modules/presets/types/preset';

import { CreateExpertDto } from '../dtos/create-expert.dto';
import { CreateExpertDtoMapper } from '../mappers/create-expert-dto.mapper';

export const checkExpertPresetIsUsed = (formValues: CreateExpertDto, preset?: IPreset) => {
  if (!preset && formValues.expertPresetId) {
    return formValues.expertPresetId;
  }

  if (!preset) {
    return null;
  }

  const presetValues = CreateExpertDtoMapper.toDomain({ ...preset, accountId: formValues.account } as any);

  const presetValuesToCompare = { ...presetValues } as any;
  delete presetValuesToCompare['id'];
  delete presetValuesToCompare['account'];
  delete presetValuesToCompare['expertPresetId'];

  const formValuesToCompare = { ...formValues } as any;
  delete formValuesToCompare['id'];
  delete formValuesToCompare['account'];
  delete formValuesToCompare['expertPresetId'];
  delete formValuesToCompare['meta'];

  if (isEqual(presetValuesToCompare, formValuesToCompare)) {
    return preset.id;
  }

  return null;
};
