import logo from '@root/assets/images/logo.png';
import bannerImg from '@root/assets/images/marketplace-banner-bg.webp';
import { useTranslation } from 'react-i18next';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { Text } from '@root/shared/ui/typography';
import { FC } from 'react';
import { MARKETPLACE_LINK } from '../constants/marketplace-link';

export const MarketplaceBanner: FC = () => {
  const { t } = useTranslation('marketplace-trade-idea-providers');

  return (
    <div className='p-4 rounded bg-gradient-to-r overflow-hidden relative flex justify-center items-center'>
      <Image src={bannerImg} alt='banner' layout='fill' className='w-full h-full absolute top-0 left-0 z-1' objectFit='cover' objectPosition='center center' />
      <div className='flex flex-col md:flex-row justify-center items-center gap-8 z-2 w-10/12'>
        <div className='flex items-center justify-center flex-shrink-0'>
          <Image src={logo} height={48} width={48} alt='Sagemaster Logo' />
        </div>
        <Text>{t('banner.bePartnerText')}</Text>
        <div className='flex-shrink-0'>
          <ButtonLink href={MARKETPLACE_LINK} target='_blank'>
            {t('banner.applyNow')}
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};
