import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SignOutAccountServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export interface SignOutAccountServiceValues {
  accountId: string;
}

export const signOutAccountService = async ({ accountId }: SignOutAccountServiceValues): Promise<SignOutAccountServiceResponse> => {
  try {
    await fetcher.post(`/accounts/${accountId}/sign-out`, undefined, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
