import { FC, ReactNode } from 'react';
import { useField } from 'formik';
import { DateInput, Field, FieldHint, DateInputProps, Label } from '@root/shared/ui/form';

export interface DateFieldProps extends Omit<DateInputProps, 'value' | 'onChange'> {
  label?: ReactNode;
  name: string;
  hideError?: boolean;
}

export const DateField: FC<DateFieldProps> = ({ label, hideError, ...props }) => {
  const [inputProps, meta, helpers] = useField<DateInputProps['value']>(props.name);

  return (
    <Field onBlur={() => helpers.setTouched(true)}>
      {label && <Label>{label}</Label>}
      <DateInput {...props} value={inputProps.value} onChange={helpers.setValue} onBlur={inputProps.onBlur} />
      {!hideError && <FieldHint variant='danger'>{meta.touched && meta.error}</FieldHint>}
    </Field>
  );
};
