import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetSubscriptionProducts } from '@root/shared-files/modules/subscriptions/hooks/use-get-subscription-products';
import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';
import { SubscriptionProduct } from '@root/shared-files/modules/subscriptions/types/subscription-products';

export const useGetCurrentSubscription = () => {
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);

  const { data: subscriptions } = useGetSubscriptionProducts();

  const currentSubscription: SubscriptionProduct | null = useMemo(() => {
    if (subscriptions && subscriptionInfo?.roles) {
      const currentSubscriptions = subscriptionInfo.roles.map((item) => subscriptions.find((sb) => sb.name?.toLocaleLowerCase() === item)).filter(Boolean);
      return currentSubscriptions?.[currentSubscriptions.length - 1] || null;
    }
    return null;
  }, [subscriptions, subscriptionInfo]);

  return {
    subscriptions,
    currentSubscription,
  };
};
