import clsx from 'clsx';
import { FC } from 'react';

import { ArrowIcon } from '@root/shared/icons/arrow-icon';

export const ChangeWidget: FC<{ value: number; units: string }> = ({ value, units }) => {
  if (!value) {
    return <span> - </span>;
  }
  return (
    <div className={clsx('flex items-center', { 'text-success-400': value > 0, 'text-danger-400': value < 0 })}>
      <div>{value} {units}</div>
      {value !== 0 && (
        <div className='ml-1'>
          <ArrowIcon className={clsx({ 'transform rotate-180': value < 0 })} />
        </div>
      )}
    </div>
  );
};
