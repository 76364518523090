import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const MagicTerminalIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.58374 9.16675C1.86125 10.3144 1.55013 11.6735 1.70141 13.0212C1.85268 14.3689 2.45736 15.6252 3.41631 16.5842C4.37525 17.5431 5.63156 18.1478 6.97926 18.2991C8.32695 18.4504 9.68606 18.1392 10.8337 17.4167M11.6671 15.0001C13.4352 15.0001 15.1309 14.2977 16.3811 13.0475C17.6314 11.7972 18.3337 10.1015 18.3337 8.33341C18.3337 6.5653 17.6314 4.86961 16.3811 3.61937C15.1309 2.36913 13.4352 1.66675 11.6671 1.66675C9.89896 1.66675 8.20327 2.36913 6.95303 3.61937C5.70278 4.86961 5.00041 6.5653 5.00041 8.33341C5.00041 10.1015 5.70278 11.7972 6.95303 13.0475C8.20327 14.2977 9.89896 15.0001 11.6671 15.0001Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.771 6.20491C13.555 5.29291 12.455 4.46991 11.134 5.08491C9.81295 5.70091 9.60295 7.68291 11.601 7.89291C12.504 7.98791 13.093 7.78291 13.631 8.36391C14.171 8.94491 14.271 10.5619 12.893 10.9979C11.515 11.4339 10.151 10.7529 10.002 9.78591M11.986 4.00391V4.87391M11.986 11.1319V12.0039'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
