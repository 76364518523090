import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSharedExpertResponse = IHttpResponse<200, IExpert> | IHttpResponse<403, null> | IHttpResponse<404, null> | IHttpResponse<400, string>;

export const getSharedExpert = async (id: string): Promise<GetSharedExpertResponse> => {
  try {
    const response = await fetcher.get(`/experts/share/${id}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error?.response?.status === 403) {
      return {
        status: 403,
        payload: null,
      };
    }

    if (error?.response?.status === 404) {
      return {
        status: 404,
        payload: null,
      };
    }

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
