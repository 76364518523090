import { FxAlertEvent } from '@3lgn/shared/dist/constants/webhooks';

export const alertOptions = [
  {
    label: 'alerts.alertOptions.startLongMarketDeal.label',
    description: 'alerts.alertOptions.startLongMarketDeal.description',
    value: FxAlertEvent.START_LONG_MARKET_DEAL,
  },
  {
    label: 'alerts.alertOptions.startShortMarketDeal.label',
    description: 'alerts.alertOptions.startShortMarketDeal.description',
    value: FxAlertEvent.START_SHORT_MARKET_DEAL,
  },
  {
    label: 'alerts.alertOptions.startLongLimitDeal.label',
    description: 'alerts.alertOptions.startLongLimitDeal.description',
    value: FxAlertEvent.START_LONG_LIMIT_DEAL,
  },
  {
    label: 'alerts.alertOptions.startShortLimitDeal.label',
    description: 'alerts.alertOptions.startShortLimitDeal.description',
    value: FxAlertEvent.START_SHORT_LIMIT_DEAL,
  },
  {
    label: 'alerts.alertOptions.closeOrderAtMarketPrice.label',
    description: 'alerts.alertOptions.closeOrderAtMarketPrice.description',
    value: FxAlertEvent.CLOSE_ORDER_AT_MARKET_PRICE,
  },
  {
    label: 'alerts.alertOptions.closeAllOrdersAtMarketPrice.label',
    description: 'alerts.alertOptions.closeAllOrdersAtMarketPrice.description',
    value: FxAlertEvent.CLOSE_ALL_ORDERS_AT_MARKET_PRICE,
  },
  {
    label: 'alerts.alertOptions.closeAllOrdersAtMarketPriceAndStopAssist.label',
    description: 'alerts.alertOptions.closeAllOrdersAtMarketPriceAndStopAssist.description',
    value: FxAlertEvent.CLOSE_ALL_ORDERS_AT_MARKET_PRICE_AND_STOP_ASSIST,
  },
  {
    label: 'alerts.alertOptions.startAssist.label',
    description: 'alerts.alertOptions.startAssist.description',
    value: FxAlertEvent.START_ASSIST,
  },
  {
    label: 'alerts.alertOptions.stopAssist.label',
    description: 'alerts.alertOptions.stopAssist.description',
    value: FxAlertEvent.STOP_ASSIST,
  },
];
