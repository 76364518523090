import { FC } from 'react';
import { useQuizModal } from '../hooks/use-quiz-modal';
import { Modal } from '@root/shared/ui/modal';
import { Button } from '@root/shared/ui/button/button';
import { Title } from '@root/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const IFrame = styled.iframe`
    height: calc(80vh - 90px);
`;

export const QuizModal: FC = () => {
    const { t } = useTranslation('common');
    const { url, step, loading, confirmCompletion } = useQuizModal();

    return <Modal isOpen={true} footer={null} hideCrossIcon className='!w-11/12 !max-w-none'>
        {step === 1 && <div className='-mt-8'>
            <Title level={7} className='font-bold text-center'>
                {t('quiz.completeText')}
            </Title>
            {url && <IFrame src={url} className='w-full' />}
            <div className="w-full flex justify-end gap-4 mt-4">
                <Button loading={loading} onClick={confirmCompletion}>
                    {t('quiz.confirmButton')}
                </Button>
            </div>
        </div>}
    </Modal>;
}

