import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IMarketplaceState = {
  isUpdatedAfterSubscription: boolean;
};

export namespace MarketplaceActions {
  export type SetUpdatedAfterSubscription = PayloadAction<boolean>;
}

export type MarketplaceSliceReducer = {
  setUpdatedAfterSubscription: CaseReducer<IMarketplaceState, MarketplaceActions.SetUpdatedAfterSubscription>;
};

export const marketplaceState: IMarketplaceState = {
  isUpdatedAfterSubscription: false,
};

export const marketplaceSlice = createSlice<IMarketplaceState, MarketplaceSliceReducer, 'marketplace'>({
  name: 'marketplace',
  initialState: marketplaceState,
  reducers: {
    setUpdatedAfterSubscription: (state, action) => {
      state.isUpdatedAfterSubscription = action.payload;
    },
  },
});
