import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Wrapper } from '@root/infra/layout/components/global-loader/global-loader.ui';
import LoadingIconDots from '@root/shared/ui/common/loading-icon-dots';
import { Text, Title } from '@root/shared/ui/typography';

const MaintenanceText = {
  forex: ({ t }) => (
    <div className='flex flex-col gap-4 max-w-[800px]'>
      <Title level={4} className='text-center'>
        {t('maintenance.title')}...
      </Title>
      <Text className='text-center' size={'base'}>
        <Trans i18nKey='common:maintenance.text' components={{ i: <span className='capitalize' />, br: <br /> }} />
      </Text>
    </div>
  ),

  crypto: ({ t }) => (
    <div className='flex flex-col gap-4 max-w-[600px]'>
      <Title level={5}>{t('maintenance.title')}...</Title>
      <Text size='base'>{t('maintenance.text')}</Text>
    </div>
  ),
};

const MaintenancePage: FC = () => {
  const { t } = useTranslation('common');
  const Text = MaintenanceText[import.meta.env.VITE_APP_TYPE];

  return (
    <Wrapper className='text-5xl'>
      <Text t={t} />
      <div className='text-primary-400'>
        <LoadingIconDots />
      </div>
    </Wrapper>
  );
};

export default MaintenancePage;
