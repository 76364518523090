import { MutationFunction } from 'react-query';

import {
  UpdateCopiedMasterExpertService,
  updateCopiedMasterExpert,
} from '@root/modules/experts/services/updated-copied-master-expert.service';

export type SyncCopiedExpertMutationData = UpdateCopiedMasterExpertService;
export type SyncCopiedExpertMutationError = Error;

export const SYNC_COPIED_MASTER_EXPERT: MutationFunction<SyncCopiedExpertMutationData, string> = async (values) => {
  const result = await updateCopiedMasterExpert(values);

  if (result.status !== 200) {
    throw new Error(result.payload);
  }

  return result;
};
