import { useTranslation } from 'react-i18next';

import { Layout } from '@root/infra/layout/containers';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { ButtonLink } from '@root/shared/ui/link';
import { Text, Title } from '@root/shared/ui/typography';

import { NoAccountBanner } from '../components/no-account-banner';
import { Accounts } from '../containers/accounts';

const AccountsPage = () => {
  const { data, isLoading } = useGetAccounts();

  const userHasNoConnectedAccount = !data?.length && !isLoading;
  const { t } = useTranslation('forex-accounts');

  return (
    <Layout>
      <div className='flex justify-between items-center flex-wrap gap-2'>
        <div className='mt-[3px]'>
          <Title bold level={5}>
            {t('title')}
          </Title>
          <Text size='sm' className='font-semibold text-grayscale-500 mt-0.5'>
            {t('counter')}: {data?.length || 0}
          </Text>
        </div>
        {!userHasNoConnectedAccount && (
          <ButtonLink isLocalRoute href={PageLinks.createAccount} className='flex items-center gap-3 -mt-[20px]'>
            <PlusIcon />
            {t('connectButton')}
          </ButtonLink>
        )}
      </div>

      {/* <div className='mt-4'>
        <MT4IssuesBanner />
      </div> */}

      {userHasNoConnectedAccount && <NoAccountBanner />}
      {!userHasNoConnectedAccount && (
        <div className='mt-4'>
          <Accounts />
        </div>
      )}
    </Layout>
  );
};

export default AccountsPage;
