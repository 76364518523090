import * as ForexSubscriptions from '@root/shared-files/modules/subscriptions/containers/forex.subscriptions';
import { Subscriptions } from '@root/shared-files/modules/subscriptions/containers/subscriptions';
import { ForexSubscriptionProductsMapper } from '@root/shared-files/modules/subscriptions/mappers/forex.subscription-products.mapper';
import { SubscriptionProductsMapper } from '@root/shared-files/modules/subscriptions/mappers/subscription-products.mapper';

const appType = import.meta.env.VITE_APP_TYPE;

const config = {
  forex: {
    sourceApp: appType,
    page: ForexSubscriptions.Subscriptions,
    productsMapper: ForexSubscriptionProductsMapper,
  },
  crypto: {
    sourceApp: appType,
    page: Subscriptions,
    productsMapper: SubscriptionProductsMapper,
  },
};

export const SubscriptionsModuleConfig = config[appType];
