import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { IAccount } from '@root/modules/accounts';
import {
  SIGN_IN_ACCOUNT_MUTATION,
  SignInAccountMutationData,
  SignInAccountMutationError,
  SignInAccountMutationValues,
} from '@root/modules/accounts/mutations/sign-in-account.mutation';
import {
  SIGN_OUT_ACCOUNT_MUTATION,
  SignOutAccountMutationData,
  SignOutAccountMutationError,
  SignOutAccountMutationValues,
} from '@root/modules/accounts/mutations/sign-out-account.mutation';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { notify } from '@root/shared/utils/notification';

// TODO: use this hook in the account card component

interface IUseConnectDisconnectAccountParams {
  accountId: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
}

export const useConnectDisconnectAccount = () => {
  const dispatch = useDispatch();

  const signInMutation = useMutation<SignInAccountMutationData, SignInAccountMutationError, SignInAccountMutationValues>(SIGN_IN_ACCOUNT_MUTATION);
  const signOutMutation = useMutation<SignOutAccountMutationData, SignOutAccountMutationError, SignOutAccountMutationValues>(SIGN_OUT_ACCOUNT_MUTATION);

  const setIsPending = (accountId: string, isPending: boolean) => {
    queryClient.setQueryData<IAccount[] | void>(['accounts'], (oldData) => {
      if (!oldData) {
        return oldData;
      }
      return oldData.map((account) => {
        if (account.id === accountId) {
          return {
            ...account,
            isPending,
          };
        }
        return account;
      });
    });
  };

  const disconnectAccountAsync = ({ accountId, showErrorMessage, showSuccessMessage }: IUseConnectDisconnectAccountParams) => {
    setIsPending(accountId, true);
    return signOutMutation.mutateAsync(
      { accountId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['experts'], { exact: false });
          dispatch(accountsSlice.actions.clearAccountBalance({ id: accountId }));
          queryClient.invalidateQueries(['accounts'], { exact: false });
        },
        onError: (error: Error) => {
          setIsPending(accountId, false);
          showErrorMessage &&
            notify({
              type: 'danger',
              title: error.message,
            });
        },
      },
    );
  };

  const connectAccountAsync = ({ accountId, showErrorMessage, showSuccessMessage }: IUseConnectDisconnectAccountParams) => {
    setIsPending(accountId, true);
    return signInMutation.mutateAsync(
      { accountId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['experts'], { exact: false});
          queryClient.invalidateQueries(['accounts'], { exact: false });
        },
        onError: (error: Error) => {
          setIsPending(accountId, false);
          showErrorMessage &&
            notify({
              type: 'danger',
              title: error.message,
            });
        },
      },
    );
  };

  return { disconnectAccountAsync, connectAccountAsync };
};
