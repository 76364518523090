import styled from 'styled-components';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { globalRound } from '@root/shared/utils/number/round';

type Props = {
  profit: number;
  loss: number;
};

const RangeLine = styled.div<{ fullBorder: boolean; type: 'success' | 'secondary'; width: number }>`
  height: 4px;
  width: ${({ width }) => width + 'px'};
  background: ${({ type, theme }) => theme[type][type === 'success' ? 500 : 200]};
  border-radius: ${({ type, fullBorder }) => {
    if (fullBorder) {
      return '4px';
    }
    if (type === 'success') {
      return '4px 0 0 4px';
    }
    if (type === 'secondary') {
      return '0 4px 4px 0';
    }
  }};
`;

export const ProfitabilityRange: FC<Props> = ({ profit, loss }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'generalStatistics' });
  const profitWidth = globalRound((114 * (profit || 0)) / 100, 0);
  const lossWidth = globalRound((114 * (loss || 0)) / 100, 0);

  return (
    <InfoTooltip
      content={
        <div>
          {t('win')}: {globalRound(profit, 2)}%
          <br />
          {t('loss')}: {globalRound(loss, 2)}%
        </div>
      }
      positions={['top']}
    >
      <div className='flex w-[114px]'>
        <RangeLine fullBorder={!lossWidth} width={profitWidth} type='success' />
        <RangeLine fullBorder={!profitWidth} width={lossWidth} type='secondary' />
      </div>
    </InfoTooltip>
  );
};
