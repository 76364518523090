import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';



import { SymbolType } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbolsWithType } from '@root/modules/accounts/hooks/use-get-symbols-with-type';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { ProviderMarketTypeToSymbolTypeMap } from '@root/modules/experts/helpers/provider-market-type-to-symbol-type-map';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';


export const useSymbolUpdater = () => {
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const accounts = useGetAccounts();
  const { traders } = useProviderTraders();
  const { data } = useGetSymbolsWithType();

  const allowedSymbolType = useMemo(() => {
    if (values.strategy.type === 'signal' && values.providerId) {
      const selectedProvider = traders.find(({ id }) => id === values.providerId);
      if (selectedProvider) return ProviderMarketTypeToSymbolTypeMap[selectedProvider.market as SymbolType];
    }
    return null;
  }, [traders, values.strategy.type, values.providerId]);

  const selectedAccount = accounts.data?.find((item) => item.id === values.account);
  const usePips = values.strategy.type === 'manual' ? values.usePips : undefined;

  const symbols = useMemo(
    () =>
      data
        ?.filter((item) => {
          const isPipsSymbol = !!(item.symbolType === SymbolType.CURRENCY || item.name.match('XAUUSD'));
          return (
            (usePips === undefined || (usePips && isPipsSymbol) || (!usePips && !isPipsSymbol)) &&
            (!allowedSymbolType || item.symbolType === allowedSymbolType || (allowedSymbolType === SymbolType.CURRENCY && item.name.match('XAUUSD'))) &&
            (selectedAccount?.symbolsMapping || {})[item.name] !== null &&
            (selectedAccount?.symbolsMapping || {})[item.name] !== undefined
          );
        })
        ?.map((item) => item.name),
    [data, selectedAccount, allowedSymbolType, usePips],
  );

  useEffect(() => {
    if (symbols && selectedAccount && values?.symbols?.some((item) => !symbols?.includes(item))) {
      setTimeout(() => {
        setFieldValue(
          'symbols',
          values.symbols.filter((item) => symbols?.includes(item)),
        );
      });
    }
  }, [symbols, setFieldValue, values.symbols, selectedAccount]);

  useEffect(() => {
    if (allowedSymbolType !== values?.meta?.allowedSymbolType)
      setTimeout(() => {
        setFieldValue('meta.allowedSymbolType', allowedSymbolType);
      });
  }, [allowedSymbolType, setFieldValue, values?.meta?.allowedSymbolType]);

  useEffect(() => {
    if (!values.symbols?.length) return;
    const lastSymbol = values.symbols[0];
    const lastSymbolWithType = data?.find(({name}) => name === lastSymbol);
    if (!lastSymbolWithType) return;
    if (lastSymbolWithType?.symbolType === SymbolType.CURRENCY || values.symbols?.includes('XAUUSD')) {
      setFieldValue('usePips', true);
      setFieldValue('unitType', TemplateUnitType.PIPS);
    } else {
      setFieldValue('usePips', false);
      setFieldValue('unitType', TemplateUnitType.POINTS);
    }
  }, [setFieldValue, values.symbols, data]);
};
