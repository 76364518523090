import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { PopoverPosition } from 'react-tiny-popover';

import { useOutsideClick } from '@root/shared/hooks/use-outside-click';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  positions?: PopoverPosition[];
  wrapperClassName?: string;
  keepOnMouseLeave?: boolean;
  arrowSize?: number;
  padding?: number;
  inline?: boolean;
  showOnClick?: boolean;
};

export const Container = styled.div`
  background: linear-gradient(0deg, rgba(252, 252, 252, 0.1), rgba(252, 252, 252, 0.1)), #202020;
  box-shadow: 0 10px 24px rgba(12, 12, 12, 0.6);
  top: 110%;
  right: 0;
`;

export const MarketplaceActionsTooltip: FC<Props> = ({ children, content, wrapperClassName = '', showOnClick = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef, () => {
    setIsDropdownOpen(false);
  });

  const open = () => setIsDropdownOpen(true);

  return (
    <div className='relative' ref={wrapperRef}>
      <div className={clsx('cursor-pointer relative', wrapperClassName)} onMouseEnter={showOnClick ? undefined : open} onClick={showOnClick ? open : undefined}>
        {children}
      </div>
      {isDropdownOpen && <Container className='absolute rounded px-6 py-4 z-50 flex justify-end items-center'>{content}</Container>}
    </div>
  );
};
