import { GET_ACCOUNTS } from '@root/modules/accounts/queries/get-accounts.query';
import * as AccountsRouter from '@root/modules/accounts/router';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';

export const AccountsModule = {
  router: AccountsRouter.Router,
  slice: accountsSlice,
  queries: {
    GET_ACCOUNTS: GET_ACCOUNTS,
  },
};

export type { IAccount } from '@root/modules/accounts/types/account';
