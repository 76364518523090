import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type StopExpertServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const stopExpertService = async (expertId: string): Promise<StopExpertServiceResponse> => {
  try {
    await fetcher.post(`/experts/${expertId}/stop`, undefined, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
