import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { updateAccountService } from '@root/modules/accounts/services/update-account.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useCheckRunningExpert } from '@root/modules/experts/hooks/use-check-running-expert';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';

export const useSaveRemappedSymbols = () => {
  const userId = useSelector(authSelector.userId);
  const { data: accounts, isLoading, refetch, dataUpdatedAt } = useGetAccounts();
  const { data: experts } = useGetExperts();
  const { stopRunningExperts, runStoppedExperts, getRunningExpertsByAccountId } = useCheckRunningExpert();

  const handleSaveRemappedSymbols = useCallback(
    async ({ accountId, symbols, proxyServerId }) => {
      const account = accounts?.find((item) => item.id === accountId);

      const result = await updateAccountService({
        symbolsMapping: symbols,
        userId: userId as string,
        accountId: accountId as string,
        // TODO: Add these fields to the UpdateAccountDto if we return back to the old way of updating accounts
        brokerServerId: '',
        serviceAccountId: '',
        proxyServerId,
        maxDrawDownLimit: account?.maxDrawDownLimit || null,
      });

      if (result.status === 200) {
        const { data } = await refetch();

        const account = accounts?.find((item) => item.id === accountId);

        if (account?.isSignedIn) {
          const runningExperts = getRunningExpertsByAccountId(accountId);

          if (runningExperts?.length) {
            await stopRunningExperts('account', accountId);
            await runStoppedExperts(
              runningExperts.map((expert) => expert.id),
              data,
            );
          }
        }
      }

      return result;
    },
    [userId, accounts, experts, isLoading, dataUpdatedAt, stopRunningExperts, runStoppedExperts],
  );

  return { handleSaveRemappedSymbols };
};
