import warningImg from '@root/assets/images/cases/warning.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';

import { FC } from 'react';

type Props = ModalProps & {
  loading?: boolean;
};

export const LeaveModal: FC<Props> = ({ isOpen, loading, onOk, onCancel }) => {
  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} footer={null}>
      <div className='flex flex-col items-center'>
        <div className='w-16 h-16 mb-8'>
          <Image src={warningImg} width={220} height={220} alt='warning' />
        </div>
        <Title level={5} className='font-bold text-center mb-8'>
          {t('leaveQuestion')}
        </Title>
        <Text size='sm' className='mb-5'>
          {t('loseChanges')}
        </Text>
        <div className='w-full flex justify-between items-center'>
          <Button onClick={onCancel} variant='info' outlined>
            {t('no')}
          </Button>
          <Button loading={loading} onClick={onOk}>
            {t('yes')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
