import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const CollectionIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 16 16' stroke='currentColor' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.666992' y='6.66602' width='14.6667' height='8' rx='2' strokeWidth='2' />
      <path d='M12.6663 6.66602V5.66602C12.6663 4.56145 11.7709 3.66602 10.6663 3.66602H5.33301C4.22844 3.66602 3.33301 4.56145 3.33301 5.66602V6.66602' strokeWidth='2' />
      <path d='M10.6663 3.66732V3.33398C10.6663 2.22942 9.77091 1.33398 8.66634 1.33398H7.33301C6.22844 1.33398 5.33301 2.22942 5.33301 3.33398V3.66732' strokeWidth='2' />
    </svg>
  );
};
