import { SearchIcon } from '@root/shared/icons/search-icon';
import { Input, InputProps } from '@root/shared/ui/form';
import clsx from 'clsx';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

type Props = InputProps;

export const SearchInput: FC<Props> = ({ className, ...props }) => {
  const [active, setActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = useCallback(() => {
    setActive(true);
  }, []);

  const handleFocusout = useCallback(() => {
    if (!props?.value) {
      setActive(false);
    }
  }, [props.value]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('focusin', handleFocus);
      inputRef.current.addEventListener('focusout', handleFocusout);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focusin', handleFocus);
        inputRef.current.removeEventListener('focusout', handleFocusout);
      }
    };
  }, [inputRef, handleFocus, handleFocusout]);

  return (
    <Input
      prefix={
        <div className='relative -left-1 cursor-pointer' onClick={() => inputRef?.current?.focus()}>
          <SearchIcon width={24} height={24} />
        </div>
      }
      size='small'
      className={clsx(
        'pl-14 transition-transform ease-in duration-700',
        { 'w-full ': active || !!inputRef?.current?.value, 'w-14 px-0': !active && !inputRef?.current?.value },
        className,
      )}
      onMouseEnter={handleFocus}
      onMouseLeave={handleFocusout}
      {...props}
      ref={inputRef}
    />
  );
};
