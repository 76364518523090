import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderOptions } from '@root/modules/experts/components/order-options';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { useExpertPartialEdit } from '@root/modules/experts/hooks/use-expert-partial-edit';
import { IExpert } from '@root/modules/experts/types/expert';
import { Text } from '@root/shared/ui/typography';

import { EditableSettingsTitle } from './editable-settings-title';


type Props = {
  expert: IExpert;
  editable?: boolean;
};

export const OrderOptionsSettings: FC<Props> = ({ editable = true }) => {
  const { t } = useTranslation('forex-experts');
  const { values } = useFormikContext<CreateExpertDto>();
  const { editing, loading, saveSettings, cancelChanges, setEditing } = useExpertPartialEdit(CreateExpertStepKey.OrderOptions);

  return (
    <div>
      <EditableSettingsTitle
        editDisabled={false}
        title={t('steps.orderOptions.title')}
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        saveSettings={saveSettings}
        cancelChanges={cancelChanges}
        section={CreateExpertStepKey.OrderOptions}
        editable={editable}
      />
      {editing ? (
        <div className='w-full px-4 py-3 rounded bg-grayscale-700'>
          <OrderOptions isPartialEdit />
        </div>
      ) : (
        <div className='w-full px-4 py-3 rounded flex flex-wrap justify-start items-center gap-1 bg-grayscale-700'>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.orderDirection.title')}
            </Text>
            <Text size='base' className='text-right'>
              {t('fields.orderDirection.options.' + values.orderDirection?.toLowerCase())}
            </Text>
          </div>
          <div className='w-full flex justify-between flex-wrap gap-x-4 items-center'>
            <Text size='base' className='text-gray-500 flex-shrink-0'>
              {t('fields.orderType.title')}
            </Text>
            <Text size='base' className='text-right'>
              {t('fields.orderType.options.' + values.orderType?.toLowerCase())}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
