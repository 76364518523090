export enum ChartType {
  Profit = 'profit',
  Growth = 'growth',
}

export const customLegendCheckboxOn =
  '<svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<g id="Property 1=on">\n' +
  '<g id="Border">\n' +
  '<rect id="Rectangle 3" x="10" y="9" width="16" height="16" rx="3.5" stroke="#9A9A9A"/>\n' +
  '</g>\n' +
  '<g id="Group" filter="url(#filter0_d_6959_54573)">\n' +
  '<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M18.6737 18.8864L13.6953 13.8074L12 15.5247L18.6737 22.3333L33 7.71738L31.3167 6L18.6737 18.8864Z" fill="#50BBFF"/>\n' +
  '<mask id="mask0_6959_54573" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="6" width="21" height="17">\n' +
  '<path id="Shape_2" fill-rule="evenodd" clip-rule="evenodd" d="M18.6737 18.8864L13.6953 13.8074L12 15.5247L18.6737 22.3333L33 7.71738L31.3167 6L18.6737 18.8864Z" fill="white"/>\n' +
  '</mask>\n' +
  '<g mask="url(#mask0_6959_54573)">\n' +
  '</g>\n' +
  '</g>\n' +
  '</g>\n' +
  '<defs>\n' +
  '<filter id="filter0_d_6959_54573" x="0" y="0" width="39" height="34.3334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
  '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
  '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
  '<feOffset dx="-2" dy="2"/>\n' +
  '<feGaussianBlur stdDeviation="3"/>\n' +
  '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.128567 0"/>\n' +
  '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6959_54573"/>\n' +
  '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6959_54573" result="shape"/>\n' +
  '</filter>\n' +
  '</defs>\n' +
  '</svg>';

export const customLegendCheckboxOff =
  '<svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<g id="Property 1=on">\n' +
  '<g id="Border">\n' +
  '<rect id="Rectangle 3" x="10" y="9" width="16" height="16" rx="3.5" stroke="#9A9A9A"/>\n' +
  '</g>\n' +
  '<g id="Group" filter="url(#filter0_d_6959_54573)">\n' +
  // '<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M18.6737 18.8864L13.6953 13.8074L12 15.5247L18.6737 22.3333L33 7.71738L31.3167 6L18.6737 18.8864Z" fill="#50BBFF"/>\n' +
  // '<mask id="mask0_6959_54573" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="6" width="21" height="17">\n' +
  // '<path id="Shape_2" fill-rule="evenodd" clip-rule="evenodd" d="M18.6737 18.8864L13.6953 13.8074L12 15.5247L18.6737 22.3333L33 7.71738L31.3167 6L18.6737 18.8864Z" fill="white"/>\n' +
  '</mask>\n' +
  '<g mask="url(#mask0_6959_54573)">\n' +
  '</g>\n' +
  '</g>\n' +
  '</g>\n' +
  '<defs>\n' +
  '<filter id="filter0_d_6959_54573" x="0" y="0" width="39" height="34.3334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
  '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
  '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
  '<feOffset dx="-2" dy="2"/>\n' +
  '<feGaussianBlur stdDeviation="3"/>\n' +
  '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.128567 0"/>\n' +
  '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6959_54573"/>\n' +
  '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6959_54573" result="shape"/>\n' +
  '</filter>\n' +
  '</defs>\n' +
  '</svg>';
