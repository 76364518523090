import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';
import { AxiosError } from 'axios';
import { MarketplaceTradeIdeaProviderMapper } from '../mappers/marketplace-trade-idea-provider.mapper';
import { MarketPlaceTradeIdeaProvider } from '../types/marketplace-trade-idea-provider';

export type MarketplaceTradeIdeaProviderResponseData = MarketPlaceTradeIdeaProvider;

export type GetMarketplaceTradeIdeaProviderServiceResponse = IHttpResponse<200, MarketplaceTradeIdeaProviderResponseData> | IHttpResponse<400, string>;

export const getMarketplaceTradeIdeaProviderService = async (id: Id): Promise<GetMarketplaceTradeIdeaProviderServiceResponse> => {
  try {
    const response = await fetcher(`/trade-ideas/providers/${id}`);

    return {
      status: 200,
      payload: MarketplaceTradeIdeaProviderMapper.toDomain(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
