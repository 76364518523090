import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AccountStatus } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { ExpertStatus } from '@root/modules/experts/enums';
import { authSelector } from '@root/shared-files/modules/auth/store';

import { IExpert } from '../types/expert';

export const useGetExpertStatus = (data: IExpert) => {
  const isEnabled = data?.isEnabled;
  const { data: accounts } = useGetAccounts();
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const selectedAccount = useMemo(() => accounts?.find((account) => account.id === data?.accountId), [accounts, data?.accountId]);
  const isSubscriptionExpired = useMemo(
    () => !subscriptionInfo?.roles?.some((role) => role.includes('forex') && !role?.toLowerCase()?.includes('post subscription')),
    [subscriptionInfo],
  );

  const status = useMemo(() => {
    if (!isEnabled) {
      return {
        text: ExpertStatus.Stopped,
      };
    }

    if (isSubscriptionExpired) {
      return {
        text: ExpertStatus.Paused,
        explanation: 'statuses.expiredHint',
      };
    }

    if (isEnabled && selectedAccount?.status === AccountStatus.Disconnected) {
      return {
        text: ExpertStatus.Paused,
        explanation: 'statuses.pausedHint',
      };
    }

    const hasDDLimit =
      (!!data?.hitMaxDrawDownLimitUntil && new Date(data?.hitMaxDrawDownLimitUntil).getTime() > new Date().getTime()) ||
      (!!selectedAccount?.hitMaxDrawDownLimitUntil && new Date(selectedAccount?.hitMaxDrawDownLimitUntil).getTime() > new Date().getTime());

    if (hasDDLimit) {
      return {
        text: ExpertStatus.DDLimit,
        explanation: 'statuses.ddLimitHint',
      };
    }

    return {
      text: ExpertStatus.Running,
    };
  }, [isEnabled, isSubscriptionExpired, selectedAccount?.status, selectedAccount?.hitMaxDrawDownLimitUntil, data?.hitMaxDrawDownLimitUntil]);

  return {
    isEnabled,
    status,
    isPaused: status.text === ExpertStatus.Paused,
  };
};
