import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const PositionSizeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M16.0016 12.0002H10.7495" stroke='currentColor'/>
      <path d="M16.0016 15.5041H10.7495" stroke='currentColor'/>
      <path d="M16.0016 8.50021H10.7495" stroke='currentColor'/>
      <rect x="2.99622" y="2.99609" width="18.0075" height="18.0075" rx="5" stroke='currentColor'/>
      <path
        d="M7.99835 11.8762C7.92984 11.8767 7.87475 11.9327 7.87531 12.0012C7.87586 12.0697 7.93184 12.1248 8.00035 12.1243C8.06886 12.1237 8.12395 12.0677 8.1234 11.9992C8.12029 11.9318 8.06579 11.8782 7.99835 11.8762"
        stroke='currentColor'/>
      <path
        d="M7.9983 15.3801C7.93034 15.3817 7.87636 15.4378 7.87727 15.5058C7.87818 15.5737 7.93363 15.6283 8.00162 15.6281C8.06961 15.628 8.12479 15.5731 8.12536 15.5051C8.12323 15.4362 8.06727 15.3811 7.9983 15.3801"
        stroke='currentColor'/>
      <path
        d="M7.9983 8.37617C7.93034 8.37782 7.87636 8.43387 7.87727 8.50185C7.87818 8.56983 7.93363 8.62441 8.00162 8.62424C8.06961 8.62407 8.12479 8.56921 8.12536 8.50123C8.12323 8.43229 8.06727 8.37721 7.9983 8.37617"
        stroke='currentColor'/>
    </svg>
)
  ;
};
